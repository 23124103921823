import { createContext, useCallback, useContext } from 'react';
import { useAppApi } from 'hooks/useAppApi';
import useRequest from 'hooks/useRequest';

const PlantillaTextoContext = createContext(null);

export const PlantillaTextoProvider = ({ children }) => {
  const map = useCallback((result) => {
    return result;
  }, []);

  const { data, isLoading, error, hasCalled, call } = useAppApi({
    baseUrl: '/plantillas-texto',
    mapResults: map,
  });

  const { request, isLoading: requestLoading } = useRequest();

  const createPlantillaTexto = async (body) => {
    return await request('/plantillas-texto', 'POST', body);
  };

  const updatePlantillaTexto = async (id, body) => {
    return await request(`/plantillas-texto/${id}`, 'PUT', body);
  };

  const deletePlantillaTexto = async (id) => {
    return await request(`/plantillas-texto/${id}`, 'DELETE');
  };

  const getPlantillasByColeccionItemId = useCallback(
    async (coleccionItemId) => {
      const result = await request(
        `/plantillas-texto/coleccion-item/${coleccionItemId}`,
        'GET'
      );
      return result;
    },
    [request]
  );

  const getPlantillasByTipoDocumento = async (tipoDocumento) => {
    return await request(
      `/plantillas-texto/tipo-documento/${tipoDocumento}`,
      'GET'
    );
  };

  return (
    <PlantillaTextoContext.Provider
      value={{
        call,
        isLoading: isLoading || requestLoading,
        error,
        hasCalled,
        data,
        createPlantillaTexto,
        updatePlantillaTexto,
        deletePlantillaTexto,
        getPlantillasByColeccionItemId,
        getPlantillasByTipoDocumento,
      }}
    >
      {children}
    </PlantillaTextoContext.Provider>
  );
};

export const usePlantillaTexto = () => useContext(PlantillaTextoContext);
