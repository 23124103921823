import { Button, Form, Input, notification } from 'antd';
import React, { useEffect } from 'react';
import TransferClientsForm from './Item/TransferClientsForm';
import { useForm } from 'antd/es/form/Form';
import { useClientes } from 'providers/ClientesProvider';
import { useAdministradoresProvider } from 'providers/Administradores/Administradores';

const AdministradorClientsForm = ({ initialValues, refresh }) => {
  const [form] = useForm();
  const { update, updateLoading } = useAdministradoresProvider();

  const { call } = useClientes();
  useEffect(() => {
    call();
  }, [call]);
  // console.log(administrador);

  const onSubmit = async () => {
    try {
      const values = form.getFieldsValue();
      const response = await update(values);
      notification.success({
        message: 'Modificación exitosa',
      });
      // form.resetFields();
      refresh();
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Error al modificar',
      });
    }
  };
  return (
    <Form form={form} onFinish={onSubmit} initialValues={initialValues}>
      <Form.Item name="adminId" hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item
        name="clientes"
        rules={[
          {
            required: true,
            message: 'Debe seleccionar por lo menos un cliente',
          },
        ]}
        className="overflow-auto"
      >
        <TransferClientsForm />
      </Form.Item>
      <Form.Item>
        <Button type="primary" loading={updateLoading} block htmlType="submit">
          Modificar Clientes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AdministradorClientsForm;
