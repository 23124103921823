import { mesesData } from 'assets/data/meses';
import React from 'react';

// Importa el arreglo de meses

const ActividadFechasList = (props) => {
  const { dataSource } = props;

  // Función para obtener el nombre del mes a partir del número
  const getMesNombre = (numero) => {
    const mesObj = mesesData.find((mes) => mes.value === numero);
    return mesObj ? mesObj.label : 'Desconocido';
  };

  return (
    <>
      <p className="font-semibold mb-2">Meses asignados:</p>
      {dataSource.length > 0 ? (
        <div className="flex flex-wrap justify-center">
          {dataSource?.map((item) => (
            <span
              key={item.id}
              className="px-2 py-1 m-1 bg-blue-500 text-white rounded-md"
            >
              {getMesNombre(item.mes)}
            </span>
          ))}
        </div>
      ) : (
        <span className="px-2 py-1 m-1 bg-red-500 text-white rounded-md">
          No asignados
        </span>
      )}
    </>
  );
};

export default ActividadFechasList;
