import React from 'react';
import { Button, notification } from 'antd';
import notificationApi from '../../utils/notificationApi';
import { deleteVoucherApi } from '../../api/attentionVoucher';
import Dowload from '../../PDF/AttentionVoucher/Dowload/Dowload';

const toggleActivatedVoucherApi = async (voucherId, refreshVouchers) => {
  const result = await deleteVoucherApi(voucherId);
  if (notificationApi(result)) {
    refreshVouchers(true);
  }
};

/**
 * Función que alterna el estado de un comprobante de atención
 * @param {Number} voucherId - Id del voucher a modificar su estado
 */

export const toggleActivatedVoucher = (voucherId, refreshVouchers) => {
  notification.open({
    message: `¿Seguro que desea desactivar el presupuesto ${voucherId}?`,
    btn: (
      <Button
        danger
        type="primary"
        onClick={() => toggleActivatedVoucherApi(voucherId, refreshVouchers)}
      >
        Desactivar
      </Button>
    ),
  });
};
/**
 * Dispara una notificación con un botón que permite descargar el comprobante
 * @param {Object} user - Datos del usuario loggeado
 * @param {Object} voucher - Datos del voucher a descargar
 */
export const showDownloadVoucherNotification = (user, voucher) => {
  notification.open({
    message: 'Descargar Presupuesto',
    btn: <Dowload user={user} voucher={voucher} />,
  });
};
