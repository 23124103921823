import { Badge, Calendar, List } from 'antd';
import { useCalendarActions } from 'providers/CalendarSolc/CalendarActionsProvider';
import React from 'react';
import es from 'antd/es/locale/es_ES';
import { useSolicitudes } from 'providers/SolicitudesProvider';

const stateColors = {
  SIN_ASIGNAR: 'gray',
  ASIGNADA: 'blue',
  EN_CURSO: 'orange',
  FINALIZADA: 'green',
  ENVIADA: 'purple',
  CANCELADA: 'red',
};

const CalendarCell = (date) => {
  const { data } = useSolicitudes();

  return (
    <ul className="list-none m-0 p-0 overflow-hidden">
      {data
        ?.filter((item) => item.fecha_asignacion === date.format('YYYY-MM-DD'))
        ?.map((item) => (
          <li key={item.id} className="overflow-hidden">
            <Badge
              color={stateColors[item.status] || 'default'}
              text={item.Cliente?.name}
            />
          </li>
        ))}
    </ul>
  );
};

const CalendarSolc = () => {
  const { handleSelectDay } = useCalendarActions();
  const onSelectDay = (day) => {
    handleSelectDay(day);
  };

  return (
    <Calendar cellRender={CalendarCell} onSelect={onSelectDay} locale={es} />
  );
};

export default CalendarSolc;
