import React, { useEffect, useState } from 'react';
import { Form, Collapse, Button, Space, Select, Input, Card } from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';
import FieldComponent from './FieldComponent'; // Importar el FieldComponent
import types from '../types';
import { prefabricParts } from '../prefabricParts'; // Importar los tipos de campos disponibles

const { Panel } = Collapse;

// todo: Se debe bloquear la opción de agregar más de un introduction en el composer

const Part = ({
  field,
  form,
  move,
  remove,
  listIndex,
  length,
  name: completeName,
}) => {
  const [newFieldType, setNewFieldType] = useState('section');
  const values = form.getFieldValue(['fields', completeName[0]]);
  const [bloqued, setBloqued] = useState([]);
  useEffect(() => {
    setBloqued(values?.bloqued ?? []);
  }, [values]);
  // Manejar el cambio en el Select de 'prefabric'
  const handlePrefabricChange = (value) => {
    const part = prefabricParts.find((p) => p.value === value);
    const { initialValues, bloqued } = part;

    if (initialValues) {
      form.setFields([
        {
          name: ['fields', completeName[0], 'children'],
          value: initialValues,
        },
        {
          name: ['fields', completeName[0], 'bloqued'],
          value: bloqued,
        },
      ]);
      setBloqued(bloqued ?? []);
    } else {
      // Si no hay initialValues, limpiar los children
      form.setFields([
        {
          name: ['fields', completeName[0], 'children'],
          value: [],
        },
        {
          name: ['fields', completeName[0], 'bloqued'],
          value: [],
        },
      ]);
      setBloqued([]);
    }
  };

  return (
    <Collapse defaultActiveKey={['1']} style={{ marginBottom: '16px' }}>
      <Panel
        header={
          <div className="flex justify-between align-middle items-center">
            <Form.Item
              name={[field.name, 'title']}
              rules={[{ required: true, message: 'Ingrese un título' }]}
              style={{ marginBottom: 0 }}
              label="Sección"
            >
              <Input
                placeholder="Título de la Parte"
                style={{ width: '300px' }}
              />
            </Form.Item>
            <Form.Item
              name={[field.name, 'prefabric']}
              initialValue="custom"
              label="Tipo Sección"
              style={{ marginBottom: 0 }}
            >
              <Select
                style={{ width: '300px' }}
                options={prefabricParts}
                onChange={handlePrefabricChange} // Agregar el onChange aquí
              />
            </Form.Item>
            <Form.Item name={[field.name, 'bloqued']} hidden>
              <Select placeholder="Seleccione el tipo de lista" />
            </Form.Item>
            <Space>
              <Button
                icon={<ArrowUpOutlined />}
                onClick={() => move(listIndex, listIndex - 1)}
                disabled={listIndex === 0}
                type="link"
                size="small"
              >
                Subir
              </Button>
              <Button
                icon={<ArrowDownOutlined />}
                onClick={() => move(listIndex, listIndex + 1)}
                disabled={listIndex === length - 1}
                type="link"
                size="small"
              >
                Bajar
              </Button>
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={() => remove(field.name)}
                type="link"
                size="small"
              >
                Eliminar
              </Button>
            </Space>
          </div>
        }
        key="1"
        extra={
          <Form.Item
            name={[field.name, 'type']}
            initialValue="part"
            noStyle
            hidden
          >
            <Input />
          </Form.Item>
        }
      >
        {/* Selector para agregar nuevos campos dentro de la Parte */}
        <Card size="small" style={{ marginBottom: '16px' }}>
          <Space>
            <Select
              value={newFieldType}
              onChange={(value) => setNewFieldType(value)}
              placeholder="Seleccione un tipo"
              style={{ width: '200px' }}
              disabled={bloqued.includes('add')}
            >
              {types
                .filter((t) => t.value !== 'part') // Excluir 'part' para evitar anidación
                .map((type) => (
                  <Select.Option key={type.value} value={type.value}>
                    {type.label}
                  </Select.Option>
                ))}
            </Select>
            <Button
              onClick={() => {
                const childrenPath = ['fields', completeName[0], 'children'];
                const currentChildren = form.getFieldValue(childrenPath) || [];
                form.setFields([
                  {
                    name: childrenPath,
                    value: [...currentChildren, { type: newFieldType }],
                  },
                ]);
              }}
              icon={<PlusOutlined />}
              type="primary"
              disabled={bloqued.includes('add')}
            >
              Agregar
            </Button>
          </Space>
        </Card>

        {/* Lista de campos internos */}
        <Form.List name={[field.name, 'children']}>
          {(fieldsInner, { remove: removeInner, move: moveInner }) => (
            <>
              {fieldsInner.map((fieldInner, indexInner) => (
                <Card
                  key={fieldInner.key} // Clave única
                  size="small"
                  type="inner"
                  title={`Elemento ${indexInner + 1}`}
                  extra={
                    <Space>
                      <Button
                        icon={<ArrowUpOutlined />}
                        onClick={() => moveInner(indexInner, indexInner - 1)}
                        disabled={indexInner === 0}
                        type="link"
                        size="small"
                      >
                        Subir
                      </Button>
                      <Button
                        icon={<ArrowDownOutlined />}
                        onClick={() => moveInner(indexInner, indexInner + 1)}
                        disabled={indexInner === fieldsInner.length - 1}
                        type="link"
                        size="small"
                      >
                        Bajar
                      </Button>
                      <Button
                        icon={<DeleteOutlined />}
                        danger
                        onClick={() => removeInner(fieldInner.name)}
                        type="link"
                        size="small"
                        disabled={bloqued.includes('remove')}
                      >
                        Eliminar
                      </Button>
                    </Space>
                  }
                >
                  {/* Campo oculto para 'type' */}
                  <Form.Item
                    name={[fieldInner.name, 'type']}
                    initialValue={fieldInner.type}
                    noStyle
                    rules={[{ required: true, message: 'Seleccione un tipo' }]}
                  >
                    {/* No se necesita renderizar nada aquí */}
                  </Form.Item>

                  {/* Campo para renderizar el componente dinámico basado en 'type' */}
                  <Form.Item
                    dependencies={[
                      `fields.${completeName[0]}.children.${fieldInner.name}.type`,
                    ]}
                    noStyle
                  >
                    {() => {
                      const type = form.getFieldValue([
                        'fields',
                        completeName[0],
                        'children',
                        fieldInner.name,
                        'type',
                      ]);
                      return (
                        <FieldComponent
                          field={fieldInner}
                          form={form}
                          type={type}
                          completeName={[
                            'fields',
                            completeName[0],
                            'children',
                            fieldInner.name,
                          ]}
                        />
                      );
                    }}
                  </Form.Item>
                </Card>
              ))}
            </>
          )}
        </Form.List>
      </Panel>
    </Collapse>
  );
};

export default Part;
