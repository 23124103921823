import { StyleSheet } from '@react-pdf/renderer';
import { fontFamily, textFontSize } from 'components/PDF2/assets';

export default StyleSheet.create({
  normal: {
    fontSize: textFontSize,
    fontFamily: fontFamily,
    // textAlign: 'justify',
    // textIndent: '25pt',
  },
  container: {},
});
