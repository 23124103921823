import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  observationContainer: {
    flexDirection: 'column',
  },
  title: {
    fontSize: 12,
    borderBottom: '0px',
    fontFamily: 'Helvetica-Bold',
    marginBottom: 15,
  },
  content: {
    fontSize: 8,
    textDecoration: 'underline',
  },
});
function VoucherObservation(props) {
  const { observations } = props;
  return (
    <View style={styles.observationContainer}>
      <Text style={styles.title}>Observaciones:</Text>
      <Text style={styles.content}>{observations}</Text>
    </View>
  );
}

VoucherObservation.propTypes = {
  observations: PropTypes.string.isRequired,
};

export default VoucherObservation;
