import { Card } from 'antd';
import React from 'react';

const CertificacionItemList = (props) => {
  const { items } = props;
  return (
    <div>
      {items?.map((item) => (
        <Card key={item.idItem} className="mt-2">
          <h3>ID: {item.idItem}</h3>
          <p>
            <span className="font-w-title mr-1">Actividad: </span>
            {item.valorActividad}
          </p>
          <p>
            <span className="font-w-title mr-1">Resultado: </span>
            {item.valorItem}
          </p>
        </Card>
      ))}
    </div>
  );
};

export default CertificacionItemList;
