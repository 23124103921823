import React from 'react';
import P from './P';

const H1 = (props) => {
  const { children } = props;
  return (
    <P
      style={{
        fontSize: '14pt',
        fontFamily: 'Times-Bold',
        textTransform: 'uppercase',
        margin: '3px 0px',
      }}
    >
      {children}
    </P>
  );
};

export default H1;
