import { Text, View } from '@react-pdf/renderer';
import { string } from 'prop-types';
import React from 'react';
import Title2Style from './Title2.style';

function Title2(props) {
  const { children, margin } = props;
  if (margin) {
    Title2Style.container.margin = margin;
  }

  return (
    <View style={Title2Style.container}>
      <Text style={Title2Style.title2}>{children}</Text>
    </View>
  );
}
Title2.propTypes = {
  children: string.isRequired,
};

export default Title2;
