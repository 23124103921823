import AdminUserForm from 'components/forms/Administradores/AdminUserForm';
import { useUserActions } from 'providers/Users/UserActions';

const {
  EyeOutlined,
  UserOutlined,
} = require('@ant-design/icons');
const { Modal, List, Button, Space, Avatar } = require('antd');
const {
  default: AdministradorClientsForm,
} = require('components/forms/Administradores/AdministradorClientsForm');
const { useState, useMemo, useEffect } = require('react');

const StatusButton = ({ user, refresh }) => {
  const { active, rut } = user;
  const [localActive, setLocalActive] = useState(active);
  const { toggleStatus, toggleLoading } = useUserActions();
  useEffect(() => {
    setLocalActive(active);
  }, [active]);

  const badgeColor = useMemo(() => {
    return localActive ? 'bg-green-500' : 'bg-red-500';
  }, [localActive]);

  const handleStatus = async () => {
    setLocalActive(!localActive);
    try {
      await toggleStatus({ rut });
      refresh();
    } catch (error) {
      console.error('Error al cambiar el estado', error);
      setLocalActive(active);
    }
  };

  return (
    <Button type="text" onClick={handleStatus} loading={toggleLoading}>
      <Space>
        <div className={`h-2 w-2 rounded-full ${badgeColor}`}></div>
        {localActive ? 'Activo' : 'Inactivo'}{' '}
        {/* Usa el estado local para el texto */}
        {/* <EditOutlined /> */}
      </Space>
    </Button>
  );
};
const AdminItemList = ({ data, refresh }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const {
    User: { fullname, type, rut, email },
    AdminClientes,
    createdAt,
  } = data;

  const formattedDate = useMemo(() => {
    return new Date(createdAt).toLocaleDateString();
  }, [createdAt]);

  const [openUser, setOpenUser] = useState(false);

  const handleOpenUser = (_open) => {
    setOpenUser(_open ?? !openUser);
  };
  return (
    <>
      <Modal
        title="Clientes"
        footer={false}
        onCancel={() => handleOpen(false)}
        open={open}
        width={700}
      >
        <AdministradorClientsForm
          initialValues={{
            clientes: AdminClientes.map((element) => element.idCliente),
            adminId: data.id,
          }}
          refresh={() => {
            refresh();
            handleOpen();
          }}
        />
      </Modal>
      <Modal
        open={openUser}
        onCancel={() => handleOpenUser(false)}
        footer={false}
        title="Administrador"
      >
        <AdminUserForm
          initialValues={{
            rut: rut,
            fullname: fullname,
            email: email,
            adminId: data.id
          }}
        />
      </Modal>
      <List.Item
        className="border-b border-gray-200 hover:bg-gray-100 !px-2 "
        actions={[
          <Button
            type="text"
            key="clientes"
            icon={<EyeOutlined />}
            onClick={() => handleOpen(true)}
          >
            Clientes
          </Button>,
          <StatusButton
            key={data.id}
            user={data.User}
            adminId={data.id}
            refresh={refresh}
          />,
        ]}
      >
        <List.Item.Meta
          // className="flex flex-row align-bottom justify-center"
          avatar={<Avatar icon={<UserOutlined />} className="mt-3" />}
          title={
            <Button
              type="link"
              className="capitalize"
              onClick={() => handleOpenUser(true)}
            >
              {fullname}
            </Button>
          }
          description={
            <span className="capitalize">{`Tipo: ${type} - Ingresado: ${formattedDate} - ${rut}`}</span>
          }
        />
      </List.Item>
    </>
  );
};

export default AdminItemList;
