import {
  CheckOutlined,
  DownloadOutlined,
  EditFilled,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Modal,
  Skeleton,
  Space,
  Table,
  notification,
} from 'antd';
import ClientConfigData from 'components/ClientConfigData';
import AscensorForm, {
  addAscensor,
  deleteAscensor,
  editAscensor,
} from 'components/forms/AscensorForm';
import ClientForm, { editClient } from 'components/forms/ClientForm';
import EliminarForm from 'components/forms/EliminarForm';
import AscensoresList from 'components/lists/AscensoresList';
import DataClientList from 'components/lists/DataClientList';
import { useGetToApi } from 'hooks/useApi/useGetAxios';
import { useClienteMantenciones } from 'providers/ClienteMantencionesProvider';
import { useCliente } from 'providers/Clientes/ClienteProvider';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { useSolicitudesMantencion } from 'providers/SolicitudesMantencionProvider';
import { useObservaciones } from 'providers/ObservacionesProvider';
import ObservacionForm from 'components/forms/ObservacionForm';
import { useObservacionAcciones } from 'providers/ObservacionAccionesProvider';

const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};

const DatosCliente = () => {
  const { id } = useParams();
  const [modalProps, setModalProps] = useState(defaultModalProps);
  let [client, isLoadingClient, callClient] = useGetToApi(`/client/one/${id}`, {
    initialValues: {
      data: [],
    },
  });
  let [ascensores, isLoadingAscensores, callAscensores] = useGetToApi(
    `/ascensor/cliente/${id}`,
    {
      initialValues: {
        data: [],
      },
    }
  );
  const { callConfig, dataConfig, isLoadingConfig, hasCalledConfig } =
    useCliente();

  useEffect(() => {
    if (!hasCalledConfig) {
      callConfig(id);
    }
  }, [callConfig, id, hasCalledConfig]);

  const successFinishClient = () => {
    callClient();
    setModalProps(defaultModalProps);
  };
  const successFinishAscensor = () => {
    callAscensores();
    setModalProps(defaultModalProps);
  };

  const openEditClient = async (data) => {
    setModalProps({
      open: true,
      children: (
        <ClientForm
          buttonLabel="Editar"
          submitForm={(values) => editClient(values, id, successFinishClient)}
          formProps={{
            initialValues: data,
          }}
        />
      ),
      title: 'Editar Cliente',
    });
  };
  const openAddAscensor = (ascensor) => {
    setModalProps({
      open: true,
      title: 'Agregar Ascensor',
      children: (
        <AscensorForm
          buttonLabel="Agregar"
          submitForm={(values) =>
            addAscensor(values, id, successFinishAscensor)
          }
          recordValues={ascensor}
        />
      ),
    });
  };

  const openEditAscensor = (ascensor) => {
    setModalProps({
      open: true,
      title: `Editar Ascensor ${ascensor.nombre}`,
      children: (
        <AscensorForm
          buttonLabel="Editar"
          submitForm={(values) =>
            editAscensor(values, ascensor.id, successFinishAscensor)
          }
          formProps={{
            initialValues: ascensor,
          }}
        />
      ),
    });
  };

  const openDeleteAscensor = (ascensor) => {
    setModalProps({
      open: true,
      title: `Eliminar Ascensor`,
      children: (
        <EliminarForm
          title={ascensor.nombre}
          id={ascensor.id}
          deleteRegister={(id) => deleteAscensor(id, successFinishAscensor)}
        />
      ),
    });
  };

  const openDuplicateAscensor = (ascensor) => {
    const { capacidad, id_cliente, marca, modelo, numero_serie, pisos } =
      ascensor;
    setModalProps({
      open: true,
      title: 'Agregar Ascensor',
      children: (
        <AscensorForm
          buttonLabel="Agregar"
          submitForm={(values) =>
            addAscensor(values, id, successFinishAscensor)
          }
          formProps={{
            initialValues: {
              capacidad,
              id_cliente,
              marca,
              modelo,
              numero_serie,
              pisos,
            },
          }}
          // recordValues={ascensor}
        />
      ),
    });
  };

  client = useMemo(() => {
    return client.data;
  }, [client.data]);
  ascensores = useMemo(() => {
    return ascensores.data;
  }, [ascensores.data]);

  useEffect(() => {
    if (id) {
      callClient();
      callAscensores();
    } else {
      notification.error({
        message: 'ID del cliente no proporcionado',
      });
    }
  }, [id, callClient, callAscensores]);

  const {
    call: callMantenciones,
    mantenciones,
    isLoading: isLoadingMantenciones,
    getIncidencias,
  } = useClienteMantenciones();
  const [actividadesConIncidencias, setActividadesConIncidencias] = useState(
    []
  );
  const [mantencionSeleccionada, setMantencionSeleccionada] = useState(null);

  useEffect(() => {
    if (mantencionSeleccionada) {
      setActividadesConIncidencias(getIncidencias(mantencionSeleccionada));
    }
  }, [mantencionSeleccionada, getIncidencias]);

  useEffect(() => {
    if (id) {
      callMantenciones(id);
    }
  }, [callMantenciones, id]);

  const { openDowloadMantencion } = useSolicitudesMantencion();
  const handleClickInSolc = (record) => {
    openDowloadMantencion({
      id_mantencion: record.id,
    });
  };

  const {
    callForClient: callObservaciones,
    dataForClient: observaciones,
    isLoading: isLoadingObservaciones,
  } = useObservaciones();

  useEffect(() => {
    if (id) {
      callObservaciones(id);
    }
  }, [callObservaciones, id]);
  const [openObservacion, setOpenObservacion] = useState(false);

  const onFinishObservacion = (response) => {
    callObservaciones(id);
    setOpenObservacion(false);
    setModalProps(defaultModalProps);
  };

  const { update, isLoadingUpdate } = useObservacionAcciones();

  const handleActiveOnservation = async (observation) => {
    await update(observation.id, {
      active: !observation.active,
    });
    callObservaciones(id);
  };

  const openEditObservation = (observation) => {
    setModalProps({
      open: true,
      title: `Editar Observación ${observation.id}`,
      children: (
        <ObservacionForm
          key={observation.id}
          onFinish={onFinishObservacion}
          initialValues={observation}
          observationId={observation.id}
        />
      ),
    });
  };
  return (
    <Skeleton loading={isLoadingClient}>
      <Modal
        title="Agregar observación"
        open={openObservacion}
        onCancel={() => setOpenObservacion(false)}
        footer={null}
      >
        <ObservacionForm
          onFinish={onFinishObservacion}
          initialValues={{
            id_cliente: id,
          }}
        />
      </Modal>
      <Badge.Ribbon
        text={client.active === true ? 'Activo' : 'Inactivo'}
        color={client.active === true ? 'green' : 'red'}
      >
        <div>
          <Card title={<h3>Datos de {client.name}</h3>} className="mb-4">
            <DataClientList data={client} openEditClient={openEditClient} />
          </Card>
          <ClientConfigData id={id} />

          <div className="flex flex-row space-x-2">
            <Card
              className="w-full "
              title={
                <div className="flex flex-col lg:flex-row items-center lg:justify-between w-full">
                  <h3>
                    Ascensores{' '}
                    <span className="hidden lg:inline-block">
                      de {client.name}
                    </span>{' '}
                  </h3>

                  <Button icon={<PlusOutlined />} onClick={openAddAscensor}>
                    Agregar
                  </Button>
                </div>
              }
            >
              <AscensoresList
                openEditClient={openEditClient}
                openEditAscensor={openEditAscensor}
                openDeleteAscensor={openDeleteAscensor}
                openDuplicateAscensor={openDuplicateAscensor}
                isLoading={isLoadingAscensores}
                nAscensores={client.nAscensores}
                ascensores={ascensores}
              />
            </Card>
            <Card
              className="w-full"
              title={<h3>Observaciones</h3>}
              extra={
                <Button
                  onClick={() => setOpenObservacion(true)}
                  icon={<PlusOutlined />}
                >
                  Agregar
                </Button>
              }
            >
              <Table
                dataSource={observaciones}
                isLoading={isLoadingObservaciones || isLoadingUpdate}
                rowKey={'id'}
                columns={[
                  {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.id - b.id,
                    showSorterTooltip: false,
                    width: 50,
                  },
                  {
                    title: 'Observación',
                    dataIndex: 'description',
                    key: 'description',
                    width: 500,
                  },
                  {
                    title: 'Tipo',
                    dataIndex: 'ObservationType',
                    key: 'ObservationType',
                    render: (ObservationType) =>
                      ObservationType?.name?.toUpperCase(),
                  },
                  {
                    title: 'Fecha',
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (date) => moment(date).format('YYYY-MM-DD'),
                    width: 200,
                  },
                  {
                    title: 'Estado',
                    dataIndex: 'active',
                    key: 'active',
                    filters: [
                      {
                        text: 'Activo',
                        value: 'activo',
                      },
                      {
                        text: 'Inactivo',
                        value: 'inactivo',
                      },
                    ],
                    onFilter: (value, record) => {
                      return value === 'activo'
                        ? record.active === true
                        : record.active === false;
                      // if(value === 'activo') {
                      //   return record.active === true;
                      // }
                      // else{
                      //   return record.active === false;
                      // }
                      // return record.active === Boolean(value);
                    },
                    defaultFilteredValue: ['activo'],
                    render: (active) => (active ? 'Activo' : 'Inactivo'),
                    width: 200,
                  },
                  {
                    title: 'Acciones',
                    dataIndex: 'actions',
                    width: 100,
                    fixed: 'right',
                    render: (_, record) => (
                      <Space>
                        <Button
                          onClick={() => openEditObservation(record)}
                          className="!border-blue-500"
                        >
                          <EditFilled className="text-blue-500" />
                        </Button>
                        <Button
                          className={` ${
                            record.active
                              ? '!border-green-500'
                              : '!border-red-500'
                          }`}
                          onClick={() => handleActiveOnservation(record)}
                        >
                          <CheckOutlined
                            className={`${
                              record.active ? 'text-green-500' : 'text-red-500'
                            }`}
                          />
                        </Button>
                      </Space>
                    ),
                  },
                ]}
              ></Table>
            </Card>
          </div>
          <div className="flex flex-row  space-x-2 mt-5">
            <Table
              className="w-full"
              title={() => <h3>Mantenciones</h3>}
              dataSource={mantenciones}
              rowSelection={{
                onChange: (selectedRowKeys) => {
                  setMantencionSeleccionada(selectedRowKeys.pop());
                },
                type: 'radio',
                hideSelectAll: true,
                selectedRowKeys: [mantencionSeleccionada],
              }}
              rowKey={'id'}
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  defaultSortOrder: 'descend',
                  sorter: (a, b) => a.id - b.id,
                  showSorterTooltip: false,
                  render: (id_mantencion) => (
                    <Link
                      to={`/admin/mantenciones/${id_mantencion}?only_edit=1`}
                    >
                      {'N° ' + id_mantencion}
                    </Link>
                  ),
                },
                {
                  title: 'Responsable',
                  dataIndex: 'Responsable',
                  key: 'responsable',
                  render: (responsable) => responsable?.fullname,
                },
                {
                  title: 'Observaciones',
                  dataIndex: 'observaciones',
                  key: 'observaciones',
                },
                {
                  title: 'Fecha de Creación',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
                },
                {
                  title: 'Última Actualización',
                  dataIndex: 'updatedAt',
                  key: 'updatedAt',
                  render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
                },
                {
                  title: 'Acciones',
                  dataIndex: 'actions',
                  width: 100,
                  fixed: 'right',
                  render: (_, record) => (
                    <div className="space-x-2">
                      <Button
                        onClick={() => handleClickInSolc(record)}
                        // className="w-20"
                        type="link"
                      >
                        <DownloadOutlined /> Descargar
                      </Button>
                    </div>
                  ),
                },
              ]}
            />
            <Table
              title={() => (
                <h3>
                  Incidencias de la mantención N° {mantencionSeleccionada}
                </h3>
              )}
              className="w-full"
              dataSource={actividadesConIncidencias}
              rowKey={'id'}
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'id',
                  defaultSortOrder: 'descend',
                  sorter: (a, b) => a.id - b.id,
                  showSorterTooltip: false,
                },
                {
                  title: 'Actividad',
                  dataIndex: 'Actividad',
                  key: 'Actividad',
                  render: (actividad) => actividad?.actividad,
                },

                {
                  title: 'Fecha',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
                },
              ]}
              expandable={{
                expandedRowRender: (record) => {
                  console.log(record, 'record');
                  return (
                    <Table
                      dataSource={record.Incidencias}
                      title={() => <h3>Incidencias</h3>}
                      rowKey={'id'}
                      columns={[
                        {
                          title: 'Descripción',
                          dataIndex: 'descripcion',
                          key: 'descripcion',
                        },
                        {
                          title: 'Incluye Imagen',
                          dataIndex: 'image',
                          key: 'image',
                          render: (imagen) => (imagen ? 'Si' : 'No'),
                        },
                        {
                          title: 'Fecha Creación',
                          dataIndex: 'createdAt',
                          key: 'createdAt',
                          render: (date) =>
                            moment(date).format('YYYY-MM-DD HH:mm:ss'),
                        },
                        {
                          title: 'Fecha Actualizacion',
                          dataIndex: 'updatedAt',
                          key: 'updatedAt',
                          render: (date) =>
                            moment(date).format('YYYY-MM-DD HH:mm:ss'),
                        },
                      ]}
                    />
                  );
                },
                defaultExpandAllRows: true,
              }}
            />
          </div>
        </div>
        <Modal
          {...modalProps}
          footer={false}
          onCancel={() => setModalProps(defaultModalProps)}
        />
      </Badge.Ribbon>
    </Skeleton>
  );
};

export default DatosCliente;
