import { DownloadOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import moment from 'moment';
import { useActionsComprobantes } from 'providers/ActionsComprobantesProvider';
import { useEmergencias } from 'providers/EmergenciasProvider';
import React, { useEffect } from 'react';

const EmergenciasPage = ({ id }) => {
  const { callEmergencias, emergencias } = useEmergencias();
  const { dowload: dowloadComprobante } = useActionsComprobantes();

  useEffect(() => {
    if (id) {
      callEmergencias(id);
    }
  }, [callEmergencias, id]);
  return (
    <Space className="w-full" direction="vertical">
      <Table
        dataSource={emergencias}
        rowKey={'id'}
        columns={[
          {
            title: 'N°',
            dataIndex: 'id',
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: 'descend',
          },
          {
            title: 'Edificio',
            dataIndex: 'Cliente',
            render: (Cliente) => <span>{Cliente?.name}</span>,
          },
          {
            title: 'Fecha',
            dataIndex: 'date',
            render: (fecha) => (
              <span>{moment(fecha).format('YYYY/MM/DD')}</span>
            ),
          },
          {
            title: 'Hora',
            dataIndex: 'date',
            render: (fecha) => <span>{moment(fecha).format('h:mm a')}</span>,
          },
          {
            title: 'Razón',
            dataIndex: 'reason',
            render: (value) => <span className="uppercase">{value}</span>,
          },
          {
            title: 'Acciones',
            key: 'action',
            render: (comprobante, record) => (
              <Button
                type="primary"
                onClick={() => dowloadComprobante(comprobante.id)}
                icon={<DownloadOutlined />}
              ></Button>
            ),
          },
        ]}
      />
    </Space>
  );
};

export default EmergenciasPage;
