import { Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  leyend: {
    fontSize: '10px',
    margin: '0 20px',
  },
  separation: {
    margin: '0px 20px',
  },
  finishContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textStrong: {
    fontSize: '12px',
    fontFamily: 'Helvetica-Bold',
  },
  subText: {
    fontSize: '10px',
    color: '#7D7D7C',
  },
  textLogo: {
    fontSize: '12px',
    color: '#2A2D8C',
  },
});

function BudgetFinish({ config, staticData }) {
  return (
    <View>
      <Text style={styles.leyend}>
        Esperando una buena acogida del presente informe y a la espera de sus
        noticias, no nos queda más que agradecer su interés y esperamos contar
        con su confianza.
      </Text>
      <View style={styles.separation} />
      <View style={styles.finishContainer} wrap={false}>
        <Text style={styles.textStrong}>
          {staticData?.titular ?? 'David Silva'}
        </Text>
        <Text style={styles.subText}>
          {staticData?.titular_titulo ??
            'Licenciado en ciencias de la ingeniería'}
        </Text>
        <Text style={styles.subText}>
          {staticData?.titular_cargo ?? 'Cofundador de STIL'}
        </Text>
        <Text style={styles.textLogo}>{config?.company ?? 'Lift Logic'}</Text>
        <Text style={styles.subText}>{config?.web ?? 'www.liftlogic.cl'}</Text>
        {config?.telefonos?.map((telefono) => (
          <Text style={styles.subText} key={telefono}>
            {telefono}
          </Text>
        ))}
      </View>
    </View>
  );
}

export default BudgetFinish;
