// KeysList.js
import React from 'react';
import { Form, Input, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const ConceptList = ({ field, form, completeName }) => {
  // Obtener el array 'bloqued' del formulario
  const bloqued = form.getFieldValue([...completeName, 'bloqued']) || [];

  return (
    <div>
      {/* Campo oculto para 'bloqued' */}
      <Form.Item name={[field.name, 'bloqued']} hidden>
        <Input />
      </Form.Item>

      <Form.List name={[field.name, 'conceptList']} className="w-full">
        {(fields, { add, remove }) => (
          <div>
            {fields.map((item) => (
              <div
                key={item.key}
                className="space-x-2 flex items-center mb-2"
              >
                {/* Campo para la Clave */}
                <Form.Item
                  name={[item.name, 'concept']}
                  rules={[
                    { required: true, message: 'Ingrese un concepto' },
                  ]}
                  className="w-1/3"
                >
                  <Input placeholder="Concepto" />
                </Form.Item>

                {/* Campo para el Contenido */}
                <Form.Item
                  name={[item.name, 'conceptContent']}
                  rules={[
                    { required: true, message: 'Ingrese el contenido' },
                  ]}
                  className="w-2/3"
                >
                  <Input placeholder="Contenido" />
                </Form.Item>

                {/* Botón para Remover Ítem */}
                <MinusCircleOutlined
                  className={
                    bloqued.includes('remove')
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'text-red-500 cursor-pointer'
                  }
                  onClick={() =>
                    bloqued.includes('remove') ? null : remove(item.name)
                  }
                  style={{ fontSize: '20px' }}
                />
              </div>
            ))}

            {/* Botón para Agregar Ítem */}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                disabled={bloqued.includes('add')}
              >
                Agregar elemento
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
    </div>
  );
};

export default ConceptList;
