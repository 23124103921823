import PropTypes from 'prop-types';

const user = {
  rut: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  fullname: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  exp: PropTypes.number,
  createToken: PropTypes.number,
};

export const ProfileProps = {
  user: PropTypes.shape(user),
};
export const EditProfileProps = {
  user: PropTypes.shape(user),
  updateUserData: PropTypes.func.isRequired,
};
export const EditProfileFormProps = {
  user: PropTypes.shape(user),
  updateUserData: PropTypes.func.isRequired,
};
