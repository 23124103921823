import React from 'react';

const LogoBlack = () => {
  return (
    <svg
      // width="800px"
      // height="800px"
      viewBox="0 0 16 16"
      version="1.1"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      id="svg1"
    >
      <defs id="defs1" />
      <g
        id="g1"
        transform="matrix(0.62409614,0,0,0.61228154,3.0072309,3.1017477)"
      >
        <polygon
          points="8,1.25 14.25,4.75 14.25,11.25 8,14.75 1.75,11.25 1.75,4.75"
          id="polygon1"
        />
        <path d="M 8,14 V 8 M 13.75,5 8,8 M 2,5 8,8" id="path1" />
      </g>
      <ellipse
        fill="#000000"
        strokeWidth="0.03"
        id="path2"
        cx="1.9406463"
        cy="8.4431572"
        rx="0.76190478"
        ry="0.74131274"
      />
      <ellipse
        fill="#000000"
        strokeWidth="0.03"
        id="path2-2"
        cx="14.225283"
        cy="8.4431524"
        rx="0.76190478"
        ry="0.74131274"
      />
    </svg>
  );
};

export default LogoBlack;
