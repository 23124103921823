import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Crear el contexto
const ToDoContext = createContext(null);

// Componente proveedor del contexto
export const ToDoProvider = ({ children }) => {
  const navigate = useNavigate();
  const to = (id) => {
    navigate({
      pathname: `/specialist/lista-mantenciones/${id}`,
    });
  };
  return <ToDoContext.Provider value={{ to }}>{children}</ToDoContext.Provider>;
};

export const useToDo = () => useContext(ToDoContext);
