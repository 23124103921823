import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import React from 'react';
import { API_PATH } from 'config';
import { transformStaticData } from 'utils/formaters';

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    margin: '10px 10px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  header_textContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '11px',
    marginRight: '20px',
    textTransform: 'uppercase',
  },
  headerTitle: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '15px',
  },
  logo: {
    width: '4.76cm',
    height: '3.34cm',
    backgroundColor: 'white',
    marginRight: '1cm',
    padding: '3px',
  },
});

function BudgetHeader({ config, staticData }) {
  return (
    <View fixed style={styles.header}>
      <View>
        <Image
          style={styles.logo}
          source={
            config?.portraitLogo
              ? `${API_PATH}${config?.portraitLogo}`
              : 'LIFT LOGIC'
          }
        />
      </View>
      <View style={styles.header_textContainer}>
        <Text style={styles.headerTitle}>
          {staticData?.secondary_title ?? 'Lift Logic'}
        </Text>
      </View>
      <View style={styles.header_textContainer}>
        <Text style={styles.textItem}>{staticData?.right_header_1}</Text>
        <Text style={styles.textItem}>{staticData?.right_header_2}</Text>
        <Text style={styles.textItem}>{staticData?.right_header_3}</Text>
      </View>
    </View>
  );
}

export default BudgetHeader;
