/* eslint-disable no-unused-vars */
import { StyleSheet, View } from '@react-pdf/renderer';
import { oneOf } from 'prop-types';
import React from 'react';

const Container = (props) => {
  const {
    children,
    dflex,
    style,
    mt,
    fixed = false,
    debug = false,
    between = false,
  } = props;

  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: dflex ? dflex : 'column',
      marginTop: mt ? mt : 0,
      justifyContent: between ? 'space-between' : 'flex-start',
    },
  });
  return (
    <View
      fixed={fixed}
      style={{
        ...styles.container,
        ...style,
      }}
      debug={debug}
    >
      {children}
    </View>
  );
};
Container.propTypes = {
  dflex: oneOf(['column', 'row']),
};

export default Container;
