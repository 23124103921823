import {
  BuildOutlined,
  EditOutlined,
  StopOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, List } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { returnKeysVoid } from 'utils/isNull';

const problemsMap = [
  {
    title: 'Ascensores',
    key: 'ascensores',
    label:
      'Si la cantidad de ascensores no es asignada, los técnicos no podrán seleccionar los ascensores cuando atiendan una mantención, emergencia, etc.',
    icon: 'error',
  },
  {
    title: 'Télefono del cliente',
    key: 'phone',
    label:
      'El teléfono del cliente le servirá a los administradores saber cómo contactarse con el cliente',
    icon: 'warning',
  },
  {
    title: 'Correo',
    key: 'email',
    label:
      'El correo es necesario para que los informes le lleguen al correo del cliente.',
    icon: 'error',
  },
  {
    title: 'Dirección',
    key: 'address',
    label:
      'La dirección del cliente es importante para que los técnicos sepan dónde deben ir a realizar los trabajos',
    icon: 'warning',
  },
  {
    title: 'Comuna',
    key: 'commune',
    label:
      'La comuna es importante para que los técnicos sepan donde ir a realizar los trabajos.',
    icon: 'warning',
  },
  {
    title: 'Nombre de Contacto',
    key: 'contactName',
    label:
      'Le permite al administrador saber el nombre de la persona con la que normalmente se contactan.',
    icon: 'warning',
  },
];
// const problemsMap = {
//   ascensores: {
//     key: 'Ascensores',
//     label:
//       'Si la cantidad de ascensores no es asignada, los técnicos no podrán seleccionar los ascensores cuando atiendan una mantención, emergencia, etc.',
//     icon: 'error',
//   },
//   phone: {
//     key: 'Télefono del cliente',
//     label:
//       'El teléfono del cliente le servirá a los administradores saber cómo contactarse con el cliente',
//     icon: 'warning',
//   },
//   email: {
//     key: 'Correo',
//     label:
//       'El correo es necesario para que los informes le lleguen al correo del cliente.',
//     icon: 'error',
//   },
//   address: {
//     key: 'Dirección',
//     label:
//       'La dirección del cliente es importante para que los técnicos sepan dónde deben ir a realizar los trabajos',
//     icon: 'warning',
//   },
//   commune: {
//     key: 'Comuna',
//     label:
//       'La comuna es importante para que los técnicos sepan donde ir a realizar los trabajos.',
//     icon: 'warning',
//   },
//   contactName: {
//     key: 'Nombre de Contacto',
//     label:
//       'Le permite al administrador saber el nombre de la persona con la que normalmente se contactan.',
//     icon: 'warning',
//   },
// };
const iconsMap = {
  warning: (
    <WarningOutlined
      style={{
        color: '#d4b106',
        marginRight: '2px',
      }}
    />
  ),
  error: (
    <StopOutlined
      style={{
        color: '#f00',
        marginRight: '2px',
      }}
    />
  ),
};

const ProblemsClientList = (props) => {
  const { client, openEditClient } = props;
  const clientProblems = useMemo(() => {
    return returnKeysVoid(client);
  }, [client]);
  console.log(client);
  return (
    <div>
      <List
        dataSource={problemsMap}
        renderItem={(problem) => (
          <>
            {!client[problem.key] ||
            client[problem.key] === 0 ||
            client[problem.key].length === 0 ? (
              <List.Item key={problem.key}>
                <p>
                  {iconsMap[problem.icon]}{' '}
                  <span className="font-semibold">
                    {problem.title}
                    {': '}
                  </span>
                  {problem.label}
                </p>
              </List.Item>
            ) : null}
          </>
        )}
      />
      <div className="flex flex-row">
        <Button
          className="w-1/2 mr-2"
          type="default"
          onClick={() => openEditClient(client)}
          icon={<EditOutlined />}
        >
          Editar
        </Button>
        <Link to={`/admin/client/${client.id}/datos`} className="w-1/2">
          <Button icon={<BuildOutlined />} type="primary" className="w-full">
            Ascensores
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ProblemsClientList;
