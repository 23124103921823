import { Text, View } from '@react-pdf/renderer';
import { fontBold } from 'PDF/keys';
import { bool, string } from 'prop-types';
import React from 'react';

function Normal(props) {
  const { children, indent, negrita, debugC, debugT } = props;
  const extraStyles = {};
  if (indent) {
    extraStyles.textIndent = '4pt';
  }
  return (
    <View style={{ height: 'auto', ...extraStyles }} debug={debugC}>
      <Text
        style={{
          fontSize: negrita ? '10pt' : '12pt',
          fontFamily: negrita ? fontBold : 'Times-Roman',
          textAlign: 'justify',
          textIndent: '25pt',
          maxWidth: '100%',
        }}
        debug={debugT}
      >
        {children}
      </Text>
    </View>
  );
}
Normal.propTypes = {
  children: string.isRequired,
  indent: bool,
  negrita: bool,
};
Normal.defaultProps = {
  indent: false,
  negrita: false,
};

export default Normal;
