import { Select } from 'antd';
import { getAccessTokenApi } from 'api/auth';
import { getClientsApi } from 'api/client';
import React, { useEffect, useState } from 'react';

const SelectClient = (props) => {
  const [clients, setClients] = useState([]);
  const token = getAccessTokenApi();
  useEffect(() => {
    getClientsApi(token).then((result) => {
      setClients(result.clients);
    });
  }, [token]);
  return (
    <Select
      placeholder="Cliente"
      fieldNames={{
        label: 'name',
        value: 'id',
      }}
      onChange={(value) => props.onChange(value)}
      options={clients}
      showSearch
      filterOption={(input, option) => {
        return (option?.name ?? '').toLowerCase().includes(input.toLowerCase());
      }}
      filterSort={(optionA, optionB) =>
        (optionA?.name ?? '')
          .toLowerCase()
          .localeCompare((optionB?.name ?? '').toLowerCase())
      }
      optionFilterProp="children"

    />
  );
};

export default SelectClient;
