import { Button, Input } from 'antd';
import React, { useState } from 'react';

const ClientFilter = (props) => {
  const { clients, setFilteredClients } = props;
  const [filterState, setFilterState] = useState(null);

  const filterToActive = () => {
    if (filterState === 'active') {
      setFilteredClients(clients);
      setFilterState(null);
    } else {
      setFilteredClients(clients.filter((client) => client.active));
      setFilterState('active');
    }
  };
  const filterToInactive = () => {
    if (filterState === 'inactive') {
      setFilteredClients(clients);
      setFilterState(null);
    } else {
      setFilteredClients(clients.filter((client) => !client.active));
      setFilterState('inactive');
    }
  };

  const filterToWarning = () => {
    if (filterState === 'warning') {
      setFilteredClients(clients);
      setFilterState(null);
    } else {
      setFilteredClients(clients.filter((client) => client.problems));
      setFilterState('warning');
    }
  };

  const filterToSearch = (e) => {
    const { value } = e.target;
    const filteredArray = clients.filter((client) =>
      client.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredClients(filteredArray);
    setFilterState('search');
  };
  return (
    <div className=" ">
      <Input.Search
        className=" w-full md:w-80 mb-2 md:mb-0"
        placeholder="Buscar cliente"
        onChange={filterToSearch}
      />
      <Button
        className={`ml-0 md:ml-2 uppercase ${
          filterState === 'active'
            ? ' bg-success text-white font-normal'
            : 'text-success font-bold'
        }`}
        type={filterState === 'active' ? 'primary' : 'link'}
        onClick={filterToActive}
      >
        Activos
      </Button>
      <Button
        className={`ml-0 md:ml-2 uppercase  ${
          filterState === 'inactive'
            ? ' bg-danger text-white font-normal'
            : 'text-danger font-bold'
        }`}
        onClick={filterToInactive}
        type={filterState === 'inactive' ? 'primary' : 'link'}
      >
        Inactivos
      </Button>
      <Button
        className={`ml-0 md:ml-2 uppercase  ${
          filterState === 'warning'
            ? ' bg-warning text-white font-normal'
            : 'text-warning font-bold'
        }`}
        type={filterState === 'warning' ? 'primary' : 'link'}
        onClick={filterToWarning}
      >
        Warning
      </Button>
    </div>
  );
};

export default ClientFilter;
