import { getAccessTokenApi } from './auth';
import { BASE_PATH_SERVER } from './config';

export const getAttentionVoucherApi = (token) => {
  const dst = `${BASE_PATH_SERVER}get-attention-vouchers`;
  const params = {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const getAttentionVoucherAdminApi = (token) => {
  const dst = `${BASE_PATH_SERVER}get-attention-vouchers-admin`;
  const params = {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const getVouchersReasons = () => {
  const dst = `${BASE_PATH_SERVER}get-vouchers-reason`;
  const params = {
    method: 'GET',
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const postAttentionVoucher = (data) => {
  const token = getAccessTokenApi();
  const dst = `${BASE_PATH_SERVER}add-attention-voucher`;
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      codeMessage: 'ERROR_EN_LA_PETICION',
      state: 'client',
      message: err.message,
    }));
};
export const getImagesVoucher = (id) => {
  const dst = `${BASE_PATH_SERVER}get-images-voucher/${id}`;
  const params = {
    method: 'GET',
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};

export const getImageVoucher = (name) => {
  const dst = `${BASE_PATH_SERVER}get-image-voucher/${name}`;
  const params = {
    method: 'GET',
  };
  return fetch(dst, params)
    .then((response) => response.url)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const getSignatureVoucher = (name) => {
  const dst = `${BASE_PATH_SERVER}get-signature-voucher/${name}`;
  const params = {
    method: 'GET',
  };
  return fetch(dst, params)
    .then((response) => response.url)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};

export const deleteVoucherApi = (id) => {
  const token = getAccessTokenApi();
  const dst = `${BASE_PATH_SERVER}/delete-voucher/${id}`;
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: token,
    },
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
