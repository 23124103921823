export const coloresEstados = {
  odt_no_asignada: {
    key: 'odt_no_asignada',
    description: 'Generada',
    color: 'yellow',
  },
  odt_asignada: {
    key: 'odt_asignada',
    description: 'Orden de Trabajo',
    color: 'orange',
  },
  odt_en_proceso: {
    key: 'odt_en_proceso',
    description: 'Orden Iniciada',
    color: 'blue',
  },
  odt_finalizada: {
    key: 'odt_finalizada',
    description: 'Orden Finalizada',
    color: 'green',
  },
};
