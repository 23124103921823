import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { deleteToApi, postToApi, putToApi } from 'api/axios';
import React, { useEffect } from 'react';

const BASE_DIRECTION = '/categoria';

export const deleteCategory = async (id, isFinish) => {
  try {
    const response = await deleteToApi(`${BASE_DIRECTION}/delete/${id}`);
    notification.success({
      message:
        response.data.message ||
        'El servidor no ha enviado respuesta de la acción. Verifique si fué eliminado.',
    });
    isFinish();
    return {
      resetFields: true,
    };
  } catch (error) {
    notification.error({
      message: `Error al eliminar: ${error.message}`,
    });
    return {
      resetFields: false,
    };
  }
};
export const addCategory = async (values, isFinish) => {
  try {
    const response = await postToApi(`${BASE_DIRECTION}/add`, values);
    notification.success({
      message:
        response.data.message ||
        'El servidor no ha enviado respuesta de la acción. Verifique si fué añadido.',
    });
    isFinish();
    return {
      resetFields: true,
    };
  } catch (error) {
    notification.error({
      message: 'Error al eliminar',
    });
    return {
      resetFields: false,
    };
  }
};

export const editCategory = async (data, id, isFinish) => {
  try {
    const response = await putToApi(`${BASE_DIRECTION}/update/${id}`, data);
    notification.success({
      message:
        response.data.message ||
        'El servidor no ha enviado respuesta de la acción. Verifique si fué actualizado.',
    });
    isFinish();
    return {
      resetFields: true,
    };
  } catch (error) {
    notification.error({
      message: 'Error al eliminar',
    });
    return {
      resetFields: false,
    };
  }
};

const CategoryMaintanceForm = (props) => {
  const { submitForm, buttonLabel, formProps } = props;

  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [formProps, form]);

  const finishForm = async () => {
    const values = form.getFieldsValue();
    const response = await submitForm(values, formProps);
    if (response.resetFields) {
      form.resetFields();
    }
  };
  return (
    <Form {...formProps} form={form} onFinish={finishForm} layout="vertical">
      <Form.Item
        label="Nombre de la categoría"
        name="nombre"
        rules={[
          {
            required: true,
            message: 'El nombre de la categoría es obligatorio',
          },
          {
            max: 90,
            message:
              'El nombre de la categoría solo puede tener hasta 90 caracteres',
          },
        ]}
      >
        <Input placeholder="Ingresar categoría" />
      </Form.Item>
      <Form.Item>
        <Button block type="primary" htmlType="submit">
          {buttonLabel || 'Enviar'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CategoryMaintanceForm;
