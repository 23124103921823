import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Modal, Select } from 'antd';
import { useAscensorEstados } from 'providers/AscensorEstadosProvider';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

const AscensorDisplayForm = (props) => {
  const [deletedAscensores, setDeletedAscensores] = useState([]);
  const { call, hasCalled, isLoading, error, data } = useAscensorEstados();

  useEffect(() => {
    if (!hasCalled) {
      call();
    }
  }, [call, hasCalled]);

  const handleRemove = (remove, name) => {
    const id = props.form.getFieldValue(['ascensores', name, 'id']);
    Modal.confirm({
      title: '¿Estás seguro que quieres eliminar este ascensor?',
      okText: 'Sí',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        if (id) {
          setDeletedAscensores((prev) => [...prev, id]);
        }
        remove(name);
      },
    });
  };

  const estadoColors = {
    1: 'border-green-100', // Operativo
    2: 'border-yellow-100', // En Mantenimiento
    3: 'border-red-100', // Fuera de Servicio
    4: 'border-orange-100', // En Espera de Repuestos
    5: 'border-blue-100', // En Observación
  };
  const bgEstadosColors = {
    1: '!bg-green-100', // Operativo
    2: '!bg-yellow-100', // En Mantenimiento
    3: '!bg-red-100', // Fuera de Servicio
    4: '!bg-orange-100', // En Espera de Repuestos
    5: '!bg-blue-100', // En Observación
  };

  return (
    <Form
      {...props}
      onFinish={() => {
        props.onFinish({ ...props.form.getFieldsValue(), deletedAscensores });
      }}
    >
      <Form.List name="ascensores">
        {(fields, { add, remove }) => (
          <>
            <div className="flex flex-wrap gap-4">
              {fields.map(({ key, name, ...restField }) => {
                const estado = props.form.getFieldValue([
                  'ascensores',
                  name,
                  'estado',
                ]);
                const cardClass = classNames(
                  'min-w-[300px] max-w-[300px] flex-1 border-2',
                  estadoColors[estado]
                );
                const headerClass = classNames(
                  'border-b-2',
                  bgEstadosColors[estado]
                );

                return (
                  <Card
                    title={`Ascensor N° ${name + 1}`}
                    key={key}
                    className={cardClass}
                    classNames={{
                      header: headerClass,
                    }}
                  >
                    <Form.Item {...restField} name={[name, 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'nombre']}
                      label="Nombre del ascensor"
                      rules={[
                        {
                          required: true,
                          message: 'El nombre es obligatorio',
                        },
                        {
                          max: 60,
                          message:
                            'El nombre no puede tener más de 60 caracteres',
                        },
                      ]}
                    >
                      <Input placeholder="Nombre" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'marca']}
                      label="Marca"
                      rules={[
                        {
                          max: 45,
                          message:
                            'La marca no puede tener más de 45 caracteres',
                        },
                      ]}
                    >
                      <Input placeholder="Marca" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'modelo']}
                      label="Modelo"
                      rules={[
                        {
                          max: 45,
                          message:
                            'El modelo no puede tener más de 45 caracteres',
                        },
                      ]}
                    >
                      <Input placeholder="Modelo" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'capacidad']}
                      label="Capacidad"
                      rules={[
                        {
                          max: 45,
                          message:
                            'La capacidad no puede tener más de 45 caracteres',
                        },
                      ]}
                    >
                      <Input placeholder="Capacidad" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'pisos']}
                      label="Pisos"
                      rules={[
                        {
                          max: 45,
                          message:
                            'Los pisos no pueden tener más de 45 caracteres',
                        },
                      ]}
                    >
                      <Input placeholder="Pisos" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'numero_serie']}
                      label="Número de serie"
                      rules={[
                        {
                          max: 135,
                          message:
                            'El número de serie no puede tener más de 135 caracteres',
                        },
                      ]}
                    >
                      <Input placeholder="Número de serie" />
                    </Form.Item>
                    <Form.Item
                      label="Estado"
                      name={[name, 'estado']}
                      rules={[
                        {
                          required: true,
                          message: 'El estado del ascensor es obligatorio',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione el estado"
                        loading={isLoading}
                        options={data?.map((element) => ({
                          label: element.nombre,
                          value: String(element.id),
                        }))}
                      />
                    </Form.Item>
                    {!props?.disabled && (
                      <Form.Item className="w-full text-center mb-0">
                        <MinusCircleOutlined
                          className="my-2 text-danger text-lg"
                          onClick={() => handleRemove(remove, name)}
                        />
                      </Form.Item>
                    )}
                  </Card>
                );
              })}
            </div>
            {!props?.disabled && (
              <Form.Item className="mt-2">
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Añadir Ascensor
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
      <Form.Item name="deletedAscensores" hidden>
        <Input value={JSON.stringify(deletedAscensores)} />
      </Form.Item>
    </Form>
  );
};

export default AscensorDisplayForm;
