import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Form,
  Table,
  Space,
  message,
  notification,
  FloatButton,
  Row,
  Col,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { CopyOutlined, SaveOutlined } from '@ant-design/icons';
import Compositor from 'components/CompositorDocumentos/Compositor';
import AscensorDisplayForm from 'components/forms/AscensorDisplayForm';
import useRequest from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import ItemNavigator from 'components/CompositorDocumentos/ItemNavigator';

const createAscensoresVariablesMap = (ascensores) => {
  return ascensores.reduce((acc, ascensor, index) => {
    const idx = index + 1;
    acc[`ascensor_${idx}_nombre`] = ascensor.nombre;
    acc[`ascensor_${idx}_marca`] = ascensor.marca;
    acc[`ascensor_${idx}_modelo`] = ascensor.modelo;
    acc[`ascensor_${idx}_capacidad`] = ascensor.capacidad;
    acc[`ascensor_${idx}_pisos`] = ascensor.pisos;
    acc[`ascensor_${idx}_estado`] = ascensor.estado;
    acc[`ascensor_${idx}_numero_serie`] = ascensor.numero_serie;
    return acc;
  }, {});
};

const columns = [
  {
    title: 'Campo',
    dataIndex: 'campo',
    key: 'campo',
  },
  {
    title: 'Valor',
    dataIndex: 'valor',
    key: 'valor',
  },
  {
    title: 'Clave',
    dataIndex: 'key',
    key: 'key',
    render: (text) => (
      <Space>
        <span>{`{${text}}`}</span>
        <Button
          icon={<CopyOutlined />}
          type="text"
          onClick={() => {
            navigator.clipboard.writeText(`{${text}}`);
            message.success('Clave copiada al portapapeles');
          }}
        ></Button>
      </Space>
    ),
  },
];

const transformClienteData = (cliente) => {
  if (!cliente) return [];
  const basicData = [
    { key: 'nombre_cliente', campo: 'Nombre', valor: cliente.name },
    { key: 'rut_cliente', campo: 'RUT', valor: cliente.rut },
    { key: 'direccion_cliente', campo: 'Dirección', valor: cliente.address },
    { key: 'comuna_cliente', campo: 'Comuna', valor: cliente.commune },
    {
      key: 'nombre_contacto',
      campo: 'Nombre de Contacto',
      valor: cliente.contactName,
    },
    { key: 'telefono_cliente', campo: 'Teléfono', valor: cliente.phone },
    { key: 'email_cliente', campo: 'Email', valor: cliente.email },
  ];

  return basicData;
};

const createVariablesMap = (cliente) => {
  const basicVariables = {
    nombre_cliente: cliente.name,
    rut_cliente: cliente.rut,
    direccion_cliente: cliente.address,
    comuna_cliente: cliente.commune,
    nombre_contacto: cliente.contactName,
    telefono_cliente: cliente.phone,
    email_cliente: cliente.email,
  };

  const ascensoresVariables = cliente.Ascensores
    ? createAscensoresVariablesMap(cliente.Ascensores)
    : {};

  return { ...basicVariables, ...ascensoresVariables };
};

const PresupuestosPage = () => {
  const [docForm] = Form.useForm();
  const [cliente, setCliente] = useState(null);
  const [variablesMap, setVariablesMap] = useState({});
  const [saveStatus, setSaveStatus] = useState('SIN_CAMBIOS'); // Estado para el indicador de guardado

  const { request } = useRequest();
  const [presupuesto, setPresupuesto] = useState({});
  const [doocumento, setDocumento] = useState({});
  const { id, documentId } = useParams();

  const getPresupuesto = useCallback(
    async (id) => {
      try {
        const result = await request(`/presupuestos/${id}/composer`, 'GET');
        setPresupuesto(result);
        setCliente(result.Cliente);
        setDocumento(result.Document);
        setVariablesMap(createVariablesMap(result.Cliente));
        return result;
      } catch (error) {
        notification.error({
          message: 'Error al cargar el presupuesto',
          description: error.message,
        });
      }
    },
    [request]
  );

  useEffect(() => {
    if (id) {
      getPresupuesto(id);
    }
  }, [id, getPresupuesto]);

  const saveDoc = async () => {
    setSaveStatus('GUARDANDO'); // Iniciar indicador de guardado
    try {
      await docForm.validateFields();
      const values = docForm.getFieldsValue();
      await request(`/presupuestos/${id}/composer`, 'PUT', {
        items: values.items,
      });
      notification.success({
        message: 'Presupuesto guardado',
        description: 'El presupuesto se ha guardado correctamente.',
      });
      setSaveStatus('GUARDADO'); // Guardado exitoso
    } catch (error) {
      notification.error({
        message: 'Error al guardar el presupuesto',
        description: error.message,
      });
      setSaveStatus('ERROR'); // Error al guardar
    } finally {
      setTimeout(() => setSaveStatus('SIN_CAMBIOS'), 3000); // Resetear el estado después de 3 segundos
    }
  };

  // Define el mensaje y color a mostrar basado en saveStatus
  const getStatusInfo = () => {
    switch (saveStatus) {
      case 'GUARDANDO':
        return { message: 'Guardando cambios...', color: 'blue' };
      case 'GUARDADO':
        return { message: 'Cambios guardados exitosamente', color: 'green' };
      case 'ERROR':
        return { message: 'Error al guardar cambios', color: 'red' };
      case 'SIN_CAMBIOS':
      default:
        return null;
    }
  };

  const statusInfo = getStatusInfo();
  const itemRefs = useRef([]);

  const handleNavigate = (position) => {
    const targetElement = itemRefs?.current[position - 1];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="relative">
      <Card className="welcome mb-2">
        <div className="flex flex-col items-center md:flex-row md:justify-between">
          <Title level={3}>
            <span className="text-white">Generar nuevo presupuesto</span>
          </Title>
        </div>
      </Card>

      <div className="flex">
        <div>
          <FloatButton
            type="primary"
            shape="circle"
            className="shadow-2xl shadow-black mt-4"
            onClick={saveDoc}
            icon={<SaveOutlined />}
          />
        </div>
        <div className="ml-72 w-full">
          <Row gutter={16}>
            <Col span={18}>
              <div id="item-0" ref={(el) => (itemRefs.current[0] = el)}>
                <Card>
                  <Title level={4} className="!mt-0">
                    Detalles del Cliente
                  </Title>
                  <Table
                    columns={columns}
                    dataSource={transformClienteData(cliente)}
                    pagination={false}
                    rowKey="key"
                  />
                </Card>
              </div>
              {cliente?.Ascensores && (
                <div id="item-1" ref={(el) => (itemRefs.current[1] = el)}>
                  <Card>
                    <Title level={4} className="!mt-0">
                      Ascensores
                    </Title>
                    <AscensorDisplayForm
                      form={docForm}
                      initialValues={{ ascensores: cliente.Ascensores }}
                      // onFinish={(values) => console.log('Ascensores:', values)}
                      disabled
                      layout="vertical"
                    />
                  </Card>
                </div>
              )}
              <Compositor
                presupuesto_id={id}
                form={docForm}
                variablesMap={variablesMap}
                documentElements={doocumento?.DocumentElements}
                cliente={cliente}
                itemRefs={itemRefs}
                setSaveStatus={setSaveStatus}
                availableModules={[
                  {
                    key: 'collection',
                    label: 'De una Colección',
                  },
                  {
                    key: 'itemTable',
                    label: 'Tabla de Ítems',
                  },
                  {
                    key: 'customerInfo',
                    label: 'Información del Cliente',
                  },
                  {
                    key: 'custom',
                    label: 'Personalizado',
                  },
                  {
                    key: 'image',
                    label: 'Imagen',
                  },
                  {
                    key: 'markdown',
                    label: 'Markdown',
                  },
                  // {
                  //   key: 'salto_pagina',
                  //   label: 'Salto de Página',
                  // },
                  // {
                  //   key: 'list',
                  //   label: 'Lista',
                  // },
                ]}
              />
            </Col>
            <Col span={6}>
              <ItemNavigator
                items={docForm.getFieldValue('items')}
                onNavigate={handleNavigate}
              />
            </Col>
          </Row>
        </div>
      </div>
      {statusInfo && (
        <div
          className="fixed bottom-0 left-0 w-full text-white text-center p-2 z-10"
          style={{ backgroundColor: statusInfo.color }}
        >
          {statusInfo.message}
        </div>
      )}
    </div>
  );
};

export default PresupuestosPage;
