import { LoadingOutlined } from '@ant-design/icons';
import MantencionDownload from 'PDF/Mantencion/MantencionDowload';
import useGenerateAndSendPDF from 'PDF/Mantencion/useMantencionSend';
import { Modal, notification } from 'antd';
import { SOLICITUD_TIPOS } from 'assets/data/SolicitudesEstados';
import { useAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SolicitudesMantencionContext = createContext(null);

export const SolicitudesMantencionProvider = ({ children, id }) => {
  const map = useCallback((result) => {
    return result;
  }, []);

  const { data, isLoading, error, hasCalled, call } = useAppApi({
    baseUrl: '/solicitudes',
    mapResults: map,
  });

  const {
    call: callmantencion,
    data: mantencion,
    isLoading: isLoadingMantencion,
  } = useAppApi({
    baseUrl: '/mantenciones',
    mapResults: map,
  });
  const navigate = useNavigate();

  const { call: notFunctionalStartMan, isLoading: isLoadingStartMan } =
    useAppApi({
      baseUrl: '/solicitudes',
      mapResults: useCallback((data) => {
        return data;
      }, []),
    });
  const { generateAndSendPDF, loading: isLoadingSendPDF } =
    useGenerateAndSendPDF();

  const openDowloadMantencion = async (solicitud) => {
    await callmantencion({
      extUrl: `/${solicitud.id_mantencion}/document-data`,
    });
    setVisible(true);
  };

  const startMantSolc = async (solicitud) => {
    if ([SOLICITUD_TIPOS.EN_CURSO].includes(solicitud?.status)) {
      // Redireccionar a otra página con react router dom
      navigate(`/specialist/mantenciones/${solicitud.id_mantencion}`); // Cambia '/ruta-destino' por la ruta deseada
      return;
    }
    if (
      [SOLICITUD_TIPOS.ENVIADA, SOLICITUD_TIPOS.FINALIZADA].includes(
        solicitud.status
      )
    ) {
      await callmantencion({
        extUrl: `/${solicitud.id_mantencion}/document-data`,
      });
      setVisible(true);
      return;
    }
    if ([SOLICITUD_TIPOS.ASIGNADA].includes(solicitud.status)) {
      const response = await notFunctionalStartMan({
        extUrl: `/${solicitud.id}/mantenciones/start`,
      });
      return response;
    } else {
      notification.error({
        message: 'Estado de la solicitud no permite iniciar la mantencion',
      });
    }
  };

  const [visible, setVisible] = useState(false);

  const handleSendPDF = async (solicitud, email) => {
    const mantencion = await callmantencion({
      extUrl: `/${solicitud.id_mantencion}`,
    });
    await generateAndSendPDF(mantencion, 'davidsilvaurrutia@gmail.com');
    // setVisible(false);
  };
  return (
    <>
      <Modal open={visible} onCancel={() => setVisible(false)} footer={null}>
        {isLoadingMantencion ? (
          <LoadingOutlined />
        ) : (
          <MantencionDownload mantencion={mantencion} />
        )}
      </Modal>
      <SolicitudesMantencionContext.Provider
        value={{
          call,
          id,
          isLoading,
          error,
          hasCalled,
          data,
          startMantSolc,
          isLoadingStartMan,
          openDowloadMantencion,
          handleSendPDF,
        }}
      >
        {children}
      </SolicitudesMantencionContext.Provider>
    </>
  );
};

export const useSolicitudesMantencion = () =>
  useContext(SolicitudesMantencionContext);
