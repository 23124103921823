import { Badge, Card, Form, List } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import Paragraph from 'antd/es/typography/Paragraph';
import React from 'react';
import { dateFormatter } from 'utils/formaters';

const itemStatus = {
  0: {
    color: 'volcano',
    text: 'Sin iniciar',
  },
  1: {
    color: 'blue',
    text: 'Iniciado',
  },
  3: {
    color: 'green',
    text: 'Finalizado',
  },
};

const OrderItemList = (props) => {
  const { items, isLoading } = props;
  return (
    <List
      loading={isLoading}
      dataSource={items}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4,
        xxl: 6,
      }}
      //   pagination={{
      //     align: 'end',
      //     position: 'bottom',
      //     pageSize: 12,
      //   }}
      //   loading={isLoading}
      renderItem={(item) => (
        <List.Item>
          <Badge.Ribbon
            color={itemStatus[item.item_status_id].color}
            text={itemStatus[item.item_status_id].text}
            className="-mt-2"
          >
            <Card
              title={item.description}
              actions={[
                <Form.Item
                  key={item.id_item}
                  name={item.id_item}
                  valuePropName="checked"
                >
                  <Checkbox className="m-0">Marcar</Checkbox>
                </Form.Item>,
              ]}
            >
              <Paragraph>
                <span className="font-semibold">ID: </span>
                {item.id_item}
              </Paragraph>
              <Paragraph>
                <span className="font-semibold">Última actualización: </span>
                {item.modified_date_item
                  ? dateFormatter(item.modified_date_item).short_h
                  : 'Sin iniciar'}
              </Paragraph>
              <Paragraph>
                <span className="font-semibold">Descripción: </span>
                {item.description}
              </Paragraph>
            </Card>
          </Badge.Ribbon>
        </List.Item>
      )}
    />
  );
};

export default OrderItemList;
