import { Badge, Button, Card, List, Table, Tag } from 'antd';
import Title from 'antd/es/typography/Title';
import { getColorForStatus } from 'assets/data/SolicitudesEstados';
import moment from 'moment';
import { useSolicitudesMantencion } from 'providers/SolicitudesMantencionProvider';
import React, { useEffect } from 'react';

const AgendaSolicitudes = () => {
  const { data, call, hasCalled, isLoading, startMantSolc, isLoadingStartMan } =
    useSolicitudesMantencion();

  useEffect(() => {
    if (!hasCalled) {
      call();
    }
  }, [call, hasCalled]);
  // Ordenar los datos por fecha
  const sortedData = data
    ? [...data].sort((a, b) => moment(a.date).diff(moment(b.date)))
    : [];

  const startMan = async (item) => {
    await startMantSolc(item);
    call();
  };

  return (
    <div className="pt-5 grid grid-cols-3 gap-4">
      <Card className="welcome col-span-3">
        <Title level={3} className=" text-start">
          <span className="uppercase text-white">
            Solicitudes de Mantención
          </span>
        </Title>
      </Card>

      <Table
        className="col-span-3"
        dataSource={sortedData}
        loading={isLoading}
        pagination={{ pageSize: 10 }}
        header={
          <div className="text-center text-lg font-semibold">
            Agenda de Mantenciones
          </div>
        }
        columns={[
          {
            title: 'Mantención',
            dataIndex: 'id_mantencion',
            key: 'id_mantencion',
            render: (id_mantencion) => 'N° ' + id_mantencion,
            sorter: (a, b) => a.id_mantencion - b.id_mantencion,
            defaultSortOrder: 'descend',
          },
          {
            title: 'Fecha',
            dataIndex: 'fecha_asignacion',
            key: 'fecha_asignacion',
            render: (date) => {
              return moment(date).format('MMMM, DD');
            },
          },
          {
            title: 'Edificio',
            dataIndex: 'Cliente',
            key: 'Cliente',
            render: (Cliente) => Cliente?.name,
          },
          {
            title: 'Dirección',
            dataIndex: 'Cliente',
            key: 'Cliente',
            render: (Cliente) => (
              <div className="capitalize-first">{`${Cliente?.address}, ${Cliente?.commune}`}</div>
            ),
          },
          {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
              <Tag color={getColorForStatus(status)} className="ml-2 ">
                {status}
              </Tag>
            ),
          },
          {
            title: 'Acciones',
            key: 'actions',
            render: (_, record) => (
              <div className="flex flex-row justify-between">
                <Button
                  onClick={() => startMan(record)}
                  className={`mx-0 md:mx-2 `}
                  type="primary"
                  loading={isLoadingStartMan}
                >
                  {record.status?.toUpperCase()}
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default AgendaSolicitudes;
