import { Card } from 'antd';
import OrderList from 'components/lists/OrderList';
import { useGetToApi } from 'hooks/useApi/useGetAxios';
import { useActionsODT } from 'providers/ActionsOdtProvider';
import React, { useEffect, useMemo } from 'react';

const SpecialistOrders = () => {
  // const [orders, refreshOrders] = useSpecOrderApi();
  let [orders, isLoading, callOrders] = useGetToApi('/order/specialist', {
    initialValues: {
      orders: [],
    },
  });
  const { dowload: dowloadODT } = useActionsODT();
  useEffect(() => {
    callOrders();
  }, [callOrders]);

  orders = useMemo(() => {
    return orders.orders;
  }, [orders.orders]);

  return (
    <div>
      <Card className="mb-5">
        <div className="flex flex-row justify-between mb-3">
          <h3 className="uppercase mx-auto md:mx-0">Ordenes de trabajo</h3>
        </div>
      </Card>
      <OrderList
        orders={orders}
        isLoading={isLoading}
        dowloadODT={dowloadODT}
      />
    </div>
  );
};

export default SpecialistOrders;
