import { Form, Input, Row, Col, Switch, Select } from 'antd';
import { useColeccion } from 'providers/ColeccionProvider';
import React, { useCallback, useEffect } from 'react';
import { rutValidator } from 'utils/formValidator';
const ACTIVIDAD_TYPE = 'ACTIVIDAD';
const ClienteDisplayForm = (props) => {
  const { findItemsByType } = useColeccion();
  const [models, setModels] = React.useState([]);
  const getAscensorModel = useCallback(async () => {
    const result = await findItemsByType(ACTIVIDAD_TYPE);
    setModels(result);
    return result;
  }, [findItemsByType]);

  useEffect(() => {
    getAscensorModel();
  }, [getAscensorModel]);

  return (
    <Form {...props}>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={8}>
          <Form.Item
            name="rut"
            label="Rut del cliente"
            rules={[
              {
                required: true,
                message: 'El rut es obligatorio',
              },
              {
                min: 0,
                max: 13,
                message: 'El rut no puede tener más de 13 caracteres',
              },
              {
                validator: (_, value) =>
                  rutValidator(value)
                    ? Promise.resolve()
                    : Promise.reject(new Error('El rut no es válido')),
              },
            ]}
          >
            <Input placeholder="Rut" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={16}>
          <Form.Item
            name="name"
            label="Nombre o Razón social"
            rules={[
              {
                required: true,
                message: 'El nombre o razón social es obligatorio',
              },
              {
                min: 0,
                max: 100,
                message:
                  'El Nombre o razón social no puede tener más de 100 caracteres',
              },
            ]}
          >
            <Input placeholder="Nombre o razón social" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={8}>
          <Form.Item
            name="contactName"
            label="Nombre de contacto"
            rules={[
              {
                min: 0,
                max: 180,
                message:
                  'El nombre de contacto no puede tener más de 180 caracteres',
              },
            ]}
          >
            <Input placeholder="Nombre de contacto" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            name="address"
            label="Dirección"
            rules={[
              {
                required: true,
                message: 'La dirección es obligatoria',
              },
              {
                min: 0,
                max: 100,
                message: 'La dirección no puede tener más de 120 caracteres',
              },
            ]}
          >
            <Input placeholder="Dirección" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            name="commune"
            label="Comuna"
            rules={[
              {
                required: true,
                message: 'La comuna es obligatoria',
              },
              {
                min: 0,
                max: 70,
                message: 'La comuna no puede tener más de 70 caracteres',
              },
            ]}
          >
            <Input placeholder="Comuna" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="email"
            label="Correo electrónico"
            rules={[
              {
                type: 'email',
                message: 'Debe tener el formato de un correo',
              },
              {
                min: 0,
                max: 80,
                message:
                  'El correo electrónico no puede tener más de 80 caracteres',
              },
            ]}
          >
            <Input placeholder="Correo Electrónico" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="phone" label="Número de teléfono">
            <Input placeholder="Número de teléfono" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="automatizadoManPreven"
            label="Solicitudes de Mantenimiento Preventivo automáticas"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="id_tipo_ascensor" label="Tipo de ascensor">
            <Select placeholder="Tipo de ascensor">
              {models?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ClienteDisplayForm;
