import { BuildFilled, DeleteFilled, EditFilled } from '@ant-design/icons';
import { Button, Card, Divider, List, Space, Switch } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import ClientDataLinkButton from 'components/links/ClientDataLinkButton';
import { useClientesActions } from 'providers/Clientes/ClientesActionsProvider';
import { useUserActions } from 'providers/Users/UserActions';
import React from 'react';
import ClienteItemList from './Clientes/ClienteItemList';

const ClientList = (props) => {
  const {
    clients,
    isLoading,
    openEditClient,
    openDeleteClient,
    getClientProblems,
    refresh,
  } = props;

  const { toggleStatus, loadingUpdate } = useClientesActions();

  const handleClientStatus = async (client) => {
    try {
      await toggleStatus(client.id, client.active);
      refresh();
    } catch (error) {
      console.error('Error al cambiar el estado', error);
      // setLocalActive(active);
    }
  };

  return (
    <List
      className="mt-3"
      itemLayout="horizontal"
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4,
        xxl: 6,
      }}
      pagination={{
        align: 'end',
        position: 'bottom',
        pageSize: 12,
      }}
      dataSource={clients}
      loading={isLoading}
      rowKey="id"
      renderItem={(usuario) => (
        <ClienteItemList
          usuario={usuario}
          openEditClient={openEditClient}
          openDeleteClient={openDeleteClient}
          getClientProblems={getClientProblems}
          handleClientStatus={handleClientStatus}
        />
      )}
    />
  );
};

export default ClientList;
