import moment from 'moment';
const variablesGlobales = [
  {
    nombre: 'nombre_cliente',
    descripcion: 'Nombre del cliente',
    tipo: 'string',
    valor: null,
  },
  {
    nombre: 'rut_cliente',
    descripcion: 'Rut del cliente',
    tipo: 'string',
    valor: null,
  },
  {
    nombre: 'direccion_cliente',
    descripcion: 'Dirección del cliente',
    tipo: 'string',
    valor: null,
  },
  {
    nombre: 'comuna_cliente',
    descripcion: 'Comuna del cliente',
    tipo: 'string',
    valor: null,
  },
  {
    nombre: 'telefono_cliente',
    descripcion: 'Telefono del cliente',
    tipo: 'string',
    valor: null,
  },
  {
    nombre: 'email_cliente',
    descripcion: 'Email del cliente',
    tipo: 'string',
    valor: null,
  },

  {
    nombre: 'fecha_actual',
    descripcion: 'Nombre del cliente',
    tipo: 'string',
    valor: moment().format('YYYY-MM-DD'),
  },
  {
    nombre: 'hora_actual',
    descripcion: 'Hora actual',
    tipo: 'string',
    valor: moment().format('HH:mm'),
  },
];
export default variablesGlobales;
