import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const LinkBlock = (props) => {
  const { to, label } = props;
  return (
    <div className="d-flex flex-allign-items-center flex-justify-content-between">
      <Link to={to}>
        <Button
          icon={<ArrowLeftOutlined />}
          type={'primary'}
          className="mr-1 content-box py-1"
        />
      </Link>
      <h3 className="font-w-title">{label}</h3>
    </div>
  );
};
LinkBlock.propTypes = {
  label: string.isRequired,
  to: string.isRequired,
};

export default LinkBlock;
