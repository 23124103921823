import { Image } from 'antd';
import React from 'react';
import LogoBlack from '../../assets/logo/LogoBlack';

const Logo = ({ simple = false }) => {
  return (
    <div className="basis-1/5 text-center m-auto">
      {simple ? null : <LogoBlack />}
      {/* <Image className="image-side" src={} /> */}
      <h1 className="p-0 m-0 text-lg xl:text-xl">
        <span className=" text-primary">LIFT</span>{' '}
        <span className=" text-black">LOGIC</span>
      </h1>
    </div>
  );
};

export default Logo;
