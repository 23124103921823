import { notification } from 'antd';
import moment from 'moment';

export const formatMoneyNumber = (number) => {
  return new Intl.NumberFormat('es-CL').format(number);
};

export const transformStaticData = (staticData) => {
  return staticData?.reduce((acc, item) => {
    acc[item.keyName] = item.textValue;
    return acc;
  }, {});
};

export const addKeyToArray = (array) => {
  let result = [];
  array.forEach((item, index) => {
    result = [...result, { ...item, key: index }];
  });
  return result;
};
export const formatter = new Intl.NumberFormat('en-CL', {
  style: 'currency',
  currency: 'CLP',
  minimumFractionDigits: 0,
});

export const dateFormatter = (_date) => {
  const date = moment(_date);
  return {
    short: date.calendar(),
    short_h: date.format('L, hh:mm'),
    long: date.format('dddd, MMMM do YYYY'),
    long_h: date.format('dddd, MMMM do YYYY, hh:mm'),
  };
};

export const virtualMonthConverter = (month, reference) => {
  if (!month || !reference) {
    notification.error({
      message: 'Error al convertir la fecha respecto a la referencia',
    });
    return undefined;
  }
  if (reference > month) {
    return month + 12 - reference + 1;
  }
  return month - reference + 1;
};
