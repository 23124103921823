import React from 'react';
import { Button, Space } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

export const getColumns = (props) => {
  const { buttonActionToggleActivatedVoucher, buttonActionDowloadVoucher } =
    props;
  return [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: '4%',
    },
    {
      title: 'Edificio',
      key: 'client_name',
      dataIndex: 'client_name',
    },
    {
      title: 'Equipos afectados',
      key: 'equipment',
      dataIndex: 'equipment',
    },
    {
      title: 'Inicio',
      key: 'attention_init',
      dataIndex: 'attention_init',
    },
    {
      title: 'Fin',
      key: 'attention_finish',
      dataIndex: 'attention_finish',
    },
    {
      title: 'Estado final',
      key: 'finish_state',
      dataIndex: 'finish_state',
    },
    {
      title: 'Recepcionista',
      key: 'recepcionist_name',
      dataIndex: 'recepcionist_name',
    },
    {
      title: 'Acciones',
      fixed: 'right',
      width: '8%',
      render: (record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => buttonActionDowloadVoucher(record)}
          >
            <DownloadOutlined />
          </Button>
          <Button
            danger
            type="primary"
            onClick={() => buttonActionToggleActivatedVoucher(record)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];
};
