import { Button as AntdButton } from 'antd';
import { any, oneOf } from 'prop-types';
import React from 'react';

function buttonWhitColor(WrappedButton) {
  return function WrappedButtonWhitColor(props) {
    WrappedButtonWhitColor.propTypes = {
      color: oneOf(['red', 'yellow', 'green', 'primary', 'none']),
      // eslint-disable-next-line react/forbid-prop-types
      children: any.isRequired,
    };
    WrappedButtonWhitColor.defaultProps = {
      color: 'none',
    };
    const { color, children, ...otherProps } = props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (
      <WrappedButton
        {...otherProps}
        className={color ? `button-${color}` : null}
      >
        {children}
      </WrappedButton>
    );
  };
}

const Button = buttonWhitColor(AntdButton);

export default Button;
