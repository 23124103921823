import { Result } from 'antd';
import React from 'react';

function Error404() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Disculpa, la página visitada no existe."
      // extra={<Button type="primary">Back Home</Button>}
    />
  );
}

export default Error404;
