import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { STATUS_ERROR, STATUS_LOADING, useCallGetApi } from 'api/useCallApi';
import ErrorComponent from 'components/ErrorComponent';
import CategoryMaintanceForm, {
  addCategory,
  deleteCategory,
  editCategory,
} from 'components/forms/CategoryMaintanceForm';
import EliminarForm from 'components/forms/EliminarForm';
import MaintanceCategoriesList from 'components/lists/MaintanceCategoriesList';
import { useGetToApi } from 'hooks/useApi/useGetAxios';
import React, { useEffect, useMemo, useState } from 'react';
const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};
const CategoryListContainer = (props) => {
  const { addButton = false, reload } = props;
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [categorias, callCategorias, statusCategorias, errorInfo] =
    useCallGetApi('/categoria/all', {
      defaultValue: [],
    });
  useEffect(() => {
    callCategorias();
  }, [callCategorias]);

  const successFinish = () => {
    callCategorias();
    if (reload) {
      reload();
    }
    setModalProps(defaultModalProps);
  };

  const openDelete = async (category) => {
    setModalProps({
      open: true,
      children: (
        <EliminarForm
          title="Eliminar Categoría"
          id={category.id}
          deleteRegister={(id) => deleteCategory(id, successFinish)}
        />
      ),
    });
  };

  const openEdit = async (category) => {
    setModalProps({
      open: true,
      children: (
        <CategoryMaintanceForm
          buttonLabel="Editar"
          submitForm={(values) =>
            editCategory(values, category.id, successFinish)
          }
          formProps={{
            initialValues: category,
          }}
        />
      ),
      title: 'Editar categoría',
    });
  };

  const openAdd = () => {
    setModalProps({
      open: true,
      children: (
        <CategoryMaintanceForm
          buttonLabel="Agregar"
          submitForm={(values) => addCategory(values, successFinish)}
        />
      ),
      title: 'Agregar Categoría',
    });
  };
  if (statusCategorias === STATUS_ERROR) {
    return <ErrorComponent message={errorInfo.message} />;
  }
  return (
    <div>
      {addButton ? (
        <Button type="primary" block icon={<PlusOutlined />} onClick={openAdd}>
          Agregar
        </Button>
      ) : null}
      <MaintanceCategoriesList
        categories={categorias}
        isLoading={statusCategorias === STATUS_LOADING}
        openDelete={openDelete}
        openAdd={openAdd}
        openEdit={openEdit}
        onList
      />
      <Modal
        {...modalProps}
        footer={false}
        onCancel={() => setModalProps(defaultModalProps)}
      />
    </div>
  );
};

export default CategoryListContainer;
