import { createContext, useCallback, useContext } from 'react';
import { useAppApi } from 'hooks/useAppApi';

import useRequest from 'hooks/useRequest';

const ColeccionContext = createContext(null);

export const ColeccionProvider = ({ children }) => {
  const { request, isLoadingReq, errors } = useRequest();

  const map = useCallback((result) => {
    return result;
  }, []);

  const { data, isLoading, error, hasCalled, call } = useAppApi({
    baseUrl: '/colecciones',
    mapResults: map,
  });

  const createColeccion = useCallback(
    async (coleccionData) => {
      return await request('/colecciones', 'POST', coleccionData);
    },
    [request]
  );

  const updateColeccion = useCallback(
    async (id, coleccionData) => {
      return await request(`/colecciones/${id}`, 'PUT', coleccionData);
    },
    [request]
  );

  const deleteColeccion = useCallback(
    async (id) => {
      return await request(`/colecciones/${id}`, 'DELETE');
    },
    [request]
  );

  const getAllItems = useCallback(
    async (id) => {
      return await request(`/colecciones/${id}/items`, 'GET');
    },
    [request]
  );

  const getItemById = useCallback(
    async (itemId) => {
      return await request(`/colecciones/items/${itemId}`, 'GET');
    },
    [request]
  );

  const createItem = useCallback(
    async (id, itemData) => {
      return await request(`/colecciones/${id}/items`, 'POST', itemData);
    },
    [request]
  );

  const updateItem = useCallback(
    async (itemId, itemData) => {
      return await request(`/colecciones/items/${itemId}`, 'PUT', itemData);
    },
    [request]
  );

  const deleteItem = useCallback(
    async (itemId) => {
      return await request(`/colecciones/items/${itemId}`, 'DELETE');
    },
    [request]
  );

  const decrementItemCount = useCallback(
    async (itemId, quantity) => {
      return await request(`/colecciones/items/${itemId}/decrement`, 'POST', {
        quantity,
      });
    },
    [request]
  );

  const incrementItemCount = useCallback(
    async (itemId, quantity) => {
      return await request(`/colecciones/items/${itemId}/increment`, 'POST', {
        quantity,
      });
    },
    [request]
  );

  const findItemsByType = useCallback(
    async (type) => {
      return await request(`/colecciones/items/type/${type}`, 'GET');
    },
    [request]
  );

  const findItemsWithLowStock = useCallback(
    async (threshold) => {
      return await request(`/colecciones/items/lowstock/${threshold}`, 'GET');
    },
    [request]
  );

  return (
    <ColeccionContext.Provider
      value={{
        call,
        createColeccion,
        updateColeccion,
        deleteColeccion,
        getAllItems,
        getItemById,
        createItem,
        updateItem,
        deleteItem,
        decrementItemCount,
        incrementItemCount,
        findItemsByType,
        findItemsWithLowStock,
        data,
        isLoading,
        errors,
      }}
    >
      {children}
    </ColeccionContext.Provider>
  );
};

export const useColeccion = () => useContext(ColeccionContext);
