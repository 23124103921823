// DocumentComposer.jsx
import React, { useState } from 'react';
import { Button , Card , Form , Space , Layout , Input , Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import FieldComponent from './Components/FieldComponent';

const { Content } = Layout;

const DocumentComposer = ({ form }) => {
  const [newFieldTitle, setNewFieldTitle] = useState(''); // Título de la nueva Parte

  return (
    <Layout>
      {/* Contenido principal con el formulario dinámico */}
      <Layout>
        <Content style={{ overflow: 'auto' }}>
          <>
            <Form.List name="fields">
              {(fields, { add, remove, move }) => {
                return (
                  <div>
                    {/* Sección para agregar nuevas Partes */}
                    <Card
                      title="Agregar Nueva Parte"
                      size="small"
                      style={{ marginBottom: '24px' }}
                    >
                      <Space>
                        <Input
                          placeholder="Título de la Parte"
                          value={newFieldTitle}
                          onChange={(e) => setNewFieldTitle(e.target.value)}
                          style={{ width: '300px' }}
                        />
                        <Button
                          onClick={() => {
                            if (newFieldTitle.trim() === '') {
                              return;
                            }
                            add({
                              type: 'part',
                              title: newFieldTitle.trim(),
                            });
                            setNewFieldTitle('');
                          }}
                          icon={<PlusOutlined />}
                          type="primary"
                        >
                          Agregar Parte
                        </Button>
                      </Space>
                    </Card>

                    {/* Renderizado de cada Parte */}
                    {fields.map((field, listIndex) => {
                      return (
                        <div key={field.key} style={{ marginBottom: '16px' }}>
                          <FieldComponent
                            field={field}
                            form={form}
                            type="part"
                            move={move}
                            remove={remove}
                            listIndex={listIndex}
                            length={fields.length}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            </Form.List>
          </>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DocumentComposer;
