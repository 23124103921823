import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Form, Select } from 'antd';
import { useColeccion } from 'providers/ColeccionProvider';
import { usePlantillaTexto } from 'providers/PlantillasTextoProvider';

const { Option } = Select;

const PresupuestoCollectionDrawer = ({
  visible,
  onClose,
  onSelectTemplate,
}) => {
  const { call, data, getAllItems } = useColeccion();
  const [colectionItems, setColectionItems] = useState([]);
  const { getPlantillasByColeccionItemId } = usePlantillaTexto();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    call();
  }, [call]);

  const handleCollectionChange = useCallback(
    async (value) => {
      setColectionItems((await getAllItems(value)).items);
    },
    [getAllItems]
  );

  const handleItemChange = async (value) => {
    setTemplates((await getPlantillasByColeccionItemId(value)).plantillas);
  };

  const handleTemplateChange = (value) => {
    const selectedTemplate = templates.find(
      (template) => template.id === value
    );
    onSelectTemplate(selectedTemplate);
    onClose();
  };

  return (
    <Drawer
      title="Seleccionar Plantilla"
      placement="right"
      closable={true}
      onClose={onClose}
      open={visible}
      width={400}
    >
      <Form layout="vertical">
        <Form.Item label="Colección">
          <Select
            placeholder="Seleccione una colección"
            onChange={handleCollectionChange}
          >
            {data?.map((collection) => (
              <Option key={collection.id} value={collection.id}>
                {collection.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Ítem de la Colección">
          <Select placeholder="Seleccione un ítem" onChange={handleItemChange}>
            {colectionItems?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Plantilla">
          <Select
            placeholder="Seleccione una plantilla"
            onChange={handleTemplateChange}
          >
            {templates?.map((plantilla) => (
              <Option key={plantilla.id} value={plantilla.id}>
                {plantilla.titulo}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default PresupuestoCollectionDrawer;
