import axios from 'axios';
import { getAccessTokenApi } from './auth';
import { API_PATH } from 'config';
import { notification } from 'antd';

export const getToApi = (url, config) => {
  return new Promise((resolve, reject) => {
    const token = getAccessTokenApi();
    axios
      .get(`${API_PATH}${url}`, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        ...config,
      })
      .then((result) => {
        return result.data;
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const postToApi = (url, body, config) => {
  return new Promise((resolve, reject) => {
    const token = getAccessTokenApi();
    axios
      .post(`${API_PATH}${url}`, body, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        ...config,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const putToApi = (url, body, config) => {
  return new Promise((resolve, reject) => {
    const token = getAccessTokenApi();
    axios
      .put(`${API_PATH}${url}`, body, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        ...config,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteToApi = (url, body, config) => {
  return new Promise((resolve, reject) => {
    const token = getAccessTokenApi();
    axios
      .delete(`${API_PATH}${url}`, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        ...config,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        if (!err.response) {
          reject(err);
        }
        if (!err.response.data) {
          reject(err);
        }
        reject(err.response.data);
      });
  });
};
