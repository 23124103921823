import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import {
  showDownloadVoucherNotification,
  toggleActivatedVoucher,
} from './table.functions';
import { getColumns } from './table.columns';

function VoucherTable(props) {
  const { vouchers, setReload } = props;
  const { user = { fullname: 'nombre especialista' } } = useAuth();

  /**
   * Acción de botón que permite disparar la notificación
   * con el botón para descargar el comprobante
   * @param {Object} voucher
   */
  const buttonActionDowloadVoucher = (voucher) => {
    showDownloadVoucherNotification(user, voucher);
  };

  /**
   * Acción de botón que permite alternar el estado del
   * comprobante
   * @param {Object} voucher
   */
  const buttonActionToggleActivatedVoucher = (voucher) => {
    toggleActivatedVoucher(voucher.id, setReload);
  };

  /**
   * Columnas para la tabla
   */
  const columns = getColumns({
    buttonActionToggleActivatedVoucher,
    buttonActionDowloadVoucher,
  });

  return <Table columns={columns} dataSource={vouchers} scroll={{ x: 1500 }} />;
}

const voucher = {
  id: PropTypes.number.isRequired,
  client_name: PropTypes.string.isRequired,
  equipment: PropTypes.string.isRequired,
  attention_init: PropTypes.string.isRequired,
  attention_finish: PropTypes.string.isRequired,
  finish_state: PropTypes.string.isRequired,
  recepcionist_name: PropTypes.string.isRequired,
};

VoucherTable.propTypes = {
  vouchers: PropTypes.arrayOf(PropTypes.shape(voucher)).isRequired,
  setReload: PropTypes.func.isRequired,
};
export default VoucherTable;
