import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  message,
  Modal,
  Table,
  Space,
  Card,
  Descriptions,
} from 'antd';
import { usePlantillaTexto } from 'providers/PlantillasTextoProvider';
import PlantillaForm from 'components/forms/PlantillaForm';
import Title from 'antd/es/typography/Title';

const DocumentComposerPage = () => {
  const { id, tipoDocumento } = useParams();
  const [plantillas, setPlantillas] = useState([]);
  const [item, setItem] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPlantilla, setCurrentPlantilla] = useState(null);
  const {
    getPlantillasByColeccionItemId,
    createPlantillaTexto,
    updatePlantillaTexto,
  } = usePlantillaTexto();

  const [form] = Form.useForm();

  const getPlantillas = useCallback(async () => {
    const result = await getPlantillasByColeccionItemId(id);
    setPlantillas(result?.plantillas || []);
    setItem(result?.item || null);
  }, [id, getPlantillasByColeccionItemId]);

  useEffect(() => {
    getPlantillas();
  }, [getPlantillas]);

  const showModal = (plantilla = null) => {
    setCurrentPlantilla(plantilla);
    form.setFieldsValue(plantilla || { nombre: '', texto: '' });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentPlantilla(null);
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      if (currentPlantilla) {
        await updatePlantillaTexto(currentPlantilla.id, values);
        message.success('Plantilla actualizada correctamente');
      } else {
        await createPlantillaTexto({
          ...values,
          coleccion_item_id: id,
          tipo_documento: tipoDocumento,
        });
        message.success('Plantilla creada correctamente');
      }
      getPlantillas();
      setIsModalVisible(false);
      setCurrentPlantilla(null);
    } catch (error) {
      message.error('Error al guardar la plantilla');
    }
  };

  const columns = [
    {
      title: 'Título',
      dataIndex: 'titulo',
      key: 'titulo',
    },
    {
      title: 'Texto',
      dataIndex: 'texto',
      key: 'texto',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (_, record) => (
        <Space>
          <Button type="primary" onClick={() => showModal(record)}>
            Editar
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="space-y-4">
      <Card className="welcome col-span-3">
        <div className="flex flex-row justify-between">
          <Title level={3} className=" text-start">
            <span className="uppercase text-white">
              Compositor de Documentos ({tipoDocumento})
            </span>
          </Title>
        </div>
      </Card>

      {item && (
        <Card title="Detalle del Ítem" className="mb-4">
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Nombre">{item.nombre}</Descriptions.Item>
            <Descriptions.Item label="Descripción">
              {item.descripcion}
            </Descriptions.Item>
            <Descriptions.Item label="Cantidad">
              {item.cantidad}
            </Descriptions.Item>
            <Descriptions.Item label="Precio">{item.precio}</Descriptions.Item>
            <Descriptions.Item label="Costo">{item.costo}</Descriptions.Item>
            <Descriptions.Item label="Tipo de Ítem">
              {item.tipo_item}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}

      <Card className="welcome col-span-3">
        <div className="flex flex-row justify-between">
          <Title level={3} className=" text-start">
            <span className="uppercase text-white">Plantillas</span>
          </Title>
          <Button type="primary" onClick={() => showModal()} className="mb-4">
            Agregar Plantilla
          </Button>
        </div>
      </Card>

      <Table dataSource={plantillas} columns={columns} rowKey="id" />
      <Modal
        title={currentPlantilla ? 'Editar Plantilla' : 'Agregar Plantilla'}
        open={isModalVisible}
        onOk={handleSave}
        onCancel={handleCancel}
      >
        <PlantillaForm form={form} />
      </Modal>
    </div>
  );
};

export default DocumentComposerPage;
