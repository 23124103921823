import { Button, Card, Modal } from 'antd';
import React, { useState } from 'react';
import FormMaintanceReports from '../../components/specialist/MaintanceReports/FormMaintanceReports/FormMaintanceReports';

import { useApi } from '../../hooks/useApi';
import Title from 'antd/es/typography/Title';
import { PlusOutlined } from '@ant-design/icons';
import MaintanceReportList from 'components/lists/MaintanceReportList';

function MaintanceReports() {
  const [modal, setModal] = useState({
    Element: null,
    title: '',
  });
  const [open, setOpen] = useState(false);

  const [reports, reloadReports] = useApi({
    url: 'maintance-report/specialist',
    defaultValue: [],
    successEnabled: false,
    key: 'reports',
  });
  const closeModal = () => {
    setOpen(false);
  };

  const openFormMaintanceReport = () => {
    setModal({
      title: 'Nuevo Informe',
      Element: (
        <FormMaintanceReports
          reloadReports={reloadReports}
          closeModal={closeModal}
        />
      ),
    });
    setOpen(true);
  };

  return (
    <div>
      <Card className="flex flex-col items-center text-center">
        <Title level={3}>Informes de mantención</Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={openFormMaintanceReport}
        >
          Agregar Informe
        </Button>
      </Card>
      <MaintanceReportList reports={reports} isLoading={false} />
      <Modal
        open={open}
        title={modal.title}
        footer={false}
        onCancel={() => setOpen(false)}
      >
        {modal.Element}
      </Modal>
    </div>
  );
}

export default MaintanceReports;
