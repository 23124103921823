import { useAppApi } from 'hooks/useAppApi';
import React, { createContext, useCallback, useContext } from 'react';

// Crear el contexto
const ProgramadasContext = createContext(null);

// Componente proveedor del contexto
export const ProgramadasProvider = ({ children }) => {
  const mapGet = useCallback((response) => {
    return response.data;
  }, []);

  const { data, error, isLoading, hasCalled, call } = useAppApi({
    baseUrl: `/mantenciones/programadas`,
    mapResults: mapGet,
  });
  return (
    <ProgramadasContext.Provider
      value={{ data, error, isLoading, hasCalled, call }}
    >
      {children}
    </ProgramadasContext.Provider>
  );
};

export const useProgramadas = () => useContext(ProgramadasContext);
