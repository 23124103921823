import { Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '12px',
    textTransform: 'uppercase',
    marginBottom: '10px',
  },
  leyend: {
    fontSize: '10px',
    margin: '0 20px',
  },
});

function BudgetGuarantee(props) {
  const { guarantee } = props;
  return (
    <View>
      <Text style={styles.title}>3. Garantía de los trabajos</Text>
      <Text style={styles.leyend}>
        {guarantee}. Por efectos de fallas de fabricación, diseño o instalación
        no incluyendo en esta garantía los daños causados por intervenciones de
        terceros, actos de vandalismo, problemas de voltaje, desastres
        naturales, incendios, o daños no atribuibles a su uso normal.
      </Text>
    </View>
  );
}
BudgetGuarantee.propTypes = {
  guarantee: PropTypes.string.isRequired,
};

export default BudgetGuarantee;
