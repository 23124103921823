import { CalendarFilled, CalendarOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { List, Typography } from 'antd';
import { Meta } from 'antd/es/list/Item';
import Paragraph from 'antd/es/typography/Paragraph';
import React, { useMemo } from 'react';
import { virtualMonthConverter } from 'utils/formaters';
const { Text } = Typography;

const ActivityCellCalendar = (props) => {
  const { month, activities, referenceMonth = 1 } = props;
  const number = useMemo(() => {
    let _month = month.number;
    _month = virtualMonthConverter(_month, referenceMonth);
    return _month;
  }, [month.number, referenceMonth]);

  const thisActivities = useMemo(() => {
    return activities.filter((activity) =>
      activity.Fechas.find((fecha) => fecha.mes === number)
    );
  }, [activities, number]);
  return (
    <List
      itemLayout="horizontal"
      dataSource={thisActivities}
      pagination={{
        align: 'end',
        position: 'bottom',
        pageSize: 6,
      }}
      renderItem={(data) => (
        <List.Item>
          <List.Item.Meta
            avatar={<CalendarOutlined />}
            title={
              <Text strong className="uppercase">
                {data.Categoria.nombre}
              </Text>
            }
            description={data.resumen}
          />
        </List.Item>
      )}
    />
  );
};

export default ActivityCellCalendar;
