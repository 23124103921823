import { Button, Card, notification, Space } from 'antd';
import Title from 'antd/es/typography/Title';
import ActividadesTable from 'components/tables/ActividadesTable';
import useRequest from 'hooks/useRequest';
import { useActividades } from 'providers/ActividadProvider';
import { useColeccion } from 'providers/ColeccionProvider';
import { usePlantillaTexto } from 'providers/PlantillasTextoProvider';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const ActividadesComponent = ({
  data,
  openAdd,
  openEdit,
  openDelete,
  itemId,
  item,
}) => {
  return (
    <Space direction="vertical" className="w-full">
      <Card className="welcome col-span-3">
        <div className="flex flex-row justify-between">
          <Title level={3} className="text-start">
            <span className="uppercase text-white">Ítems de la Colección</span>
          </Title>
          <Button
            type="primary"
            className="mb-4"
            onClick={() => openAdd(null, true, itemId)}
          >
            Agregar Item
          </Button>
        </div>
      </Card>
      <ActividadesTable
        data={item?.Actividades}
        openEdit={openEdit}
        openDelete={openDelete}
      />
    </Space>
  );
};

const ItemsColeccionPage = () => {
  const { id, itemId } = useParams();
  const location = useLocation();
  const [current, setCurrent] = useState();

  const { openAdd, openEdit, openDelete } = useActividades();

  const [item, setItem] = useState({});

  const { request, isLoadingReq, errors } = useRequest();
  const getItem = useCallback(async () => {
    try {
      const result = await request(`/colecciones/items/${itemId}`, 'GET');
      setItem(result);
      return result;
    } catch (error) {
      notification.error({
        message: 'Error al cargar el ítem',
        description: error.message,
      });
    }
  }, [request, itemId]);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    if (type) {
      setCurrent(type);
    }
  }, [location.search]);

  useEffect(() => {
    getItem();
  }, [getItem]);

  const renderComponent = () => {
    switch (current) {
      case 'ACTIVIDADES':
        return (
          <ActividadesComponent
            openAdd={openAdd}
            openEdit={openEdit}
            openDelete={openDelete}
            itemId={itemId}
            item={item}
          />
        );
      case 'comprobantes':
        return <div>Comprobantes</div>;
      default:
        return <div>Error</div>;
    }
  };

  return renderComponent();
};

export default ItemsColeccionPage;
