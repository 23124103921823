import { Button, Form, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useForm } from 'antd/lib/form/Form';
import { arrayOf, func, shape } from 'prop-types';
import React, { useEffect } from 'react';

function EditMaintanceItemForm(props) {
  const { updateFunc, initialValues, categorys } = props;
  const [form] = useForm();
  const submitToEdit = async () => {
    const values = form.getFieldsValue();

    updateFunc(values, initialValues.id);
  };
  useEffect(() => {
    form.resetFields();
  });

  return (
    <Form form={form} initialValues={initialValues} onFinish={submitToEdit}>
      <Form.Item
        name="id_category"
        rules={[
          {
            required: true,
            message: 'Seleccione la categoría',
          },
        ]}
        label="Categoría"
      >
        <Select placeholder="Seleccione la categoría">
          {categorys?.map((item) => (
            <Select.Option key={item.id}>{item.category}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Descripción"
        name="description"
        rules={[
          {
            required: true,
            message: 'La descripción es obligatoria',
          },
        ]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">Editar</Button>
      </Form.Item>
    </Form>
  );
}

EditMaintanceItemForm.propTypes = {
  initialValues: shape({}).isRequired,
  updateFunc: func.isRequired,
  categorys: arrayOf(shape({})).isRequired,
};

export default EditMaintanceItemForm;
