import { createContext, useCallback, useContext } from 'react';
import { useAppApi } from 'hooks/useAppApi';
import useRequest from 'hooks/useRequest';

const AdminContext = createContext(null);

export const AdminProvider = ({ children }) => {
  const { request, isLoadingReq, errors } = useRequest();

  // Mapear los resultados de la llamada API, si es necesario
  const map = useCallback((result) => {
    return result;
  }, []);

  // Base para las rutas CRUD generales
  const { data, isLoading, error, hasCalled, call } = useAppApi({
    baseUrl: '/admin',
    mapResults: map,
  });

  // Obtener todos los modelos registrados
  const getAllModels = useCallback(async () => {
    return await request('/admin/models', 'GET');
  }, [request]);

  // Operaciones CRUD generales para cualquier modelo registrado
  const createRecord = useCallback(
    async (model, recordData) => {
      return await request(`/admin/${model}`, 'POST', recordData);
    },
    [request]
  );

  const updateRecord = useCallback(
    async (model, id, recordData) => {
      console.log(model);

      return await request(`/admin/${model}/${id}`, 'PUT', recordData);
    },
    [request]
  );

  const deleteRecord = useCallback(
    async (model, id) => {
      return await request(`/admin/${model}/${id}`, 'DELETE');
    },
    [request]
  );

  const getRecordById = useCallback(
    async (model, id) => {
      return await request(`/admin/${model}/${id}`, 'GET');
    },
    [request]
  );

  const getAllRecords = useCallback(
    async (model) => {
      return await request(`/admin/${model}`, 'GET');
    },
    [request]
  );

  return (
    <AdminContext.Provider
      value={{
        call,
        getAllModels,
        createRecord,
        updateRecord,
        deleteRecord,
        getRecordById,
        getAllRecords,
        data,
        isLoading,
        errors,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);
