import { Button, Form, Input, Select, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { useObservacionAcciones } from 'providers/ObservacionAccionesProvider';
import { useObservacionesTypes } from 'providers/ObservacionesTypesProvider';
import React, { useEffect } from 'react';

const ObservacionForm = ({ initialValues, observationId, onFinish }) => {
  const {
    call: callObservacionesTypes,
    hasCalled: hasCalledObservacionesTypes,
    isLoading: isLoadingObservacionesTypes,
    data: dataObservacionesTypes,
  } = useObservacionesTypes();
  const { create, update, isLoadingCreate, isLoadingUpdate } =
    useObservacionAcciones();
  useEffect(() => {
    if (!hasCalledObservacionesTypes) {
      callObservacionesTypes();
    }
  }, [callObservacionesTypes, hasCalledObservacionesTypes]);
  const [form] = useForm();
  const handleSubmit = async (values) => {
    try {
      let response;
      if (observationId) {
        response = await update(observationId, values);
      } else {
        response = await create(values);
      }
      onFinish(response);
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error',
        description: error.message,
      });
    }
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Form.Item name="id_cliente" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name="observation_type_id"
        label="Tipo de observación"
        rules={[
          {
            required: true,
            message: 'Por favor selecciona un tipo de observación',
          },
        ]}
      >
        <Select
          loading={isLoadingObservacionesTypes}
          options={dataObservacionesTypes?.map((type) => ({
            value: type.id,
            label: type.name,
          }))}
          placeholder="Selecciona un tipo de observación"
        />
      </Form.Item>
      <Form.Item
        name="description"
        label="Descripción de la observación"
        rules={[
          {
            required: true,
            message: 'Por favor escribe una descripción de la observación',
          },
        ]}
      >
        <TextArea placeholder="Escribe aquí la observación" />
      </Form.Item>
      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          loading={isLoadingUpdate || isLoadingCreate}
        >
          {observationId ? 'Actualizar' : 'Agregar'}
          {/* Agregar */}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ObservacionForm;
