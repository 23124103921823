/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import './Signature.scss';

import SignatureCanvas from 'react-signature-canvas';
import {
  ArrowLeftOutlined,
  ClearOutlined,
  FullscreenOutlined,
} from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';

function Signature(props) {
  const { onChange } = props;
  const signatureRef = useRef({});
  const saveSignature = () => {
    const originalCanvas = signatureRef.current.getTrimmedCanvas();
    // const originalDataUrl = originalCanvas.toDataURL('image/png');

    // Rotar la imagen en sentido antihorario en 90 grados
    const rotatedCanvas = document.createElement('canvas');
    const ctx = rotatedCanvas.getContext('2d');
    rotatedCanvas.width = originalCanvas.height;
    rotatedCanvas.height = originalCanvas.width;
    ctx.rotate(-Math.PI / 2);
    ctx.drawImage(originalCanvas, -originalCanvas.width, 0);

    // Obtener la representación en base64 de la imagen rotada
    const rotatedDataUrl = rotatedCanvas.toDataURL('image/jpeg');
    onChange(rotatedDataUrl);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (open) {
      notification.info({
        message:
          'Para firmar, la flecha debe apuntar hacia tí o hacia el suelo.',
      });
    }
  }, [open]);
  return (
    <div className="signature-container">
      {!open ? (
        <Button block icon={<FullscreenOutlined />} onClick={handleOpen}>
          Presiona Aquí para Firmar
        </Button>
      ) : null}
      <Modal
        open={open}
        footer={false}
        onCancel={() => setOpen(false)}
        className={'signature-modal-container'}
        title="La flecha hacia tí o hacia el suelo"
        width={350}
      >
        <div className="d-flex flex-dc flex-allign-items-center">
          <SignatureCanvas
            ref={signatureRef}
            canvasProps={{
              width: 300,
              height: 450,
              className: 'sigCanvas',
            }}
            backgroundColor="white"
            onEnd={() => saveSignature()}
          />
          <Button
            icon={<ClearOutlined />}
            danger
            type="default"
            className="mt-2 wd-50"
            onClick={() => signatureRef.current.clear()}
            block
          />
          <div className="w-full text-center">
            <ArrowLeftOutlined className="text-2xl mt-2" />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Signature;
