import { useState, useCallback } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { API_PATH } from 'config';
import { getAccessTokenApi } from 'api/auth';

// Configurar axios-retry
axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 1000,
  retryCondition: (error) =>
    error.response.status >= 500 || error.code === 'ECONNABORTED',
});

const useRequest = () => {
  const [loadingStates, setLoadingStates] = useState({});
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const request = useCallback(
    async (url, method, body = null, headers = {}, queryParams = {}) => {
      const token = getAccessTokenApi();
      const query = queryParams
        ? new URLSearchParams(queryParams).toString()
        : '';
      const fullUrl = `${API_PATH}${url}${query ? `?${query}` : ''}`;

      setLoadingStates((prev) => ({ ...prev, [fullUrl]: true }));

      try {
        const response = await axios({
          headers: {
            Authorization: token,
            ...headers,
          },
          method,
          url: fullUrl,
          data: body,
        });

        setData((prev) => ({ ...prev, [fullUrl]: response.data }));
        setLoadingStates((prev) => ({ ...prev, [fullUrl]: false }));
        return response.data;
      } catch (err) {
        setErrors((prev) => ({ ...prev, [fullUrl]: err }));
        setLoadingStates((prev) => ({ ...prev, [fullUrl]: false }));
        return Promise.reject(err);
      }
    },
    []
  );

  const isLoading = useCallback(
    (url) => {
      const fullUrl = `${API_PATH}${url}`;
      return loadingStates[fullUrl] || false;
    },
    [loadingStates]
  );

  return { request, data, errors, isLoading };
};

export default useRequest;
