import { notification } from 'antd';

/**
 * Muestra una notificación utilizando la biblioteca Ant Design.
 * Si la respuesta contiene un mensaje, se muestra una notificación de éxito.
 * De lo contrario, se muestra una notificación de advertencia con un mensaje de fallback.
 *
 * @param {object} response - La respuesta recibida de la API.
 * @param {string} successMessageFallback - Mensaje de fallback para notificación de éxito.
 * @param {string} errorMessagePrefix - Prefijo para mensajes de error.
 */
export const showNotification = (
  response,
  successMessageFallback,
  errorMessagePrefix
) => {
  const { data = {} } = response;
  if (data && data.message) {
    notification.success({
      message: data.message,
    });
  } else {
    notification.warning({
      message: successMessageFallback,
    });
  }
};

/**
 * Muestra una notificación de error utilizando la biblioteca Ant Design.
 *
 * @param {Error} error - El error recibido al realizar una operación.
 * @param {string} errorMessagePrefix - Prefijo para mensajes de error.
 */
export const showErrorNotification = (error, errorMessagePrefix) => {
  notification.error({
    message: `${errorMessagePrefix}: ${error.message}`,
  });
};
