import { generarmantencionesProg } from 'components/forms/MantencionProgForm';
import { useAppApi } from 'hooks/useAppApi';
import { report } from 'process';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const MantencionContext = createContext(null);

export const MantencionProvider = ({ children }) => {
  const [filteredReports, setFilteredReports] = useState([]);
  const [manStates, setManStates] = useState([]);

  const mapReports = useCallback((result) => {
    setManStates(result.states);
    return result.data;
  }, []);

  const {
    data: reports,
    isLoading,
    error,
    hasCalled,
    call: callReports,
  } = useAppApi({
    baseUrl: '/mantenciones/all/programadas',
    mapResults: mapReports,
  });

  // useEffect(() => {
  //   callReports();
  // }, [callReports]);

  // Actualiza el estado con los reports filtrados
  const filterReports = useCallback(
    (filterCallback) => {
      if (typeof filterCallback === 'function') {
        const newFilteredReports = [...reports].filter(filterCallback);
        setFilteredReports(newFilteredReports);
      }
    },
    [reports]
  );
  useEffect(() => {
    setFilteredReports(reports);
  }, [reports]);

  const finish = () => {
    callReports();
  };

  const syncMantAuto = async () => {
    await generarmantencionesProg(finish);
  };
  const defaultStatus = 'SIN ASIGNAR';

  return (
    <MantencionContext.Provider
      value={{
        isLoading,
        error,
        hasCalled,
        reports,
        filteredReports,
        filterReports,
        sync: syncMantAuto,
        manStates,
        defaultStatus,
      }}
    >
      {children}
    </MantencionContext.Provider>
  );
};

export const useMantencion = () => useContext(MantencionContext);
