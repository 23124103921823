import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { usePostApi } from '../../../../hooks/useApi';
import Signature from '../../../global/Form/Signature/Signature';

function FormFinishMaintanceReport() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const [post] = usePostApi({
    url: 'maintance-report/specialist/report',
  });

  const submit = async () => {
    const values = form.getFieldsValue();
    const response = await post({
      ...values,
      idMaintanceReport: id,
    });

    if (response.state === 'success') {
      return navigate('../specialist/MaintanceReports');
    }
  };

  return (
    <div>
      <div className="d-flex flex-allign-items-center flex-justify-content-between">
        <Link to={`../specialist/MaintanceReports/${id}`}>
          <Button
            icon={<ArrowLeftOutlined />}
            type={'primary'}
            className="mr-1 content-box"
          />
        </Link>
        <h3 className="font-w-title">Finalizar Informe N°{id}</h3>
      </div>
      <Card className="mt-5 wd-60 wd-md mv-c">
        <Form form={form} onFinish={submit}>
          <Form.Item
            label="Observaciones generales"
            name="generalObservation"
            labelCol={{ span: 24 }}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            label="Nombre de recepción"
            name="recepcionistName"
            labelCol={{ span: 24 }}
          >
            <Input placeholder="Nombre de quien recibe" />
          </Form.Item>
          <Form.Item
            label="Cargo de recepción"
            name="recepcionistCharge"
            rules={[
              {
                required: true,
                message: 'El cargo del recepcionista es obligatorio',
              },
            ]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder="Cargo de quien recibe" />
          </Form.Item>
          <Form.Item
            label="Firma de recepción"
            name="recepcionistSignature"
            labelCol={{ span: 24 }}
          >
            <Signature form={form} />
          </Form.Item>
          <Form.Item
            label="Firma del especialista"
            name="especialistSignature"
            labelCol={{ span: 24 }}
          >
            <Signature form={form} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Finalizar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default FormFinishMaintanceReport;
