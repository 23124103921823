import { useAppApi, usePAppApi, useUAppApi } from 'hooks/useAppApi';
import React, { createContext, useCallback, useContext } from 'react';

// Crear el contexto
const UserActionsContext = createContext(null);

// Componente proveedor del contexto
export const UserActionsProvider = ({ children }) => {
  const { call: toggleStatus, isLoading: toggleLoading } = useUAppApi({
    url: '/user/toggle-active',
    mapResults: useCallback((data) => {
      console.log(data);
      return data;
    }, []),
  });
  const { call: addUserNotFunctional } = usePAppApi({
    url: '/user/add',
    mapResults: useCallback((data) => {
      console.log(data);
      return data;
    }, []),
  });
  const add = async (values) => {
    console.log(values);
    const response = await addUserNotFunctional(values);
    return response;
  };
  return (
    <UserActionsContext.Provider
      value={{
        toggleStatus,
        toggleLoading,
        add,
      }}
    >
      {children}
    </UserActionsContext.Provider>
  );
};

export const useUserActions = () => useContext(UserActionsContext);
