import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Button, Modal, Form, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useColeccion } from 'providers/ColeccionProvider';
import Title from 'antd/es/typography/Title';
import ColeccionForm from 'components/forms/ColeccionForm';
import { EditOutlined } from '@ant-design/icons';

const ColeccionesPage = () => {
  const navigate = useNavigate();
  const { call, hasCalled, data, createColeccion, updateColeccion } =
    useColeccion();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentColeccion, setCurrentColeccion] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!hasCalled) {
      call();
    }
  }, [call, hasCalled]);

  const showModal = (coleccion = null) => {
    setCurrentColeccion(coleccion);
    form.setFieldsValue(
      coleccion || { nombre: '', descripcion: '', imagen: '', type: '' }
    );
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentColeccion(null);
  };

  const handleSave = async (values) => {
    try {
      if (currentColeccion) {
        await updateColeccion(currentColeccion.id, values);
        message.success('Colección actualizada correctamente');
      } else {
        await createColeccion(values);
        message.success('Colección creada correctamente');
      }
      call();
      setIsModalVisible(false);
      setCurrentColeccion(null);
    } catch (error) {
      message.error('Error al guardar la colección');
    }
  };

  return (
    <div className="site-card-wrapper space-y-2">
      <Card className="welcome col-span-3">
        <div className="flex justify-between items-center">
          <Title level={3} className="text-start">
            <span className="uppercase text-white">Colecciones de Datos</span>
          </Title>
          <Button type="primary" onClick={() => showModal()}>
            Agregar Colección
          </Button>
        </div>
      </Card>
      <Row gutter={16}>
        {data?.map((coleccion) => (
          <Col span={8} key={coleccion.id}>
            <Card
              hoverable
              onClick={() =>
                navigate(
                  `/admin/colecciones/${coleccion.id}?type=${coleccion.type}`
                )
              }
              title={coleccion.nombre}
              extra={
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    showModal(coleccion);
                  }}
                >
                  Editar
                </Button>
              }
            >
              <Card.Meta
                description={
                  <>
                    <p>{coleccion.descripcion}</p>
                    <p>
                      <strong>Tipo: </strong>
                      {coleccion.type}
                    </p>
                  </>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        title={currentColeccion ? 'Editar Colección' : 'Agregar Colección'}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <ColeccionForm
          form={form}
          initialValues={
            currentColeccion || {
              nombre: '',
              descripcion: '',
              imagen: '',
              tipo: '',
            }
          }
          onFinish={handleSave}
        />
      </Modal>
    </div>
  );
};

export default ColeccionesPage;
