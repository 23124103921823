import React, { useState } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import ComposerHeader from 'PDF/ComposerComponents/ComposerHeader';
import ComposerFooter from 'PDF/ComposerComponents/ComposerFooter';
import PresupuestoTableItems from './PresupuestoTableItems';
import CustomerInfo from 'PDF/ComposerComponents/CustomerInfo';
import ComposerMarkdown from '../ComposerComponents/ComposerMarkdown';

const replaceVariables = (text, variablesMap) => {
  return (
    text &&
    text.replace(/{(\w+)}/g, (_, key) => variablesMap[key] || `{${key}}`)
  );
};

const isNonEmptyString = (str) => str && str.trim().length > 0;

const PresupuestoViewer = ({ items, variablesMap, config, colors, id }) => {
  let imageCounter = 1;
  let primaryTitleCounter = 1; // Contador para los títulos principales

  const styles = StyleSheet.create({
    page: {
      paddingTop: 130,
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 70,
    },
    section: {
      marginBottom: 10,
    },
    primaryTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      color: colors.primary ?? '#4CAF50',
      marginBottom: 10,
      marginTop: 20,
      textTransform: 'uppercase',
    },
    secondaryTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#000',
      marginBottom: 10,
      marginTop: 15,
      textTransform: 'uppercase',
    },
    bold: {
      fontWeight: 'extrabold',
      fontSize: 14,
    },
    text: {
      fontSize: 12,
      marginBottom: 10,
      lineHeight: 1.5,
    },
    imageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
    },
    image: {
      maxWidth: '100%',
      maxHeight: 200,
      height: 'auto',
    },
    caption: {
      fontSize: 10,
      fontStyle: 'italic',
      textAlign: 'center',
      marginTop: 4,
    },
    ul: {
      marginBottom: 10,
      paddingLeft: 15, // Añadir sangría a la izquierda para toda la lista
    },
    li: {
      fontSize: 12,
      lineHeight: 1.5,
      marginBottom: 4,
      flexDirection: 'row', // Para mostrar el símbolo de lista y el texto en línea
      alignItems: 'center', // Centra el círculo con el texto
    },
    bulletPoint: {
      marginRight: 5, // Espacio entre el círculo y el texto
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <ComposerHeader config={config} colors={colors} id={id} />
        {items?.map((item, index) => (
          <View key={index} style={styles.section} wrap>
            <Item
              item={item}
              styles={styles}
              variablesMap={variablesMap}
              colors={colors}
              primaryTitleCounter={primaryTitleCounter}
              imageCounter={imageCounter}
            />
          </View>
        ))}
        <ComposerFooter config={config} colors={colors} />
      </Page>
    </Document>
  );
};
const Item = ({
  item,
  styles,
  variablesMap,
  colors,
  primaryTitleCounter,
  imageCounter,
}) => {
  if (item.type === 'markdown') {
    return <ComposerMarkdown text={item.description} styles={styles} />;
  }
  return (
    <>
      {item.titulo_type === 'principal' && isNonEmptyString(item.titulo) && (
        <Text style={styles.primaryTitle}>
          {`${primaryTitleCounter++}. ${replaceVariables(
            item.titulo,
            variablesMap
          )}`}
        </Text>
      )}
      {item.titulo_type === 'secundario' && isNonEmptyString(item.titulo) && (
        <Text style={styles.secondaryTitle}>
          {replaceVariables(item.titulo, variablesMap)}
        </Text>
      )}
      {isNonEmptyString(item.description) && (
        <Text style={styles.text}>
          {replaceVariables(item.description, variablesMap)}
        </Text>
      )}
      {item.type === 'itemTable' && (
        <PresupuestoTableItems items={item.itemsTable} colors={colors} />
      )}
      {item.type === 'image' && (
        <View style={styles.imageContainer} wrap={false}>
          <Image
            style={styles.image}
            source={item?.image?.path}
            resizeMode="contain"
          />
          <Text style={styles.caption}>
            {`Imagen ${imageCounter}: ${replaceVariables(
              item.caption,
              variablesMap
            )}`}
          </Text>
          {imageCounter++}
        </View>
      )}
      {item.type === 'customerInfo' && (
        <CustomerInfo
          colors={colors}
          customerInfo={item.customerInfo}
          variablesMap={variablesMap}
        />
      )}
    </>
  );
};
export default PresupuestoViewer;
