import React from 'react';
import { Form, Checkbox, Row, Col, Input } from 'antd';

const customerFields = [
  { label: 'Nombre', key: 'nombre_cliente' },
  { label: 'RUT', key: 'rut_cliente' },
  { label: 'Dirección', key: 'direccion_cliente' },
  { label: 'Comuna', key: 'comuna_cliente' },
  { label: 'Nombre de Contacto', key: 'nombre_contacto' },
  { label: 'Teléfono', key: 'telefono_cliente' },
  { label: 'Email', key: 'email_cliente' },
];

const CustomerInfoModule = ({ restField, name }) => {
  return (
    <Row gutter={16}>
      <Col xs={24}>
        <Form.Item {...restField} name={[name, 'titulo']} label="Título">
          <Input placeholder="Título (Opcional)" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          {...restField}
          name={[name, 'customerInfo']}
          label="Seleccionar Información del Cliente"
        >
          <Checkbox.Group>
            <Row>
              {customerFields.map((field) => (
                <Col span={8} key={field.key}>
                  <Checkbox value={field.key}>{field.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Col>
      {/* <Col span={24}>
        <Form.Item {...restField} name={[name, 'date']} label="Fecha">
          <DatePicker />
        </Form.Item>
      </Col> */}
    </Row>
  );
};

export default CustomerInfoModule;
