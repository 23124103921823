import PropTypes from 'prop-types';

const budgetProp = {
  name: PropTypes.string.isRequired,
  idBudget: PropTypes.number.isRequired,
  address: PropTypes.string.isRequired,
  commune: PropTypes.string.isRequired,
  email: PropTypes.string,
  reason: PropTypes.string.isRequired,
  guarantee: PropTypes.string.isRequired,
  include: PropTypes.string.isRequired,
  conditions: PropTypes.string.isRequired,
  createdOn: PropTypes.string,
  description: PropTypes.string.isRequired,
  finishTime: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};
export default {
  budget: PropTypes.shape(budgetProp).isRequired,
};
