import { DownloadOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { useClienteMantenciones } from 'providers/ClienteMantencionesProvider';
import { useSolicitudesMantencion } from 'providers/SolicitudesMantencionProvider';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ClienteMantencionesPage = ({ id }) => {
  const { call: callMantenciones, mantenciones } = useClienteMantenciones();
  useEffect(() => {
    if (id) {
      callMantenciones(id);
    }
  }, [callMantenciones, id]);
  const { openDowloadMantencion } = useSolicitudesMantencion();

  const handleClickInSolc = (record) => {
    openDowloadMantencion({
      id_mantencion: record.id,
    });
  };

  const getEstadoClassName = (estado) => {
    switch (estado) {
      case 'FINALIZADA':
        return 'bg-green-100 text-green-800';
      case 'ENVIADA':
        return 'bg-blue-100 text-blue-800';
      case 'EN_CURSO':
        return 'bg-yellow-100 text-yellow-800';
      case 'CANCELADA':
        return 'bg-red-100 text-red-800';
      default:
        return '';
    }
  };
  const { user } = useAuth();

  return (
    <div>
      <Table
        className="w-full"
        title={() => <h3>Mantenciones</h3>}
        dataSource={mantenciones}
        rowKey={'id'}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
            showSorterTooltip: false,
            render: (id_mantencion) =>
              user.is_ejecutivo ? (
                <Link to={`/admin/mantenciones/${id_mantencion}?only_edit=1`}>
                  {'N° ' + id_mantencion}
                </Link>
              ) : (
                <>{'N° ' + id_mantencion}</>
              ),
          },
          {
            title: 'Responsable',
            dataIndex: 'Responsable',
            key: 'responsable',
            render: (responsable) => responsable?.fullname,
          },
          {
            title: 'Observaciones',
            dataIndex: 'observaciones',
            key: 'observaciones',
          },
          {
            title: 'Fecha de Creación',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
          },
          {
            title: 'Última Actualización',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
          },
          {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            render: (estado) => (
              <span
                className={`px-2 py-1 rounded ${getEstadoClassName(estado)}`}
              >
                {estado}
              </span>
            ),
          },
          {
            title: 'Acciones',
            dataIndex: 'actions',
            width: 100,
            fixed: 'right',
            render: (_, record) => (
              <div className="space-x-2">
                {(record.estado === 'FINALIZADA' ||
                  record.estado === 'ENVIADA') && (
                  <Button onClick={() => handleClickInSolc(record)} type="link">
                    <DownloadOutlined /> Descargar
                  </Button>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ClienteMantencionesPage;
