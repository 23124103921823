import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import BudgetDocument from '../BudgetDocument/BudgetDocument';
import BudgetDowloadProp from './propTypes';
import { getAccessTokenApi } from 'api/auth';
import { getImagesApi, getItemsApi, getStaticBudgetData } from 'api/budget';
import { Button } from 'antd';

function BudgetDowload(props) {
  const { budget } = props;
  const token = getAccessTokenApi();
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);
  const [config, setConfig] = useState({});
  const [staticData, setStaticData] = useState([]);
  useEffect(() => {
    getItemsApi(token, budget.idBudget).then((result) => {
      setItems(result.items);
    });
  }, [token, budget?.idBudget]);
  useEffect(() => {
    getStaticBudgetData(token).then((result) => {
      setConfig(result.tenant);
      setStaticData(result.static);
    });
  }, [token]);
  useEffect(() => {
    getImagesApi(token, budget?.idBudget).then((result) => {
      setImages(result.images);
    });
  }, [token, budget?.idBudget]);

  return (
    <PDFDownloadLink
      document={
        <BudgetDocument
          budget={budget}
          items={items}
          images={images}
          config={config}
          staticData={staticData}
        />
      }
      fileName={`NDR-${budget.idBudget}.pdf`}
    >
      {({ loading }) => (
        <Button type="primary" disabled={loading} block>
          {loading ? 'Generando documento...' : 'Descargar PDF'}
        </Button>
      )}
    </PDFDownloadLink>
    // <a href={instance.url} download={`NDR-${budget.idBudget}.pdf`}>
    //   Descargar
    // </a>
  );
}
BudgetDowload.propTypes = BudgetDowloadProp;

export default BudgetDowload;
