import { Button, Form, Input, InputNumber } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { deleteToApi, postToApi, putToApi } from 'api/axios';
import React, { useEffect } from 'react';
import { rutValidator } from 'utils/formValidator';
import notificationApi from 'utils/notificationApi';

export const deleteClient = async (id, isFinish) => {
  const response = await deleteToApi(`/client/delete/${id}`);
  if (notificationApi(response.data || response)) {
    isFinish();
    return {
      resetFields: true,
    };
  }
  return {
    resetFields: false,
  };
};
export const addClient = async (values, isFinish) => {
  const response = await postToApi('/client/add', values);
  if (notificationApi(response.data || response)) {
    isFinish();
    return {
      resetFields: true,
    };
  }
  return {
    resetFields: false,
  };
};
export const desactivateClient = async (id, isFinish) => {
  const response = await putToApi(`/client/edit/${id}`, {
    active: false,
  });
  if (notificationApi(response.data || response)) {
    isFinish();
    return {
      resetFields: true,
    };
  }
  return {
    resetFields: false,
  };
};

export const editClient = async (data, id, isFinish) => {
  const response = await putToApi(`/client/edit/${id}`, data);
  if (notificationApi(response.data || response)) {
    isFinish();
    return {
      resetFields: true,
    };
  }
  return {
    resetFields: false,
  };
};

const ClientForm = (props) => {
  const { submitForm, buttonLabel, formProps } = props;

  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [formProps, form]);

  const finishForm = async () => {
    const values = form.getFieldsValue();
    const response = await submitForm(values, formProps);
    if (response.resetFields) {
      form.resetFields();
    }
  };
  return (
    <Form {...formProps} form={form} onFinish={finishForm} layout="vertical">
      <Form.Item
        name="rut"
        label="Rut del cliente"
        rules={[
          {
            required: true,
            message: 'El rut es obligatorio',
          },
          {
            min: 0,
            max: 13,
            message: 'El rut no puede tener más de 13 caracteres',
          },
          {
            validator: (_, value) =>
              rutValidator(value)
                ? Promise.resolve()
                : Promise.reject(new Error('El rut no es válido')),
          },
        ]}
      >
        <Input placeholder="Rut" />
      </Form.Item>
      <Form.Item
        name="name"
        label="Nombre o Razón social"
        rules={[
          {
            required: true,
            message: 'El nombre o razón social es obligatorio',
          },
          {
            min: 0,
            max: 100,
            message:
              'El Nombre o razón social no puede tener más de 100 caracteres',
          },
        ]}
      >
        <Input placeholder="Nombre o razón social" />
      </Form.Item>
      <Form.Item
        label="Número de ascensores"
        help="Solo debe ingresar el número de ascensores, no importa si son de distintas torres. Si no está seguro, deje este valor vacío o con un 0"
        name="nAscensores"
        rules={[
          {
            type: 'number',
            message: 'Solo se pueden ingresar números',
          },
        ]}
      >
        <InputNumber className="w-full" placeholder="Número de ascensores" />
      </Form.Item>
      <Form.Item
        name="address"
        label="Dirección"
        rules={[
          {
            required: true,
            message: 'La dirección es obligatoria',
          },
          {
            min: 0,
            max: 100,
            message: 'La dirección no puede tener  más de 120 caracteres',
          },
        ]}
      >
        <Input placeholder="Dirección" />
      </Form.Item>
      <Form.Item
        name="commune"
        label="Comuna"
        rules={[
          {
            required: true,
            message: 'La comuna es obligatoria',
          },
          {
            min: 0,
            max: 70,
            message: 'La comuna no puede tener  más de 70 caracteres',
          },
        ]}
      >
        <Input placeholder="Comuna" />
      </Form.Item>
      <Form.Item
        name="contactName"
        label="Nombre de contacto"
        rules={[
          {
            min: 0,
            max: 180,
            message:
              'El nombre de contacto no puede tener  más de 180 caracteres',
          },
        ]}
      >
        <Input placeholder="Nombre de contacto" />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Número de teléfono"
        // rules={[
        //   {
        //     type: 'number',
        //     message: 'Solo se permiten números',
        //   },
        // ]}
      >
        <Input placeholder="Número de teléfono" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Correo electrónico"
        rules={[
          {
            type: 'email',
            message: 'Debe tener el formato de un correo',
          },
          {
            min: 0,
            max: 80,
            message:
              'El correo electrónico no puede tener más de 80 caracteres',
          },
        ]}
      >
        <Input placeholder="Correo Electrónico" />
      </Form.Item>
      <Form.Item>
        <Button block type="primary" htmlType="submit">
          {buttonLabel || 'Enviar'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ClientForm;
