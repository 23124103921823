import React from 'react';
import { Form , Input , Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';

const Section = ({ field }) => {
  return (
    <>
      <Form.Item name={[field.name, 'deleted']} hidden >
        <Input />
      </Form.Item>
      <Form.Item
        name={[field.name, 'titleType']}
        label="Tipo de título"
        className="col-span-2"
      >
        <Select placeholder="Tipo de título">
          <Select.Option value="principal">Principal</Select.Option>
          <Select.Option value="secundario">Secundario</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={[field.name, 'title']} label="Título" className="col-span-2" required>
        <Input placeholder="Título" />
      </Form.Item>
      <Form.Item name={[field.name, 'description']} label="Descripción" className="col-span-2">
        <TextArea placeholder="Descripción" />
      </Form.Item>
    </>
  );
};

export default Section;