import { PDFViewer } from '@react-pdf/renderer';
import Constructor from 'PDF/Constructor/Constructor';
import { PDFStyleProvider } from 'providers/PDF/PDFStyleProvider';
import React from 'react';

const ConstructorPdf = () => {
  return (
    <PDFViewer className="w-full h-full">
      <Constructor />
    </PDFViewer>
  );
};

export default ConstructorPdf;
