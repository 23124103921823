import { Form, Modal, notification, Button } from 'antd';
import FileForm from 'components/forms/FileForm';
import { useAppApi, usePAppApi } from 'hooks/useAppApi';
import useRequest from 'hooks/useRequest';
import { createContext, useCallback, useContext, useState } from 'react';

const StorageContext = createContext(null);
const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};

export const StorageProvider = ({ children }) => {
  const CARPETA0 = 'CARPETA0';
  const CARPETA_DIGITAL = 'CARPETA_DIGITAL';
  const { request, isLoading: requestLoading } = useRequest();

  const [loadingGetByType, setLoadingGetByType] = useState(false);
  const [loadingGetCarpeta0, setLoadingGetCarpeta0] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingCreateCarpeta0, setLoadingCreateCarpeta0] = useState(false);
  const [idCliente, setIdCliente] = useState(null);

  const getByType = useCallback(
    async (type, idCliente) => {
      try {
        setLoadingGetByType(true);
        const result = await request(
          `/storages/types/${type}/cliente/${idCliente}`,
          'GET'
        );
        return result;
      } finally {
        setLoadingGetByType(false);
      }
    },
    [request]
  );

  const [carpeta0, setCarpeta0] = useState(null);

  const getCarpeta0 = useCallback(
    async (idCliente) => {
      try {
        setIdCliente(idCliente);
        setLoadingGetCarpeta0(true);
        const result = await getByType(CARPETA0, idCliente);
        setCarpeta0(result);
        return result;
      } finally {
        setLoadingGetCarpeta0(false);
      }
    },
    [getByType]
  );
  const [carpetaDigital, setCarpetaDigital] = useState(null);
  const [loadingGetCarpetaDigital, setLoadingGetCarpetaDigital] =
    useState(false);
  const [loadingCreateCarpetaDigital, setLoadingCreateCarpetaDigital] =
    useState(false);
  const getCarpetaDigital = useCallback(
    async (idCliente) => {
      try {
        setIdCliente(idCliente);
        setLoadingGetCarpetaDigital(true);
        const result = await getByType(CARPETA_DIGITAL, idCliente);
        setCarpetaDigital(result);
        return result;
      } finally {
        setLoadingGetCarpetaDigital(false);
      }
    },
    [getByType]
  );

  const create = async (body) => {
    try {
      setLoadingCreate(true);
      const result = await request('/storages', 'POST', body);
      return result;
    } finally {
      setLoadingCreate(false);
    }
  };

  const createCarpeta0 = async (idCliente) => {
    try {
      setLoadingCreateCarpeta0(true);
      const result = await create({
        title: 'Carpeta 0',
        type: CARPETA0,
        idCliente: idCliente,
        description:
          'Documentación técnica exigida por la Ordenanza General de Urbanismo y Construcciones OGUC y los requerimientos normativos.',
      });
      await getCarpeta0(idCliente);
      return result;
    } catch (error) {
      notification.error({
        message: 'Error al crear la carpeta 0',
        description: error.message,
      });
      return error;
    } finally {
      setLoadingCreateCarpeta0(false);
    }
  };
  const createCarpetaDigital = async (idCliente) => {
    try {
      setLoadingCreateCarpetaDigital(true);
      const result = await create({
        title: 'Carpeta Digital',
        type: CARPETA_DIGITAL,
        idCliente: idCliente,
        description:
          'Carpeta con documentos variados relacionados con un cliente.',
      });
      await getCarpetaDigital(idCliente);
      return result;
    } catch (error) {
      notification.error({
        message: 'Error al crear la carpeta digital',
        description: error.message,
      });
      return error;
    } finally {
      setLoadingCreateCarpetaDigital(false);
    }
  };

  const [modalProps, setModalProps] = useState(defaultModalProps);

  const [form] = Form.useForm();

  const openCreate = (storage) => {
    form.resetFields();
    setModalProps({
      open: true,
      children: (
        <FileForm
          form={form}
          onFinish={(values) => addFile(values, storage)}
          onCancel={() => setModalProps(defaultModalProps)}
        />
      ),
      title: 'Añadir archivo',
    });
  };

  const addFile = async (values, storage) => {
    try {
      await form.validateFields();
      const result = await request(`/storages/${storage.id}/file`, 'POST', {
        title: values.title,
        description: values.description,
        id: values?.files[0].response.file.id,
      });
      setModalProps(defaultModalProps);
      if (idCliente) {
        if (storage.type === CARPETA_DIGITAL) {
          await getCarpetaDigital(idCliente);
        } else {
          await getCarpeta0(idCliente);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Error al crear el archivo',
        description: error.message,
      });
    }
  };

  const openEdit = (record, storage) => {
    form.setFieldsValue({
      title: record.title,
      description: record.description,
      files: [
        {
          uid: record.id,
          name: record.filename,
          status: 'done',
          url: record.path,
          thumbUrl: record.path,
        },
      ],
      idStorage: storage.id,
    });

    setModalProps({
      open: true,
      children: (
        <FileForm
          disabledFields={['files']}
          form={form}
          onFinish={(values) => editFile(values, record.id, storage)}
          onCancel={() => setModalProps(defaultModalProps)}
        />
      ),
      title: 'Editar archivo',
    });
  };

  const editFile = async (values, fileId, storage) => {
    try {
      await form.validateFields();
      console.log(values);
      const result = await request(`/storages/${storage.id}/file`, 'POST', {
        title: values.title,
        description: values.description,
        id: fileId,
      });
      setModalProps(defaultModalProps);
      if (idCliente) {
        if (storage.type === CARPETA_DIGITAL) {
          await getCarpetaDigital(idCliente);
        } else {
          await getCarpeta0(idCliente);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Error al editar el archivo',
        description: error.message,
      });
    }
  };

  const openDelete = (fileId, storage) => {
    Modal.confirm({
      title: '¿Estás seguro de que deseas eliminar este archivo?',
      content: 'Esta acción no se puede deshacer.',
      onOk: async () => {
        try {
          await request(`/storages/file/${fileId}`, 'DELETE');
          notification.success({
            message: 'Archivo eliminado',
            description: 'El archivo ha sido eliminado exitosamente.',
          });
          if (idCliente) {
            if (storage.type === CARPETA_DIGITAL) {
              await getCarpetaDigital(idCliente);
            } else {
              await getCarpeta0(idCliente);
            }
          }
        } catch (error) {
          notification.error({
            message: 'Error al eliminar el archivo',
            description: error.message,
          });
        }
      },
      onCancel() {},
    });
  };

  return (
    <>
      <Modal
        footer={false}
        onCancel={() => setModalProps(defaultModalProps)}
        {...modalProps}
      />
      <StorageContext.Provider
        value={{
          carpeta0,
          getCarpeta0,
          createCarpeta0,
          loadingGetByType,
          loadingGetCarpeta0,
          loadingCreate,
          loadingCreateCarpeta0,
          openCreate,
          openEdit,
          openDelete,
          createCarpetaDigital,
          getCarpetaDigital,
          carpetaDigital,
          loadingGetCarpetaDigital,
          loadingCreateCarpetaDigital,
        }}
      >
        {children}
      </StorageContext.Provider>
    </>
  );
};

export const useStorage = () => useContext(StorageContext);
