import React, { useEffect } from 'react';
import { Button, Card, Table } from 'antd';
import { useGetToApi } from 'hooks/useApi/useGetAxios';
import moment from 'moment';
import Title from 'antd/es/typography/Title';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Ascensores',
    dataIndex: 'nAscensores',
    key: 'nAscensores',
  },
  {
    title: 'Dirección',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Comuna',
    dataIndex: 'commune',
    key: 'commune',
  },
  {
    title: 'Creado En',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text) => moment(text).format('DD/MM/YYYY'),
  },
  {
    title: 'Actualizado En',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (text) => moment(text).format('DD/MM/YYYY'),
  },
  {
    title: 'Acciones',
    dataIndex: 'actions',
    key: 'actions',
    render: (_, record) => (
      <Link to={`/specialist/edificios/${record.id}`}>
        <Button type="link" icon={<EyeOutlined />}>
          Ver
        </Button>
      </Link>
    ),
  },
];

const EdificiosPage = () => {
  const [clients, isLoadingClients, callClients] = useGetToApi('/client/all', {
    config: {
      params: { whitProblems: true },
    },
    initialValues: {
      data: [],
    },
  });

  useEffect(() => {
    callClients();
  }, [callClients]);

  // Verifica si hay datos antes de renderizarlos en la tabla
  const dataSource = clients.data || [];

  return (
    <div className="space-y-2">
      <Card className="welcome text-center md:text-left">
        <Title level={3} className=" !text-white">
          EDIFICIOS
        </Title>
      </Card>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        loading={isLoadingClients}
      />
    </div>
  );
};

export default EdificiosPage;
