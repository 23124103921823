import { SearchOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import React from 'react';

const NFilter = ({ onChange }) => {
  return (
    <Space direction="vertical" className="w-full">
      <Paragraph className="font-bold">Buscar por N°:</Paragraph>
      <Input
        prefix={<SearchOutlined />}
        placeholder="Ingresa un N°"
        onChange={(value) => onChange(value.target.value)}
      ></Input>
    </Space>
  );
};

export default NFilter;
