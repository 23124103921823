import Definition from '../Elements/Some/Definition';
import { string } from 'prop-types';
import React, { useMemo } from 'react';
import Signature from '../Elements/Some/Signature';
import Container from '../Elements/Container';
import Title1 from '../Elements/Text/Title1';
import { rutTools } from 'prettyutils';

const UserFinishData = (props) => {
  const { title, name, rut, position, signature } = props;
  const formatedRut = useMemo(() => {
    if (rutTools.validate(rut)) {
      const [rutBody, vDigit] = rutTools.format(rut).split('-');
      return `${rutBody}\u2013${vDigit}`; // Usar el carácter Unicode para ndash
    }
    return rut;
  }, [rut]);
  return (
    <Container>
      <Title1>{title}</Title1>
      <Definition concept="Nombre" value={name} />
      <Definition concept="Rut" value={formatedRut} />
      <Definition concept="cargo" value={position} />
      <Signature signature={signature} />
    </Container>
  );
};
UserFinishData.propTypes = {
  title: string.isRequired,
  name: string.isRequired,
  rut: string.isRequired,
  position: string.isRequired,
};
export default UserFinishData;
