import React from 'react';
import Preview from '../../../PDF/AttentionVoucher/Preview/Preview';

function Test() {
  return (
    <div>
      <Preview />
    </div>
  );
}

export default Test;
