import { Button, Divider, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect } from 'react';

const EliminarForm = (props) => {
  const { title, id, deleteRegister, desactivate } = props;
  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const finishForm = async () => {
    const response = await deleteRegister(id);
    if (response.resetFields) {
      form.resetFields();
    }
  };

  const desactivarRegistro = async () => {
    const response = desactivate(id);
    if (response.resetFields) {
      form.resetFields();
    }
  };
  return (
    <Form form={form} layout="column" onFinish={finishForm}>
      <div>
        <h3>{title || 'Eliminar'}</h3>
        <p>
          ¿Está seguro que desea eliminar el registro?{' '}
          {desactivate ? (
            <>
              <strong>Si no desea eliminar el registro</strong>, pero si desea
              que deje de aparecer en los formularios, debe presionar el botón
              que dice &apos;Desactivar &apos;.
            </>
          ) : null}
          Si desea eliminar permanentemente el registro, debe escribir
          &apos;ELIMINAR&apos; en mayúsculas y sin comillas en el campo de
          abajo.
        </p>
      </div>
      {desactivate ? (
        <>
          <Divider>CLICK PARA DESACTIVAR</Divider>
          <Form.Item>
            <Button block type="primary" onClick={desactivarRegistro}>
              DESACTIVAR REGISTRO
            </Button>
          </Form.Item>
        </>
      ) : null}

      <Divider>
        PARA ELIMINAR PERMANENTEMENTE ESCRIBA &apos; ELIMINAR &apos;
      </Divider>
      <Form.Item
        name="eliminar"
        rules={[
          {
            required: true,
            message: 'Debe escribir ELIMINAR en el campo',
          },
          {
            validator(_, value) {
              return value === 'ELIMINAR'
                ? Promise.resolve()
                : Promise.reject(
                    new Error(
                      'Debe escribir ELIMINAR en mayúsculas en el campo'
                    )
                  );
            },
          },
        ]}
      >
        <Input placeholder="ELIMINAR" />
      </Form.Item>
      <Form.Item>
        <Button danger block htmlType="submit">
          ELIMINAR PERMANENTEMENTE
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EliminarForm;
