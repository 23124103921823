import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Modal, notification } from 'antd';
import React, { useState } from 'react';
import BudgetDowload from '../../../../PDF/Presupuesto/BudgetDowload/BudgetDowload';
import { toggleActivatedBudgetApi } from '../../../../api/budget';
import { getAccessTokenApi } from '../../../../api/auth';
import notificationApi from '../../../../utils/notificationApi';
import { useNavigate } from 'react-router-dom';
import PresupuestoDowload from 'PDF/PresupuestoComposer/PresupuestoDowload';
const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};
function BudgetDropdownButton(props) {
  const { budget, refreshData, editBudget } = props;
  const { document_id } = budget;
  const navigate = useNavigate();

  const token = getAccessTokenApi();
  const toggleActivated = async (record) => {
    const result = await toggleActivatedBudgetApi(
      token,
      record.budgetActivated,
      record.idBudget
    );

    if (notificationApi(result)) {
      refreshData(true);
    }
  };

  const toggleActivatedBudget = async (record) => {
    notification.open({
      message: `¿Seguro que desea desactivar el presupuesto ${record.idBudget}?`,
      btn: (
        <Button danger type="primary" onClick={() => toggleActivated(record)}>
          Desactivar
        </Button>
      ),
    });
  };
  const items = [
    {
      label: 'Editar',
      key: 'editar',
      icon: <EditOutlined />,
    },
    {
      label: 'Eliminar',
      key: 'eliminar',
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];
  const handleMenuClick = ({ key }) => {
    if (key === 'eliminar') {
      toggleActivatedBudget(budget);
    }
    if (key === 'editar') {
      if (document_id) {
        return navigate(
          `/admin/presupuestos/${budget.idBudget}/documentos`
        );
      } else {
        editBudget(budget);
      }
    }
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  const dowloadPdf = (record) => {
    if (record.document_id) {
      setModalProps({
        open: true,
        children: <PresupuestoDowload id={record.idBudget} />,
        title: `Descargar Presupuesto ${record.idBudget}`,
      });
    } else {
      notification.open({
        message: 'Descargar Presupuesto',
        btn: <BudgetDowload budget={record} />,
      });
    }
  };
  const [modalProps, setModalProps] = useState(defaultModalProps);

  return (
    <>
      <Modal
        {...modalProps}
        footer={false}
        onCancel={() => setModalProps(defaultModalProps)}
      />
      <Dropdown.Button menu={menuProps} onClick={() => dowloadPdf(budget)}>
        <DownloadOutlined />
      </Dropdown.Button>
    </>
  );
}

export default BudgetDropdownButton;
