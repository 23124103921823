import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Form, notification } from 'antd';
import AscensorDisplayForm from 'components/forms/AscensorDisplayForm';
import useAuth from 'hooks/useAuth';
import { useCliente } from 'providers/Clientes/ClienteProvider';
import React, { useEffect, useState } from 'react';

const AscensoresPage = ({ id }) => {
  const { ascensores, loadingAscensores, callAscensores, saveAscensores } =
    useCliente();
  const [ascensorForm] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);

  useEffect(() => {
    callAscensores(id);
  }, [callAscensores, id]);

  useEffect(() => {
    if (ascensores && ascensores.length > 0 && id) {
      console.log(ascensores);
      ascensorForm.setFieldsValue({ ascensores });
    }
  }, [ascensores, ascensorForm, id]);

  const onFinish = async (values) => {
    try {
      await ascensorForm.validateFields();
      console.log(values);
      const result = await saveAscensores(values, id);
      callAscensores(id);
      notification.success({
        message: 'Ascensores actualizados',
        description: 'Los ascensores han sido actualizados exitosamente',
      });
      setDisabledSave(true);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'No se pudo guardar los ascensores',
      });
    }
    // Aquí puedes manejar los valores del formulario, por ejemplo, enviarlos a una API
  };
  const { user } = useAuth();
  return (
    <Card
      title="Datos de los ascensores"
      loading={loadingAscensores}
      extra={
        user.is_ejecutivo && (
          <Button
            icon={<SaveOutlined />}
            type="primary"
            onClick={() => ascensorForm.submit()}
            disabled={disabledSave}
          >
            Guardar
          </Button>
        )
      }
    >
      <AscensorDisplayForm
        disabled={!user.is_ejecutivo}
        form={ascensorForm}
        onFinish={onFinish}
        layout="vertical"
        onFieldsChange={() => setDisabledSave(false)}
      />
    </Card>
  );
};

export default AscensoresPage;
