import { View, Image as PDFImage } from '@react-pdf/renderer';
import { bool, string } from 'prop-types';
import React from 'react';
import ImagenHorizontal from '../../../assets/img/jpg/imagen_horizontal.jpeg';
import ImageStyle from './Image.style';

function Image(props) {
  const { small, medium, big, bigger, source } = props;
  const perStyles = {};

  if (small) perStyles.width = '150px';
  if (medium) perStyles.width = '200px';
  if (big) perStyles.width = '250px';
  if (bigger) perStyles.width = '300px';

  return (
    <View style={ImageStyle.container}>
      <PDFImage
        style={{ ...perStyles, ...ImageStyle.image }}
        source={source || ImagenHorizontal}
      />
    </View>
  );
}

Image.propTypes = {
  small: bool,
  medium: bool,
  big: bool,
  bigger: bool,
  source: string,
};
Image.defaultProps = {
  small: false,
  medium: true,
  big: false,
  bigger: false,
  source: null,
};
export default Image;
