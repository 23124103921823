import { usePDF } from '@react-pdf/renderer';
import React from 'react';
import PropTypes from 'prop-types';
import VoucherDocument from '../VoucherDocument/VoucherDocument';

function Dowload(props) {
  const { voucher, user } = props;

  const [instance] = usePDF({
    document: <VoucherDocument voucher={voucher} />,
  });
  if (instance.loading) return <div>Cargando...</div>;
  if (instance.error) return <div>Algo salió mal: {instance.error}</div>;
  return (
    <a href={instance.url} download={`Comprobante-${voucher.id}.pdf`}>
      Descargar Comprobante N°{voucher.id}
    </a>
  );
}
const voucherProps = {
  attention_finish: PropTypes.string.isRequired,
  attention_init: PropTypes.string.isRequired,
  client_name: PropTypes.string.isRequired,
  client_rut: PropTypes.string.isRequired,
  finish_state: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  id_client: PropTypes.number.isRequired,
  observations: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  recepcionist_name: PropTypes.string.isRequired,
  recepcionist_signature: PropTypes.string.isRequired,
  specialist_rut: PropTypes.string.isRequired,
  specialist_signature: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
const userProps = {
  fullname: PropTypes.string.isRequired,
};
Dowload.propTypes = {
  voucher: PropTypes.shape(voucherProps).isRequired,
  user: PropTypes.shape(userProps).isRequired,
};
export default Dowload;
