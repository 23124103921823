/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid black',
  },
  cell: {
    borderRight: '1px solid black',
    height: '100%',
    paddingLeft: '2pt',
    paddingVertical: '1pt',
    letterSpacing: '0pt',
  },
});
function Row(props) {
  const { item, columns, itemsStyles } = props;

  return columns.map((column) =>
    column.render ? (
      column.render(item, column, {
        width: column.width,
        ...styles.cell,
        ...itemsStyles,
        ...(column.styleItems ? column.styleItems : {}),
      })
    ) : (
      <Text
        key={column.key}
        debug={column.debug}
        style={{
          width: column.width,
          ...styles.cell,
          ...itemsStyles,
          ...(column.styleItems ? column.styleItems : {}),
        }}
      >
        {item[column.key]}
      </Text>
    )
  );
}

function TableRow({ items, columns, itemsStyles }) {
  const rows = items.map((item) => (
    <View style={styles.row} key={item.id.toString()}>
      <Row
        key={item.id}
        item={item}
        columns={columns}
        itemsStyles={itemsStyles}
      />
    </View>
  ));
  return <>{rows}</>;
}

export default TableRow;
