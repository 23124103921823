import { usePDF } from '@react-pdf/renderer';
import { arrayOf, shape } from 'prop-types';
import React from 'react';
import OrderDocument from './OrderDocument';

function Dowload(props) {
  const { data, items, config } = props;
  // console.log(data);

  const [instance] = usePDF({
    document: <OrderDocument config={config} data={data} items={items} />,
  });
  if (instance.loading) return <div>Cargando...</div>;
  if (instance.error) return <div>Algo salió mal: {instance.error}</div>;
  return (
    <>
      <a href={instance.url} download={`order N°${data.id_budget}.pdf`}>
        Descargar Orden
      </a>
      {/* <PDFViewer style={{ width: '80vw', height: '80vh ' }} showToolbar={false}>
        <OrderDocument data={data} items={items} />
      </PDFViewer> */}
    </>
  );
}
Dowload.propTypes = {
  data: shape({}).isRequired,
  items: arrayOf(shape({})).isRequired,
};

export default Dowload;
