import { generarmantencionesProg } from 'components/forms/MantencionProgForm';
import { useAppApi, usePAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext, useEffect } from 'react';

const ClienteAutoContext = createContext(null);

export const ClienteAutoProvider = ({ children, id }) => {
  const map = useCallback((result) => {
    return result;
  }, []);
  const mapPResult = useCallback((result) => {
    return result;
  }, []);

  const {
    data: clientesAuto,
    isLoading,
    error,
    hasCalled,
    call,
  } = useAppApi({
    baseUrl: '/programacion-automatica/all',
    mapResults: map,
  });

  const {
    isLoading: isLoadingAdd,
    call: add,
    error: errorAdd,
    hasCalled: hasCalledAdd,
  } = usePAppApi({
    url: '/programacion-automatica/add',
    mapResults: mapPResult,
  });

  return (
    <ClienteAutoContext.Provider
      value={{
        call,
        id,
        isLoading,
        error,
        hasCalled,
        clientesAuto,
        isLoadingAdd,
        add,
        errorAdd,
        hasCalledAdd,
      }}
    >
      {children}
    </ClienteAutoContext.Provider>
  );
};

export const useClientesAuto = () => useContext(ClienteAutoContext);
