import { DownloadOutlined, FormOutlined } from '@ant-design/icons';
import { Badge, Button, Card, List, Modal } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Spin from 'components/global/Spin';
import MaintancePDFContainer from 'components/specialist/MaintanceReports/FormMaintanceReports/TableMaintanceReports/MaintancePDFContainer';
import React, { Suspense, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
const reportStatus = {
  1: {
    color: 'green',
    text: 'Finalizado',
  },
  0: {
    color: 'volcano',
    text: 'Sin Finalizar',
  },
};

const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};

const MaintanceReportList = (props) => {
  const { reports, isLoading } = props;
  const [open, setOpen] = useState(false);
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const onClickToDowloadReport = (report) => {
    setOpen(true);
    setModalProps({
      children: <MaintancePDFContainer itemData={report} />,
      open: true,
      title: 'Descargar Informe de mantención',
    });
  };

  const getAction = (report) => {
    const { report_finish, id_maintance_report } = report;
    let action = null;
    if (report_finish === 0) {
      action = (
        <Link to={`${id_maintance_report}`}>
          <FormOutlined />
        </Link>
      );
    }
    if (report_finish === 1) {
      action = (
        <Button onClick={() => onClickToDowloadReport(report)}>
          <DownloadOutlined />
        </Button>
      );
    }
    return [action];
  };

  return (
    <>
      <List
        className="mt-3"
        itemLayout="horizontal"
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 6,
        }}
        pagination={{
          align: 'end',
          position: 'bottom',
          pageSize: 12,
        }}
        dataSource={reports}
        loading={isLoading}
        renderItem={(report) => (
          <List.Item>
            <Badge.Ribbon
              className="-mt-2"
              color={
                reportStatus[report.report_finish]
                  ? reportStatus[report.report_finish].color
                  : 'red'
              }
              text={
                reportStatus[report.report_finish]
                  ? reportStatus[report.report_finish].text
                  : 'Error'
              }
            >
              <Card title={report.client_name} actions={getAction(report)}>
                <Paragraph>
                  <span className=" font-semibold">Alias: </span>
                  {report.alias}
                </Paragraph>
                <Paragraph>
                  <span className=" font-semibold">ID del reporte: </span>
                  N°{report.id_maintance_report}
                </Paragraph>
              </Card>
            </Badge.Ribbon>
          </List.Item>
        )}
      />
      <Modal
        footer={false}
        onCancel={() => setModalProps(defaultModalProps)}
        {...modalProps}
      />
    </>
  );
};

export default MaintanceReportList;
