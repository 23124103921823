
/**
 * Verifica si una cadena es una URL válida.
 * @param {string} str - La cadena a verificar.
 * @returns {boolean} - Retorna true si es una URL, de lo contrario false.
 */
export const isValidURL = (str) => {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
};

