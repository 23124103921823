import { usePDF } from '@react-pdf/renderer';
import { arrayOf, shape } from 'prop-types';
import React from 'react';

import MaintanceDocument from '../../../../../PDF/MaintanceReport/MaintanceDocument';

function MaintancePDF(props) {
  const { report, items, config } = props;
  const { id_maintance_report: idReport } = report;

  const [instance] = usePDF({
    document: (
      <MaintanceDocument config={config} report={report} items={items} />
    ),
  });
  if (instance.loading) return <div>Cargando...</div>;
  if (instance.error) return <div>Algo salió mal: {instance.error}</div>;

  return (
    <div>
      <a
        href={instance.url}
        download={`Informe de Mantención N°${idReport}.pdf`}
      >
        Descargar Informe
      </a>
    </div>
  );
}
MaintancePDF.propTypes = {
  report: shape({}).isRequired,
  items: arrayOf(shape({})).isRequired,
};

export default MaintancePDF;
