import React from 'react';

import Layout, { Content } from 'antd/lib/layout/layout';
import { Card, Image } from 'antd';
import { Navigate } from 'react-router-dom';
import LogoEleva from 'assets/logo';
import LoginForm from '../components/admin/LoginForm';
import { getAccessTokenApi } from '../api/auth';
import Logo from 'components/Logo/Logo';

function SignIn() {
  if (getAccessTokenApi()) {
    return <Navigate to="/" />;
  }

  return (
    <Layout className="sign-in">
      <Card bordered={false} className="sign-in-card">
        <Content className="sign-in-content">
          <div className="image-side" />
          {/* <Image src={ImagenVistaMontana} className="image-side"  /> */}
          <div className="form-container">
            <Logo />
            {/* <Image src={LogoEleva} preview={false} /> */}
            <LoginForm />
          </div>
        </Content>
      </Card>
    </Layout>
  );
}

export default SignIn;
