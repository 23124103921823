import { Image, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { string } from 'prop-types';
import BlueBar from '../../assets/img/png/blue-bar.png';
import TecnologicalBar from '../../assets/img/png/tecnological-bar.png';
import LogoProlift from '../../assets/img/png/prolift_logo_sbg.png';
import Title1 from '../Elements/Text/Title1';
import { API_PATH } from 'config';

const styles = StyleSheet.create({
  frontBar: {
    opacity: 0.9,
    position: 'absolute',
    top: '0',
    zIndex: '0',
    height: '4.09cm',
    width: '19.05cm',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  backBar: {
    position: 'absolute',
    top: '0',
    zIndex: '1',
    height: '4.09cm',
    width: '19.05cm',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  logo: {
    height: '2.5cm',
    width: '3.57cm',
    position: 'absolute',
    left: '14cm',
    top: '0.2cm',
    backgroundColor: 'white',
    padding: '0.1cm',
    borderRadius: '5px',
  },
  header: {
    width: '100vw',
    height: '4.09cm',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function Header(props) {
  const { title, config } = props;
  return (
    <View style={styles.header}>
      <Image src={BlueBar} style={styles.frontBar} />
      <Image src={TecnologicalBar} style={styles.backBar} />
      <Image
        style={styles.logo}
        source={
          config?.portraitLogo
            ? `${API_PATH}${config?.portraitLogo}`
            : 'LIFT LOGIC'
        }
      />
      <View
        style={{
          position: 'absolute',
          left: '13px',
          fontSize: '16',
          fontFamily: 'Times-Bold',
          color: 'white',
          backgroundColor: '#17365D',
          width: '270px',
          paddingVertical: '10px',
          marginLeft: '15px',
          textAlign: 'center',
        }}
      >
        <Title1>{title}</Title1>
      </View>
    </View>
  );
}
Header.propTypes = {
  title: string.isRequired,
};

export default Header;
