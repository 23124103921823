import React from 'react';
import TableMaintanceItems from '../../../components/admin/MaintanceReports/MaintanceItemsList/TableMaintanceItems';

function MaintanceReports() {
  return (
    <div className="maintance-reports">
      <div className="maintance-reports__list">
        <TableMaintanceItems />
      </div>
    </div>
  );
}

export default MaintanceReports;
