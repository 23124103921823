import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  footer: {
    width: '100vw',
    position: 'absolute',
    bottom: '0px',
    textAlign: 'center',
    fontSize: '10px',
    marginBottom: '10px',
    color: 'rgba(0,0,0,0.5)',
  },
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  leyend: {
    fontSize: '10px',
  },
});

function BudgetFooter({ config }) {
  return (
    <View style={styles.footer} fixed>
      <Text style={styles.title}>
        {config?.footer ??
          'LiftLogic - Por Sociedad de Tecnología e Innovación Limitada'}
      </Text>
      <Text style={styles.leyend}>
        {config?.footer_secondary ?? 'www.liftlogic.cl'}
      </Text>
    </View>
  );
}

export default BudgetFooter;
