import React from 'react';
import CertificationContainer from 'Containers/Certification';

const Certificacion = () => {
  return (
    <div>
      <CertificationContainer />
    </div>
  );
};

export default Certificacion;
