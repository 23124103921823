/* eslint-disable camelcase */
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getSignatureVoucher } from '../../../api/attentionVoucher';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  row: {
    flexDirection: 'column',
    width: '50%',
  },
  title: {
    fontSize: 11,
    borderBottom: '0px',
    fontFamily: 'Helvetica-Bold',
  },
  signature: {
    height: '100px',
    width: '100px',
    marginLeft: '50px',
    marginTop: '20px',
  },
});

function VoucherSignatures(props) {
  const { recepcionist_signature, specialist_signature } = props;
  const [recepcionist, setRecepcionist] = useState();
  const [specialist, setSpecialist] = useState();
  useEffect(() => {
    getSignatureVoucher(recepcionist_signature).then(
      (result) => {
        setRecepcionist(result);
      },
      [recepcionist_signature]
    );
  });
  useEffect(() => {
    getSignatureVoucher(specialist_signature).then(
      (result) => {
        setSpecialist(result);
      },
      [specialist_signature]
    );
  });

  return (
    <View style={styles.container}>
      {specialist ? (
        <View style={styles.row}>
          <Text style={styles.title}>Especialista: </Text>
          <Image style={styles.signature} source={specialist} />
        </View>
      ) : null}
      {recepcionist ? (
        <View style={styles.row}>
          <Text style={styles.title}>Recibe: </Text>
          <Image style={styles.signature} source={recepcionist} />
        </View>
      ) : null}
    </View>
  );
}

VoucherSignatures.propTypes = {
  recepcionist_signature: PropTypes.string.isRequired,
  specialist_signature: PropTypes.string.isRequired,
};

export default VoucherSignatures;
