import React from 'react';
import { Card } from 'antd';
import { SafetyCertificateOutlined } from '@ant-design/icons';

const AscensorCard = ({ data, columnasPersonalizadas, extra }) => {
  const { Ascensor } = data;

  // Función para renderizar una columna personalizada
  const renderColumnaPersonalizada = (columna) => {
    const { columnKey, render, title } = columna;
    const valor = Ascensor[columnKey] || 'N/A';
    return (
      <p key={columnKey}>
        <strong>{title || columnKey}:</strong>{' '}
        {render ? render(valor, Ascensor) : valor}
      </p>
    );
  };

  const columnasPorDefecto = [
    { columnKey: 'marca' },
    { columnKey: 'modelo' },
    { columnKey: 'capacidad' },
    { columnKey: 'pisos' },
    { columnKey: 'estado' },
    { columnKey: 'numero_serie' },
    { columnKey: 'mantencion_automatica' },
  ];

  const columnas = columnasPersonalizadas || columnasPorDefecto;

  return (
    <Card
      extra={extra}
      title={
        <div className="flex items-center">
          <SafetyCertificateOutlined className="mr-2" />
          {Ascensor.nombre}
        </div>
      }
      bordered={true}
      className="bg-gray-100"
    >
      {columnas.map((columna) => renderColumnaPersonalizada(columna))}
    </Card>
  );
};

export default AscensorCard;
