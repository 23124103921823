import { useApi } from 'hooks/useApi';
import React from 'react';
import { useParams } from 'react-router-dom';
import CertificacionItemList from './CertificacionItemList';
import LinkBlock from 'components/global/LinkBlock';

const CertificationDetail = () => {
  const { id } = useParams();
  const [certificacion] = useApi({
    url: `asimet/certificaciones/${id}`,
    defaultValue: {},
    key: 'values',
    successEnabled: false,
  });
  const { items } = certificacion;
  return (
    <div>
      <div className="mb-5">
        <LinkBlock
          to="../admin/certificacion"
          label="Resultados certificación"
        />
      </div>
      <CertificacionItemList items={items} />
    </div>
  );
};

export default CertificationDetail;
