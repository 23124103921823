/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy } from 'react';
import { useBudgetApi } from '../../hooks/useApi/useBudgetApi';

import useModal from '../../hooks/useModal';
import Budget from '../../pages/admin/Budget/Budget';

/**
 * Se importa el formulario para añadir presupuestos
 * de forma lazy para que se cargue solo si lo invocamos
 */

const AddBudgetFormLazy = lazy(() =>
  import('../../components/admin/Budget/AddBudgetForm/AddBudgetForm')
);

function BudgetPageContainer() {
  /**
   * Traemos el hook que nos permitirá tanto refrescar como obtener los presupuestos de la api
   */

  const [budgets, refreshBudgets] = useBudgetApi();

  /**
   * Lo utilizamos para que a partir de un caso, devuelva cierto elemento para que se muestre en el modal
   * @param {Text} type - Se define el tipo de componente a utilizar en el modal
   * @returns {React.Element} - Devuelve el modal
   */

  const modalCases = (type) => {
    switch (type) {
      case 'add_budget_form':
        return AddBudgetFormLazy;
      default:
        return null;
    }
  };

  // Inicializamos el hook useModal
  const modal = useModal(modalCases);

  /**
   * Función que modifica el modal para que funcione como el formulario para añadir presupuestos
   */

  const setModalToAddBudgetForm = () => {
    modal.handleAtributes({
      footer: false,
      title: 'Añadir Presupuesto',
    });
    modal.handleElement('add_budget_form');
  };

  const ModalComponent = modal.getElement();

  /**
   * Pasamos un objeto de funciones que modifican el elemento
   * del modal según lo que se necesite
   */

  const setModalOptions = {
    setModalToAddBudgetForm,
  };

  return (
    <Budget
      budgets={budgets}
      refreshBudgets={refreshBudgets}
      ModalComponent={ModalComponent}
      setModalOptions={setModalOptions}
      handleOpenModal={modal.handleOpen}
      modalAtributes={modal.atributes}
      modalOpen={modal.open}
    />
  );
}

export default BudgetPageContainer;
