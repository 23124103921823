import React from 'react';
import { Form, Input, InputNumber, Select } from 'antd';

const { Option } = Select;

const ColeccionItemForm = ({ form, initialValues, visibleFields }) => {
  console.log(initialValues);
  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      {visibleFields.includes('nombre') && (
        <Form.Item
          name="nombre"
          label="Nombre"
          rules={[{ required: true, message: 'Por favor ingrese el nombre' }]}
        >
          <Input placeholder="Nombre" />
        </Form.Item>
      )}
      {visibleFields.includes('precio') && (
        <Form.Item
          name="precio"
          label="Precio"
          rules={[{ required: true, message: 'Por favor ingrese el precio' }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="Precio"
          />
        </Form.Item>
      )}
      {visibleFields.includes('costo') && (
        <Form.Item
          name="costo"
          label="Costo"
          rules={[{ required: true, message: 'Por favor ingrese el costo' }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="Costo"
          />
        </Form.Item>
      )}
      {visibleFields.includes('cantidad') && (
        <Form.Item
          name="cantidad"
          label="Cantidad"
          rules={[{ required: true, message: 'Por favor ingrese la cantidad' }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            placeholder="Cantidad"
          />
        </Form.Item>
      )}
      {visibleFields.includes('descripcion') && (
        <Form.Item
          name="descripcion"
          label="Descripción"
          rules={[
            { required: true, message: 'Por favor ingrese la descripción' },
          ]}
        >
          <Input.TextArea placeholder="Descripción" rows={10} />
        </Form.Item>
      )}
      {visibleFields.includes('tipo_item') && (
        <Form.Item
          name="tipo_item"
          label="Tipo de Ítem"
          rules={[
            { required: true, message: 'Por favor seleccione el tipo de ítem' },
          ]}
        >
          <Select disabled placeholder="Seleccione el tipo de ítem">
            <Option value="TEXTO">TEXTO</Option>
            <Option value="PRODUCTO">PRODUCTO</Option>
            <Option value="ACTIVIDAD">ACTIVIDAD</Option>
          </Select>
        </Form.Item>
      )}
    </Form>
  );
};

export default ColeccionItemForm;
