import { Form, Input, Select } from 'antd';
import React from 'react';

const ClientDetailMaintanceToDoForm = ({
  form,
  changeRecepcionista,
  Mantencion,
}) => {
  return (
    <Form form={form} onChange={() => changeRecepcionista(true)}>
      {Mantencion ? (
        <>
          <Form.Item
            name="name_recep"
            initialValue={Mantencion.name_recep}
            rules={[
              {
                required: true,
                message: 'Se debe ingresar el nombre del recepcionista',
              },
            ]}
          >
            <Input placeholder="Ingresar Nombre" />
          </Form.Item>
          <Form.Item
            name="cargo_recep"
            initialValue={Mantencion.cargo_recep}
            rules={[
              {
                required: true,
                message: 'Se debe ingresar el cargo del recepcionista',
              },
            ]}
          >
            <Input placeholder="Ingresar Cargo" />
          </Form.Item>
        </>
      ) : null}
    </Form>
  );
};

export default ClientDetailMaintanceToDoForm;
