import React from 'react';
import { View, Text, StyleSheet, Svg, Circle } from '@react-pdf/renderer';

/**
 * Aplica formato a un texto, convirtiendo los fragmentos rodeados por ** en negrita.
 * @param {string} text - Texto que puede contener partes en negrita.
 * @param {object} styles - Estilos para el texto en negrita.
 * @returns {array} Fragmentos de texto con estilos aplicados.
 */
function formatBoldText(text, styles) {
  const parts = text.split(/(\*\*[^*]+\*\*)/g); // Divide en partes con **texto**
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      // Si el texto está rodeado por **, aplica el estilo en negrita
      console.log (part);
      return (
        <Text key={index} style={styles.bold}>
          {part.slice(2, -2)}
        </Text>
      );
    }
    // Si no, devuelve el texto normal
    return <Text key={index}>{part}</Text>;
  });
}

/**
 * Procesa un texto en formato Markdown y devuelve un objeto con el formato estructurado.
 *
 * @param {string} markdownText - Texto en formato Markdown.
 * @returns {object} Objeto con el formato estructurado.
 */
function procesarMarkdown(markdownText, styles) {
  const lines = markdownText.split('\n');
  const resultado = [];
  const headers = {
    '#': 'h1',
    '##': 'h2',
    '###': 'h3',
    '####': 'h4',
    '#####': 'h5',
    '######': 'h6',
  };

  lines.forEach((line) => {
    let key = '';
    let value = line.trim();
    const originalValue = value;

    // Verificar si es un header
    Object.keys(headers).forEach((header) => {
      if (value.startsWith(header)) {
        key = headers[header];
        value = value.replace(header, '').trim();
        if (key === 'h1') {
          resultado.push({ [key]: <Text style={styles.primaryTitle}>{value}</Text> });
        } else {
          resultado.push({ [key]: <Text style={styles.secondaryTitle}>{value}</Text> });
        }
        return;
      }
    });

    // Si es un elemento de lista
    if (value.startsWith('- ') || value.startsWith('+ ') || value.startsWith('* ')) {
      const listItem = value.replace(/^[-+*] /, '');
      resultado.push({
        ul: (
          <View style={styles.li}>
            <Svg width={6} height={6} style={styles.bulletPoint}>
              <Circle cx="3" cy="3" r="3" fill="#000" />
            </Svg>
            <Text>{formatBoldText(listItem, styles)}</Text>
          </View>
        ),
      });
      return;
    }

    // Si es un párrafo normal
    if (originalValue !== '' && Object.keys(headers).every((header) => !originalValue.startsWith(header))) {
      key = 'p';
      resultado.push({ [key]: <Text style={styles.text}>{formatBoldText(value, styles)}</Text> });
    }
  });
  return resultado;
}

const ComposerMarkdown = ({ text, styles }) => {
  return (
    <View wrap={true}>
      {/* Procesar el texto en formato Markdown y renderizar el resultado en el PDF */}
      {procesarMarkdown(text, styles).map((item, index) => (
        <View key={index} style={styles.ul} wrap={true}>
          {Object.keys(item).map((key) => (
            <View key={key} wrap={true}>
              {item[key]}
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};

export default ComposerMarkdown;