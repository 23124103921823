import {
  CON_CAMBIOS,
  GUARDADO,
  GUARDANDO,
  IDLE,
  SIN_CAMBIOS,
} from 'assets/states/savestates';
import { usePAppApi, useUAppApi } from 'hooks/useAppApi';
import React, { createContext, useCallback, useContext, useState } from 'react';

// Componente proveedor del contexto
const MaintanceToDoContext = createContext(null);

// Componente proveedor del contexto
export const MaintanceToDoProvider = ({ children }) => {
  // Almacenar estado de los items de mantencion
  const [estados, setEstados] = useState(null);
  // Almacenar los problemas que han tenido los items de mantencion
  const [problems, setProblems] = useState(null);
  // Almacenar las observaciones y sugerencias de mantención
  const [obsSug, setObsSug] = useState(null);
  // Almacenar las observaciones de mantención
  const [observaciones, setObservaciones] = useState(null);
  // Almacenar el estado de guardado
  const [saveStatus, setSaveStatus] = useState(SIN_CAMBIOS); // Iniciar con SIN_CAMBIOS
  const [ascensoresEstados, setAscensoresEstados] = useState(null);
  const [finishForm, setFinishForm] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleObservaciones = (data) => {
    setObservaciones(data);
    setToConCambios();
  };

  const handleAscensoresEstados = (data) => {
    setAscensoresEstados(data);
    setToConCambios();
  };

  const setToConCambios = () => {
    if (saveStatus !== CON_CAMBIOS) {
      setSaveStatus(CON_CAMBIOS);
    }
  };

  const onChangeEstado = (idRes, estado) => {
    setToConCambios();
    setEstados({
      ...estados,
      [idRes]: estado,
    });
  };

  const onChangeProblems = (idRes, data) => {
    setToConCambios();
    setProblems({
      ...problems,
      [idRes]: [
        ...data.map((item) => ({
          ...item,
          image:
            item?.image?.length > 0 ? item?.image[0].response?.filename : null,
        })),
      ],
    });
  };

  const onChangeObsSug = (idRes, type, data) => {
    setToConCambios();
    setObsSug((prev) => ({
      ...prev,
      [idRes]: {
        ...prev?.[idRes],
        [type]: data,
      },
    }));
  };

  const { call: notFunctionalUpdateMant } = useUAppApi({
    url: '/mantenciones',
    mapResults: useCallback((data) => {
      return data;
    }, []),
  });

  const { call: postFinishMant } = usePAppApi({
    url: '/mantenciones/finish',
    mapResults: useCallback((data) => {
      return data;
    }, []),
  });

  const save = async (id) => {
    setSaveStatus(GUARDANDO); // Actualiza el estado a 'guardando'
    setIsLoading(true);

    let formatedProblems = {};
    if (problems) {
      for (let key in problems) {
        const items = problems[key];
        let result = [];
        items?.forEach((item) => {
          let image = item?.image;
          if (Array.isArray(image) && image?.length > 0) {
            result.push({
              ...item,
              image: image[0].existing ? image[0].url : image[0].response.path,
            });
          } else {
            result.push(item);
          }
        });
        formatedProblems[key] = result;
      }
    }

    const response = await notFunctionalUpdateMant(
      {
        items: estados,
        problems: formatedProblems,
        mantencionData: observaciones ? { observaciones } : null,
        itemObs: obsSug ? obsSug : null,
        ascensoresEstados: ascensoresEstados,
      },
      `/${id}`
    );

    if (response) {
      setSaveStatus(GUARDADO);
      // Después de un guardado exitoso, establecer el estado a SIN_CAMBIOS
      setSaveStatus(SIN_CAMBIOS);
      setProblems(null);
      setObservaciones(null);
      setObsSug(null);
    } else {
      // Manejo de errores si la respuesta no es exitosa
      setSaveStatus(IDLE);
    }
    setIsLoading(false);
  };

  const handleFinishForm = (values) => {
    setFinishForm(values);
    return postFinishMant(values);
  };

  return (
    <MaintanceToDoContext.Provider
      value={{
        onChangeEstado,
        save,
        onChangeProblems,
        onChangeObsSug,
        saveStatus,
        observaciones,
        handleObservaciones,
        ascensoresEstados,
        handleAscensoresEstados,
        finishForm,
        handleFinishForm,
        isLoading,
      }}
    >
      {children}
    </MaintanceToDoContext.Provider>
  );
};

export const useMaintanceToDo = () => useContext(MaintanceToDoContext);
