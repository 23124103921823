import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Badge, Button, Card, List } from 'antd';
import React from 'react';
import ActividadFechasList from './ActividadFechasList';

const MaintanceActivitiesList = (props) => {
  let { dataSource, isLoading = true, openDelete, openEdit } = props;
  const clickOnOpenEdit = (data) => {
    openEdit({
      ...data,
    });
  };
  return (
    <List
      className="mt-3"
      itemLayout="horizontal"
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4,
        xxl: 6,
      }}
      pagination={{
        align: 'end',
        position: 'bottom',
        pageSize: 12,
      }}
      loading={isLoading}
      dataSource={dataSource}
      rowKey="id"
      renderItem={(data) => (
        <List.Item key={data.id}>
          <Badge.Ribbon
            text={data.implemented ? 'Implementado' : 'No implementado'}
            className="-mt-2 text-center"
            color={data.implemented ? 'green' : 'red'}
          >
            <Card
              actions={[
                !data.implemented ? (
                  <Button
                    key={'delete'}
                    type="link"
                    danger
                    onClick={() => openDelete(data)}
                  >
                    <DeleteFilled />
                  </Button>
                ) : null,
                <Button
                  key={'edit'}
                  type="link"
                  onClick={() => clickOnOpenEdit(data)}
                >
                  <EditFilled />
                </Button>,
              ]}
            >
              <div className="text-center p-4 bg-gray-50 rounded-md shadow-md">
                <p className=" font-semibold mb-2">
                  Actividad:
                  <span className="px-2 py-1 m-1 rounded-md font-normal">
                    {data.resumen}
                  </span>
                </p>
                <p className=" font-semibold mb-2">
                  Categoría:
                  <span className="px-2 py-1 m-1 rounded-md font-normal">
                    {data.Categoria ? data.Categoria.nombre : 'Error'}
                  </span>
                </p>
                <ActividadFechasList dataSource={data.Fechas} />
              </div>
            </Card>
          </Badge.Ribbon>
        </List.Item>
      )}
    />
  );
};

export default MaintanceActivitiesList;
