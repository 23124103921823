import { deleteToApi, getToApi, postToApi, putToApi } from 'api/axios';
import React from 'react';
import { handleApiResponse } from 'utils/handlers';

const BASE_DIRECTION = '/mantenciones';

export const finishMaintanceToDo = async (params, data, isFinish) => {
  console.log(isFinish);
  return handleApiResponse(
    putToApi,
    `${BASE_DIRECTION}/finish?id_man_prog=${params.id_man_prog}&id_cli_auto=${params.id_cli_auto}`,
    data,
    'El servidor no ha respondido con un mensaje. Por favor contacte a soporte',
    isFinish
  );
};

export const deleteMantencionProg = async (id, isFinish) =>
  handleApiResponse(
    deleteToApi,
    `${BASE_DIRECTION}/drop/${id}`,
    null,
    'El servidor no ha enviado respuesta, Verifique si fué eliminado',
    isFinish
  );

export const generarmantencionesProg = async (isFinish) =>
  handleApiResponse(
    getToApi,
    `${BASE_DIRECTION}/generar`,
    null,
    'El servidor no ha enviado respuesta.',
    isFinish
  );

export const iniciarMantencionProg = async (data, isFinish) =>
  handleApiResponse(
    postToApi,
    `${BASE_DIRECTION}/start`,
    data,
    'El servidor no  ha enviado respuesta. Verifique si fué iniciada',
    isFinish
  );

export const storeRespuestaItemsMantencion = async (data, isFinish) =>
  handleApiResponse(
    postToApi,
    `${BASE_DIRECTION}/items/save`,
    data,
    'El servidor no ha enviado respuesta al almacenar',
    isFinish
  );

const MantencionProgForm = () => {
  return <div>SIN FORMULARIO</div>;
};

export default MantencionProgForm;
