import React from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { API_PATH } from 'config';

const ComposerHeader = ({ config, colors, id }) => {
  const styles = StyleSheet.create({
    header: {
      backgroundColor: colors.primary ?? '#4CAF50',
      color: 'white',
      padding: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: 80,
      zIndex: 100,
    },
    logoContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      // backgroundColor: 'black',
      position: 'relative',
      zIndex: 1000,
      // borderColor: '',
    },
    logo: {
      pósition: 'absolute',
      width: 120,
      height: 170,
      border: '3px solid ' + colors.primary ?? '#4CAF50',
      zIndex: 2000,
      marginRight: 10,
      backgroundColor: 'white',
      // paddingBottom: 5,
      // textAlign: 'center',
    },
    logoImg: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
    },
    titleContainer: {
      textAlign: 'right',
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    subtitle: {
      fontSize: 12,
    },
  });
  return (
    <View style={styles.header} fixed>
      <View style={styles.logoContainer}>
        <View style={styles.logo}>
          <Image
            style={styles.logoImg}
            source={`${API_PATH}${config?.portraitLogo}`}
          />
        </View>
        {/* <Text>Pro LIFT</Text> */}
      </View>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>PRESUPUESTO</Text>
        <Text style={styles.subtitle}>N° {id}</Text>
      </View>
    </View>
  );
};

export default ComposerHeader;
