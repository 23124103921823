/* eslint-disable react/prop-types */
import { Button, Card, Modal, Space, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import './TableUsers.scss';
import BookEditForm from './BookEditForm';
import { BookOutlined, EditFilled, StopFilled } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';

function TableUsers(props) {
  const {
    usersActive,
    usersInactive,
    openAddUser,
    openEditUser,
    toggleActiveUser,
    loading,
  } = props;

  const [users, setUsers] = useState([]);

  const [showActiveUsers, setShowActiveUsers] = useState(true);
  const [modalContent, setModalContent] = useState(null);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);

  useEffect(() => {
    if (showActiveUsers) {
      setUsers(usersActive);
    } else {
      setUsers(usersInactive);
    }
  }, [showActiveUsers, usersInactive, usersActive]);

  const editSpecialistBook = (user) => {
    setIsVisibleModal(true);
    setModalTitle('Información del especialista');
    setModalContent(<BookEditForm user={user} />);
  };

  const columns = [
    {
      title: 'RUT',
      dataIndex: 'rut',
    },
    {
      title: 'Nombre',
      dataIndex: 'fullname',
      className: 'capitalize',
    },
    {
      title: 'Cargo',
      dataIndex: 'type',
      className: 'capitalize',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      className: 'lowercase',
    },
    {
      title: 'Acciones',
      render: (data) => (
        <Space>
          <Button
            type="primary"
            onClick={() => toggleActiveUser(data)}
            icon={<StopFilled />}
            danger
            className="rounded-md"
          />
          <Button
            onClick={() => openEditUser(data)}
            type="default"
            icon={<EditFilled />}
            className="rounded-md bg-warning text-white"
          />
          <Button
            className="button button-yellow-border rounded-md "
            type="default"
            icon={<BookOutlined />}
            onClick={() => editSpecialistBook(data)}
            disabled={data.type === 'especialista' ? false : true}
          />
        </Space>
      ),
    },
  ];
  return (
    <div className="list-users">
      <Card className="welcome mb-5">
        <div className="flex flex-col items-center md:flex-row md:justify-between">
          <Title level={3}>
            <span className="text-white">Usuarios</span>
          </Title>
          <div className="mb-5 md:mb-0">
            <Switch
              checked={showActiveUsers}
              onClick={() => setShowActiveUsers(!showActiveUsers)}
              id="userSwitch"
            />
            <label htmlFor="userSwitch" className="capitalize ml-1 text-white ">
              {showActiveUsers ? 'activos' : 'inactivos'}
            </label>
          </div>
          <Button
            onClick={openAddUser}
            type="primary"
            className="list-users__header-button"
          >
            Agregar Usuario
          </Button>
        </div>
      </Card>
      <div>
        <Table
          rowKey="rut"
          dataSource={users}
          columns={columns}
          loading={loading}
        />
      </div>
      <Modal
        open={isVisibleModal}
        onCancel={() => setIsVisibleModal(false)}
        title={modalTitle}
        footer={false}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

export default TableUsers;
