/* eslint-disable no-unused-vars */
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getImageVoucher } from '../../../api/attentionVoucher';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  title: {
    fontSize: 12,
    borderBottom: '0px',
    fontFamily: 'Helvetica-Bold',
    marginBottom: 15,
  },
  imagesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  image: {
    width: '120px',
    height: '120px',
  },
});
function OutImage(props) {
  const { imageData } = props;
  const [image, setImage] = useState(null);
  useEffect(() => {
    getImageVoucher(imageData.image).then(
      (result) => {
        setImage(result);
      },
      [imageData.image]
    );
  });
  if (!image) return null;
  return <Image style={styles.image} source={image} />;
}
const imagesProps = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
};
OutImage.propTypes = {
  imageData: PropTypes.shape(imagesProps).isRequired,
};
function VoucherImages(props) {
  const { images } = props;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Imagenes: </Text>
      <View style={styles.imagesContainer}>
        {images?.map((image) => (
          <OutImage key={image.id} imageData={image} />
        ))}
      </View>
    </View>
  );
}

VoucherImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(imagesProps)).isRequired,
};

export default VoucherImages;
