import React, { useEffect, useMemo, useState } from 'react';
import TableUsers from '../../components/admin/Users/TableUsers';
import Modal from 'antd/es/modal/Modal';
import UserForm from 'components/forms/UserForm';
import { STATUS_ERROR, STATUS_LOADING, useCallGetApi } from 'api/useCallApi';
import ErrorComponent from 'components/ErrorComponent';
import { putToAPi } from 'api';
import { notification } from 'antd';
import { handleApiError, handleApiResponse } from 'utils/handlers';
import { postToApi } from 'api/axios';

const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};

function Users() {
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [users, callUsers, statusUsers, errorInfo] = useCallGetApi('/user/all');

  const activeUsers = useMemo(() => {
    return users?.filter((user) => user.active === true);
  }, [users]);
  const inactiveUsers = useMemo(() => {
    return users?.filter((user) => user.active === false);
  }, [users]);

  useEffect(() => {
    callUsers();
  }, [callUsers]);

  const reloadUsers = () => callUsers();

  const addUser = async (value) => {
    delete value.repeatPassword;
    return handleApiResponse(
      postToApi,
      `/user/add`,
      {
        ...value,
        active: true,
      },
      'El servidor no ha respondido',
      () => {
        reloadUsers();
        setModalProps(defaultModalProps);
        return {
          resetFields: true,
        };
      }
    );
    //   delete value.repeatPassword;
    //   const addResponse = await postToApi('/user/add', {
    //     ...value,
    //     active: true,
    //   });
    //   notification.success({
    //     message: addResponse.message,
    //   });

    // } catch (error) {
    //   handleApiError(error);
    //   return {
    //     resetFields: false,
    //   };
    // }
  };
  const openAddUser = async () => {
    setModalProps({
      open: true,
      children: <UserForm buttonLabel="Agregar" submitForm={addUser} />,
      title: 'Agregar usuario',
    });
  };
  const editUser = async (value, formProps) => {
    const { initialValues } = formProps;
    try {
      // value.id = initialValues.rut;
      const putResponse = await putToAPi(
        `/user/edit/${initialValues.rut}`,
        value
      );
      notification.success({
        message: putResponse.message,
      });
      reloadUsers();
      setModalProps(defaultModalProps);
      return {
        resetFields: true,
      };
    } catch (error) {
      handleApiError(error);
      return {
        resetFields: false,
      };
    }
  };

  const toggleActiveUser = async (user) => {
    try {
      const putResponse = await putToAPi('/user/toggle-active', {
        rut: user.rut,
      });
      notification.success({
        message: putResponse.message,
      });
      reloadUsers();
      setModalProps(defaultModalProps);
      return {
        resetFields: true,
      };
    } catch (error) {
      handleApiError(error);
      return {
        resetFields: false,
      };
    }
  };
  const openEditUser = (user) => {
    setModalProps({
      open: true,
      children: (
        <UserForm
          buttonLabel="Guardar Cambios"
          formProps={{
            initialValues: user,
          }}
          editing={true}
          submitForm={editUser}
        />
      ),
      title: 'Editar Usuario',
    });
  };

  if (statusUsers === STATUS_ERROR) {
    return <ErrorComponent message={errorInfo.message} />;
  }

  return (
    <div className="users">
      {statusUsers !== STATUS_ERROR ? (
        <>
          <TableUsers
            usersActive={activeUsers}
            usersInactive={inactiveUsers}
            reloadUsers={reloadUsers}
            openAddUser={openAddUser}
            openEditUser={openEditUser}
            toggleActiveUser={toggleActiveUser}
            loading={statusUsers === STATUS_LOADING}
          />
          <Modal
            {...modalProps}
            footer={false}
            onCancel={() => setModalProps(defaultModalProps)}
          />
        </>
      ) : null}
    </div>
  );
}

export default Users;
