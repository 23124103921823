/* eslint-disable no-unused-vars */
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import UbicationLogo from '../../../assets/img/png/ubicacion_icon.png';
import TelefonoLogo from '../../../assets/img/png/telefono_icon.png';
import EmailLogo from '../../../assets/img/png/email_icon.png';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '120px',
  },
  mainBar: {
    position: 'relative',
    width: '100%',
    height: '80px',
    top: '40px',
    backgroundColor: '#F2F2F2',
    borderTop: '3px solid #17375E',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '-30px',
  },
  icon: {
    height: '15px',
    width: '15px',
    position: 'relative',
    top: '-20px',
  },
  leyend: {
    fontSize: '10px',
  },
});
function VoucherFooter() {
  return (
    <View style={styles.container}>
      <View style={styles.mainBar}>
        <View style={styles.column}>
          <Image style={styles.icon} src={UbicationLogo} />
          <Text style={styles.leyend}>Leonor de la Corte #5915</Text>
        </View>
        <View style={styles.column}>
          <Image style={styles.icon} src={TelefonoLogo} />
          <Text style={styles.leyend}>+569 95732217</Text>
        </View>
        <View style={styles.column}>
          <Image style={styles.icon} src={EmailLogo} />
          <Text style={styles.leyend}>info@proliftingenieria.cl</Text>
        </View>
      </View>
    </View>
  );
}

export default VoucherFooter;
