import { View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import { fontBold, fontSizep } from '../keys';
import PDFTableProps from './propTypes';

export const styleTable = StyleSheet.create({
  table: {
    width: '80vw',
    margin: '0px auto',
    border: '1px solid black',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: fontSizep,
  },
  tableCell: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '2px 5px',
    fontSize: fontSizep,
    display: 'flex',
    justifyContent: 'center',
  },
  tableHead: {
    fontFamily: fontBold,
  },
  blackCell: {
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  cell5: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '2px 5px',
    width: '5vw',
  },
  cell10: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '2px 5px',
    width: '10vw',
  },
  cell15: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    width: '15vw',
    padding: '2px 5px',
  },
  cell20: {
    width: '20vw',
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '2px 5px',
  },
  cell25: {
    width: '25vw',
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '2px 5px',
  },
  cell30: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    width: '30vw',
    padding: '2px 5px',
  },
  cell35: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '2px 5px',
    width: '35vw',
  },
  cell40: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '2px 5px',
    width: '40vw',
  },
  cell45: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '2px 5px',
    width: '45vw',
  },
  cell50: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '2px 5px',
    width: '50vw',
  },
  cell60: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '2px 5px',
    width: '60vw',
  },
  cell70: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '2px 5px',
    width: '70vw',
  },
  cell80: {
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    padding: '2px 5px',
    width: '80vw',
  },
});

function PDFTable(props) {
  const { bodyData, headData, renderBody, renderHead, renderExtraData } = props;
  return (
    <View style={styleTable.table}>
      <View style={styleTable.tableHead}>
        <View style={styleTable.tableRow}>
          {headData?.map((item, index) => renderHead(item, index))}
        </View>
      </View>
      <View style={styleTable.tableBody}>
        {bodyData?.map((item, index) => renderBody(item, index))}
        {renderExtraData && renderExtraData}
      </View>
    </View>
  );
}

PDFTable.propTypes = PDFTableProps;

export default PDFTable;
