import { notification } from 'antd';

export const handleApiError = (error) => {
  console.log(error, 'Errror en handler', error?.message);
  if (error.status) {
    // Error con status de la API, mostramos la notificación según el status
    const { message, status } = error;
    switch (status) {
      case 300:
        notification.error({
          message: `Error 300: Multiple Choices - ${message}`,
        });
        break;
      case 400:
        notification.error({
          message: `Error 400: Solicitud incorrecta - ${message}`,
        });
        break;
      case 401:
        notification.error({
          message: `Error 401: No autorizado - ${message}`,
        });
        break;
      case 403:
        notification.error({ message: `Error 403: Prohibido - ${message}` });
        break;
      case 404:
        notification.error({
          message: `Error 404: No encontrado - ${message}`,
        });
        break;
      case 422:
        notification.error({
          message,
        });
        break;
      case 429:
        notification.error({
          message: `Error 429: Demasiadas solicitudes - ${message}`,
        });
        break;
      case 500:
        notification.error({
          message: `Error 500: Error del servidor - ${message}`,
        });
        break;
      default:
        notification.error({ message: `Error desconocido - ${message}` });
    }
  } else if (error instanceof Error) {
    // Otros errores del cliente, como errores de red
    notification.error({ message: 'Ocurrió un error inesperado' });
  } else {
    // Otros errores desconocidos
    notification.error({ message: 'Error desconocido' });
  }
};
/**
 * Maneja las respuestas de la API y genera notificaciones basadas en el resultado.
 *
 * @async
 * @function
 * @param {Function} action - La acción a realizar (postToApi, putToApi, deleteToApi).
 * @param {string} endpoint - El endpoint de la API al que llamar.
 * @param {Object|null} data - Los datos para enviar en la solicitud (si los hay).
 * @param {string} defaultMsg - Mensaje por defecto para mostrar si la respuesta no tiene mensaje.
 *
 * @returns {Promise<{resetFields: boolean}>} - Objeto que indica si se deben resetear los campos.
 */
export const handleApiResponse = async (
  action,
  endpoint,
  data,
  defaultMsg,
  isFinish,
  config = { successAlert: true }
) => {
  try {
    const response = await action(endpoint, data);
    if (config?.successAlert) {
      notification.success({
        message: response.data.message || defaultMsg,
      });
    }

    isFinish();
    return {
      resetFields: true,
    };
  } catch (error) {
    if (error?.response?.data?.message) {
      notification.error({
        message: `${error?.response?.data?.message}`,
      });
      return {
        resetFields: false,
      };
    } else {
      notification.error({
        message: `Error al realizar la acción: ${error.message}`,
      });
      return {
        resetFields: false,
        error,
      };
    }
  }
};
