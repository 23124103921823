import { useApi } from '.';
/**
 *
 * @param {Number} budgetId - Se ingresa el id del presupuesto del que se necesitan los items
 * @returns - Hook con los items y el refresh
 */

export const useBudgetItemsApi = (budgetId) => {
  const url = `get-items-budget/${budgetId}`;
  const key = 'items';
  const defaultValue = [];
  const [items, refresh] = useApi({
    url,
    key,
    defaultValue,
    successEnabled: false,
  });
  return [items, refresh];
};

/**
 *
 * @param {bool} successEnabled - Determina si se debe notificar si la llamada fue exitosa. Por defecto false
 * @returns - Devuelve los budgets y una función para refrescarlos [budgets, refresh]
 */

export const useBudgetApi = (successEnabled = false) => {
  const url = 'get-budget';
  const key = 'budgets';
  const defaultValue = [];
  // Llamamos al hook que se encargará de llamar a la api y traer los datos
  const [budgets, refresh] = useApi({ url, key, successEnabled, defaultValue });
  return [budgets, refresh];
};
