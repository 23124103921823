import AddCertificationForm from 'components/forms/AddCertification';
import { Card } from 'antd';
import LinkBlock from 'components/global/LinkBlock';
import { useApi, usePostApi } from 'hooks/useApi';
import React from 'react';

const AddCertification = () => {
  const [perfiles] = useApi({
    url: '/asimet/actividades/perfil',
    key: 'values',
    successEnabled: false,
  });
  const [post] = usePostApi({
    url: 'asimet/certificacion',
  });
  return (
    <Card className='lassName="mt-5 wd-60 wd-md mv-c'>
      <LinkBlock to="../admin/certificacion" label="Generar Evaluación" />
      <AddCertificationForm perfiles={perfiles} post={post} />
    </Card>
  );
};

export default AddCertification;
