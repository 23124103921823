import { handleApiError } from 'utils/handlers';
import { getAccessTokenApi } from './auth';
import { BASE_PATH_SERVER } from './config';
import { API_PATH } from 'config';

export const getApi = async (dst, params) => {
  const token = getAccessTokenApi();
  let url = `${BASE_PATH_SERVER}${dst}`;
  if (params) {
    url = url + '/' + params.join('/');
  }
  const config = {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  };
  return fetch(url, config)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const postApi = async (dst, body) => {
  const token = getAccessTokenApi();
  const url = `${BASE_PATH_SERVER}${dst}`;
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const putApi = async (dst, body) => {
  const token = getAccessTokenApi();
  const url = `${BASE_PATH_SERVER}${dst}`;
  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};
export const deleteApi = async (dst) => {
  const token = getAccessTokenApi();
  const url = `${BASE_PATH_SERVER}${dst}`;
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: token,
    },
  };
  return fetch(url, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};

export const postToApi = (_url, body) => {
  const token = getAccessTokenApi();
  const url = `${API_PATH}${_url}`;
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, params)
    .then(async (response) => {
      const result = await response.json();
      if (!response.ok) {
        // Si el estado no es exitoso (200-299), lanzar el error con el status y el mensaje si está disponible
        const message = result?.error || 'Error desconocido';
        throw { status: response.status, message };
      }
      return Promise.resolve(result);
    })
    .catch((err) => {
      // Devolver una promesa rechazada para permitir que el cliente de la función maneje el error si es necesario
      return Promise.reject(err);
    });
};

export const putToAPi = (_url, body) => {
  const token = getAccessTokenApi();
  const url = `${API_PATH}${_url}`;
  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, params)
    .then(async (response) => {
      const result = await response.json();
      if (!response.ok) {
        // Si el estado no es exitoso (200-299), lanzar el error con el status y el mensaje si está disponible
        const message = result?.error || 'Error desconocido';
        throw { status: response.status, message };
      }
      return Promise.resolve(result);
    })
    .catch((err) => {
      // Devolver una promesa rechazada para permitir que el cliente de la función maneje el error si es necesario
      return Promise.reject(err);
    });
};
