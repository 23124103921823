/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Document, Page } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import styles from '../styles';
import BudgetPortrait from '../BudgetPortrait/BudgetPortrait';
import BudgetHeader from '../BudgetHeader/BudgetHeader';
import BudgetFooter from '../BudgetFooter/BudgetFooter';
import BudgetBody from '../BudgetBody/BudgetBody';
import { transformStaticData } from 'utils/formaters';

function BudgetDocument(props) {
  const { budget, items, images, config, staticData } = props;
  const { idBudget: id, reason, name } = budget;
  const transformedData = transformStaticData(staticData);
  console.log(transformedData);
  return (
    <Document>
      <Page size="A4">
        <BudgetPortrait id={id} reason={reason} name={name} config={config} />
      </Page>

      <Page size="A4" wrap style={styles.page}>
        <BudgetHeader config={config} staticData={transformedData} />
        <BudgetBody
          budget={budget}
          items={items}
          images={images}
          config={config}
          staticData={transformedData}
        />
        <BudgetFooter config={config} />
      </Page>
    </Document>
  );
}

export default BudgetDocument;
