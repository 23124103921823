import { useAppApi, usePAppApi, useUAppApi } from 'hooks/useAppApi';
import React, { createContext, useCallback, useContext } from 'react';

// Crear el contexto
const UserContext = createContext(null);

// Componente proveedor del contexto
export const UserProvider = ({ children }) => {
  const { call, isLoading, data, hasCalled } = useAppApi({
    baseUrl: '/user/all',
    mapResults: useCallback((data) => {
      console.log(data);
      return data;
    }, []),
  });
  return (
    <UserContext.Provider
      value={{
        call,
        isLoading,
        data,
        hasCalled,
        especialistas: data?.filter(
          (element) => element.type === 'especialista'
        ),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
