/* eslint-disable no-unused-vars */
import { Spin as AntdSpin } from 'antd';
import React from 'react';

const Spin = (props) => {
  const { size = 'large' } = props;
  return <AntdSpin size={size}></AntdSpin>;
};

export default Spin;
