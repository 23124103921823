import React from 'react';
import { DatePicker, Form, Input } from 'antd';
const DocumentoForm = () => {
  return (
    <div className="grid grid-cols-3 gap-6">
      <Form.Item
        name="idDoc"
        label="Número de documento"
        className={'col-span-1'}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="reason"
        label="Razón del documento"
        className={'col-span-2'}
        rules={[
          { required: true, message: 'Debe ingresar una razón del documento' },
        ]}
      >
        <Input placeholder="Razón del documento" />
      </Form.Item>
      <Form.Item
        name={'createdOn'}
        label="Fecha de emisión"
        className={'col-span-1'}
        rules={[
          {
            required: true,
            message: 'Debe ingresar la fecha de emisión del documento',
          },
        ]}
      >
        <DatePicker className="w-full" />
      </Form.Item>
    </div>
  );
};

export default DocumentoForm;
