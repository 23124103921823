import { Button, Form, Input, notification, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useTenants } from 'providers/Tenant/TenantProvider';
import React from 'react';

const AddTenantForm = ({ onSuccess }) => {
  const [form] = useForm();
  const { addTenant } = useTenants();
  const onFinish = async (values) => {
    try {
      const response = await addTenant(values);
      if (onSuccess) {
        onSuccess(response);
      }
      console.log(response);
      notification.success({
        message: 'Tenant agregado exitosamente',
      });
      form.resetFields();
    } catch (error) {
      notification.error({
        message: 'Error al agregar el tenant',
        description: error.message,
      });
    }
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item name="organization" label="Nombre del tenant" required>
        <Input placeholder="Ingresar nombre del tenant" />
      </Form.Item>
      <Form.Item name="active" label="Activo" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item name="portraitLogo" label="Logo retrato">
        <Input placeholder="Ingresar URL del logo retrato" />
      </Form.Item>
      <Form.Item name="correo" label="Correo">
        <Input placeholder="Ingresar correo" />
      </Form.Item>
      <Form.Item name="telefono" label="Teléfono">
        <Input placeholder="Ingresar teléfono" />
      </Form.Item>
      <Form.Item name="direccion" label="Dirección">
        <Input placeholder="Ingresar dirección" />
      </Form.Item>
      <Form.Item name="web" label="Sitio web">
        <Input placeholder="Ingresar sitio web" />
      </Form.Item>
      <Form.Item name="company" label="Empresa">
        <Input placeholder="Ingresar empresa" />
      </Form.Item>
      <Form.Item name="rut" label="RUT">
        <Input placeholder="Ingresar RUT" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Agregar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddTenantForm;
