import { Table } from 'antd';
import React from 'react';
import { usePutApi } from '../../hooks/useApi';
import { getColumns } from './table.config';
import { buttonToggleState } from './table.functions';

const { func, arrayOf, shape } = require('prop-types');

function OrderItemsTable(props) {
  const { items, refreshItems } = props;

  // Llamada a la api que modifica el estado del item
  const [updateItem] = usePutApi({
    url: 'order/specialist/item',
    successEnabled: true,
  });

  const buttonActionToggleState = (statusId, itemId) =>
    buttonToggleState(statusId, itemId, updateItem, refreshItems);

  const columns = getColumns({ buttonActionToggleState });
  return <Table dataSource={items} columns={columns} rowKey="id_item" />;
}

OrderItemsTable.propTypes = {
  refreshItems: func.isRequired,
  items: arrayOf(shape({})).isRequired,
};

export default OrderItemsTable;
