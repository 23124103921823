import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';
import Button from '../../../global/Button/Button';

export const getColumns = ({ deleteNotification, modalEditItem }) => [
  {
    dataIndex: 'category',
    title: 'Categoría',
    width: '20%',
  },
  {
    dataIndex: 'description',
    title: 'Descripción',
    width: '50%',
  },
  {
    title: 'Acciones',
    width: '15%',
    render: (data) => (
      <Space>
        <Button color="yellow" onClick={() => modalEditItem(data)}>
          <EditFilled />
        </Button>
        <Button type="primary" danger onClick={() => deleteNotification(data)}>
          <DeleteFilled />
        </Button>
      </Space>
    ),
  },
];
export const auxs = null;
