import {
  CopyOutlined,
  DeleteFilled,
  EditFilled,
  InfoCircleOutlined,
  UpCircleFilled,
  UpOutlined,
} from '@ant-design/icons';
import { Button, Card, Checkbox, List, Typography } from 'antd';
import React from 'react';
import { Form } from 'react-router-dom';

const { Text } = Typography;

const keysObject = {
  nombre: 'Nombre',
  marca: 'Marca',
  capacidad: 'Capacidad',
  pisos: 'Pisos',
  //   createdAt: 'Agregado en',
  modelo: 'Modelo',
  numero_serie: 'Número serie',
  // estado: null,
  // id: 1,
  // id_cliente: 31,

  // updatedAt: "2023-07-06T01:27:48.000Z"
};

const Ascensor = (props) => {
  const {
    data = {},
    openEditAscensor,
    openDeleteAscensor,
    openDuplicateAscensor,
    noActions = false,
    extra = null,
  } = props;
  return (
    <List.Item>
      <Card
        className="rounded-lg shadow-md"
        title={
          <div className="flex items-center">
            <UpOutlined style={{ marginRight: '8px' }} />
            <Text strong>{data.nombre}</Text>
          </div>
        }
        extra={extra ? extra(data) : null}
        actions={
          !noActions
            ? [
                <Button
                  key="edit"
                  onClick={() => openEditAscensor(data)}
                  className="btn-warning"
                >
                  <EditFilled /> Editar
                </Button>,
                <Button
                  key="delete"
                  onClick={() => openDeleteAscensor(data)}
                  className="btn-danger"
                >
                  <DeleteFilled /> Eliminar
                </Button>,
                <Button
                  key="duplicate"
                  onClick={() => openDuplicateAscensor(data)}
                >
                  <CopyOutlined /> Duplicar
                </Button>,
              ]
            : null
        }
      >
        {Object.keys(keysObject).map((key) =>
          data[key] ? (
            <p key={key} className="flex items-center">
              <Text>{keysObject[key]}:</Text> {'  '}
              {data[key]}
            </p>
          ) : null
        )}
      </Card>
    </List.Item>
  );
};

export default Ascensor;
