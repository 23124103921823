import React, { useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Table, Tag } from 'antd';
import useAuth from 'hooks/useAuth';

const ActividadesTable = ({ data, openEdit, openDelete }) => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const getMonthNames = (months) => {
    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    return months
      .sort((a, b) => a - b)
      .map((month) => monthNames[month - 1])
      .join(', ');
  };
  // Transform data to include month names for each activity
  const transformedData = data?.map((activity) => ({
    ...activity,
    meses: getMonthNames(activity?.Fechas?.map((fecha) => fecha.mes)),
  }));

  // Get unique categories for the filter options
  const uniqueCategories = [
    ...new Set(data?.map((activity) => activity.Categoria.nombre)),
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const { user } = useAuth();
  return (
    <Table
      dataSource={transformedData}
      pagination={false}
      rowKey={'id'}
      size="small"
      onChange={handleTableChange}
      columns={[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          sorter: (a, b) => a.id - b.id,
          sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
          defaultSortOrder: 'descend',
        },
        {
          title: 'Título',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Categoría',
          dataIndex: 'Categoria',
          key: 'Categoria',
          filters: uniqueCategories.map((category) => ({
            text: category,
            value: category,
          })),
          filteredValue: filteredInfo.Categoria || null,
          onFilter: (value, record) => record.Categoria.nombre.includes(value),
          render: (categoria) => (
            <div className="text-center">
              <Tag color="blue">{categoria.nombre}</Tag>
            </div>
          ),
        },
        {
          title: 'Descripción',
          dataIndex: 'actividad',
          key: 'actividad',
        },
        {
          title: 'Meses',
          dataIndex: 'meses',
          key: 'meses',
        },
        {
          title: 'Obligatorio',
          dataIndex: 'obligatorio',
          key: 'obligatorio',
          render: (obligatorio) => (
            <div className="text-center">
              <Tag color={obligatorio ? 'green' : 'yellow'}>
                {obligatorio ? 'Sí' : 'No'}
              </Tag>
            </div>
          ),
          width: 100,
        },
        {
          title: 'Acciones',
          key: 'acciones',
          hidden: !user.is_ejecutivo,
          render: (_, record) => (
            <div className="flex justify-end space-x-2">
              <Button
                onClick={() => openEdit(record)}
                type="primary"
                icon={<EditOutlined />}
              >
                Editar
              </Button>
              <Button
                onClick={() => openDelete(record.id)}
                type="primary"
                danger
                icon={<DeleteOutlined />}
              >
                Eliminar
              </Button>
            </div>
          ),
        },
      ]}
    />
  );
};

export default ActividadesTable;
