import { useAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext } from 'react';

const MantencionesContext = createContext(null);

export const MantencionesProvider = ({ children }) => {
  const { data, isLoading, error, hasCalled, call } = useAppApi({
    baseUrl: '/mantenciones',
    mapResults: useCallback((result) => {
      return result;
    }, []),
  });

  // useEffect(() => {
  //   callReports();
  // }, [callReports]);

  return (
    <MantencionesContext.Provider
      value={{
        isLoading,
        error,
        hasCalled,
        data,
        call,
      }}
    >
      {children}
    </MantencionesContext.Provider>
  );
};

export const useMantenciones = () => useContext(MantencionesContext);
