import React, { useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Avatar, Button, Col, Form, Input, Modal, notification, Row } from 'antd';
import { editUserApi } from '../../../api/user';
import NoAvatar from '../../../assets/img/png/no-avatar.png';
import './Profile.scss';

import { getAccessTokenApi, logout } from '../../../api/auth';
import {
  EditProfileFormProps,
  EditProfileProps,
  ProfileProps,
} from './propTypes';
import { PoweroffOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import SetPasswordForm from 'components/forms/SetPasswordForm';
const userDataMap = {
  fullname: {
    label: 'Nombre',
  },
  type: {
    label: 'Tipo',
  },
  email: {
    label: 'Correo',
  },
  rut: {
    label: 'Rut',
  },
  cargo: {
    label: 'Cargo',
  },
  especialidad: {
    label: 'Especialidad',
  },
  otros: {
    label: 'Otros',
  },
};

function Profile(props) {
  const { user } = props;
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }
  const navigate = useNavigate();
  const logoutAction = () => {
    logout();
    navigate('/login');
    // window.location.href = '/login';
  };
  return (
    <div className=" flex flex-col items-center h-full justify-between">
      <Modal open={open} onCancel={handleOpen} title="Cambiar Contraseña" footer={false}>
        <SetPasswordForm finish={handleOpen}/>
      </Modal>
      <div>
        <Avatar className="mb-5" src={NoAvatar} size={200} />
        <div className="mb-5">
          {Object.keys(userDataMap)?.map((objKey) =>
            user[objKey] ? (
              <p key={objKey}>
                <span className=" font-semibold">
                  {userDataMap[objKey].label}
                </span>
                {`: ${user[objKey]}`}{' '}
              </p>
            ) : null
          )}
        </div>
        <Button onClick={handleOpen} block type='default'>Cambiar Contraseña</Button>
      </div>
      <Button
        type="primary"
        block
        className="btn-danger"
        onClick={logoutAction}
      >
        <PoweroffOutlined />
      </Button>
    </div>
  );
}
Profile.propTypes = ProfileProps;
function EditProfile(props) {
  const { user, updateUserData } = props;
  return (
    <div className="edit-profile">
      <div className="edit-profile__avatar">
        <Avatar src={NoAvatar} size={200} />
      </div>
      <div className="edit-profile__content">
        <EditProfileForm updateUserData={updateUserData} user={user} />
      </div>
    </div>
  );
}
EditProfile.propTypes = EditProfileProps;

function EditProfileForm(props) {
  const [form] = useForm();
  const { user, updateUserData } = props;
  const submitEditUser = async () => {
    const token = getAccessTokenApi();
    const data = { ...form.getFieldsValue(), id: user.rut };
    const editApiResult = await editUserApi(token, data);
    switch (editApiResult.state) {
      case 'success':
        form.resetFields();
        updateUserData();
        notification.success({
          message: 'Usuario actualizado correctamente',
        });
        break;
      case 'error':
        notification.error({
          message: `Error del servidor. Por favor registre el siguiente código y envíeselo a soporte. Error: ${editApiResult.codeMessage}`,
        });
        break;
      case 'client':
        notification.error({
          message: `Error del cliente. Por favor registre el siguiente texto y envíeselo a soporte. Error: ${editApiResult.message}`,
        });
        break;
      default:
        notification.error({
          message: `Error del servidor. Por favor registre el siguiente código y envíeselo a soporte. Error: ${editApiResult.codeMessage}`,
        });
        break;
    }
  };

  return (
    <Form
      form={form}
      initialValues={user}
      onFinish={submitEditUser}
      className="edit-profile-form"
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Nombre"
            rules={[
              {
                required: true,
                message: 'El nombre no puede estar vacio',
              },
            ]}
            className="edit-profile-form__item"
          >
            <Input placeholder="Nombre" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="edit-profile-form__item"
            name="lastName"
            label="Apellidos"
            rules={[
              {
                required: true,
                message: 'Los apellidos son obligatorios',
              },
            ]}
          >
            <Input placeholder="Apellidos" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            className="edit-profile-form__item"
            name="email"
            label="Correo Electrónico"
            rules={[
              {
                required: true,
                message: 'El correo es obligatorio',
              },
              {
                type: 'email',
                message: 'Debe Ingresar un correo electrónico válido',
              },
              {
                max: 60,
                min: 0,
                message: 'Este campo no puede tener más de 60 caracteres',
              },
            ]}
          >
            <Input placeholder="Correo Electrónico" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="edit-profile-form__item"
            name="password"
            label="Contraseña"
            rules={[
              {
                max: 40,
                min: 0,
                message: 'Este campo no puede tener más de 40 caracteres',
              },
            ]}
          >
            <Input.Password placeholder="Contraseña" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="repeatPassword"
            className="edit-profile-form__item"
            label="Repita la contraseña"
            rules={[
              {
                max: 40,
                min: 0,
                message: 'Este campo no puede tener más de 40 caracteres',
              },
              {
                validator: (_, value) =>
                  form.getFieldValue('password') !== value
                    ? Promise.reject(
                        new Error(
                          'Las contraseñas deben ser exactamente iguales'
                        )
                      )
                    : Promise.resolve(),
              },
            ]}
          >
            <Input.Password placeholder="Repita la contraseña" />
          </Form.Item>
        </Col>
        <Form.Item className="edit-profile-form__button">
          <Button type="primary" htmlType="submit">
            Editar
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
}
EditProfileForm.propTypes = EditProfileFormProps;

export default Profile;
