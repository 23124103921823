import { Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '12px',
    textTransform: 'uppercase',
    marginBottom: '10px',
  },
  leyend: {
    fontSize: '10px',
    margin: '0 20px',
  },
});

function BudgetConditions(props) {
  const { conditions, finishTime, include } = props;
  return (
    <View>
      <Text style={styles.title}>4. Condiciones</Text>
      <Text style={styles.leyend}>
        Nota: Los valores de cada ítem incluyen {include}.
      </Text>
      <Text style={styles.leyend}>Condiciones de pago: {conditions}</Text>
      <Text style={styles.leyend}>Tiempo de producción: {finishTime}.</Text>
      <Text style={styles.leyend}>
        Condiciones generales: El presupuesto tiene validez de 10 días corridos.
        LUEGO DE ESTE PLAZO, PROLIFT SE RESERVA EL DERECHO A MODIFICAR VALORES Y
        TIEMPOS DE EJECUCIÓN.
      </Text>
    </View>
  );
}

BudgetConditions.propTypes = {
  conditions: PropTypes.string.isRequired,
  finishTime: PropTypes.string.isRequired,
};

export default BudgetConditions;
