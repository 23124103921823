import { List } from 'antd';
import { useAdministradoresProvider } from 'providers/Administradores/Administradores';
import React from 'react';

const AdministradoresList = ({ itemList }) => {
  const { data, loading } = useAdministradoresProvider();

  return (
    <List
      pagination={{
        pageSize: 5,
      }}
      rowKey={'id'}
      itemLayout="vertical"
      className="divide-y divide-gray-200"
      loading={loading}
      dataSource={data}
      renderItem={(item) => itemList(item)}
    />
  );
};

export default AdministradoresList;
