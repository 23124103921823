import React, { useEffect } from 'react';
import { Select, Space, Typography } from 'antd';
import { useClientes } from 'providers/Clientes/ClientesProvider';

const { Paragraph } = Typography;

const ClienteCompleteSelect = ({ onChange }) => {
  const { data, call, loading } = useClientes();

  useEffect(() => {
    call();
  }, [call]);

  const handleSelect = (value) => {
    const selectedCliente = data.find((cliente) => cliente.id === value);
    onChange(selectedCliente);
  };

  return (
    <Space direction="vertical" className="w-full">
      <Select
        loading={loading}
        className="w-full"
        placeholder="Seleccione un cliente"
        showSearch
        filterOption={(input, option) => {
          return (option?.children ?? '')
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        filterSort={(optionA, optionB) =>
          (optionA?.children ?? '')
            .toLowerCase()
            .localeCompare((optionB?.children ?? '').toLowerCase())
        }
        optionFilterProp="children"
        onSelect={handleSelect}
      >
        {data
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map((opt) => (
            <Select.Option value={opt.id} key={opt.id}>
              {opt.name}
            </Select.Option>
          ))}
      </Select>
    </Space>
  );
};

export default ClienteCompleteSelect;
