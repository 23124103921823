import React, { useMemo } from 'react';
import { Card, Anchor } from 'antd';

const ItemNavigator = ({ items, onNavigate }) => {
  const onClick = (e, link) => {
    e.preventDefault();
    onNavigate(link.href.split('-')[1]);
  };
  // Crear un mapa de titulos en base a la llave del elemento con lo de mpás abajo
  // const titles = {
  //   'item-0': 'Datos del Cliente',
  //   'item-1': 'Ascensores',
  //   'item-2': 'Título de la colección',
  //   'item-3': 'Título de la colección',
  //   'item-4': 'Título de la colección',
  //   'item-5': 'Título de la colección',
  //   availableModules={[
  //     {
  //       key: 'collection',
  //       label: 'De una Colección',
  //     },
  //     {
  //       key: 'itemTable',
  //       label: 'Tabla de Ítems',
  //     },
  //     {
  //       key: 'customerInfo',
  //       label: 'Información del Cliente',
  //     },
  //     {
  //       key: 'custom',
  //       label: 'Personalizado',
  //     },
  //     {
  //       key: 'image',
  //       label: 'Imagen',
  //     },
  //   ]}
  const getTitle = (type, item) => {
    switch (type) {
      case 'collection':
        return item.titulo;
      case 'itemTable':
        return 'Tabla de Ítems';
      case 'customerInfo':
        return 'Información del Cliente';
      case 'custom':
        return item.titulo;
      case 'image':
        return 'Imagen';
      case 'item-0':
        return 'Datos del Cliente';
      default:
        return 'Título de la colección';
    }
  };

  const anchorItems = useMemo(() => {
    const elements = [
      {
        key: 'item-0',
        href: '#item-0',
        title: 'Datos del Cliente',
      },
      {
        key: 'item-1',
        href: '#item-1',
        title: 'Ascensores',
      },
    ];
    if (items) {
      elements.push(
        ...items.map((item) => ({
          key: `item-${item.position + 1}`,
          title: `Item ${item.position} - ${getTitle(item.type, item)}`,
          href: `#item-${item.position + 1}`,
        }))
      );
    }
    return elements;
  }, [items]);
  //   const anchorItems = [
  //     {
  //       key: 'item-0',
  //       href: '#item-0',
  //       title: 'Datos del Cliente',
  //     },
  //     {
  //       key: 'item-1',
  //       href: '#item-1',
  //       title: 'Ascensores',
  //     },
  //     ...items.map((item) => ({
  //       key: `item-${item.position + 1}`,
  //       title: `Item ${item.position} - ${item.type}`,
  //       href: `#item-${item.position + 1}`,
  //     })),
  //   ];

  return <Anchor onClick={onClick} items={anchorItems} className="mt-16" />;
};

export default ItemNavigator;
