/* eslint-disable no-unused-vars */
import { Image, StyleSheet } from '@react-pdf/renderer';
import { string } from 'prop-types';
import React from 'react';
import DefaultSignature from './place.jpeg';
import { BASE_PATH_SERVER } from 'config';
import { API_PATH } from 'config';
const styles = StyleSheet.create({
  signature: {
    height: '80px',
    width: '240px',
    marginTop: '20px',
  },
});

const Signature = (props) => {
  const { signature } = props;
  return <Image style={styles.signature} source={`${signature}`} />;
};
Signature.propTypes = {
  source: string,
};

export default Signature;
