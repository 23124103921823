import { Button, Card, Modal } from 'antd';
import React, { useState, useEffect } from 'react';

import { getAttentionVoucherApi } from '../../../api/attentionVoucher';
import { getAccessTokenApi } from '../../../api/auth';
import AddAttentionVoucherForm from '../../../components/specialist/AttentionVoucher/AddAttentionVoucherForm/AddAttentionVoucherForm';
import Tables from '../../../Tables';
import { addKeyToArray } from '../../../utils/formaters';

function AttentionVoucher() {
  const [vouchers, setVouchers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [reload, setReload] = useState(false);

  const token = getAccessTokenApi();

  useEffect(() => {
    getAttentionVoucherApi(token).then((result) => {
      setVouchers(addKeyToArray(result.vouchers));
    });
    setReload(false);
  }, [token, reload]);

  return (
    <div className="page">
      <div className="page__header">
        <div className="page__header-filters" />
        <div className="page__header-button">
          <Button type="primary" onClick={() => setModalVisible(true)}>
            Generar Comprobante
          </Button>
        </div>
      </div>
      <Card>
        <Tables>
          <Tables.VoucherTable vouchers={vouchers} setReload={setReload} />
        </Tables>
      </Card>
      <Modal
        footer={false}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        title="Generar Comprobante de Atención"
        width={600}
      >
        <AddAttentionVoucherForm
          setReload={setReload}
          setModalVisible={setModalVisible}
        />
      </Modal>
    </div>
  );
}

export default AttentionVoucher;
