import { Button, Form, Input, InputNumber, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddCertification = (props) => {
  const { perfiles = [], post } = props;
  const [form] = useForm();
  const navigate = useNavigate();
  const [actividades, setActividades] = useState([]);
  const onSelectProfile = (value) => {
    const perfilData = perfiles.find((perfil) => perfil.idPerfil === value);
    setActividades(perfilData.actividades);
  };

  const submit = async () => {
    let values = form.getFieldsValue();
    const items = Object.entries(values.items);
    values.items = items;
    const response = await post(values);
    if (response.state === 'success') {
      return navigate('../admin/certificacion');
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={submit}>
      <Form.Item label="Nombre del candidato" name="nombre">
        <Input placeholder="Ingresar Nombre" />
      </Form.Item>
      <Form.Item label="Rut del candidato sin guión" name="rut">
        <InputNumber placeholder="Ingresar RUT" className="wd-100" />
      </Form.Item>
      <Form.Item label="Perfil del candidato" name="idPerfil">
        <Select
          onChange={onSelectProfile}
          placeholder="Perfil"
          options={perfiles}
          fieldNames={{
            label: 'valorPerfil',
            value: 'idPerfil',
          }}
        />
      </Form.Item>
      {actividades.map((actividad) => (
        <Form.Item
          className="mt-5"
          key={actividad.idActividad}
          name={['items', actividad.idActividad.toString()]}
          label={actividad.valorActividad}
          initialValue={null}
        >
          <TextArea placeholder="Evaluación de la Actividad" autoSize={true} />
        </Form.Item>
      ))}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Agregar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddCertification;
