import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tag } from 'antd';
import {
  SOLICITUD_TIPOS,
  SOLICITUD_TIPOS_ARRAY,
  getColorForStatus,
} from 'assets/data/SolicitudesEstados';
import { useCalendarActions } from 'providers/CalendarSolc/CalendarActionsProvider';
import { useSolicitudesMantencion } from 'providers/SolicitudesMantencionProvider';
import { useSolicitudes } from 'providers/SolicitudesProvider';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

const SolicitudesTable = () => {
  const { data, hasCalled, call, isLoading, addSolicitudToDay } =
    useSolicitudes();
  const { openDowloadMantencion } = useSolicitudesMantencion();
  const { selectedDay } = useCalendarActions();

  const handleClickInSolc = (record) => {
    if (
      [SOLICITUD_TIPOS.ENVIADA, SOLICITUD_TIPOS.FINALIZADA].includes(
        record.status
      )
    ) {
      openDowloadMantencion(record);
    } else {
      addSolicitudToDay(record, selectedDay);
    }
  };

  useEffect(() => {
    if (!hasCalled) {
      call();
    }
  }, [hasCalled, call]);

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };
  const { clientNames } = useMemo(() => {
    // Inicializa el objeto con arrays vacíos para los nombres y estados.
    const initial = { clientNames: [], status: [] };

    const result = data?.reduce((acc, solicitud) => {
      // Verifica si el nombre ya existe en el acumulador.
      if (
        !acc.clientNames.some((item) => item.value === solicitud.Cliente?.name)
      ) {
        acc.clientNames.push({
          value: solicitud?.Cliente?.name,
          text: solicitud?.Cliente?.name,
        });
      }

      // // Verifica si el estado ya existe en el acumulador.
      // if (!acc.status.some((item) => item.value === budget.status)) {
      //   acc.status.push({
      //     text: budget.statusDescription,
      //     value: budget.status,
      //   });
      // }

      return acc;
    }, initial);
    // Devuelve los objetos formateados.
    return {
      clientNames: result?.clientNames,
      // status: result.status,
    };
  }, [data]);

  return (
    <>
      <Modal open={open} onCancel={closeModal} footer={false}></Modal>
      <Table
        loading={isLoading}
        size="small"
        className="h-full"
        scroll={{ x: 'calc(700px + 50%)' }}
        dataSource={data}
        columns={[
          {
            title: 'Mantención',
            dataIndex: 'id_mantencion',
            key: 'id_mantencion',
            render: (id_mantencion) =>
              id_mantencion ? (
                <Link to={`/admin/mantenciones/${id_mantencion}?only_edit=1`}>
                  {'N° ' + id_mantencion}
                </Link>
              ) : (
                'NA'
              ),
            width: 100,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id_mantencion - b.id_mantencion,
            showSorterTooltip: false,
          },
          {
            title: 'Estado',
            dataIndex: 'status',
            width: 100,
            render: (estado) => {
              return (
                <Tag color={getColorForStatus(estado)}>
                  {estado?.toUpperCase()}
                </Tag>
              );
            },
            filters: SOLICITUD_TIPOS_ARRAY.map((estado) => ({
              value: estado,
              text: estado,
            })),
            onFilter: (value, record) => record.status === value,
            defaultFilteredValue: [SOLICITUD_TIPOS.SIN_ASIGNAR],
          },
          {
            title: 'Cliente',
            dataIndex: 'Cliente',
            render: (data) => (
              <span>
                {data?.name} ({data?.rut})<br />
                {data?.address}, {data?.commune}
              </span>
            ),
            width: 250,
            filters: clientNames,
            onFilter: (value, record) => {
              return record.name.includes(value);
            },
          },
          {
            title: 'Descripción',
            dataIndex: 'description',
            width: 250,
          },
          {
            title: 'Tipo',
            dataIndex: 'SolicitudTipo',
            render: (data) => <span>{data?.descripcion}</span>,
            width: 250,
          },

          {
            title: 'Encargado',
            dataIndex: 'encargado',
            width: 150,
          },
          {
            title: 'Fecha de Asignación',
            dataIndex: 'fecha_asignacion',
            render: (fecha_asignacion) =>
              moment(fecha_asignacion).format('YYYY-MM-DD HH:mm:ss'),
            width: 150,
          },
          {
            title: 'Creado',
            dataIndex: 'createdAt',
            render: (createdAt) =>
              moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
            width: 150,
          },
          {
            title: 'Actualizado',
            dataIndex: 'updatedAt',
            render: (updatedAt) =>
              moment(updatedAt).format('YYYY-MM-DD HH:mm:ss'),
            width: 150,
          },
          {
            title: 'Acciones',
            dataIndex: 'actions',
            // width: 120,
            // fixed: 'right',
            render: (_, record) => (
              <div className="space-x-2">
                <Button
                  onClick={() => handleClickInSolc(record)}
                  // className="w-20"
                  type="link"
                >
                  {[
                    SOLICITUD_TIPOS.FINALIZADA,
                    SOLICITUD_TIPOS.ENVIADA,
                  ].includes(record.status) ? (
                    <>
                      <DownloadOutlined /> DESCARGAR/ENVIAR
                    </>
                  ) : (
                    <>
                      <PlusOutlined /> AGREGAR
                    </>
                  )}
                  {/* {record.status === 'COMPLETADO' ? 'DESCARGAR' : 'AGREGAR'} */}
                </Button>
              </div>
            ),
          },
        ]}
      />
    </>
  );
};

export default SolicitudesTable;
