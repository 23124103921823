import React from 'react';
import { Modal, List, Typography, Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import variablesGlobales from 'assets/data/variablesDinámicas';

const VariablesModal = ({ visible, onClose }) => {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success('Variable copiada al portapapeles');
      },
      () => {
        message.error('Error al copiar la variable');
      }
    );
  };

  return (
    <Modal
      title="Variables Globales"
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <Typography.Paragraph>
        Puedes utilizar las siguientes variables pegándolas en el texto del
        compositor. Selecciona y copia la variable que deseas usar.
      </Typography.Paragraph>
      <List
        dataSource={variablesGlobales}
        renderItem={(item) => (
          <List.Item>
            <div>
              <Typography.Text strong>{`{${item.nombre}}`}</Typography.Text>:
              <Typography.Text>{item.descripcion}</Typography.Text>
              <Button
                icon={<CopyOutlined />}
                onClick={() => handleCopy(`{${item.nombre}}`)}
                type="text"
              >
                Copiar
              </Button>
            </div>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default VariablesModal;
