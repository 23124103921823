import { useAppApi, usePAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext } from 'react';

const TenantsContext = createContext(null);

export const TenantsProvider = ({ children, id }) => {
  const map = useCallback((result) => {
    return result;
  }, []);

  const {
    data,
    isLoading: loading,
    error,
    hasCalled,
    call,
  } = useAppApi({
    baseUrl: '/tenants',
    mapResults: map,
  });

  const { call: NFaddTenant } = usePAppApi({
    url: '/tenants',
    mapResults: useCallback((data) => {
      console.log(data);
      return data;
    }, []),
  });

  const addTenant = async (values) => {
    const response = await NFaddTenant(values);
    return response;
  };

  return (
    <TenantsContext.Provider
      value={{
        call,
        id,
        loading,
        error,
        hasCalled,
        data,
        addTenant,
      }}
    >
      {children}
    </TenantsContext.Provider>
  );
};

export const useTenants = () => useContext(TenantsContext);
