import { SearchOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import React from 'react';

const NameFilter = ({ onChange }) => {
  return (
    <Space direction="vertical" className="w-full">
      <Paragraph className="font-bold">Buscar por Nombre:</Paragraph>
      <Input
        prefix={<SearchOutlined />}
        placeholder="Ingresa el Nombre"
        onChange={(value) => onChange(value.target.value)}
      ></Input>
    </Space>
  );
};

export default NameFilter;
