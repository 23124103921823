/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Text, StyleSheet, View } from '@react-pdf/renderer';

import Icons from '../Svg/Icons';
import TableRowHeader from './TableRowHeader';
import { fontFamilyBold } from 'components/PDF2/assets';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottom: '1px solid black',
    fontFamily: fontFamilyBold,
  },
  cell: {
    borderRight: '1px solid black',
    height: '100%',
    paddingLeft: '2pt',
  },
});

function TableHeader(props) {
  const { columns } = props;
  return (
    <TableRowHeader style={styles.row}>
      {columns.map((column) =>
        column.icon ? (
          <View
            key={column.key}
            style={{
              width: column.width,
              ...styles.cell,
              ...(column.styleHeader ? column.styleHeader : {}),
            }}
          >
            <Icons icon={column.icon} width={8} height={8} />
          </View>
        ) : (
          <Text
            key={column.key}
            style={{
              width: column.width,
              ...styles.cell,
              ...(column.styleHeader ? column.styleHeader : {}),
            }}
          >
            {column.title}
          </Text>
        )
      )}
    </TableRowHeader>
  );
}

export default TableHeader;
