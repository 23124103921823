import { useAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext } from 'react';

const ClientesContext = createContext(null);

export const ClientesProvider = ({ children, id }) => {
  const map = useCallback((result) => {
    return result.data;
  }, []);

  const { data, isLoading, error, hasCalled, call } = useAppApi({
    baseUrl: '/client/all',
    mapResults: map,
  });

  return (
    <ClientesContext.Provider
      value={{
        call,
        id,
        isLoading,
        error,
        hasCalled,
        data,
        activeData: data?.filter((element) => element.active === true),
      }}
    >
      {children}
    </ClientesContext.Provider>
  );
};

export const useClientes = () => useContext(ClientesContext);
