import { ArrowRightOutlined } from '@ant-design/icons';
import { Badge, Card, Pagination } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const status = {
  completo: {
    color: 'green',
  },
  incompleto: {
    color: 'red',
  },
};

const CertificationList = (props) => {
  const { certificaciones } = props;
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const changePage = (_page) => {
    setPage(_page);
  };
  return (
    <div>
      <Pagination
        total={certificaciones.length}
        pageSize={pageSize}
        current={page}
        onChange={changePage}
        className="mb-3"
      />
      {certificaciones
        .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
        ?.map((certificacion) => (
          <Badge.Ribbon
            key={certificacion.idCertificacion}
            color={status[certificacion.statusCertificacion].color}
            text={certificacion.statusCertificacion}
          >
            <Card className="mb-3">
              <div className="flex flex-row align-middle  justify-between">
                <div className="">
                  <h3 className="font-w-title capitalize">
                    {certificacion.nombreCandidato}
                  </h3>
                  <p>ID: {certificacion.idCertificacion}</p>
                  <p>{certificacion.perfil}</p>
                  <p>RUT: {certificacion.rutCandidato}</p>
                </div>
                <Link
                  className="not-border py-1 px-3 font-large font-subtitle button ml-3 my-auto h-1/3"
                  to={`${certificacion.idCertificacion}`}
                >
                  <ArrowRightOutlined />
                </Link>
              </div>
            </Card>
          </Badge.Ribbon>
        ))}
    </div>
  );
};

export default CertificationList;
