/* eslint-disable camelcase */
import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { shape } from 'prop-types';
import Moment from 'moment';
import 'moment/locale/es';
import P from 'PDF/Elements/Text/P';

Moment.locale('es');

const styles = StyleSheet.create({
  voucherData: {
    flexDirection: 'column',
    fontSize: '12pt',
  },
  space: {
    flexGrow: '1',
    width: '38px',
  },
  bigRow: {
    flexDirection: 'column',
    marginVertical: 5,
  },
  row: {
    flexDirection: 'row',
    width: '100%',
  },
  title: {
    borderBottom: '0px',
    fontFamily: 'Times-Bold',
    marginLeft: '3px',
  },
  specialist: {
    flexGrow: '3',
    borderBottom: '1px solid black',
    width: '114px',
    PTransform: 'capitalize',
  },
  date: {
    flexGrow: '2',
    borderBottom: '1px solid black',
    width: '76px',
  },
  type: {
    flexGrow: 2,
    borderBottom: '1px solid black',
    width: '76px',
    PTransform: 'capitalize',
  },
  attention_init: {
    flexGrow: 2,
    borderBottom: '1px solid black',
    width: '76px',
  },
  attention_finish: {
    flexGrow: 1,
    borderBottom: '1px solid black',
    width: '38px',
  },
  equipment: {
    flexGrow: 5,
    borderBottom: '1px solid black',
    width: '190px',
    PTransform: 'capitalize',
  },
  recepcionist_name: {
    flexGrow: 3,
    borderBottom: '1px solid black',
    width: '114px',
    PTransform: 'capitalize',
  },
  client_name: {
    flexGrow: 3,
    borderBottom: '1px solid black',
    width: '114px',
    PTransform: 'capitalize',
  },
  client_rut: {
    flexGrow: 2,
    borderBottom: '1px solid black',
    width: '76px',
  },
});

function OrderData(props) {
  const { data } = props;
  const {
    specialist_name,
    recepcionist_name,
    client_name,
    order_init,
    client_rut,
  } = data;
  return (
    <View style={styles.voucherData}>
      <View style={styles.bigRow}>
        <View style={styles.row}>
          <P style={styles.specialist}>{specialist_name}</P>
          <View style={styles.space} />
          <P style={styles.recepcionist_name}>{recepcionist_name}</P>
          <View style={styles.space} />
          <P style={styles.client_name}>{client_name}</P>
        </View>
        <View style={styles.row}>
          <P style={{ ...styles.specialist, ...styles.title }}>Especialista</P>
          <View style={styles.space} />
          <P style={{ ...styles.recepcionist_name, ...styles.title }}>Recibe</P>
          <View style={styles.space} />
          <P style={{ ...styles.client_name, ...styles.title }}>Cliente</P>
        </View>
      </View>
      <View style={styles.bigRow}>
        <View style={styles.row}>
          <P style={styles.type}>ODT </P>
          <View style={styles.space} />
          <P style={styles.date}>
            {Moment().format('DD [de] MMMM [del] YYYY')}
          </P>
          <View style={styles.space} />
          <P style={styles.attention_init}>
            {Moment(order_init).format('DD [de] MMMM [del] YYYY')}
          </P>
          <View style={styles.space} />
          <P style={styles.client_rut}>{client_rut}</P>
        </View>
        <View style={styles.row}>
          <P style={{ ...styles.type, ...styles.title }}>Tipo</P>
          <View style={styles.space} />
          <P style={{ ...styles.date, ...styles.title }}>Fecha Emisión</P>
          <View style={styles.space} />
          <P style={{ ...styles.attention_init, ...styles.title }}>
            Inicio Orden
          </P>
          <View style={styles.space} />
          <P style={{ ...styles.client_rut, ...styles.title }}>Rut cliente</P>
        </View>
      </View>
    </View>
  );
}

OrderData.propTypes = {
  data: shape({}).isRequired,
};
export default OrderData;
