import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Modal, Space } from 'antd';
import Title from 'antd/es/typography/Title';
import AddTenantForm from 'components/forms/AddTenantForm';
import TenantsTable from 'components/tables/TenantsTable';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { useTenants } from 'providers/Tenant/TenantProvider';
import React, { useEffect, useMemo, useState } from 'react';

const Inicio = () => {
  // Abrir o cerrar modal de agregar tenant
  const [open, setOpen] = useState(false);
  const handleAdd = () => {
    setOpen(!open);
  };

  const dayOfWeek = useMemo(() => {
    return moment().format('dddd');
  }, []);
  const { user } = useAuth();

  // Cargamos los datos de los tenants si es necesario
  const { call: callTenants, hasCalled: hasCalledTenants } = useTenants();

  useEffect(() => {
    if (!hasCalledTenants) {
      callTenants();
    }
  }, [callTenants, hasCalledTenants]);
  const onSuccessTenant = (response) => {
    console.log(response);
    setOpen(false);
    callTenants();
  };
  return (
    <Space direction="vertical" className="w-full">
      <Card className="w-full  welcome text-white">
        <div className="flex flex-col justify-center items-center md:flex-row md:justify-between">
          <div className="text-lg">
            <div className="uppercase">!Buen día, {user.fullname}!</div>
            <div>Ten un excelente {dayOfWeek}!</div>
          </div>
          <Avatar className="mt-3" size={64} icon={<UserOutlined />} />
        </div>
      </Card>
      <Card>
        <div className="flex flex-row justify-between">
          <Title level={3}> Tenants </Title>
          <Button
            onClick={() => handleAdd()}
            type="primary"
            icon={<PlusOutlined />}
          >
            Agregar
          </Button>
        </div>
      </Card>
      <TenantsTable />
      <Modal
        onCancel={() => handleAdd()}
        open={open}
        footer={false}
        title="Agregar Tenant"
      >
        <AddTenantForm onSuccess={onSuccessTenant} />
      </Modal>
    </Space>
  );
};

export default Inicio;
