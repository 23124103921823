/* eslint-disable react/prop-types */
import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
// import TableRow from './TableRow';
// import TableHeader from './TableHeader';

// const styles = StyleSheet.create({
//   tableContainer: {
//     flexDirection: 'row',
//     flexWrap: 'wrap',
//     border: '1px solid black',
//     borderBottom: 'initial',
//     borderRight: 'initial',
//     fontSize: '12pt',
//     fontFamily: 'Times-Roman',
//   },
// });

// function Table({ data, columns }) {
//   return (
//     <View style={styles.tableContainer}>
//       <TableHeader columns={columns} />
//       <TableRow items={data} columns={columns} />
//       {/* <TableFooter items={data.items} /> */}
//     </View>
//   );
// }
const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'column',
    border: '1px solid black',
    fontSize: '12pt',
    fontFamily: 'Times-Roman',
  },
  rowContainer: {
    flexDirection: 'row',
    borderBottom: '1px solid black',
  },
  rowText: {
    textAlign: 'center',
    padding: 4,
    borderRight: '1px solid black',
  },
  headerContainer: {
    flexDirection: 'row',
    borderBottom: '1px solid black',
    backgroundColor: '#f0f0f0',
  },
  headerText: {
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 4,
    borderRight: '1px solid black',
  },
});

function TableHeader({ columns }) {
  return (
    <View style={styles.headerContainer}>
      {columns.map((col) => (
        <Text key={col.key} style={[styles.headerText, { width: col.width }]}>
          {col.title}
        </Text>
      ))}
    </View>
  );
}

function TableRow({ items, columns }) {
  return (
    <>
      {items.map((item, rowIndex) => (
        <View key={rowIndex} style={styles.rowContainer}>
          {columns.map((col) => (
            <Text key={col.key} style={[styles.rowText, { width: col.width }]}>
              {item[col.key]}
            </Text>
          ))}
        </View>
      ))}
    </>
  );
}

function Table({ data, columns }) {
  return (
    <View style={styles.tableContainer}>
      <TableHeader columns={columns} />
      <TableRow items={data} columns={columns} />
    </View>
  );
}

export default Table;
