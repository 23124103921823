import { createContext, useCallback, useContext, useState } from 'react';
import { getToApi , putToApi } from '../../api/axios';

const PresupuestosContext = createContext(null);

export const PresupuestosProvider = ({ children }) => {

  const retrieve = useCallback(async (id) => {
    const response = await getToApi(`/presupuestos/${id}`);
    return response;
  }, []);

  const update = useCallback(async (id, data) => {
    const response = await putToApi(`/presupuestos/${id}`, data);
    return response;
  }, []);

  return (
    <PresupuestosContext.Provider
      value={{
        retrieve,
        update,
      }}
    >
      {children}
    </PresupuestosContext.Provider>
  );
};

export const usePresupuestos = () => useContext(PresupuestosContext);