import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';

const SetPasswordForm = ({finish}) => {

  const [form] = useForm()

  const [loading, setLoading] = useState(false)

  const {changePassword} = useAuth()

  const submit = async (values) => {
    try {
        setLoading(true)
        const response = await changePassword(values)
        if(response?.message){
            notification.success({
                message:response?.message
            })
        }else{
            notification.warning({
                message:'Contraseña modificada exitosamente'
            })
        }
        form.resetFields()
        setLoading(false)
        finish()
        
        
    } catch (error) {
        const { response } = error
        if(response?.data?.message){
            notification.error({
                message: response?.data?.message
            })
        }else{
            notification.error({
                message: "Error al procesar la solicitud"
            })
        }
        setLoading(false)
    }
  }

  // Función para validar la entropía de la contraseña
  const validatePasswordStrength = (password) => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
    return strongRegex.test(password);
  }

  return (
    <Form form={form} onFinish={submit} layout='vertical' requiredMark >
        <Form.Item name="actual" label="Contraseña Actual" rules={[
            {
                required: true,
                message: 'Por favor, ingrese su contraseña actual.'
            }
        ]}>
            <Input.Password placeholder='Ingresar contraseña actual'></Input.Password>
        </Form.Item>
        <Form.Item name='password' label="Contraseña Nueva" rules={[
            {
                required: true,
                message: 'Por favor, ingrese su nueva contraseña.',
            },
            {
                validator: (_, value) =>
                    validatePasswordStrength(value) ? Promise.resolve() : Promise.reject(new Error('La contraseña debe tener al menos 8 caracteres, incluyendo una mayúscula, una minúscula, un número y un símbolo especial.')),
            }
        ]}
        >
            <Input.Password placeholder='Ingresar contraseña nueva'></Input.Password>
        </Form.Item>
        <Form.Item name='newPassword' label="Repetir Contraseña" rules={[
            {
                required: true,
                message: 'Por favor, confirme su contraseña.',
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error('Las contraseñas no son iguales.'));
                },
            }),
        ]}>
            <Input.Password placeholder='Repetir contraseña nueva'></Input.Password>
        </Form.Item>
        <Form.Item>
            <Button loading={loading} htmlType='submit' block type='primary'>Actualizar Contraseña</Button>
        </Form.Item>
    </Form>
  )
}

export default SetPasswordForm;
