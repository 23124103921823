import { getAccessTokenApi } from './auth';
import { BASE_PATH_SERVER } from './config';

export const getClientsApi = () => {
  const token = getAccessTokenApi();
  const dst = `${BASE_PATH_SERVER}clients`;
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      status: 'client',
      codeMessage: 'ERROR_EN_LA_PETICION',
      message: err.message,
    }));
};

export const addClientApi = (token, data) => {
  const dst = `${BASE_PATH_SERVER}add-client`;
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      codeMessage: 'ERROR_EN_LA_PETICION',
      state: 'client',
      message: err.message,
    }));
};
export const editClientApi = (token, data, id) => {
  const dst = `${BASE_PATH_SERVER}edit-client/${id}`;
  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      codeMessage: 'ERROR_EN_LA_PETICION',
      state: 'client',
      message: err.message,
    }));
};
export const deleteClientApi = (token, id) => {
  const dst = `${BASE_PATH_SERVER}delete-client/${id}`;
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: token,
    },
  };

  return fetch(dst, params)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => ({
      codeMessage: 'ERROR_EN_LA_PETICION',
      state: 'client',
      message: err.message,
    }));
};
