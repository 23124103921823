import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Result, Typography } from 'antd';
const { Paragraph, Text } = Typography;

const ErrorComponent = (props) => {
  const { message } = props;
  return (
    <Result
      status="error"
      title="Lo sentimos"
      subTitle="Ha ocurrido un error inesperado, por favor, contáctese con soporte."
    >
      <div className="desc">
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            {'Más información sobre el error: '}
            {message || ' No existe información sobre el error'}
          </Text>
        </Paragraph>
      </div>
    </Result>
  );
};

export default ErrorComponent;
