import { useMediaQuery } from 'react-responsive';
import PresupuestoViewer from './PresupuestoViewer';
import { useCallback, useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import { Button, notification } from 'antd';
import { transformData } from 'utils/transformDataPresupuesto';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PresupuestoDocument from './PresupuestoDocument';
import { useTenantData } from 'providers/Tenant/TenantDataProvider';

const createVariablesMap = (cliente) => {
  const basicVariables = {
    nombre_cliente: cliente.name,
    rut_cliente: cliente.rut,
    direccion_cliente: cliente.address,
    comuna_cliente: cliente.commune,
    nombre_contacto: cliente.contactName,
    telefono_cliente: cliente.phone,
    email_cliente: cliente.email,
  };

  return { ...basicVariables };
};

function PresupuestoDowload({ id }) {
  const { colors, tenant } = useTenantData();

  const isMobile = useMediaQuery({ maxWidth: 767 });
  //   const [cliente, setCliente] = useState(null);
  const [variablesMap, setVariablesMap] = useState({});
  //   const [doocumento, setDocumento] = useState({});
  const [itemsPresupuesto, setItemsPresupuesto] = useState([]);

  const { request, isLoading: isLoadingReq, errors } = useRequest();

  const getPresupuesto = useCallback(
    async (id) => {
      try {
        const result = await request(`/presupuestos/${id}/composer`, 'GET');
        // setPresupuesto(result);
        // setCliente(result.Cliente);
        // setDocumento(result.Document);
        setVariablesMap(createVariablesMap(result.Cliente));
        setItemsPresupuesto(
          transformData(result.Document.DocumentElements)?.items
        );
        return result;
      } catch (error) {
        notification.error({
          message: 'Error al cargar el presupuesto',
          description: error.message,
        });
      }
    },
    [request]
  );

  useEffect(() => {
    if (id) {
      getPresupuesto(id);
    }
  }, [id, getPresupuesto]);
  console.log (itemsPresupuesto, variablesMap, id);
  return (
    <div>
      {isMobile && (
        <PresupuestoViewer
          items={itemsPresupuesto}
          variablesMap={variablesMap}
          id={id}
        />
      )}
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <PDFDownloadLink
          document={
            <PresupuestoDocument
              variablesMap={variablesMap}
              items={itemsPresupuesto}
              colors={colors}
              config={tenant}
              id={id}
            />
          }
          fileName={'Presupuesto N°' + id}
        >
          {({ loading }) => (
            <Button type="primary" disabled={loading} block>
              {loading ? 'Generando documento...' : 'Descargar Presupuesto'}
            </Button>
          )}
        </PDFDownloadLink>
      </div>
    </div>
  );
}

export default PresupuestoDowload;
