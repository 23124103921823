import { PDFViewer } from '@react-pdf/renderer';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VoucherDocument from '../VoucherDocument/VoucherDocument';

import { getImagesVoucher } from '../../../api/attentionVoucher';
import useAuth from '../../../hooks/useAuth';

function Preview(props) {
  const { voucher } = props;
  const [images, setImages] = useState([]);
  const { user = { fullname: 'nombre especialista' } } = useAuth();
  useEffect(() => {
    getImagesVoucher(voucher.id).then((result) => {
      setImages(result.images);
    });
  }, [voucher.id]);
  return (
    <PDFViewer style={{ width: '80vw', height: '80vh ' }} showToolbar={false}>
      <VoucherDocument voucher={voucher} user={user} images={images} />
    </PDFViewer>
  );
}

const voucherProps = {
  attention_finish: PropTypes.string.isRequired,
  attention_init: PropTypes.string.isRequired,
  client_name: PropTypes.string.isRequired,
  client_rut: PropTypes.string.isRequired,
  finish_state: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  id_client: PropTypes.number.isRequired,
  observations: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  recepcionist_name: PropTypes.string.isRequired,
  recepcionist_signature: PropTypes.string.isRequired,
  specialist_rut: PropTypes.string.isRequired,
  specialist_signature: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
Preview.propTypes = {
  voucher: PropTypes.shape(voucherProps).isRequired,
};

export default Preview;
