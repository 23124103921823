export const prefabricParts = [
  {
    label: 'Personalizado',
    key: 'custom',
    value: 'custom',
  },
  {
    label: 'Introducción',
    key: 'introduction',
    value: 'introduction',
    initialValues: [
      {
        type: 'lista',
        listType: 'bullets',
        items: [
          {
            text: '1',
          },
          {
            text: '2',
          },
          {
            text: '3',
          },
        ],
        bloqued: ['add', 'remove', 'listType'],
      },
    ],
    bloqued: ['add', 'remove','max-1'],
  },
  {
    label: 'Datos de Transferencia',
    key: 'transferData',
    value: 'transferData',
    initialValues: [
      {
        type: 'section',
        title: 'Datos de Transferencia',
        titleType: 'principal',
      },
      {
        type: 'conceptList',
        conceptList: [
          {
            concept: 'Banco',
            conceptContent: 'Banco Estado',
          },
          {
            concept: 'Tipo de Cuenta',
            conceptContent: 'Cuenta Vista o Chequera Electrónica',
          },
          {
            concept: 'Número de cuenta',
            conceptContent: 'N°34970928539',
          },
          {
            concept: 'Nombre de la cuenta',
            conceptContent: 'Prolift Ingniería y Ascensores Ltda.',
          },
          {
            concept: 'RUT',
            conceptContent: '76.820.024-6',
          },
          {
            concept: 'Correo',
            conceptContent: 'info@proliftingenieria.cl',
          },
          {
            concept: 'Consideraciones',
            conceptContent:
              'Por favor, realizar la transferencia a Prolift Ingeniería y Ascensores Ltda. R.U.T: 76.820.024-6 o extender un cheque cruzado a nombre de Prolift Ingeniería y Ascensores Ltda.',
          },
        ],
        bloqued: [],
      },
    ],
  },
  {
    label: 'Imagen',
    key: 'preImage',
    value: 'preImage',
    initialValues: [
      {
        type: 'section',
        title: 'Título de la imagen',
        description: 'Descripción de la imagen',
        titleType: 'principal',
      },
      {
        type: 'image',
        image: null,
        caption: 'Imagen.',
      },
    ],
  },
  {
    label: 'Finalización',
    key: 'finalizacion',
    value: 'finalizacion',
    initialValues: [
      {
        type: 'section',
        title: '¡Muchas Gracias!',
        description: 'Marco Iturra A. \n Ingeniero Electrónico \n Especialista en Ascensores',
        titleType: 'principal',
      },
    ],
    bloqued: ['add', 'remove'],
  },
  {
    label: 'Garantía',
    key: 'garantia',
    value: 'garantia',
    initialValues: [
      {
        type: 'section',
        title: 'Garantía',
        description: 'Garantía de 6 meses luego de la finalización de los trabajos. Por efectos de fallas de fabricación, diseño o instalación no incluyendo en esta garantía los daños causados por intervenciones de terceros, actos de vandalismo, problemas de voltaje, desastres naturales, incendios, o daños no atribuibles a su uso normal.',
        titleType: 'principal',
      },
    ],
  },
  {
    label: 'Condiciones',
    key: 'condiciones',
    value: 'condiciones',
    initialValues: [
      {
        type: 'section',
        title: 'Condiciones',
        titleType: 'principal',
      },
      {
        type: 'conceptList',
        conceptList: [
          {
            concept: 'Condiciones de Pago',
            conceptContent: "Contado"
          },
          {
            concept: 'Tiempo de trabajos',
            conceptContent: '6 meses',
          },
          {
            concept: 'Nota',
            conceptContent:'Los valors de cada item incluyen retiro, reparación e instalación.',
          },
          {
            concept: 'Condiciones generales',
            conceptContent: 'El presupuesto tiene validez de 10 días corridos. LUEGO DE ESTE PLAZO, PROLIFT SE RESERVA EL DERECHO A MODIFICAR VALORES Y TIEMPOS DE EJECUCIÓN',
          },
        ],
        bloqued: [],
      },
    ],
  },
  {
    label: 'Precios',
    key: 'precios',
    value: 'precios',
    initialValues: [
      {
        type: 'section',
        title: 'Precios',
        titleType: 'principal',
      },
      {
        type: 'productos',
        productos: [],
        totalSinIva: 0,
        totalConIva: 0,
      },
    ],
  },
];
