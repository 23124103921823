import {
  BuildOutlined,
  CalendarOutlined,
  DownloadOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { Button, Card, List, Tag } from 'antd';
import EstadosProgramados from 'assets/states/Programados.estados';
import { useProgramadas } from 'providers/Mantenciones/ProgramadasProvider';
import { useToDo } from 'providers/Mantenciones/useToDo';
import React from 'react';

const MantencionesProgramadasList = () => {
  const { data, error, isLoading, hasCalled } = useProgramadas();
  const { to } = useToDo();

  return (
    <List
      loading={isLoading}
      itemLayout="horizontal"
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4,
        xxl: 6,
      }}
      pagination={{
        align: 'end',
        position: 'bottom',
        pageSize: 8,
      }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <Card
            actions={[
              <Button
                className="bg-blue-400 hover:bg-blue-500 text-white rounded p-1"
                key="play"
                icon={<PlayCircleOutlined />}
                disabled={EstadosProgramados[item.estado]?.startDisabled}
                onClick={() => to(item.id)}
              />,
              <Button
                className="bg-orange-500 hover:bg-orange-600 text-white rounded p-1"
                key="dowload"
                icon={<DownloadOutlined />}
                disabled={EstadosProgramados[item.estado]?.dowloadDisabled}
              />,
            ]}
          >
            <p>
              <BuildOutlined className="mr-2" />
              <strong>Edificio:</strong> {item.Cliente.name}
            </p>
            <div className="flex flex-col">
              <p>
                <CalendarOutlined className="mr-2" />
                <strong>Fecha programada:</strong> {item.fecha_programada}
              </p>
            </div>
            <p>
              <InfoCircleOutlined className="mr-2" />
              <strong>Estado:</strong>{' '}
              <Tag color={EstadosProgramados[item.estado]?.color}>
                {EstadosProgramados[item.estado]?.label}
              </Tag>{' '}
              {}
            </p>
            <p>
              <HomeOutlined className="mr-2" />
              <strong>Dirección:</strong> {item.Cliente.address},{' '}
              {item.Cliente.commune}
            </p>
          </Card>
        </List.Item>
      )}
    />
  );
};

export default MantencionesProgramadasList;
