import { useAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext } from 'react';

const ObservacionesTypesContext = createContext(null);

export const ObservacionesTypesProvider = ({ children }) => {
  const map = useCallback((result) => {
    return result;
  }, []);

  const { isLoading, call, data, error, hasCalled } = useAppApi({
    baseUrl: '/clientes/observations/types',
    mapResults: map,
  });

  return (
    <ObservacionesTypesContext.Provider
      value={{
        isLoading,
        call,
        data,
        error,
        hasCalled,
      }}
    >
      {children}
    </ObservacionesTypesContext.Provider>
  );
};

export const useObservacionesTypes = () =>
  useContext(ObservacionesTypesContext);
