import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';

export const transformData = (documentElements) => {
  if (documentElements) {
    const sortedElements = documentElements.sort(
      (a, b) => a.position - b.position
    );
    return {
      items: sortedElements.map((item) => ({
        ...item,
        id: item.id || uuidv4(),
        customerInfo: item.customer_info
          ? JSON.parse(item.customer_info)
          : undefined,
        date: item.date ? moment(item.date) : undefined,
        itemsTable: item.PresupuestoItems
          ? item.PresupuestoItems.map((item) => ({
              ...item,
              ascensor: item.elevator ? item.elevator.split(',') : [],
            }))
          : [],
        ant_image: item.File
          ? [
              {
                uid: '-1',
                name: item.File.filename,
                status: 'done',
                url: item.File.path,
                existing: true,
                response: {
                  filename: item.File.filename,
                },
              },
            ]
          : null,
        image: item.File ? item.File : undefined,
      })),
    };
  }
  return null;
};
