// FieldComponent.jsx
import React from 'react';
import { Typography } from 'antd';
import types from '../types'; // Importar los tipos de campos disponibles

const { Text } = Typography;

const FieldComponent = ({ field, form, type, move, remove, listIndex, length, completeName }) => {
  // Encontrar el componente correspondiente según el tipo
  const Component = types.find((t) => t.value === type)?.render;
  // Renderizar el componente correspondiente o un mensaje de error
  return Component ? (
    <Component field={field} form={form} move={move} remove={remove} listIndex={listIndex} length={length} name={[field.name, 'children']} completeName={completeName}/>
  ) : (
    <Text type="danger">Tipo desconocido: {type}</Text>
  );
};

export default FieldComponent;
