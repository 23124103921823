import {
  BookOutlined,
  FileOutlined,
  HomeOutlined,
  SafetyOutlined,
} from '@ant-design/icons';
import { Card, Menu, Select, Alert } from 'antd';
import Title from 'antd/es/typography/Title';
import AscensoresPage from 'pages/admin/IDClientePage/AscensoresPage';
import Carpeta0 from 'pages/admin/IDClientePage/Carpeta0';
import ClienteActividadesPage from 'pages/admin/IDClientePage/ClienteActividadesPage';
import ClienteConfiguracionPage from 'pages/admin/IDClientePage/ClienteConfiguracionPage';
import ClienteInicioPage from 'pages/admin/IDClientePage/ClienteInicioPage';
import ClienteMantencionesPage from 'pages/admin/IDClientePage/ClienteMantencionesPage';
import EmergenciasPage from 'pages/admin/IDClientePage/EmergenciasPage';
import ODTPage from 'pages/admin/IDClientePage/ODTPage';
import { useTenantData } from 'providers/Tenant/TenantDataProvider';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const items = [
  { label: 'General', key: 'general', icon: <HomeOutlined /> },
  { label: 'Ascensores', key: 'ascensores' },
  { label: 'Mantenciones', key: 'mantenciones' },
  { label: 'Emergencias', key: 'emergencias', icon: <SafetyOutlined /> },
  { label: 'ODT', key: 'odt', icon: <FileOutlined /> },
  // {
  //   label: 'Plan de Mantenimiento',
  //   key: 'actividades',
  //   icon: <CalendarOutlined />,
  // },
  { label: 'Carpeta Cero', key: 'carpeta-0', icon: <BookOutlined /> },
];

const AdminClientePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { clientes, loading } = useTenantData();
  const [current, setCurrent] = useState('general');
  const [selectedCliente, setSelectedCliente] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');
    const clienteId = queryParams.get('clienteId');

    if (tab) {
      setCurrent(tab);
    }

    if (clienteId) {
      const cliente = clientes?.find(
        (cliente) => cliente.id === Number(clienteId)
      );
      setSelectedCliente(cliente);
    }
  }, [location.search, clientes]);

  const onClick = (e) => {
    setCurrent(e.key);
    const clienteId = selectedCliente?.id;
    navigate(`?tab=${e.key}${clienteId ? `&clienteId=${clienteId}` : ''}`); // Actualiza la query en la URL
  };

  const onChangeCliente = (value) => {
    const cliente = clientes?.find((cliente) => cliente.id === value);
    setSelectedCliente(cliente);
    navigate(`?tab=${current}&clienteId=${value}`); // Actualiza la query en la URL
  };

  return (
    <>
      <Card className="welcome col-span-3">
        <div className="flex flex-col justify-center items-center">
          <Title level={3} className=" text-start">
            <span className="uppercase text-white">
              Solicitudes de Mantención
            </span>
          </Title>
          <div>
            <Select
              className="min-w-[300px]"
              onChange={onChangeCliente}
              loading={loading}
              placeholder="Seleccione un cliente"
              value={selectedCliente?.id}
            >
              {clientes?.map((cliente) => (
                <Select.Option value={cliente.id} key={cliente.id}>
                  {cliente.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </Card>
      <Card>
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
        <div className="mt-2">
          {!selectedCliente ? (
            <Alert
              message="Debe seleccionar un cliente para continuar"
              type="warning"
              showIcon
            />
          ) : (
            <>
              {current === 'general' && (
                <ClienteInicioPage id={selectedCliente?.id} />
              )}
              {current === 'ascensores' && (
                <AscensoresPage id={selectedCliente?.id} />
              )}
              {current === 'mantenciones' && (
                <ClienteMantencionesPage id={selectedCliente?.id} />
              )}
              {current === 'emergencias' && (
                <EmergenciasPage id={selectedCliente?.id} />
              )}
              {current === 'odt' && <ODTPage id={selectedCliente?.id} />}
              {current === 'configuracion' && (
                <ClienteConfiguracionPage id={selectedCliente?.id} />
              )}
              {current === 'actividades' && (
                <ClienteActividadesPage id={selectedCliente?.id} />
              )}
              {current === 'carpeta-0' && <Carpeta0 id={selectedCliente?.id} />}
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default AdminClientePage;
