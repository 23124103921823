import { Button, Form, notification, Select, Table, Tag } from 'antd';
import Title from 'antd/es/skeleton/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import useRequest from 'hooks/useRequest';
import { useClientes } from 'providers/ClientesProvider';
import React, { useEffect, useState } from 'react';

const ImportActividadesForm = ({ id, onFinish }) => {
  const { activeData, call, isLoading } = useClientes();
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const { request, isLoading: isLoadingRequest, errors, data } = useRequest();
  useEffect(() => {
    call();
  }, [call]);

  const [items, setItems] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const callItems = async (id_cliente) => {
    const response = await request(`/actividad/clientes/${id_cliente}`, 'GET');
    setItems(response);
    return response;
  };
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  // Function to get month names from the month numbers and sort them
  const getMonthNames = (months) => {
    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    return months
      .sort((a, b) => a - b)
      .map((month) => monthNames[month - 1])
      .join(', ');
  };

  // Transform data to include month names for each activity
  const transformedData = items?.map((activity) => ({
    ...activity,
    meses: getMonthNames(activity?.Fechas?.map((fecha) => fecha.mes)),
  }));

  // Get unique categories for the filter options
  const uniqueCategories = [
    ...new Set(items?.map((activity) => activity.Categoria.nombre)),
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const submitImport = async () => {
    try {
      setIsLoadingAdd(true);
      if (selectedRowKeys.length === 0) {
        notification.error({
          message: 'Seleccione al menos un ítem',
        });
        return;
      }
      const response = await request('/actividad/import', 'POST', {
        items: selectedRowKeys,
        cliente: id,
      });
      onFinish(response);
      setIsLoadingAdd(false);
      return response;
    } catch (error) {
      setIsLoadingAdd(false);
      notification.error({
        message: 'Error al importar los ítems',
        description: error.message,
      });
    }
  };
  return (
    <div className="space-y-2">
      <div>
        <Paragraph>Seleccione un cliente: </Paragraph>
        <Select
          loading={isLoading}
          placeholder="Seleccione un cliente"
          onChange={(value) => callItems(value)}
          className="w-full"
        >
          {activeData?.map((cliente) => (
            <Select.Option key={cliente.id} value={cliente.id}>
              {cliente.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div>
        <Paragraph>Seleccione los ítems a importar: </Paragraph>

        <Table
          dataSource={transformedData}
          pagination={false}
          rowKey={'id'}
          size="small"
          onChange={handleTableChange}
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              sorter: (a, b) => a.id - b.id,
              sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
              defaultSortOrder: 'descend',
            },
            {
              title: 'Título',
              dataIndex: 'title',
              key: 'title',
            },
            {
              title: 'Categoría',
              dataIndex: 'Categoria',
              key: 'Categoria',
              filters: uniqueCategories.map((category) => ({
                text: category,
                value: category,
              })),
              filteredValue: filteredInfo.Categoria || null,
              onFilter: (value, record) =>
                record.Categoria.nombre.includes(value),
              render: (categoria) => (
                <div className="text-center">
                  <Tag color="blue">{categoria.nombre}</Tag>
                </div>
              ),
            },
            {
              title: 'Descripción',
              dataIndex: 'actividad',
              key: 'actividad',
            },
            {
              title: 'Meses',
              dataIndex: 'meses',
              key: 'meses',
            },
            {
              title: 'Obligatorio',
              dataIndex: 'obligatorio',
              key: 'obligatorio',
              render: (obligatorio) => (
                <div className="text-center">
                  <Tag color={obligatorio ? 'green' : 'yellow'}>
                    {obligatorio ? 'Sí' : 'No'}
                  </Tag>
                </div>
              ),
              width: 100,
            },
          ]}
        />
      </div>
      <div>
        <Button
          block
          type="primary"
          onClick={submitImport}
          loading={isLoadingAdd}
        >
          Importar
        </Button>
      </div>
    </div>
  );
};

export default ImportActividadesForm;
