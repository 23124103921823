import moment from 'moment';
import { createContext, useContext, useState } from 'react';

const CalendarActionsContext = createContext(null);

export const CalendarActionsProvider = ({ children, id }) => {
  // const map = useCallback((result) => {
  //   return result;
  // }, []);

  // const { data, isLoading, error, hasCalled, call } = useAppApi({
  //   baseUrl: '/CalendarActions',
  //   mapResults: map,
  // });
  const [selectedDay, setSelectedDay] = useState(moment());

  const handleSelectDay = (day) => {
    setSelectedDay(day);
  };

  return (
    <CalendarActionsContext.Provider value={{ handleSelectDay, selectedDay }}>
      {children}
    </CalendarActionsContext.Provider>
  );
};

export const useCalendarActions = () => useContext(CalendarActionsContext);
