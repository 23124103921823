/* eslint-disable no-unused-vars */
import { Checkbox, Col, Divider, Form, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';

const ascOptions = [
  {
    label: 'A1',
    value: 'A1',
  },
  {
    label: 'A2',
    value: 'A2',
  },
  {
    label: 'A3',
    value: 'A3',
  },
  {
    label: 'A4',
    value: 'A4',
  },
  {
    label: 'A5',
    value: 'A5',
  },
  {
    label: 'A6',
    value: 'A6',
  },
  {
    label: 'A7',
    value: 'A7',
  },
  {
    label: 'A8',
    value: 'A8',
  },
];

const MReportItemCard = (props) => {
  const { items } = props;
  return (
    <div>
      {items?.map(({ item_description, id, observation, ascensores }) => (
        <div key={id}>
          <p>{item_description}</p>
          <div>
            <Divider className="font-w-text">Agregar Observación</Divider>
            <Form.Item
              name={[id, 'observation']}
              initialValue={observation ? observation : null}
            >
              <TextArea placeholder="Observación" className="wd-100" />
            </Form.Item>
            <Form.Item
              name={[id, 'ascensores']}
              initialValue={ascensores ? ascensores.split(', ') : null}
            >
              <Checkbox.Group>
                <Row>
                  {ascOptions?.map((ascensor) => (
                    <Col span={6} key={ascensor.value}>
                      <Checkbox value={ascensor.value}>
                        {ascensor.label}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <Divider />
        </div>
      ))}
    </div>
  );
};

export default MReportItemCard;
