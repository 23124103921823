import { object } from 'prop-types';
import React from 'react';
import OrderItemsTable from './OrderItemsTable';
import VoucherTable from './VoucherTable';
// import VoucherTable from "./VoucherTable"

function Tables(props) {
  const { children } = props;
  return <div>{children}</div>;
}

Tables.VoucherTable = VoucherTable;
Tables.OrderItems = OrderItemsTable;

Tables.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: object.isRequired,
};

export default Tables;
