import { Card } from 'antd';
import Title from 'antd/es/typography/Title';
import MantencionesProgramadasList from 'components/lists/Especialista/MantencionesProgramadasList';
import { useProgramadas } from 'providers/Mantenciones/ProgramadasProvider';
import React, { useEffect } from 'react';

const ListaMantenciones = () => {
  const { call } = useProgramadas();

  useEffect(() => {
    call();
  }, [call]);

  return (
    <div className="space-y-3">
      <Card>
        <Title level={3}>Mantenciones Programadas</Title>
      </Card>
      <MantencionesProgramadasList />
    </div>
  );
};

export default ListaMantenciones;
