import React from 'react';
import PropTypes from 'prop-types';
import './BudgetBadge.scss';

function BudgetBadge(props) {
  const { description, color, amount } = props;
  return (
    <span className={`budget-badge state-color state-color-${color}`}>
      {description} {amount}
    </span>
  );
}

BudgetBadge.propTypes = {
  description: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  amount: PropTypes.number,
};
BudgetBadge.defaultProps = {
  amount: null,
};

export default BudgetBadge;
