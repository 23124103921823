import { View } from '@react-pdf/renderer';
import { any, bool, oneOf } from 'prop-types';
import React from 'react';

function Container(props) {
  const { children, justify, wrap } = props;
  const perStyles = {};

  if (justify) {
    perStyles.justifyContent = justify;
  }
  if (wrap) {
    perStyles.flexWrap = 'wrap';
  }

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        ...perStyles,
      }}
    >
      {children}
    </View>
  );
}
Container.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: any.isRequired,
  justify: oneOf([
    'space-around',
    'center',
    'flex-end',
    'flex-start',
    'space-evenly',
    'space-between',
  ]),
  wrap: bool,
};

Container.defaultProps = {
  justify: 'space-around',
  wrap: false,
};

export default Container;
