// IDClientePage.js
import {
  BookOutlined,
  CalendarOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { Card, Menu } from 'antd';
import AscensoresPage from 'pages/admin/IDClientePage/AscensoresPage';
import CarpetaDigital from 'pages/admin/IDClientePage/CarpetaDigital';
import ClienteActividadesPage from 'pages/admin/IDClientePage/ClienteActividadesPage';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

// import otros componentes aquí si es necesario

const items = [
  //   { label: 'General', key: 'general', icon: <HomeOutlined /> },
  { label: 'Ascensores', key: 'ascensores', icon: <ToolOutlined /> },
  //   { label: 'Mantenciones', key: 'mantenciones', icon: <SettingOutlined /> },
  //   { label: 'Emergencias', key: 'emergencias', icon: <SafetyOutlined /> },
  //   { label: 'ODT', key: 'odt', icon: <FileOutlined /> },
  {
    label: 'Plan de Mantenimiento',
    key: 'actividades',
    icon: <CalendarOutlined />,
  },
  {
    label: 'Carpeta Digital',
    key: 'carpeta-digital',
    icon: <BookOutlined />,
  },
  // { label: 'Presupuestos', key: 'presupuestos', icon: <DollarOutlined /> },
  // { label: 'Configuración', key: 'configuracion', icon: <SettingOutlined /> },
  //   { label: 'Carpeta Cero', key: 'carpeta-0', icon: <BookOutlined /> },
];

const IDClientePage = () => {
  const { id } = useParams(); // Obtener el ID del cliente de la ruta
  const location = useLocation();
  const navigate = useNavigate();
  const [current, setCurrent] = useState('general');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');
    if (tab) {
      setCurrent(tab);
    }
  }, [location.search]);

  const onClick = (e) => {
    setCurrent(e.key);
    navigate(`?tab=${e.key}`); // Actualiza la query en la URL
  };

  return (
    <Card>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
      />
      <div className="mt-2">
        {/* {current === 'general' && <ClienteInicioPage id={id} />} */}
        {current === 'ascensores' && <AscensoresPage id={id} />}
        {/* {current === 'mantenciones' && <ClienteMantencionesPage id={id} />}
          {current === 'emergencias' && <EmergenciasPage id={id} />}
          {current === 'odt' && <ODTPage id={id} />}
          {current === 'configuracion' && <ClienteConfiguracionPage id={id} />} */}
        {current === 'actividades' && <ClienteActividadesPage id={id} />}
        {current === 'carpeta-digital' && <CarpetaDigital id={id} />}
      </div>
    </Card>
  );
};

export default IDClientePage;
