import { MinusOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Modal, Table, Tag } from 'antd';
import {
  SOLICITUD_TIPOS,
  SOLICITUD_TIPOS_ARRAY,
  getColorForStatus,
} from 'assets/data/SolicitudesEstados';
import SpecialistSelect from 'components/selects/SpecialistSelect';
import moment from 'moment';
import { useCalendarActions } from 'providers/CalendarSolc/CalendarActionsProvider';
import { useSolicitudesMantencion } from 'providers/SolicitudesMantencionProvider';
import { useSolicitudes } from 'providers/SolicitudesProvider';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const SolicitudesForDayTable = () => {
  const {
    hasCalled,
    call,
    isLoading,
    deleteSolicitudToDay,
    selectedData,
    asignSpecialist,
  } = useSolicitudes();
  const { openDowloadMantencion } = useSolicitudesMantencion();
  const [open, setOpen] = useState(false);
  const { selectedDay } = useCalendarActions();

  useEffect(() => {
    if (!hasCalled) {
      call();
    }
  }, [hasCalled, call]);

  const closeModal = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };
  // console.log(selectedData);

  return (
    <Card
      title={
        selectedDay
          ? `Solicitudes del ${selectedDay?.format(
              'dddd, D [de] MMMM [de] YYYY'
            )}`
          : 'Seleccione un día para ver las solicitudes'
      }
      className="flex-1"
      style={{
        body: {
          padding: 0,
        },
      }}
    >
      <Modal open={open} onCancel={closeModal} footer={false}></Modal>
      <Table
        loading={isLoading}
        size="small"
        className="h-full"
        scroll={{ x: 'calc(700px + 50%)' }}
        dataSource={selectedData}
        columns={[
          {
            title: 'Mantención',
            dataIndex: 'id_mantencion',
            key: 'id_mantencion',
            render: (id_mantencion) =>
              id_mantencion ? (
                <Link to={`/admin/mantenciones/${id_mantencion}?only_edit=1`}>
                  {'N° ' + id_mantencion}
                </Link>
              ) : (
                'NA'
              ),
            width: 100,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id_mantencion - b.id_mantencion,
            showSorterTooltip: false,
          },
          {
            title: 'Estado',
            dataIndex: 'status',
            width: 100,
            render: (estado) => {
              return (
                <Tag color={getColorForStatus(estado)}>
                  {estado?.toUpperCase()}
                </Tag>
              );
            },
            filters: SOLICITUD_TIPOS_ARRAY.map((estado) => ({
              value: estado,
              text: estado,
            })),
            onFilter: (value, record) => record.status === value,
          },
          {
            title: 'Encargado',
            dataIndex: 'encargado',
            width: 150,
            render: (value, record, index) => (
              <div className="space-x-2 ">
                <SpecialistSelect
                  onChange={async (value) =>
                    asignSpecialist(record, value, index)
                  }
                  defaultValue={value}
                />
              </div>
            ),
          },
          {
            title: 'Cliente',
            dataIndex: 'Cliente',
            render: (data) => (
              <span>
                {data?.name} ({data?.rut})<br />
                {data?.address}, {data?.commune}
              </span>
            ),
            width: 250,
          },
          {
            title: 'Descripción',
            dataIndex: 'description',
            width: 250,
          },
          {
            title: 'Tipo',
            dataIndex: 'SolicitudTipo',
            render: (data) => <span>{data?.descripcion}</span>,
            width: 250,
          },
          {
            title: 'Fecha de Asignación',
            dataIndex: 'fecha_asignacion',
            render: (fecha_asignacion) =>
              moment(fecha_asignacion).format('YYYY-MM-DD HH:mm:ss'),
            width: 150,
          },
          {
            title: 'Creado',
            dataIndex: 'createdAt',
            render: (createdAt) =>
              moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
            width: 150,
          },
          {
            title: 'Actualizado',
            dataIndex: 'updatedAt',
            render: (updatedAt) =>
              moment(updatedAt).format('YYYY-MM-DD HH:mm:ss'),
            width: 150,
          },
          {
            dataIndex: 'actions',
            title: 'Acciones',
            // width: 180,
            // fixed: 'right',
            render: (_, record, index) => (
              <div className="space-x-2 flex flex-row justify-between">
                {[SOLICITUD_TIPOS.ENVIADA, SOLICITUD_TIPOS.FINALIZADA].includes(
                  record.status
                ) ? (
                  <Button
                    onClick={() => openDowloadMantencion(record)}
                    className="w-20"
                    type="link"
                    icon={<DownloadOutlined />}
                  >
                    DESCARGAR/ENVIAR
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      deleteSolicitudToDay(record, selectedDay, index)
                    }
                    className="w-20"
                    danger
                    icon={<MinusOutlined />}
                    type="link"
                  >
                    QUITAR
                  </Button>
                )}
              </div>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default SolicitudesForDayTable;
