import { Form, Input } from 'antd';
import { useMaintanceToDo } from 'providers/MaintanceToDoProvider';
import React from 'react';

const GeneralMaintanceToDoForm = ({ observaciones }) => {
  const { handleObservaciones } = useMaintanceToDo();
  return (
    <Form>
      <Form.Item
        initialValue={observaciones}
        name={'observaciones'}
        label="Observaciones"
      >
        <Input.TextArea
          onChange={(e) => handleObservaciones(e.target.value)}
          rows={4}
          placeholder="Ingresar Observaciones generales"
        />
      </Form.Item>
    </Form>
  );
};

export default GeneralMaintanceToDoForm;
