import { useAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext, useState } from 'react';

const ClienteMantencionesContext = createContext(null);

export const ClienteMantencionesProvider = ({ children }) => {
  const mapReports = useCallback((result) => result, []);

  const {
    data: mantenciones,
    isLoading,
    error,
    hasCalled,
    call: callMantencionesNotFunctional,
  } = useAppApi({
    baseUrl: '/clientes',
    mapResults: mapReports,
  });

  const call = useCallback(
    async (id) => {
      return await callMantencionesNotFunctional({
        extUrl: `/${id}/mantenciones`,
      });
    },
    [callMantencionesNotFunctional]
  );

  const getIncidencias = useCallback(
    (idMantencion) => {
      if (!mantenciones) return [];
      const mantencion = mantenciones.find((m) => m.id === idMantencion);
      if (!mantencion) return [];

      return mantencion.Respuestas.filter(
        (r) => r.Incidencias && r.Incidencias.length > 0
      );
    },
    [mantenciones]
  );

  return (
    <ClienteMantencionesContext.Provider
      value={{
        isLoading,
        error,
        hasCalled,
        mantenciones,
        call,
        getIncidencias,
      }}
    >
      {children}
    </ClienteMantencionesContext.Provider>
  );
};

export const useClienteMantenciones = () =>
  useContext(ClienteMantencionesContext);
