import { List } from 'antd';
import React, { useMemo, useState } from 'react';
import MaintanceToDoItemList from './MaintanceToDoItemList';
import { useMaintanceToDo } from 'providers/MaintanceToDoProvider';

const MaintanceToDoContainer = ({ actividades }) => {
  const { onChangeEstado, estados, onChangeProblems } = useMaintanceToDo();

  const categorias = useMemo(() => {
    const agrupadosPorCategoria = actividades?.reduce((acumulador, obj) => {
      const { Actividad: ActividadData, ...Respuesta } = obj;
      const { Categoria, ...Actividad } = ActividadData;
      const { nombre } = Categoria;

      if (!acumulador[nombre]) {
        acumulador[nombre] = {
          ...Categoria,
          actividades: [],
        };
      }
      acumulador[nombre].actividades.push({
        Respuesta,
        Actividad,
      });

      return acumulador;
    }, {});

    return Object.values(agrupadosPorCategoria) || [];
  }, [actividades]);

  return (
    <>
      <List
        dataSource={categorias}
        className="w-full"
        renderItem={(item, index) => (
          <MaintanceToDoItemList
            onChangeEstado={onChangeEstado}
            onChangeProblems={onChangeProblems}
            estados={estados}
            key={index}
            item={item}
          />
        )}
      />
    </>
  );
};

export default MaintanceToDoContainer;
