// import BudgetDowload from 'PDF/Presupuesto/BudgetDowload/BudgetDowload';
import Dowload from 'PDF/AttentionVoucher/Dowload/Dowload';
import { notification } from 'antd';
import { getAccessTokenApi } from 'api/auth';
import axios from 'axios';
import { API_PATH } from 'config';
import { createContext, useContext } from 'react';

const ActionsComprobantesContext = createContext(null);

export const ActionsComprobantesProvider = ({ children }) => {
  const getDowloadData = async (idBudget) => {
    const token = getAccessTokenApi();
    await axios
      .get(`${API_PATH}/get-budget/${idBudget}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => response.data)
      .then((value) => {
        console.log(value);
        return value;
      });
  };
  const dowload = async (id) => {
    const token = getAccessTokenApi();
    const data = await axios
      .get(`${API_PATH}/get-attention-vouchers/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => response.data)
      .then((value) => {
        return value;
      });

    notification.open({
      duration: 30,
      message: `Descargar Comprobante N°"${id}"`,
      btn: <Dowload voucher={data} />,
    });
  };

  return (
    <ActionsComprobantesContext.Provider
      value={{
        dowload,
      }}
    >
      {children}
    </ActionsComprobantesContext.Provider>
  );
};

export const useActionsComprobantes = () =>
  useContext(ActionsComprobantesContext);
