import { Select } from 'antd';
import { useUser } from 'providers/Users/UserProvider';
import React, { useEffect } from 'react';

const SpecialistSelect = ({ onChange, defaultValue }) => {
  const { call, isLoading, especialistas, hasCalled } = useUser();
  const [selected, setSelected] = React.useState(defaultValue);
  useEffect(() => {
    if (!hasCalled) {
      call();
    }
  }, [hasCalled, call]);
  const onChangeSelect = (value) => {
    onChange(value);
    setSelected(value);
  };
  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);
  return (
    <Select
      loading={isLoading}
      variant="borderless"
      className="w-full !capitalize"
      placeholder="Especialista"
      // defaultValue={defaultValue}
      onChange={onChangeSelect}
      showSearch
      value={selected}
      filterOption={(input, option) => {
        return (option?.children ?? '')
          .toLowerCase()
          .includes(input.toLowerCase());
      }}
      filterSort={(optionA, optionB) =>
        (optionA?.fullname ?? '')
          .toLowerCase()
          .localeCompare((optionB?.fullname ?? '').toLowerCase())
      }
      optionFilterProp="children"
    >
      {especialistas
        ?.sort((a, b) => a.fullname.localeCompare(b.fullname))
        ?.map((opt) => (
          <Select.Option value={opt.rut} key={opt.rut} className="capitalize">
            {opt.fullname}
          </Select.Option>
        ))}
    </Select>
  );
};

export default SpecialistSelect;
