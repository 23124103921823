import { DownloadOutlined, FormOutlined } from '@ant-design/icons';
import { Badge, Button, Card, List } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import React from 'react';
import { Link } from 'react-router-dom';
import { dateFormatter } from 'utils/formaters';

const orderStates = {
  odt_asignada: {
    text: 'Sin Iniciar',
    color: 'purple',
  },
  odt_en_proceso: {
    text: 'En proceso',
    color: 'volcano',
  },
  odt_finalizada: {
    text: 'Finalizado',
    color: 'green',
  },
};

const OrderList = (props) => {
  const { orders, isLoading, dowloadODT } = props;

  return (
    <List
      loading={isLoading}
      dataSource={orders}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4,
        xxl: 6,
      }}
      pagination={{
        align: 'end',
        position: 'both',
        pageSize: 8,
      }}
      //   loading={isLoading}
      renderItem={(order) => (
        <List.Item key={order.id_order}>
          <Badge.Ribbon
            text={
              orderStates[order.budget_status]
                ? orderStates[order.budget_status].text
                : 'ERROR'
            }
            color={
              orderStates[order.budget_status]
                ? orderStates[order.budget_status].color
                : 'red'
            }
            className=" -mt-2"
          >
            <Card
              title={order.name_client}
              hoverable
              actions={[
                <Link
                  className="hover:scale-110"
                  key="to_detail_order"
                  to={`${order.id_order}`}
                >
                  <Button icon={<FormOutlined />} type="text"></Button>
                </Link>,
                <Button
                  key="to_dowload_odt"
                  to={`${order.id_order}`}
                  onClick={() => dowloadODT(order.id_budget)}
                  type="text"
                  icon={<DownloadOutlined />}
                ></Button>,
              ]}
            >
              <Paragraph>
                <span className="font-semibold">N°: </span> {order.id_budget}
              </Paragraph>
              <Paragraph>
                <span className=" font-semibold">Dirección: </span>
                {order.address}, {order.commune}.
              </Paragraph>
              <Paragraph />
              <Paragraph>
                <span className="font-semibold">Fecha: </span>
                {dateFormatter(order.insertion_date_order).long}
              </Paragraph>

              {/* <Paragraph>
                <span className="font-semibold">N° Orden: </span>{' '}
                {order.id_order}
              </Paragraph> */}
            </Card>
          </Badge.Ribbon>
        </List.Item>
      )}
    />
  );
};

export default OrderList;
