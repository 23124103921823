import { useAppApi, usePAppApi, useUAppApi } from 'hooks/useAppApi';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

// Crear el contexto
const AdministradoresContext = createContext(null);

// Componente proveedor del contexto
export const AdministradoresProvider = ({ children }) => {
  const [filtro, setFiltro] = useState({ rut: null, fullname: null });

  const mapResults = useCallback((response) => {
    console.log(response);
    return response;
  }, []);

  const {
    isLoading: addLoading,
    addError,
    call: add,
  } = usePAppApi({
    url: '/administradores',
    mapResults: mapResults,
  });

  const { call: update, isLoading: updateLoading } = useUAppApi({
    url: '/administradores',
    mapResults: useCallback((data) => {
      console.log(data);
      return data;
    }, []),
  });



  const { call, data, isLoading, hasCalled } = useAppApi({
    baseUrl: '/administradores',
    mapResults: useCallback((data) => {
      return data;
    }, []),
  });

  const administradoresFiltrados = useMemo(() => {
    let administradores = data;
    if (!administradores) return [];

    if (filtro.rut) {
      administradores = administradores.filter((p) => p.rut === filtro.rut);
    }

    if (filtro.fullname) {
      administradores = administradores?.filter((p) => {
        // console.log(p, filtro);
        return p?.User?.fullname
          ?.toLowerCase()
          .includes(filtro?.fullname?.toLowerCase());
      });
    }
    return administradores;
  }, [data, filtro]);

  // Función para actualizar el filtro
  const addFilter = useCallback((nuevoFiltro) => {
    setFiltro((f) => ({ ...f, ...nuevoFiltro }));
  }, []);

  return (
    <AdministradoresContext.Provider
      value={{
        add,
        addLoading,
        addError,
        call,
        data: administradoresFiltrados,
        loading: isLoading,
        update,
        updateLoading,
        hasCalled,
        addFilter,
        cleanData: data,
      }}
    >
      {children}
    </AdministradoresContext.Provider>
  );
};

export const useAdministradoresProvider = () =>
  useContext(AdministradoresContext);
