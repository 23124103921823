import { StyleSheet, View } from '@react-pdf/renderer';
import { bool, string } from 'prop-types';
import React from 'react';

const Space = (props) => {
  const {
    children,
    marginInBlocks,
    direction,
    width,
    justify,
    align,
    margin,
    wrap,
  } = props;
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: direction,
      width,
      justifyContent: justify,
      alignItems: align,
      margin,
      flexWrap: wrap ? 'wrap' : 'nowrap',
    },
  });
  return (
    <View style={styles.container}>
      {children?.map((child, index) => (
        <View key={index.toString()} style={{ margin: marginInBlocks }}>
          {child}
        </View>
      ))}
    </View>
  );
};
Space.propTypes = {
  marginInBlocks: string,
  direction: string,
  width: string,
  justify: string,
  align: string,
  margin: string,
  wrap: bool,
};
Space.defaultValues = {
  marginInBlocks: '0pt 10pt',
  direction: 'column',
  width: 'auto',
  justify: 'flex-start',
  align: 'flex-start',
  margin: '0pt',
  wrap: false,
};

export default Space;
