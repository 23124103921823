/* eslint-disable no-unused-vars */
import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Skeleton } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { getApi } from 'api';
import { STATUS_ERROR, STATUS_LOADING, useCallGetApi } from 'api/useCallApi';
import ErrorComponent from 'components/ErrorComponent';
import { useAppApi } from 'hooks/useApi';
import React, { useEffect, useMemo } from 'react';
import notificationApi from 'utils/notificationApi';

const BookEditForm = (props) => {
  const { user } = props;



  const {
    payload: specialistPayload,
    refresh: reloadSpecialist,
    isLoading,
    updateParams,
  } = useAppApi({
    url: `specialist`,
    key: 'specialist',
    defaultReload: false,
    defaultValue: {},
    options: {
      payloadInState: ['warning', 'success'],
    },
    notification: true,
  });

  // const { specialist } = specialistPayload;

  const { call: updateSpecialist } = useAppApi({
    url: `specialist/${user.rut}`,
    method: 'PUT',
    defaultReload: false,
  });

  const [responseSpecialist, callSpecialist, statusCall, errorInfo] = useCallGetApi(`/specialist/${user.rut}`);

  const specialist = useMemo(() => {
    return responseSpecialist ? responseSpecialist.specialist : null;
  }, [responseSpecialist]);

  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, specialist]);

  useEffect(() => {
    callSpecialist();
  }, [callSpecialist, user.rut]);

  useEffect(() => {
    updateParams([user.rut]);
  }, [user.rut, updateParams]);

  const createSpecialist = async () => {
    const response = await getApi(`specialist/create-register/${user.rut}`);
    if (notificationApi(response, true)) {
      reloadSpecialist();
    }
  };

  const onSubmit = async () => {
    const data = form.getFieldsValue();
    const response = await updateSpecialist(data);
    if (response.state === 'success') {
      reloadSpecialist();
    }
  };

  if (statusCall === STATUS_ERROR) {
    return <ErrorComponent message={errorInfo.message} />;
  }
  console.log(specialist);
  return (
    <div>
      <div className="mb-5 center">
        <h3 className="font-primary font-w-title">{user.fullname}</h3>
      </div>
      <Skeleton loading={isLoading && statusCall === STATUS_LOADING}>
        {specialist ? (
          <Form form={form} layout="vertical" onFinish={onSubmit} initialValues={specialist}>
            <Form.Item
              rules={[{ max: 50, message: 'Máximo 50 caracteres' }]}
              label="Cargo del especialista"
              name="cargo"
              // initialValue={specialist.cargo}
            >
              <Input placeholder="Cargo del especialista" />
            </Form.Item>
            <Form.Item
              // initialValue={specialist.especialidad}
              rules={[{ max: 50, message: 'Máximo 50 caracteres' }]}
              name="especialidad"
              label="Especialidad"
            >
              <Input placeholder="Especialidad" />
            </Form.Item>
            <Form.Item
              name="otros"
              label="Otros"
              // initialValue={specialist.otros}
            >
              <TextArea placeholder="Otros" />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" block type="primary">
                Actualizar
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Button
            block
            type="primary"
            icon={<UserOutlined />}
            onClick={createSpecialist}
          >
            Crear Registro a {user.fullname}
          </Button>
        )}
      </Skeleton>
    </div>
  );
};

export default BookEditForm;
