import { useAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext } from 'react';

const AscensorEstadosContext = createContext(null);

export const AscensorEstadosProvider = ({ children, id }) => {
  const map = useCallback((result) => {
    return result;
  }, []);

  const { data, isLoading, error, hasCalled, call } = useAppApi({
    baseUrl: '/ascensores/estados',
    mapResults: map,
  });

  return (
    <AscensorEstadosContext.Provider
      value={{
        call,
        id,
        isLoading,
        error,
        hasCalled,
        data,
      }}
    >
      {children}
    </AscensorEstadosContext.Provider>
  );
};

export const useAscensorEstados = () => useContext(AscensorEstadosContext);
