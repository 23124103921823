import { Card, Divider, Form, Input, Select } from 'antd';
import React from 'react';
import StatesOptions from 'assets/data/ascensorStatesOptions.json';

const DetailsMaintanceToDoForm = ({
  form,
  changeObservaciones,
  changeAscensores,
  Ascensores,
  Mantencion,
}) => {
  const validateState = (_, value) => {
    if (value === 'NO_DECLARADO') {
      return Promise.reject(
        new Error('Se le debe declarar un estado al ascensor')
      );
    }
    return Promise.resolve();
  };

  return (
    <Form form={form}>
      {Mantencion ? (
        <Form.Item
          label="Observaciones Generales"
          name="observaciones"
          initialValue={Mantencion?.observaciones || null}
          rules={[
            {
              required: true,
              message: 'Debe ingresar observaciones generales de la mantención',
            },
          ]}
        >
          <Input.TextArea
            onChange={changeObservaciones}
            placeholder="Ingresar Observaciones"
          ></Input.TextArea>
        </Form.Item>
      ) : null}
      <Divider>Estados de los equipos</Divider>
      {Ascensores ? (
        <Form.List
          initialValue={Ascensores?.map((ascensorProg) => ({
            id_ascensor: ascensorProg.id_equipo,
            estado: ascensorProg.Ascensor.estado ?? 'NO_DECLARADO',
          }))}
          name="ascensores"
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Form.Item key={key} required={false}>
                  <Card
                    title={`Ascensor "${Ascensores[index].Ascensor.nombre}"`}
                  >
                    <Form.Item hidden name="id_ascensor">
                      <Input hidden></Input>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'estado']}
                      label="Estado del ascensor"
                      rules={[
                        {
                          validator: validateState,
                        },
                        {
                          required: true,
                          message: 'Debe seleccionar el estado del ascensor',
                        },
                      ]}
                    >
                      <Select
                        options={StatesOptions}
                        onChange={changeAscensores}
                        placeholder="Seleccionar estado del ascensor"
                      ></Select>
                    </Form.Item>
                  </Card>
                </Form.Item>
              ))}
            </>
          )}
        </Form.List>
      ) : null}
    </Form>
  );
};

export default DetailsMaintanceToDoForm;
