import { notification } from 'antd';

/**
 * Lanza una notificación con el mensaje de la api y el estado si así se indica
 * @param {Object} apiMessage - Contiene la información de la api
 * @param {string} apiMessage.message - El mensaje que proviene de la api
 * @param {string} apiMessage.state - El estado de la respuesta de la api
 * @param {bool} enabled - Si desea que muestre la notificación si la petición fue exitosa
 * @returns {bool} Indica true si fue exitoso o false si hubo un warning o error
 */
const notificationObject = {
  success: {
    notification: (message) => {
      notification.success({
        message,
      });
    },
  },
  warning: {
    notification: (message) => {
      notification.warning({
        message,
      });
    },
  },
  error: {
    notification: (message) => {
      notification.error({
        message,
      });
    },
  },
};

export const responseData = (response) => {
  const { data } = response;
  return {
    notification: (_message) => {
      const state = data.state || 'error'; // Estado por defecto es 'warning' si no está definido en data.state
      if (notificationObject[state]) {
        notificationObject[state].notification(_message || data.message);
      } else {
        notification.warning({
          // Notificación 'warning' por defecto si el estado no está definido en notificationObject
          message: _message || data.message,
        });
      }
    },
    success: data.state === 'success',
    data,
  };
};

export const validateResponse = (response, enabled = false) => {
  const { message, state } = response;
  let result = false;
  if (message) {
    switch (state) {
      case 'success':
        if (enabled) {
          notification.success({
            message,
          });
        }
        result = true;
        break;
      case 'warning':
        notification.warning({
          message,
        });
        break;
      case 'error':
        notification.error({
          message,
        });
        break;

      default:
        notification.error({
          message:
            message ||
            'Error: El servidor no ha clasificado el estado de su solicitud',
        });
        break;
    }
  } else {
    notification.error({
      message: 'Error: El servidor no ha enviado respuesta a la solicitud',
    });
  }
  return {
    success: result,
    response,
  };
};
export default (apiMessage, enabled = true) => {
  // console.log(apiMessage);
  const { message, state } = apiMessage;
  let result = false;
  switch (state) {
    case 'success':
      if (enabled) {
        notification.success({
          message: message || 'Acción exitosa',
        });
      }
      result = true;
      break;
    case 'warning':
      notification.warning({
        message,
      });
      break;
    case 'error':
      notification.error({
        message,
      });
      break;

    default:
      notification.error({
        message:
          message ||
          'Error: El servidor no ha clasificado el estado de su solicitud',
      });
      break;
  }
  return result;
};
