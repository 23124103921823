import React from 'react';
// import './assets/boxicons-2.0.7/css/boxicons.min.css';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import App from './App';
import whitTheme from 'assets/theme/default';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<React.StrictMode>{whitTheme(<App />)}</React.StrictMode>);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.active.postMessage('version_updated');
  });
}

serviceWorkerRegistration.register();

reportWebVitals();
