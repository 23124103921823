import { EditOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import moment from 'moment';
import React from 'react';

export const displayDataMap = {
  id: {
    key: 'ID',
    icon: 'warning',
  },
  nAscensores: {
    key: 'Ascensores declarados / asignados',
    icon: 'error',
    render: ({ nAscensores, Ascensores }) => {
      return `${nAscensores || 'no definido'} / ${
        Ascensores ? Ascensores.length : 0
      }`;
    },
  },
  rut: {
    key: 'RUT',
    icon: 'warning',
  },
  name: {
    key: 'Nombre',
    icon: 'warning',
  },
  address: {
    key: 'Dirección',
    icon: 'warning',
  },
  commune: {
    key: 'Comuna',
    icon: 'warning',
  },
  contactName: {
    key: 'Contacto',
    icon: 'warning',
  },
  phone: {
    key: 'Teléfono',
    icon: 'warning',
  },
  email: {
    key: 'Correo',
    icon: 'error',
  },
  createdAt: {
    key: 'Creado en',
    icon: 'warning',
    render: ({ createdAt }) =>
      createdAt
        ? moment(createdAt).format('YYYY/MM/DD')
        : 'Creado antes de la actualización',
  },
  updatedAt: {
    key: 'Última actualización',
    icon: 'warning',
    render: ({ updatedAt }) =>
      updatedAt
        ? moment(updatedAt).format('YYYY/MM/DD')
        : 'Sin actualizaciones',
  },
};

const DataClientList = (props) => {
  const { data, openEditClient } = props;

  const renderDataItem = (dataKey) => {
    const displayItem = displayDataMap[dataKey];
    const displayKey = displayItem.key;
    const displayRender = displayItem.render;
    const displayIcon = displayItem.icon;
    const displayValue = displayRender
      ? displayRender(data)
      : data[dataKey] || (
          <span className=" text-red-600 font-extrabold">No asignado</span>
        );

    return (
      <List.Item>
        <p>
          <span className="font-semibold">{displayKey}: </span>
          {displayValue}
        </p>
      </List.Item>
    );
  };

  return (
    <div>
      <List
        dataSource={Object.keys(displayDataMap)}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 6,
        }}
        bordered
        renderItem={renderDataItem}
      />
      <div className="w-1/6 mt-2 ml-auto">
        <Button
          key="edit"
          onClick={() => openEditClient(data)}
          type="primary"
          icon={<EditOutlined />}
          block
        />
      </div>
    </div>
  );
};

export default DataClientList;
