import React from 'react';
import { Button, Divider, Form, Input, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Title from 'antd/es/typography/Title';
import { rutValidator } from 'utils/formValidator';
import { useAdministradoresProvider } from 'providers/Administradores/Administradores';
import TransferClientsForm from './Item/TransferClientsForm';
import Paragraph from 'antd/es/typography/Paragraph';

const AdministradorForm = ({ refresh }) => {
  const [form] = useForm();
  const { add, addLoading } = useAdministradoresProvider();

  const onSubmit = async () => {
    try {
      const values = form.getFieldsValue();
      const response = await add(values);
      notification.success({
        message: 'Administrador creado Exitosamente',
      });
      form.resetFields();
      refresh();
    } catch (error) {
      if (error?.response?.data?.message) {
        notification.error({
          message: error?.response?.data?.message,
        });
      } else {
        notification.error({
          message: 'Error al crear el administrador',
        });
      }
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="fullname"
        label="Nombre Completo"
        rules={[
          {
            required: true,
            message: 'El nombre completo es obligatorio',
          },
          {
            max: 180,
            min: 0,
            message: 'Este campo no puede tener más de 180 caracteres',
          },
        ]}
      >
        <Input placeholder="Nombre Completo" />
      </Form.Item>

      <Form.Item
        name="rut"
        label="Rut"
        rules={[
          {
            max: 12,
            min: 0,
            message: 'Este campo no puede tener más de 12 caracteres',
          },
          {
            required: true,
            message: 'El rut es obligatorio',
          },
          {
            validator: (_, value) =>
              rutValidator(value)
                ? Promise.resolve()
                : Promise.reject(new Error('El rut ingresado no es válido')),
          },
        ]}
      >
        <Input placeholder="Rut" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Correo electrónico"
        rules={[
          {
            type: 'email',
            message: 'Debe Ingresar un correo electrónico válido',
          },
          {
            max: 80,
            min: 0,
            message: 'Este campo no puede tener más de 80 caracteres',
          },
        ]}
      >
        <Input placeholder="Correo Electrónico" />
      </Form.Item>
      <Divider>
        <Title level={5}>Clientes Permitidos</Title>
      </Divider>
      <Form.Item
        name="clientes"
        className="overflow-auto"
        rules={[
          {
            required: true,
            message: 'Debe seleccionar por lo menos un cliente',
          },
        ]}
      >
        <TransferClientsForm />
      </Form.Item>
      <Paragraph>La contraseña por defecto será el rut del usuario. La puede cambiar seleccionado el perfil del usuario.</Paragraph>
      <Form.Item>
        <Button htmlType="submit" type="primary" block loading={addLoading}>
          Agregar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AdministradorForm;
