import {
  CheckOutlined,
  ClockCircleFilled,
  DeleteFilled,
  EditFilled,
  PauseOutlined,
} from '@ant-design/icons';
import { Badge, Button, Card, Divider, List, Switch } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { switchActiveAsignation } from 'components/forms/MaintanceAsignationForm';
import React from 'react';

const status = {
  active: {
    color: 'green',
    text: 'Activo',
  },
  inactive: {
    color: 'red',
    text: ' Inactivo',
  },
};

// const AscensoresAsignados = (props) => {
//   const { ascensores } = props;
//   const ascensoresString = ascensores
//     ?.map((item) => {
//       return item.Ascensor.nombre;
//     })
//     .join('-');
//   return (
//     <Paragraph>
//       <span className="font-semibold">Ascensores asignados: </span>
//       {ascensoresString}
//     </Paragraph>
//   );
// };

const MaintanceAsignationsList = (props) => {
  const { dataSource, isLoading, openDelete, openEdit, switchActive } = props;
  return (
    <List
      className="mt-3"
      itemLayout="horizontal"
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4,
        xxl: 6,
      }}
      pagination={{
        align: 'end',
        position: 'bottom',
        pageSize: 12,
      }}
      dataSource={dataSource}
      loading={isLoading}
      rowKey="id"
      renderItem={({ AscProgs, Cliente, ...data }) => (
        <List.Item key={data.id}>
          <Badge.Ribbon
            {...status[data.activo ? 'active' : 'inactive']}
            placement="start"
            className="-mt-3"
          >
            <Card
              hoverable
              className="rounded-lg shadow-md"
              actions={[
                <Button
                  key={'delete'}
                  type="text"
                  danger
                  className="p-0"
                  onClick={() => openDelete(data)}
                >
                  <DeleteFilled />
                </Button>,
                <Button
                  key={'edit'}
                  type="text"
                  className="p-0"
                  onClick={() => openEdit({ AscProgs, Cliente, ...data })}
                >
                  <EditFilled />
                </Button>,
              ]}
              extra={
                <Switch
                  className="mt-2"
                  defaultChecked={data.activo}
                  onChange={() => switchActive(data)}
                  checkedChildren={<ClockCircleFilled />}
                  unCheckedChildren={<PauseOutlined />}
                />
              }
              title={
                <div className="font-semibold text-lg">{Cliente.name}</div>
              }
            >
              <Divider>Ascensores Asignados</Divider>
              {AscProgs.length > 0 ? (
                <List
                  dataSource={AscProgs}
                  renderItem={({ Ascensor, id }) => (
                    <List.Item key={id}>{Ascensor.nombre}</List.Item>
                  )}
                />
              ) : (
                <Paragraph type="danger">Sin ascensores asignados</Paragraph>
              )}
            </Card>
          </Badge.Ribbon>
        </List.Item>
      )}
    />
  );
};

export default MaintanceAsignationsList;
