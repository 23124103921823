import { Document, View, StyleSheet, Text } from '@react-pdf/renderer';
import React from 'react';
import Moment from 'moment';
import PageA4 from '../Elements/Pages/PageA4';
import Normal from '../Elements/Text/Normal';
import Title1 from '../Elements/Text/Title1';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import 'moment/locale/es';
import RecSpeSignatures from 'PDF/Elements/Some/RecSpeSignatures';

const styles = StyleSheet.create({
  page: {
    paddingTop: '4.5cm',
  },
  header: {
    position: 'absolute',
  },
  section: {
    // margin: 10,
    padding: 0,
  },
  table: {
    display: 'table',
    width: '100%',
    marginTop: 0,
  },
  observationImage: {
    marginTop: 5,
    width: '100%',
    height: 'auto',
  },
  footer: {
    position: 'absolute',
    height: '80px',
    width: '100vw',
    bottom: 0,
  },

  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '16.66%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
    padding: 5,
  },
  tableColObservationHeader: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#44b3e1',
    textAlign: 'center',
    padding: 5,
  },
  tableColSuggestionHeader: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#ffff00',
    textAlign: 'center',
    padding: 5,
  },
  tableCol: {
    width: '16.66%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    textAlign: 'center',
    padding: 5,
  },
  tableColObservation: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    textAlign: 'center',
    padding: 5,
  },
  tableColSuggestion: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    textAlign: 'center',
    padding: 5,
  },
  tableCellHeader: {
    margin: 'auto',
    fontSize: 12,
    fontWeight: 'bold',
  },
  tableCell: {
    margin: 'auto',
    fontSize: 10,
  },
  normal: {
    marginBottom: 10,
  },
  tableRowNA: {
    backgroundColor: '#fff3cd',
  },
  tableRowExitoso: {
    backgroundColor: '#d4edda',
  },
  tableRowRechazado: {
    backgroundColor: '#f8d7da',
  },
  tableColCategory: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#d1ecf1',
    textAlign: 'center',
    padding: 5,
  },
  tableColActivity: {
    width: '80%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#d1ecf1',
    textAlign: 'center',
    padding: 5,
  },
  headerRow: {
    flexDirection: 'row',
    backgroundColor: '#001f5b',
    color: '#fff',
    marginTop: 0,
  },
  headerColCategory: {
    width: '20%',
    textAlign: 'left',
    padding: 5,
    fontSize: 12,
  },
  headerColActivity: {
    width: '80%',
    textAlign: 'left',
    padding: 5,
    fontSize: 12,
  },
});

function MantencionDocument({
  mantencion: data,
  actividades,
  config,
  staticData,
}) {
  const { Cliente, Responsable, ...mantencion } = data;

  const getTextByKey = (key) => {
    const entry = staticData?.find(
      (item) => item.keyName === key && item.documentType === 'mantencion'
    );
    return entry ? entry.textValue : '';
  };

  const formatText = (text) => {
    return text
      .replace(/{client_name}/g, Cliente.name)
      .replace(/{client_address}/g, Cliente.address)
      .replace(/{client_commune}/g, Cliente.commune)
      .replace(
        /{date}/g,
        Moment(mantencion.createdAt).format('DD [de] MMMM [del] YYYY')
      );
  };

  const renderTableHeader = () => (
    <View style={styles.tableRow}>
      <View style={{ ...styles.tableColHeader, backgroundColor: '#bfbfbf' }}>
        <Text style={styles.tableCellHeader}>Ascensores</Text>
      </View>
      <View style={{ ...styles.tableColHeader, backgroundColor: '#3c7d22' }}>
        <Text style={styles.tableCellHeader}>Estado</Text>
      </View>
      <View style={styles.tableColObservationHeader}>
        <Text style={styles.tableCellHeader}>Observación</Text>
      </View>
      <View style={styles.tableColSuggestionHeader}>
        <Text style={styles.tableCellHeader}>Sugerencia</Text>
      </View>
    </View>
  );

  const getRowStyle = (estado) => {
    switch (estado) {
      case 'NA':
        return styles.tableRowNA;
      case 'EXITOSO':
        return styles.tableRowExitoso;
      case 'RECHAZADO':
        return styles.tableRowRechazado;
      default:
        return {};
    }
  };

  const renderTableRow = (respuesta) => (
    <View
      key={respuesta.id}
      style={[styles.tableRow, getRowStyle(respuesta.estado)]}
      wrap={false}
    >
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{respuesta.Ascensor.nombre}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={{ ...styles.tableCell, fontSize: 8 }}>
          {respuesta.estado === 'EXITOSO'
            ? 'FUNCIONANDO'
            : respuesta.estado === 'RECHAZADO'
            ? 'FALLA'
            : 'NO APLICA'}
        </Text>
      </View>
      <View style={styles.tableColObservation}>
        <Text style={styles.tableCell}>
          {respuesta.estado === 'RECHAZADO' &&
          (!respuesta.observacion || respuesta.observacion.trim() === '')
            ? 'Con problemas'
            : respuesta.estado === 'EXITOSO' &&
              (!respuesta.observacion || respuesta.observacion.trim() === '')
            ? 'Estado normal'
            : respuesta.observacion}
        </Text>
      </View>
      <View style={styles.tableColSuggestion}>
        <Text style={styles.tableCell}>
          {!respuesta.sugerencia ||
          respuesta.sugerencia === '' ||
          respuesta.sugerencia === null
            ? 'Sin sugerencias'
            : respuesta.sugerencia}
        </Text>
      </View>
    </View>
  );

  return (
    <Document>
      <PageA4 style={styles.page}>
        <View style={styles.header} fixed>
          <Header
            title={`Informe de Mantención N°${mantencion.id}`}
            config={config}
          />
        </View>
        <Title1>{formatText(getTextByKey('greeting'))}</Title1>
        <Normal style={styles.normal}>
          {formatText(getTextByKey('introduction'))}
        </Normal>
        <Normal style={styles.normal}>{getTextByKey('report_includes')}</Normal>

        <View wrap={false}>
          <Title1>{getTextByKey('report_title')}</Title1>
          <Normal style={styles.normal}>{getTextByKey('following')}</Normal>
        </View>

        {actividades
          ?.filter((actividad) =>
            actividad.Respuestas.some((respuesta) => respuesta.estado !== 'NA')
          )
          .map((actividad, index) => (
            <View
              key={actividad.id}
              style={[styles.section, { marginTop: index === 0 ? 0 : 0 }]}
              wrap={false}
            >
              <View style={styles.headerRow}>
                <Text
                  style={{
                    ...styles.headerColCategory,
                    textAlign: 'center',
                  }}
                >
                  Sector
                </Text>
                <Text
                  style={{
                    ...styles.headerColActivity,
                    textAlign: 'center',
                  }}
                >
                  Actividad
                </Text>
              </View>
              <View
                style={{
                  ...styles.headerRow,
                  backgroundColor: '#c0e6f5',
                }}
              >
                <Text
                  style={{
                    ...styles.headerColCategory,
                    color: 'black',
                    textAlign: 'center',
                  }}
                >
                  {actividad.Categoria.nombre}
                </Text>
                <Text style={{ ...styles.headerColActivity, color: 'black' }}>
                  {actividad.actividad}
                </Text>
              </View>
              <View style={styles.table}>
                {renderTableHeader()}
                {actividad.Respuestas.filter(
                  (respuesta) => respuesta.estado !== 'NA'
                )?.map((respuesta) => renderTableRow(respuesta))}
              </View>
            </View>
          ))}

        <View wrap={false}>
          <Title1>Observaciones Generales</Title1>
          <Normal>
            {mantencion.observaciones ?? 'SIN OBSERVACIONES GENERALES'}
          </Normal>
        </View>
        <View wrap={false}>
          <Title1>Información de Entrega</Title1>
          <Normal style={styles.normal}>
            A continuación, se encuentra la firma, la información del
            especialista y de quien recibe la atención. Esto, cuenta como una
            constancia firmada de que se realizó la atención.
          </Normal>
          <RecSpeSignatures
            specialistSignature={mantencion.firma_spec}
            recepcionistName={mantencion.name_recep}
            recepcionistSignature={mantencion.firma_recep}
            recepcionistCharge={mantencion.cargo_recep}
            specialistName={Responsable.fullname}
          />
        </View>
        <View style={styles.footer} fixed>
          <Footer config={config} />
        </View>
      </PageA4>
    </Document>
  );
}

export default MantencionDocument;
