import { any, string } from 'prop-types';
import React from 'react';

import './Badge.scss';

function Badge(props) {
  const { color, children } = props;
  return (
    <span className={`badge state-color state-color-${color}`}>{children}</span>
  );
}
Badge.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: any,
  color: string,
};

Badge.defaultProps = {
  children: null,
  color: '',
};

export default Badge;
