import React from 'react';
import { Drawer, Form, Select } from 'antd';

const { Option } = Select;

const PresupuestoClienteDrawer = ({ visible, onClose, onSelectTemplate }) => {
  const handleSelect = (value) => {
    const selectedTemplate = {
      titulo: 'Información del Cliente',
      texto: 'Contenido de la información del cliente.',
    };
    onSelectTemplate(selectedTemplate);
  };

  return (
    <Drawer
      title="Seleccionar Plantilla de Información del Cliente"
      placement="right"
      closable={true}
      onClose={onClose}
      open={visible}
      width={400}
    >
      <Form layout="vertical">
        <Form.Item label="Seleccione una plantilla">
          <Select
            placeholder="Seleccione una plantilla"
            onChange={handleSelect}
          >
            <Option value="template1">Plantilla 1</Option>
            <Option value="template2">Plantilla 2</Option>
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default PresupuestoClienteDrawer;
