import { Document, View } from '@react-pdf/renderer';
import { arrayOf, shape } from 'prop-types';
import React from 'react';
import Moment from 'moment';
import PageA4 from '../Elements/Pages/PageA4';
import Table from '../Elements/Table/Table';
import Normal from '../Elements/Text/Normal';
import Title1 from '../Elements/Text/Title1';
import Title2 from '../Elements/Text/Title2';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import 'moment/locale/es';
import RecSpeSignatures from 'PDF/Elements/Some/RecSpeSignatures';
import { API_PATH } from 'config';

const columns = [
  {
    width: '13%',
    title: 'Categoría',
    key: 'category',
  },
  {
    width: '17%',
    title: 'Ascensores',
    key: 'ascensores',
  },
  {
    width: '35%',
    title: 'Descripción',
    key: 'item_description',
  },
  {
    width: '35%',
    title: 'Observación',
    key: 'observation',
  },
];

function MaintanceDocument(props) {
  const { items, report, config } = props;
  const { name, address, commune } = report;
  const {
    general_observation: observation,
    specialist_name: specialistName,
    recepcionist_name: recepcionistName,
    specialist_cargo: specialistCargo,
    specialist_especialidad: specialistEspecialidad,
    specialist_otros: specialistOtros,
    insert_in: insertIn,
  } = report;
  const {
    recepcionist_signature: recepcionistSignature,
    recepcionist_charge: recepcionistCharge,
    specialist_signature: specialistSignature,
    id_maintance_report: idReport,
  } = report;

  return (
    <Document>
      <PageA4
        style={{
          paddingTop: '4.09cm',
        }}
      >
        <View
          style={{
            position: 'absolute',
          }}
          fixed
        >
          <Header
            title={`Informe de Mantención N°${idReport}`}
            config={config}
          />
        </View>
        <Title1>Estimados {name},</Title1>
        <Normal>
          Es un placer presentarle el informe detallado de mantenimiento
          realizado en {address}, en la comuna de {commune} realizado el{' '}
          {Moment(insertIn).format('DD [de] MMMM [del] YYYY')}. Los
          especialistas han llevado a cabo una revisión exhaustiva de las
          instalaciones, a fin de garantizar su funcionamiento óptimo y
          seguridad.
        </Normal>
        <Normal>
          Este informe incluye un registro completo de los trabajos realizados y
          las observaciones encontradas durante la inspección. Cualquier duda o
          pregunta sobre los resultados de este mantenimiento, por favor no dude
          en contactarnos a través de nuestro correo electrónico.
        </Normal>

        <View wrap={false}>
          <Title1>Informe</Title1>
          <Normal>
            En la siguiente tabla, se observan los items revisados y los
            ascensores involucrados.
          </Normal>
          <Title2>Tabla</Title2>
        </View>
        <Table columns={columns} data={items} />
        <View
          fixed
          style={{
            position: 'absolute',
            height: '80px',
            width: '100vw',
            bottom: '0',
          }}
        >
          <Footer config={config} />
        </View>
        <View wrap={false}>
          <Title1>Observaciones Generales</Title1>
          <Normal>{observation}</Normal>
        </View>
        <View wrap={false}>
          <Title1>Información de Entrega</Title1>
          <Normal>
            A continuación, se encuentra la firma, la información del
            especialista y de quien recibe la atención. Esto, cuenta como una
            constancia firmada de que se realizó la atención.
          </Normal>

          <RecSpeSignatures
            specialistSignature={`${API_PATH}/public/signature/${specialistSignature}`}
            recepcionistName={recepcionistName}
            recepcionistSignature={`${API_PATH}/public/signature/${recepcionistSignature}`}
            recepcionistCharge={recepcionistCharge}
            specialistName={specialistName}
            specialistEspecialidad={specialistEspecialidad}
            specialistCargo={specialistCargo}
            specialistOtros={specialistOtros}
          />
        </View>
      </PageA4>
    </Document>
  );
}
MaintanceDocument.propTypes = {
  items: arrayOf(shape({})).isRequired,
  report: shape({}).isRequired,
};

export default MaintanceDocument;
