import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Select } from 'antd';
import moment from 'moment';
import { useCliente } from 'providers/Clientes/ClienteProvider';
import React, { useEffect, useState } from 'react';

const { Option } = Select;

const ClientConfigData = ({ id }) => {
  const {
    callConfig,
    dataConfig,
    isLoadingConfig,
    hasCalledConfig,
    saveConfig,
  } = useCliente();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(
    dataConfig?.mes_referencia_mantencion || 0
  );

  useEffect(() => {
    if (!hasCalledConfig) {
      callConfig(id);
    }
  }, [callConfig, id, hasCalledConfig]);

  useEffect(() => {
    if (dataConfig?.mes_referencia_mantencion) {
      setSelectedMonth(dataConfig.mes_referencia_mantencion);
    }
  }, [dataConfig]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = () => {
    // Lógica para guardar el mes seleccionado
    saveConfig(id, {
      mes_referencia_mantencion: selectedMonth,
    });
    setIsEditing(false);
  };

  return (
    <Card
      title={<h3>Configuración del Cliente</h3>}
      className="mb-4"
      extra={
        <Button
          type="text"
          key="editar"
          icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
          onClick={isEditing ? handleSaveClick : handleEditClick}
        >
          {isEditing ? 'Guardar' : 'Editar'}
        </Button>
      }
    >
      <Descriptions
        size="small"
        // extra={

        // }
        bordered
      >
        <Descriptions.Item
          label={
            <span className="font-bold">
              Mes Inicio de Calendario de Mantención:{' '}
            </span>
          }
          // span={2}
          contentStyle={{ width: '30%' }}
        >
          {isEditing ? (
            <Select
              value={selectedMonth}
              onChange={(value) => setSelectedMonth(value)}
              className="w-full"
              // style={{ width: 120 }}
            >
              {moment.months().map((month, index) => (
                <Option key={index} value={index} className=" capitalize">
                  {month}
                </Option>
              ))}
            </Select>
          ) : (
            moment().month(selectedMonth).format('MMMM')
          )}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default ClientConfigData;
