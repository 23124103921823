import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  List,
  Select,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import SelectClient from 'components/global/Form/SelectClient';
import React, { useEffect, useMemo, useState } from 'react';
import { deleteToApi, getToApi, postToApi, putToApi } from 'api/axios';
import { useGetToApi } from 'hooks/useApi/useGetAxios';
import ActivityCalendar from 'components/views/ActivityCalendar';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import AscensoresList from 'components/lists/AscensoresList';
import { mesesData } from 'assets/data/meses';
import { handleApiResponse } from 'utils/handlers';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import AscensorCard from 'components/AscensorCard';
import { useClientes } from 'providers/ClientesProvider';

const BASE_DIRECTION = '/programacion-automatica';

export const deleteAsignation = async (id, isFinish) =>
  handleApiResponse(
    deleteToApi,
    `${BASE_DIRECTION}/drop/${id}`,
    null,
    'El servidor no ha enviado respuesta. Verifique si fué eliminado',
    isFinish
  );

export const addAsignation = async (values, isFinish) =>
  handleApiResponse(
    postToApi,
    `${BASE_DIRECTION}/add`,
    values,
    'El servidor no ha enviado respuesta. Verifique si fué añadido',
    isFinish
  );

export const switchActiveAsignation = async (id, isFinish) =>
  handleApiResponse(
    getToApi,
    `${BASE_DIRECTION}/toggle-active/${id}`,
    null,
    'El servidor no ha enviado respuesta.',
    isFinish
  );

export const editAsignation = async (data, id, isFinish) => {
  if (data.ascensores) {
    data.ascensores = Object.entries(data.ascensores);
  }

  return handleApiResponse(
    putToApi,
    `${BASE_DIRECTION}/update/${id}`,
    data,
    'El servidor no ha enviado respuesta. Por favor, verifique si fué modificado',
    isFinish
  );
};

const MaintanceAsignationForm = (props) => {
  const { form } = props;
  const [referenceMonth, setReferenceMonth] = useState();
  const [client, setClient] = useState({});
  const {
    call: callClientes,
    data: clientes,
    isLoading: isLoadingClientes,
  } = useClientes();

  useEffect(() => {
    callClientes();
  }, [callClientes]);

  const onChangeMonth = (value) => {
    form.setFieldValue('number_init', value);
    setReferenceMonth(value);
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id_cliente"
        label="Cliente"
        rules={[{ required: true, message: 'Debe seleccionar un cliente' }]}
      >
        <Select
          placeholder="Seleccione el cliente"
          loading={isLoadingClientes}
          onChange={(value) =>
            setClient(clientes?.find((cliente) => cliente.id === value))
          }
          options={clientes?.map((cliente) => ({
            value: cliente.id,
            label: cliente.name,
          }))}
          value={client.id}
        />
      </Form.Item>

      {client?.Ascensores?.length !== 0 ? (
        <List
          dataSource={client?.Ascensores}
          grid={{
            gutter: 12,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1,
            xxl: 1,
          }}
          renderItem={(ascensor) => (
            <List.Item>
              <AscensorCard
                extra={
                  <Form.Item
                    key={'ascensores'}
                    valuePropName={'checked'}
                    name={['ascensores', ascensor.id.toString()]}
                    className="pt-3"
                  >
                    <Checkbox />
                  </Form.Item>
                }
                data={{ Ascensor: ascensor }}
                columnasPersonalizadas={[
                  { columnKey: 'marca', title: 'Marca' },
                  { columnKey: 'modelo', title: 'Modelo' },
                  { columnKey: 'capacidad', title: 'Capacidad' },
                  { columnKey: 'pisos', title: 'Pisos' },
                  { columnKey: 'estado', title: 'Estado' },
                  { columnKey: 'numero_serie', title: 'N° Serie' },
                  {
                    columnKey: 'mantencion_automatica',
                    title: 'Automatizado',
                    render: (value, object) =>
                      object.mantencion_automatica ? 'SI' : 'NO',
                  },
                ]}
              />
            </List.Item>
          )}
        />
      ) : (
        <Alert
          className="uppercase my-3"
          message="El cliente no tiene ascensores inscritos"
          type="warning"
        />
      )}
      <Form.Item
        label="Mes de Inicio"
        name={'mes_ref'}
        rules={[
          {
            required: true,
            message: 'Seleccione el mes de referencia',
          },
        ]}
      >
        <Select
          options={mesesData}
          onChange={onChangeMonth}
          placeholder="Seleccione el mes de referencia para las mantenciones"
        />
      </Form.Item>
      {/* <Title level={3} className="text-blue-600">
        Calendario de Mantenciones
        <Tooltip
          title="Información sobre el calendario"
          overlay={
            <Paragraph className=" text-white">
              {' '}
              Nuestra app web cuenta con una función llamada{' '}
              <strong>Calendario de Mantenciones</strong>. Esta te permite
              programar tareas de mantenimiento durante el año. Si eliges un
              mes, el sistema reajusta las tareas de otros meses para que se
              realicen en el mes seleccionado. Así, si eliges marzo, las tareas
              de enero se moverán a marzo. Esto asegura una planificación
              ordenada y eficiente.
            </Paragraph>
          }
        >
          <InfoCircleOutlined className="ml-2" />
        </Tooltip>
      </Title> */}

      {/* <div className="mb-2">
        <ActivityCalendar
          activities={activities}
          referenceMonth={referenceMonth}
        />
      </div> */}
    </Form>
  );
};

export default MaintanceAsignationForm;
