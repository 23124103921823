import {
  AppstoreOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileProtectOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  OrderedListOutlined,
  SafetyOutlined,
  TeamOutlined,
  UserOutlined,
  DollarOutlined,
  CalendarOutlined,
  BarcodeOutlined,
} from '@ant-design/icons';
import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useTenantData } from 'providers/Tenant/TenantDataProvider'; // Ajustar el import según tu estructura

// Mapeo de nombres de iconos a componentes de iconos
const iconMapping = {
  HomeOutlined: <HomeOutlined />,
  FileProtectOutlined: <FileProtectOutlined />,
  OrderedListOutlined: <OrderedListOutlined />,
  FilePdfOutlined: <FilePdfOutlined />,
  SafetyOutlined: <SafetyOutlined />,
  TeamOutlined: <TeamOutlined />,
  UserOutlined: <UserOutlined />,
  AppstoreOutlined: <AppstoreOutlined />,
  DollarOutlined: <DollarOutlined />,
  FileTextOutlined: <FileTextOutlined />,
  DatabaseOutlined: <DatabaseOutlined />,
  FolderOpenOutlined: <FolderOpenOutlined />,
  CalendarOutlined: <CalendarOutlined />,
  BarcodeOutlined: <BarcodeOutlined />,
};

const MenuSider = ({ type }) => {
  const { menu } = useTenantData(); // Obtener datos del menú desde el proveedor

  // Filtrar los elementos del menú según el tipo de usuario
  const filteredMenuItems = menu?.filter((item) => item.userType === type);

  // Crear elementos de menú dinámicamente
  const createMenuItems = (items) => {
    return items?.map((item) => {
      if (item.children) {
        return {
          key: item.key,
          icon: iconMapping[item.icon],
          label: item.label,
          children: createMenuItems(item.children),
        };
      }
      return {
        key: item.key,
        icon: iconMapping[item.icon],
        label: <Link to={item.route}>{item.label}</Link>,
      };
    });
  };

  // Manually nest "Calendario", "Mantenciones" and "Actividades de Mantención" under "Mantenciones"
  const nestMantenciones = (items) => {
    const mantencionesIndex = items?.findIndex(
      (item) => item.key === '/admin/mantenciones'
    );
    if (mantencionesIndex && mantencionesIndex !== -1) {
      const mantencionesItem = items[mantencionesIndex];
      mantencionesItem.children = items?.filter(
        (item) =>
          item.key === '/' ||
          item.key === '/mantenciones' ||
          item.key === '/actividades'
      );
      items = items.filter(
        (item) =>
          item.key !== '/' &&
          item.key !== '/mantenciones' &&
          item.key !== '/actividades'
      );
      items[mantencionesIndex] = mantencionesItem;
    }
    return items;
  };

  let menuItems = createMenuItems(filteredMenuItems);
  menuItems = nestMantenciones(menuItems);

  return <Menu mode="inline" items={menuItems} />;
};

export default MenuSider;
