import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Table } from 'antd';

import { useMantenciones } from 'providers/MantencionesProvider';
import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { useSolicitudesMantencion } from 'providers/SolicitudesMantencionProvider';

const Mantenciones = () => {
  const refCard = useRef(null);
  const refCalendar = useRef(null);
  const {
    data: mantenciones,
    hasCalled: mantencionesHasCalled,
    call: callMantenciones,
    isLoading: mantencionesIsLoading,
  } = useMantenciones();

  useEffect(() => {
    if (refCard.current && refCalendar.current) {
      const calendarHeight = refCalendar.current.offsetHeight;
      refCard.current.style.height = `${calendarHeight}px`;
    }
  }, []);

  useEffect(() => {
    if (!mantencionesHasCalled) {
      callMantenciones();
    }
  }, [callMantenciones, mantencionesHasCalled]);
  const { openDowloadMantencion } = useSolicitudesMantencion();
  const handleClickInSolc = (record) => {
    openDowloadMantencion({
      id_mantencion: record.id,
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
      showSorterTooltip: false,
    },
    {
      title: 'Responsable',
      dataIndex: 'Responsable',
      key: 'responsable',
      render: (responsable) => responsable?.fullname,
    },
    {
      title: 'Cliente',
      dataIndex: 'Cliente',
      key: 'cliente',
      render: (cliente) => cliente?.name,
    },
    {
      title: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Última Actualización',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      width: 100,
      fixed: 'right',
      render: (_, record) => (
        <div className="space-x-2">
          <Button
            onClick={() => handleClickInSolc(record)}
            // className="w-20"
            type="link"
          >
            <DownloadOutlined /> Descargar
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="space-y-5 p-4">
      <Card title="Todas las Mantenciones Realizadas" className="mt-4">
        <Table
          dataSource={mantenciones}
          columns={columns}
          loading={mantencionesIsLoading}
          rowKey="id"
          style={{ overflowX: 'auto' }}
        />
      </Card>
    </div>
  );
};

export default Mantenciones;
