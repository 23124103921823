import React, { useState, useEffect } from 'react';
import {
  PlusOutlined,
  FilePdfOutlined,
  FileOutlined,
  DownloadOutlined,
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Card, List, Avatar, Image } from 'antd';
import { useStorage } from 'providers/StorageProvider';
import moment from 'moment'; // Asumiendo que tienes 'moment' instalado para manejar fechas
import useAuth from 'hooks/useAuth';

const Carpeta0 = ({ id }) => {
  const {
    getCarpeta0,
    carpeta0,
    loadingGetCarpeta0,
    createCarpeta0,
    loadingCreateCarpeta0,
    openCreate,
    openEdit,
    openDelete,
  } = useStorage();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    getCarpeta0(id);
  }, [getCarpeta0, id]);

  const filetypeIcon = (type) => {
    if (type.startsWith('image')) {
      return null;
    } else if (type === 'application/pdf') {
      return <FilePdfOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />;
    } else {
      return <FileOutlined style={{ fontSize: '24px', color: '#1890ff' }} />;
    }
  };

  const handlePreview = (file) => {
    setPreviewImage(file.path);
    setPreviewVisible(true);
  };

  return (
    <Card
      title="Carpeta Cero"
      loading={loadingGetCarpeta0}
      extra={
        !carpeta0 ? (
          <Button
            type="primary"
            loading={loadingCreateCarpeta0}
            onClick={() => createCarpeta0(id)}
          >
            Crear Carpeta
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => openCreate(carpeta0)}
            icon={<PlusOutlined />}
          >
            Añadir Archivo
          </Button>
        )
      }
    >
      {carpeta0 && carpeta0.Files.length > 0 && (
        <List
          itemLayout="horizontal"
          dataSource={carpeta0.Files}
          renderItem={(file) => (
            <List.Item
              actions={[
                file.file_type.startsWith('image') && (
                  <Button
                    icon={<EyeOutlined />}
                    type="link"
                    onClick={() => handlePreview(file)}
                  ></Button>
                ),
                <a
                  key="download"
                  href={file.path}
                  download={file.filename}
                  title="Descargar"
                  // Se abre en una nueva pestaña
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button icon={<DownloadOutlined />} type="link" />
                </a>,
                <Button
                  key="edit"
                  type="link"
                  onClick={() => openEdit(file, carpeta0)}
                  disabled={user.is_administrador}
                  icon={<EditOutlined />}
                ></Button>,
                <Button
                  icon={<DeleteOutlined />}
                  key="edit"
                  type="link"
                  disabled={user.is_administrador}
                  onClick={() => openDelete(file.id, carpeta0)}
                  danger
                ></Button>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  file.file_type.startsWith('image') ? (
                    <Avatar src={file.path} shape="square" size="large" />
                  ) : (
                    <Avatar
                      icon={filetypeIcon(file.file_type)}
                      shape="square"
                      style={{ backgroundColor: '#fde3cf' }}
                    />
                  )
                }
                title={file.title}
                description={`${file.description} - ${moment(
                  file.createdAt
                ).format('DD/MM/YYYY')}`} // Formatea la fecha como desees
              />
            </List.Item>
          )}
        />
      )}
      <Image
        preview={{
          visible: previewVisible,
          onVisibleChange: (value) => setPreviewVisible(value),
        }}
        src={previewImage}
        onClick={() => setPreviewVisible(false)}
        style={{ display: 'none' }}
      />
    </Card>
  );
};

export default Carpeta0;
