import { WarningFilled } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useAdministradoresProvider } from 'providers/Administradores/Administradores';
import React from 'react';

const AdminUserForm = ({ initialValues }) => {
  const [form] = useForm();
  const { update } = useAdministradoresProvider()
  const finishForm = async () => {
    const formValues = form.getFieldsValue();
    let {resetPassword, ...values} = formValues
    if(resetPassword){
      values.password = initialValues.rut
    }
    console.log(values);
    
    const response = await update(values)
    console.log(response);
  };
  return (
    <Form
      form={form}
      onFinish={finishForm}
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item
        name="fullname"
        label="Nombre Completo"
        rules={[
          {
            required: true,
            message: 'El nombre completo es obligatorio',
          },
          {
            max: 180,
            min: 0,
            message: 'Este campo no puede tener más de 180 caracteres',
          },
        ]}
      >
        <Input placeholder="Nombre Completo" />
      </Form.Item>
      <Form.Item name="rut" label="Rut">
        <Input disabled placeholder="Rut" />
      </Form.Item>
      <Form.Item name="adminId" label="ID Administrador" hidden>
        <Input disabled placeholder="Id Administrador" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Correo electrónico"
        rules={[
          {
            type: 'email',
            message: 'Debe Ingresar un correo electrónico válido',
          },
          {
            max: 80,
            min: 0,
            message: 'Este campo no puede tener más de 80 caracteres',
          },
        ]}
      >
        <Input placeholder="Correo Electrónico" />
      </Form.Item>
      <Form.Item
        label="Contraseña por defecto"
        // initialValue={}
      >
        <Input
          disabled
          value={'La contraseña por defecto es el rut del usuario'}
        />
      </Form.Item>
      <Form.Item name="resetPassword" valuePropName="checked" >
        <Checkbox>Dejar contraseña por defecto (rut)</Checkbox>
      </Form.Item>
      <Form.Item>
        <Button block type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AdminUserForm;
