/* eslint-disable no-unused-vars */
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Space,
  Tooltip,
  Upload,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import {
  LineOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  RollbackOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../../api/auth';
import { postBudgetApi } from '../../../../api/budget';
import notificationApi from '../../../../utils/notificationApi';
import { Link, useNavigate } from 'react-router-dom';
import budgetInfo from 'assets/data/budget.json';
import SelectClient from 'components/global/Form/SelectClient';
import Title from 'antd/es/typography/Title';

function AddBudgetForm() {
  const [form] = useForm();
  const [data, setData] = useState([
    [{ value: '' }, { value: '' }, { value: '' }, { value: '' }],
  ]);

  const token = getAccessTokenApi();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const navigate = useNavigate();

  const submitAddBudget = async () => {
    const body = form.getFieldsValue();
    console.log(body);
    const postBudgetResult = await postBudgetApi(token, {
      items: data,
      ...body,
    });
    if (notificationApi(postBudgetResult)) {
      form.resetFields();
      return navigate('../admin/budget');
    }
  };
  return (
    <>
      <Card className="welcome">
        <div className="flex flex-row justify-between">
          <Link to="/admin/budget">
            <Button
              icon={<RollbackOutlined />}
              className="rounded-md"
              type="primary"
            ></Button>
          </Link>
          <Title level={3}>
            <span className="text-white">Generar Presupuesto</span>
          </Title>
        </div>
      </Card>
      <Card className="mt-3">
        <Form form={form} onFinish={submitAddBudget} layout="vertical">
          <Form.Item
            name="idClient"
            label="Seleccione un Cliente"
            rules={[
              {
                required: true,
                message: 'Seleccionar el cliente es obligatorio',
              },
            ]}
          >
            <SelectClient />
          </Form.Item>
          <Form.Item
            name="reason"
            label="Razón del presupuesto"
            rules={[
              {
                required: true,
                message: 'La razón del presupuesto es obligatoria',
              },
              {
                min: 0,
                max: 70,
                message:
                  'La razón del presupuesto no puede tener más de 70 caracteres',
              },
            ]}
          >
            <TextArea placeholder="Razón del presupuesto" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Descripción del presupuesto"
            rules={[
              {
                required: true,
                message: 'La descripción del presupuesto es obligatoria',
              },
              {
                max: 65535,
                message: 'El campo no puede tener mas de 65535 caracteres',
              },
            ]}
          >
            <TextArea placeholder="Descripción del presupuesto" />
          </Form.Item>
          <Form.Item
            name="images"
            label="Imagenes"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload name="budgetImage" listType="picture">
              <Button
                type="link"
                className=" border-primary"
                icon={<UploadOutlined />}
              >
                Click para subir
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="guarantee"
            label={
              <>
                <Tooltip title={budgetInfo.garantia}>
                  <span className="mr-2">Garantía del trabajo</span>
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
            rules={[
              {
                required: true,
                message: 'La garantía es obligatoria',
              },
              {
                min: 0,
                max: 65535,
                message: 'La garantía no puede tener más de 65 535 caracteres',
              },
            ]}
          >
            <TextArea placeholder="Garantía del presupuesto" />
          </Form.Item>
          <Form.Item
            name="include"
            rules={[
              {
                max: 150,
                message: 'El campo no puede tener más de 150 caracteres',
              },
              {
                required: true,
                message: 'Este campo es obligatorio',
              },
            ]}
            label={
              <Tooltip title={budgetInfo.incluye}>
                <span className="mr-2">Incluye</span>
                <QuestionCircleOutlined />
              </Tooltip>
            }
          >
            <Input placeholder="Incluye" />
          </Form.Item>
          <Form.Item
            name="conditions"
            label={
              <Tooltip title={budgetInfo.condiciones}>
                <span className="mr-2">Condiciones de pago</span>
                <QuestionCircleOutlined />
              </Tooltip>
            }
            rules={[
              {
                required: true,
                message: 'Las condiciones de pago son obligatorias',
              },
              {
                max: 65535,
                message: 'Este campo no puede tener más de 65 535 caracteres',
              },
            ]}
          >
            <Input placeholder="Condiciones de pago" />
          </Form.Item>
          <Form.Item
            name="finishTime"
            label={
              <Tooltip title={budgetInfo.finaliza}>
                <span className="mr-2">Finalización de los trabajos</span>
                <QuestionCircleOutlined />
              </Tooltip>
            }
            rules={[
              {
                required: true,
                message: 'El tiempo de finalización es obligatorio',
              },
              {
                max: 120,
                message: 'El campo no puede tener mas de 120 caracteres',
              },
            ]}
          >
            <Input placeholder="Tiempo de finalización de los trabajos" />
          </Form.Item>
          <Form.Item>
            <Title level={3} className="text-center">
              <span className="uppercase ">Items del presupuesto</span>
            </Title>
          </Form.Item>
          <Form.List name="items">
            {(fields, { add, remove }) => (
              <>
                {fields?.map(({ key, name, ...restField }) => (
                  <div key={key} className="flex flex-row">
                    <div className=" grid grid-cols-6 w-full">
                      <Divider className="col-span-6">Item N°{key + 1}</Divider>
                      <Form.Item
                        {...restField}
                        name={[name, 'ascensor']}
                        className=" col-span-2 mr-2"
                        rules={[
                          {
                            required: true,
                            message: 'Debe ingresar el ascensor',
                          },
                        ]}
                      >
                        <Input placeholder="Ascensor" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        rules={[
                          {
                            required: true,
                            message: 'Debe ingresar una descripción del item',
                          },
                        ]}
                        name={[name, 'description']}
                        className=" col-span-4"
                      >
                        <Input placeholder="Descripción" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        rules={[
                          {
                            required: true,
                            message: 'Debe ingresar el valor unitario',
                          },
                        ]}
                        name={[name, 'unitValue']}
                        className=" col-span-3 mr-2"
                      >
                        <InputNumber
                          prefix={'$'}
                          placeholder="Valor unitario"
                          className="w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'quantity']}
                        className=" col-span-3"
                        rules={[
                          {
                            required: true,
                            message: 'Debe ingresar la cantidad',
                          },
                        ]}
                      >
                        <InputNumber
                          className="w-full"
                          placeholder="Cantidad"
                        />
                      </Form.Item>
                    </div>
                    <MinusCircleOutlined
                      className="ml-2 text-lg text-red-600"
                      onClick={() => remove(name)}
                    />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    className="rounded-md text-white"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    type="primary"
                  >
                    Añadir Item
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            label="Descuento"
            help="Si quiere agregar un descuento del 20%, deberá ingresar 0.2"
            name="discount"
            initialValue={0}
            rules={[
              {
                validator: (_, value) =>
                  value >= 0 && value < 1
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error('El descuento debe se un valor entre 0 y 1')
                      ),
              },
            ]}
          >
            <InputNumber type="float" placeholder="Por defecto será de 0" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="rounded-md"
              block
            >
              Generar Presupuesto
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default AddBudgetForm;
