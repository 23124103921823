import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useTenantText } from 'providers/Tenant/TextTenantProvider';
import React from 'react';

const TenantTextForm = ({ onSuccess, initialValues }) => {
  const [form] = useForm();
  const { addText } = useTenantText();
  const onFinish = async (values) => {
    try {
      const response = await addText(values);
      if (onSuccess) {
        onSuccess(response);
      }
      notification.success({
        message: 'Tenant agregado exitosamente',
      });
      form.resetFields();
    } catch (error) {
      notification.error({
        message: 'Error al agregar el tenant',
        description: error.message,
      });
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item name="tenantId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="keyName" label="Clave del texto" required>
        <Input placeholder="Ingresar clave del texto" />
      </Form.Item>
      <Form.Item name="textValue" label="Valor del texto" required>
        <Input placeholder="Ingresar valor del texto" />
      </Form.Item>
      <Form.Item name="documentType" label="Tipo de documento" required>
        <Input placeholder="Ingresar tipo de documento" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Agregar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TenantTextForm;
