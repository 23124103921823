import { Text, View } from '@react-pdf/renderer';
import { usePDFStyle } from 'providers/PDF/PDFStyleProvider';
import React from 'react';

const FinishDoc = ({
  titular,
  titularTitulo,
  titularCargo,
  tituloSegundario,
  web,
  telefonos,
}) => {
  const { text, bold } = usePDFStyle();
  return (
    <View>
      <Text style={text}>
        Esperando una buena acogida del presente informe y a la espera de sus
        noticias, no nos queda más que agradecer su interés y esperamos contar
        con su confianza.
      </Text>
      <View
        style={{
          marginTop: 5,
        }}
      />
      <View
        wrap={false}
        style={{
          textAlign: 'center',
        }}
      >
        <Text style={bold}>{titular ?? 'David Silva'}</Text>
        <Text style={text}>
          {titularTitulo ?? 'Licenciado en ciencias de la ingeniería'}
        </Text>
        <Text style={text}>{titularCargo ?? 'Cofundador de STIL'}</Text>
        <Text style={text}>{tituloSegundario ?? 'Lift Logic'}</Text>
        <Text style={text}>{web ?? 'www.liftlogic.cl'}</Text>
        {telefonos?.map((telefono) => (
          <Text style={text} key={telefono}>
            {telefono}
          </Text>
        ))}
      </View>
    </View>
  );
};

export default FinishDoc;
