import React, { useState, useEffect } from 'react';
import {
  PlusOutlined,
  FilePdfOutlined,
  FileOutlined,
  DownloadOutlined,
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Card, List, Avatar, Image } from 'antd';
import { useStorage } from 'providers/StorageProvider';
import moment from 'moment';
import useAuth from 'hooks/useAuth';

const CarpetaDigital = ({ id }) => {
  const {
    getCarpetaDigital,
    createCarpetaDigital,
    carpetaDigital,
    openCreate,
    openEdit,
    openDelete,
    loadingGetCarpetaDigital,
    loadingCreateCarpetaDigital,
  } = useStorage();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    getCarpetaDigital(id);
  }, [getCarpetaDigital, id]);

  const filetypeIcon = (type) => {
    if (type.startsWith('image')) {
      return null;
    } else if (type === 'application/pdf') {
      return <FilePdfOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />;
    } else {
      return <FileOutlined style={{ fontSize: '24px', color: '#1890ff' }} />;
    }
  };

  const handlePreview = (file) => {
    setPreviewImage(file.path);
    setPreviewVisible(true);
  };

  // Función para obtener la extensión del archivo
  const getFileExtension = (filename) => {
    const parts = filename.split('.');
    return parts.length > 1 ? parts.pop().toUpperCase() : '';
  };

  return (
    <Card
      title="Carpeta Digital"
      loading={loadingGetCarpetaDigital}
      extra={
        !carpetaDigital ? (
          <Button
            type="primary"
            loading={loadingCreateCarpetaDigital}
            onClick={() => createCarpetaDigital(id)}
          >
            Crear Carpeta
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => openCreate(carpetaDigital)}
            icon={<PlusOutlined />}
          >
            Añadir Archivo
          </Button>
        )
      }
    >
      {carpetaDigital && carpetaDigital.Files.length > 0 && (
        <List
          itemLayout="horizontal"
          dataSource={carpetaDigital.Files}
          renderItem={(file) => (
            <List.Item
              actions={[
                file.file_type.startsWith('image') && (
                  <Button
                    icon={<EyeOutlined />}
                    type="link"
                    onClick={() => handlePreview(file)}
                  ></Button>
                ),
                <a
                  key="download"
                  href={file.path}
                  download={file.filename}
                  title="Descargar"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button icon={<DownloadOutlined />} type="link" />
                </a>,
                <Button
                  key="edit"
                  type="link"
                  onClick={() => openEdit(file, carpetaDigital)}
                  disabled={!user.is_administrador}
                  icon={<EditOutlined />}
                ></Button>,
                <Button
                  icon={<DeleteOutlined />}
                  key="delete"
                  type="link"
                  disabled={!user.is_administrador}
                  onClick={() => openDelete(file.id, carpetaDigital)}
                  danger
                ></Button>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  file.file_type.startsWith('image') ? (
                    <Avatar src={file.path} shape="square" size="large" />
                  ) : (
                    <Avatar
                      icon={filetypeIcon(file.file_type)}
                      shape="square"
                      style={{ backgroundColor: '#fde3cf' }}
                    />
                  )
                }
                title={`${file.title} (${getFileExtension(file.filename)})`}
                description={`${file.description} - ${moment(
                  file.createdAt
                ).format('DD/MM/YYYY')}`}
              />
            </List.Item>
          )}
        />
      )}
      <Image
        preview={{
          visible: previewVisible,
          onVisibleChange: (value) => setPreviewVisible(value),
        }}
        src={previewImage}
        onClick={() => setPreviewVisible(false)}
        style={{ display: 'none' }}
      />
    </Card>
  );
};

export default CarpetaDigital;
