import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useOrderFinish } from '../../../../hooks/useApi/useOrderApi';
import Signature from '../../../global/Form/Signature/Signature';

function FinishOrderForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const [post] = useOrderFinish();
  const submit = async () => {
    const body = { ...form.getFieldsValue(), idOrder: id };
    const result = await post(body);
    if (result.state === 'success') {
      return navigate('../specialist/order');
    }
  };
  return (
    <div>
      <div className="d-flex flex-allign-items-center flex-justify-content-between">
        <Link to={`../specialist/order/${id}`}>
          <Button
            icon={<ArrowLeftOutlined />}
            type={'primary'}
            className="mr-1 content-box"
          />
        </Link>
        <h3 className="font-w-title">Finalizar Orden N°{id}</h3>
      </div>
      <Card className="mt-5 wd-60 wd-md mv-c">
        <Form form={form} onFinish={submit} requiredMark={false}>
          <Form.Item
            name="recepcionist_name"
            label="Nombre del recepcionista"
            rules={[
              {
                required: true,
                message:
                  'Debe ingresar el nombre de quien recibe el comprobante',
              },
            ]}
            labelCol={{ span: 24 }}
          >
            <Input placeholder="Ingresar nombre de recepcionista" />
          </Form.Item>
          <Form.Item
            label="Cargo de recepción"
            name="recepcionist_charge"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: 'El cargo del recepcionista es obligatorio',
              },
            ]}
          >
            <Input placeholder="Cargo de quien recibe" />
          </Form.Item>
          <Form.Item
            label="Firma recepcionista"
            name="recepcionist_signature"
            rules={[
              {
                required: true,
                message: 'La firma del recepcionista es obligatoria',
              },
            ]}
            labelCol={{ span: 24 }}
          >
            <Signature form={form} />
          </Form.Item>
          <Form.Item
            label="Firma Especialista"
            name="specialist_signature"
            rules={[
              {
                required: true,
                message: 'La firma del especialista es obligatoria',
              },
            ]}
            labelCol={{ span: 24 }}
          >
            <Signature form={form} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Finalizar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default FinishOrderForm;
