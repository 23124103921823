import LayoutMain from 'components/layouts/LayoutMain';
import Inicio from 'pages/liftlogic/Inicio';
import ConstructorPdf from 'pages/liftlogic/ConstructorPdf';
import Usuarios from 'pages/liftlogic/Usuarios';
import IDTenant from 'pages/liftlogic/IDTenant';
import Admin from 'pages/liftlogic/Admin';

const liftlogicRoutes = [
  {
    path: '/',
    layout: LayoutMain,
    element: Inicio,
  },
  {
    path: '/constructorPdf',
    layout: LayoutMain,
    element: ConstructorPdf,
  },
  {
    path: '/tenants/:id/usuarios/',
    layout: LayoutMain,
    element: Usuarios,
  },
  {
    path: '/tenants/:id/',
    layout: LayoutMain,
    element: IDTenant,
  },
  {
    path: '/administrador',
    layout: LayoutMain,
    element: Admin,
  },
];
export default liftlogicRoutes;
