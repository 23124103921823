export const returnKeysVoid = (element = {}) => {
  const nulls = Object.keys(element).filter((key) =>
    verificarVoid(element[key], {
      exclude: ['boolean'],
    })
  );
  return nulls;
};

export function verificarVoid(elemento, config) {
  if (config && config.exclude) {
    // Verificar si el elemento es una de las exclusiones definidas en config.exclude
    for (let i = 0; i < config.exclude.length; i++) {
      const exclusion = config.exclude[i];
      if (exclusion === 'null' && elemento === null) {
        return false;
      }
      if (exclusion === 'undefined' && typeof elemento === 'undefined') {
        return false;
      }
      if (exclusion === 'boolean' && elemento === false) {
        return false;
      }
    }
  }

  // Verificar si el elemento es nulo, vacío, indefinido o una cadena vacía
  if (elemento === null || elemento === undefined || elemento === '') {
    return true;
  }

  return false;
}
