import { useCallback, useEffect, useMemo, useState } from 'react';
import { List, Button, Drawer, Card, FloatButton, message } from 'antd';
import { BuildOutlined, SaveFilled, SaveOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import AscensorCard from 'components/AscensorCard';
import AscTabsMaintanceToDo from 'components/views/AscTabsMaintanceToDo';
import MaintanceToDoContainer from 'components/MaintanceToDoContainer';
import {
  MaintanceToDoProvider,
  useMaintanceToDo,
} from 'providers/MaintanceToDoProvider';
import DetailsMaintanceToDoForm from 'components/forms/DetailsMaintanceToDoForm';
import { useForm } from 'antd/es/form/Form';
import { CON_CAMBIOS } from 'assets/states/savestates';
import ClientDetailMaintanceToDoForm from 'components/forms/ClientDetailMaintanceToDoForm';
import SignMaintanceToDoForm from 'components/forms/SignMaintanceToDoForm';

const View = () => {
  const {
    save,
    maintanceProg,
    actividades,
    changeObservaciones,
    AscensoresProg,
    saveStatus,
    changeAscensores,
    changeRecepcionista,
    Mantencion,
    finishMaintance,
  } = useMaintanceToDo();
  const [ascOpen, setAscOpen] = useState(false);
  const handleAscOpen = () => {
    setAscOpen(!ascOpen);
  };

  const tabsItems = useMemo(() => {
    return actividades
      ? actividades?.map(({ AscProg, ...ascensor }) => {
          const { RespuestasActividades } = AscProg;
          return {
            label: `${ascensor.nombre}`,
            key: ascensor.id,
            children: (
              <MaintanceToDoContainer actividades={RespuestasActividades} />
            ),
          };
        })
      : null;
  }, [actividades]);

  const [form] = useForm();
  const [clientForm] = useForm();
  const [signForm] = useForm();

  const [signOpen, setSignOpen] = useState(false);

  const handleSignForm = (_open = !signOpen) => {
    setSignOpen(_open);
  };

  const saveData = async (notification = true) => {
    if (saveStatus === CON_CAMBIOS) {
      messageApi.open({
        type: 'loading',
        content: 'Guardando...',
        duration: 0,
      });
      const { observaciones, ascensores } = form.getFieldsValue();
      const recepcionistaValues = clientForm.getFieldsValue();
      await save({
        observaciones,
        ascensores,
        recepcionista: recepcionistaValues,
      });
      messageApi.destroy();
      messageApi.open({
        type: 'success',
        content: 'Guardado exitosamente',
        duration: 1.5,
      });
    } else {
      if (notification) {
        messageApi.open({
          type: 'info',
          content: 'No hay cambios que guardar',
          duration: 1.5,
        });
      }
    }
  };

  const finish = async () => {
    await form.validateFields();
    await clientForm.validateFields();
    saveData(false);
    handleSignForm(true);
  };
  const [messageApi, contextHolder] = message.useMessage();

  const handleBeforeUnload = useCallback(
    (e) => {
      if (saveStatus === CON_CAMBIOS) {
        // Cancela el evento como se indica en los estándares.
        e.preventDefault();
        // Chrome requiere que returnValue sea establecido.
        e.returnValue = '';
      }
    },
    [saveStatus]
  );
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]); // Ejecuta el efecto solo cuando hasUnsavedChanges cambia.

  return (
    <div className="p-4 bg-gray-100">
      {contextHolder}
      <div className="flex flex-row w-full px-3 md:px-0 pb-5 md:w-96">
        <Button
          icon={<BuildOutlined />}
          type="primary"
          className="w-1/2 mr-3"
          disabled
        >
          Edificio
        </Button>
        <Button className="w-1/2" onClick={handleAscOpen}>
          Datos Ascensores
        </Button>
      </div>
      <Card className="mb-5" title="Mantencion Preventiva">
        <AscTabsMaintanceToDo tabsItems={tabsItems} />
      </Card>
      <Card title="Detalles Generales" className="mb-5">
        <DetailsMaintanceToDoForm
          form={form}
          Mantencion={Mantencion}
          changeObservaciones={changeObservaciones}
          changeAscensores={changeAscensores}
          Ascensores={AscensoresProg}
        />
      </Card>
      <Card title="Datos de recepción" className="mb-5">
        <ClientDetailMaintanceToDoForm
          form={clientForm}
          Mantencion={Mantencion}
          changeRecepcionista={changeRecepcionista}
        />
      </Card>
      <div className="flex flex-row w-full mt-5 px-3 md:px-0 pb-5 md:w-96">
        <Button
          icon={<SaveFilled />}
          onClick={saveData}
          type="primary"
          className="w-1/2 mr-3"
        >
          Guardar
        </Button>
        <Button className="w-1/2" onClick={finish}>
          Finalizar
        </Button>
      </div>
      <Drawer
        open={signOpen}
        onClose={() => setSignOpen(false)}
        title="Finalizar Mantención Preventiva"
      >
        <SignMaintanceToDoForm
          form={signForm}
          finishMaintance={finishMaintance}
        />
      </Drawer>

      <Drawer open={ascOpen} onClose={handleAscOpen}>
        <List
          grid={{
            gutter: 12,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1,
            xxl: 1,
          }}
          dataSource={maintanceProg?.Programado?.AscProgs}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <AscensorCard
                data={item}
                columnasPersonalizadas={[
                  { columnKey: 'marca', title: 'Marca' },
                  { columnKey: 'modelo', title: 'Modelo' },
                  { columnKey: 'capacidad', title: 'Capacidad' },
                  { columnKey: 'pisos', title: 'Pisos' },
                  { columnKey: 'estado', title: 'Estado' },
                  { columnKey: 'numero_serie', title: 'N° Serie' },
                ]}
              />
            </List.Item>
          )}
        />
      </Drawer>
      <FloatButton
        type="primary"
        shape="circle"
        className="shadow-2xl shadow-black"
        onClick={saveData}
        icon={<SaveOutlined />}
      />
    </div>
  );
};

const MaintanceToDo = () => {
  const { idMantencion } = useParams();

  return (
    <MaintanceToDoProvider id={idMantencion}>
      <View />
    </MaintanceToDoProvider>
  );
};

export default MaintanceToDo;
