import { Text } from '@react-pdf/renderer';
import { usePDFStyle } from 'providers/PDF/PDFStyleProvider';
import React from 'react';

const Title = ({ title }) => {
  const style = usePDFStyle();
  //   console.log(style);

  return (
    <Text
      style={[
        style.title,
        {
          textAlign: 'center',
        },
      ]}
    >
      {title}
    </Text>
  );
};

export default Title;
