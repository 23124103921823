import axios from 'axios';
import { isValidURL } from './validatos';
export function getUrlFromFileItem(image) {
  return image[0].response.path;
}
import Buffer from 'buffer';
import { IMAGE_MAX_HEIGHT , IMAGE_MAX_WIDTH } from '../CONSTANTS/CONSTANTS';
/**
 * Obtiene la imagen desde una URL o como base64.
 * @param {string} tagValue - La URL o ruta local/base64 de la imagen.
 * @param {string} tagName - El nombre de la etiqueta (e.g., 'image').
 * @returns {Promise<Buffer>} - El buffer de la imagen.
 */
export const getImage = async (tagValue, tagName) => {

  if (isValidURL(tagValue)) {
    // Obtener la imagen desde una URL
    try {
      const response = await axios.get(tagValue, { responseType: 'arraybuffer' });
      return response.data;
    } catch (error) {
      console.error(`Error al obtener la imagen desde la URL ${tagValue}:`, error);
      throw error;
    }
  } else if (tagValue.startsWith('data:image')) {
    // Manejar imágenes en formato base64
    try {
      const base64Data = tagValue.split(',')[1];
      return Buffer.from(base64Data, 'base64');
    } catch (error) {
      console.error(`Error al procesar la imagen base64:`, error);
      throw error;
    }
  } else {
    // Manejar rutas locales relativas (ajusta según tus necesidades)
    // Por ejemplo, podrías usar fetch si las imágenes están alojadas públicamente
    try {
      const response = await fetch(tagValue);
      if (!response.ok) throw new Error('Network response was not ok');
      const arrayBuffer = await response.arrayBuffer();
      return arrayBuffer;
    } catch (error) {
      console.error(`Error al obtener la imagen desde la ruta ${tagValue}:`, error);
      throw error;
    }
  }
};

const getSize = (img, tagValue, tagName) => {
  // Aquí podrías implementar lógica para obtener el tamaño real de la imagen.
  // Por simplicidad, retornaremos un tamaño fijo.
  return [150, 150];
};


// Función para calcular dimensiones proporcionales
export const calculateDimensions = (width, height) => {
  let newWidth, newHeight;

  if (width > height) {
    // Ancho más grande que el largo
    if (width > IMAGE_MAX_WIDTH) {
      newWidth = IMAGE_MAX_WIDTH;
      newHeight = (height * IMAGE_MAX_WIDTH) / width;
    } else {
      newWidth = width;
      newHeight = height;
    }
  } else {
    // Largo más grande que el ancho
    if (height > IMAGE_MAX_HEIGHT) {
      newHeight = IMAGE_MAX_HEIGHT;
      newWidth = (width * IMAGE_MAX_HEIGHT) / height;
    } else {
      newWidth = width;
      newHeight = height;
    }
  }

  return { width: newWidth, height: newHeight };
};