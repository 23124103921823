/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, TimePicker, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';

import TextArea from 'antd/lib/input/TextArea';
import {
  getVouchersReasons,
  postAttentionVoucher,
} from '../../../../api/attentionVoucher';
import Signature from '../../../global/Form/Signature/Signature';
import { BASE_PATH_SERVER } from '../../../../config';
import { estadosFinalesAscensor } from 'assets/data/FinishStates';
import moment from 'moment';
import notificationApi from 'utils/notificationApi';
import SelectClient from 'components/global/Form/SelectClient';

const format = 'HH:mm';

function AddAttentionVoucherForm(props) {
  const { setReload, setModalVisible } = props;

  const [voluchersReason, setVouchersReason] = useState();
  const [imagesList, setImagesList] = useState([]);
  const [imagesView, setImagesView] = useState([]);
  const [form] = useForm();
  const changeUpload = ({ fileList: newFileList }) => {
    setImagesView(newFileList);
    newFileList = newFileList?.map((file) => {
      const { response, size, type, uid, name, percent } = file;
      return { response, size, type, uid, name, percent };
    });
    setImagesList(newFileList);
  };
  useEffect(() => {
    getVouchersReasons().then((result) => {
      setVouchersReason(result.reasons);
    });
  }, []);

  const submitForm = async () => {
    let values = form.getFieldValue();
    values.images = imagesList;
    const attentionInit = moment(values.attention_init.$d).format(format);
    const attentionFinish = moment(values.attention_finish.$d).format(format);
    const postVoucherResult = await postAttentionVoucher({
      ...values,
      attention_init: attentionInit,
      attention_finish: attentionFinish,
    });
    if (notificationApi(postVoucherResult)) {
      form.resetFields();
      setReload(true);
      setModalVisible(false);
    }
  };
  return (
    <Form form={form} onFinish={submitForm} layout="vertical">
      <Form.Item
        name="id_client"
        label="Cliente"
        rules={[{ required: true, message: 'Debe seleccionar un cliente' }]}
      >
        <SelectClient />
      </Form.Item>
      <Form.Item
        name="reason"
        label="Razón"
        rules={[{ required: true, message: 'Debe seleccionar una razón' }]}
      >
        <Select placeholder="Selecciona Razón">
          {voluchersReason?.map((item) => (
            <Select.Option value={item.reason} key={item.reason}>
              {item.reason}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="equipment"
        label="Ascensores"
        rules={[
          {
            required: true,
            message: 'Debe ingresar al menos un ascensor involucrado',
          },
          {
            validator: (_, value) =>
              value.length > 10
                ? Promise.reject(
                    new Error('La cantidad máxima de tags es de 10')
                  )
                : Promise.resolve(),
          },
          {
            validator: (_, value) =>
              value[value.length - 1].length > 15
                ? Promise.reject(
                    new Error('El tamaño máximo por tag debe ser de máximo 15')
                  )
                : Promise.resolve(),
          },
        ]}
      >
        <Select mode="tags" placeholder="Equipos involucrados" />
      </Form.Item>
      <Form.Item
        name="attention_init"
        label="Llegada"
        rules={[
          { required: true, message: 'Debe ingresar la hora de llegada' },
        ]}
      >
        <TimePicker format={format} />
      </Form.Item>
      <Form.Item
        name="attention_finish"
        label="Salida"
        rules={[{ required: true, message: 'Debe ingresar la hora de salida' }]}
      >
        <TimePicker format={format} />
      </Form.Item>
      <Form.Item
        name="observations"
        label="Observaciones"
        rules={[{ required: true, message: 'Debe ingresar una observacion' }]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        name="images"
        label="Imagenes"
        rules={[
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve();
              }
              return value.fileList.length > 3
                ? Promise.reject(new Error('Máximo 3 imagenes'))
                : Promise.resolve();
            },
          },
        ]}
      >
        <Upload
          action={`${BASE_PATH_SERVER}/attention-voucher-image-tmp`}
          listType="picture-card"
          onChange={changeUpload}
          fileList={imagesView}
        >
          {imagesView.length >= 3 ? 'Máximo 3' : 'Cargar'}
        </Upload>
      </Form.Item>
      <Form.Item
        name="finish_state"
        label="Estado Final"
        rules={[
          {
            required: true,
            message: 'Debe ingresar el estado final del o los equipos',
          },
        ]}
      >
        <Select
          placeholder={'Seleccionar Estado'}
          showSearch
          options={estadosFinalesAscensor}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '')
              .toLowerCase()
              .localeCompare((optionB?.label ?? '').toLowerCase())
          }
        ></Select>
      </Form.Item>
      <Form.Item
        name="recepcionist_name"
        label="Nombre recepción"
        rules={[
          {
            required: true,
            message: 'Debe ingresar el nombre de quien recibe el comprobante',
          },
        ]}
      >
        <Input placeholder="Ingresar nombre de recepcionista" />
      </Form.Item>
      <Form.Item
        label="Cargo recepción"
        name="recepcionist_charge"
        rules={[
          {
            required: true,
            message: 'El cargo del recepcionista es obligatorio',
          },
        ]}
      >
        <Input placeholder="Cargo de quien recibe" />
      </Form.Item>
      <Form.Item
        label="Firma recepcionista"
        name="recepcionist_signature"
        rules={[
          {
            required: true,
            message: 'La firma del recepcionista es obligatoria',
          },
        ]}
      >
        <Signature form={form} />
      </Form.Item>
      <Form.Item
        label="Firma Especialista"
        name="specialist_signature"
        rules={[
          {
            required: true,
            message: 'La firma del especialista es obligatoria',
          },
        ]}
      >
        <Signature form={form} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">Agregar</Button>
      </Form.Item>
    </Form>
  );
}

export default AddAttentionVoucherForm;
