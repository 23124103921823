import { Button, Card, Modal, Table } from 'antd';
import TenantTextForm from 'components/forms/TenantTextForm';
import { useTenantText } from 'providers/Tenant/TextTenantProvider';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const IDTenant = () => {
  const { id } = useParams();
  const { call, data, isLoading, hasCalled, addText } = useTenantText();
  const [visibleAddText, setVisibleAddText] = useState(false);

  useEffect(() => {
    if (!hasCalled && id) {
      call(id);
    }
  }, [call, hasCalled, id]);

  return (
    <div>
      <Modal
        title="Agregar Texto"
        open={visibleAddText}
        onCancel={() => setVisibleAddText(false)}
        footer={null}
      >
        <TenantTextForm
          initialValues={{
            tenantId: id,
          }}
        />
      </Modal>
      <Card title="Acciones">
        <Link to={`/tenants/${id}/usuarios`}>
          <Button type="primary" href={`/tenants/${id}/usuarios`}>
            Ir a sus usuarios
          </Button>
        </Link>
      </Card>
      <Card
        title="Textos"
        extra={
          <Button type="primary" onClick={() => setVisibleAddText(true)}>
            Agregar Texto
          </Button>
        }
      >
        <Table
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
            },

            {
              title: 'Key Name',
              dataIndex: 'keyName',
              key: 'keyName',
            },
            {
              title: 'Text Value',
              dataIndex: 'textValue',
              key: 'textValue',
            },
            {
              title: 'Document Type',
              dataIndex: 'documentType',
              key: 'documentType',
            },
            {
              title: 'Created At',
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: (text) =>
                text ? new Date(text).toLocaleString() : 'N/A',
            },
            {
              title: 'Updated At',
              dataIndex: 'updatedAt',
              key: 'updatedAt',
              render: (text) =>
                text ? new Date(text).toLocaleString() : 'N/A',
            },
          ]}
          loading={isLoading}
          dataSource={data}
        />
      </Card>
    </div>
  );
};

export default IDTenant;
