import { View, StyleSheet, Text } from '@react-pdf/renderer';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '12px',
    textTransform: 'uppercase',
    marginBottom: '10px',
  },
  leyend: {
    fontSize: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '10px',
    border: '1px solid black',
    margin: '0px auto',
    width: '80vw',
  },
  cellHeader: {
    width: '15vh',
    fontFamily: 'Helvetica-Bold',
    padding: '2px 5px',
    borderLeft: '1px solid black',
    display: 'flex',
    justifyContent: 'center',
  },
  cellContent: {
    width: '40vw',
    fontSize: '10px',
    borderRight: '1px solid black',
    padding: '2px 5px',
    display: 'flex',
    justifyContent: 'center',
  },
  item: {
    marginBottom: '10px',
    padding: '0px 20px',
  },
});

function BudgetDataClient(props) {
  const { budget } = props;
  const { name, rut, phone, address, commune, email, contactName, createdOn } =
    budget;
  return (
    <View>
      <Text style={styles.title}>1. Datos del cliente</Text>
      <View style={styles.row}>
        <View style={styles.cellHeader}>
          <Text>Razon social</Text>
        </View>
        <View style={styles.cellContent}>
          <Text>{name}</Text>
        </View>
        <View style={styles.cellHeader}>
          <Text>Fecha</Text>
        </View>
        <View style={styles.cellContent}>
          <Text>{moment(createdOn).format('L')}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.cellHeader}>
          <Text>RUT</Text>
        </View>
        <View style={styles.cellContent}>
          <Text>{rut}</Text>
        </View>
        <View style={styles.cellHeader}>
          <Text>Teléfono</Text>
        </View>
        <View style={styles.cellContent}>
          <Text>{phone}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.cellHeader}>
          <Text>Dirección</Text>
        </View>
        <View style={styles.cellContent}>
          <Text>{address}</Text>
        </View>
        <View style={styles.cellHeader}>
          <Text>Comuna</Text>
        </View>
        <View style={styles.cellContent}>
          <Text>{commune}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.cellHeader}>
          <Text>Correo</Text>
        </View>
        <View style={styles.cellContent}>
          <Text>{email}</Text>
        </View>
        <View style={styles.cellHeader}>
          <Text>Contacto</Text>
        </View>
        <View style={styles.cellContent}>
          <Text>{contactName}</Text>
        </View>
      </View>
    </View>
  );
}

const budgetProp = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  commune: PropTypes.string.isRequired,
  email: PropTypes.string,
  reason: PropTypes.string.isRequired,
  guarantee: PropTypes.string.isRequired,
  include: PropTypes.string.isRequired,
  conditions: PropTypes.string.isRequired,
  createdOn: PropTypes.string,
  description: PropTypes.string.isRequired,
  finishTime: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

BudgetDataClient.propTypes = {
  budget: PropTypes.shape(budgetProp).isRequired,
};

export default BudgetDataClient;
