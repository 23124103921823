import { Button, Form } from 'antd';
import Signature from 'components/global/Form/Signature';
import React from 'react';

const SignMaintanceToDoForm = ({ form, finishMaintance }) => {
  const submit = () => {
    const values = form.getFieldsValue();
    finishMaintance(values);
  };
  return (
    <Form form={form} layout="vertical" onFinish={submit}>
      <Form.Item
        label="Firma del recepcionista"
        name="firma_recep"
        rules={[
          {
            required: true,
            message: 'La firma del recepcionista es obligatoria',
          },
        ]}
      >
        <Signature form={form} />
      </Form.Item>
      <Form.Item
        label="Firma del especialista"
        name="firma_spec"
        rules={[
          {
            required: true,
            message: 'La firma del especialista es obligatoria',
          },
        ]}
      >
        <Signature form={form} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" block htmlType="submit">
          Finalizar Mantención Preventiva
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SignMaintanceToDoForm;
