const EstadosProgramados = {
  'SIN ASIGNAR': {
    label: 'SIN ASIGNAR',
    color: 'gray',
    startDisabled: false,
    formDisabled: true,
    dowloadDisabled: true,
  },
  ASIGNADO: {
    label: 'ASIGNADO',
    color: 'blue',
    startDisabled: true,
    formDisabled: false,
    dowloadDisabled: true,
  },
  INICIADO: {
    label: 'INICIADO',
    color: '#87d068',
    startDisabled: true,
    formDisabled: false,
    dowloadDisabled: true,
  },
  FINALIZADO: {
    label: 'FINALIZADO',
    color: 'green',
    startDisabled: true,
    formDisabled: true,
    dowloadDisabled: false,
  },
  ATRASADO: {
    label: 'ATRASADO',
    color: 'red',
    startDisabled: false,
    formDisabled: true,
  },
  RECHAZADO: {
    label: 'RECHAZADO',
    color: 'orange',
    startDisabled: false,
    formDisabled: false,
  },
};

export default EstadosProgramados;
