import { UserAddOutlined } from '@ant-design/icons';
import { Button, Card, Modal } from 'antd';
import Title from 'antd/es/typography/Title';
import NameFilter from 'components/filters/NameFilter';
import AdministradorForm from 'components/forms/Administradores/AdministradorForm';
import AdminItemList from 'components/lists/Administradores/AdminItemList';
import AdministradoresList from 'components/lists/Administradores/AdministradoresList';
import { useAdministradoresProvider } from 'providers/Administradores/Administradores';
import React, { useEffect, useState } from 'react';

const Administradores = () => {
  const [open, setOpen] = useState(false);

  const { call, hasCalled, addFilter } = useAdministradoresProvider();

  useEffect(() => {
    if (!hasCalled) {
      call();
    }
  }, [call, hasCalled]);

  const handleOpen = (_open) => {
    setOpen(_open ?? !open);
  };

  const refresh = () => {
    handleOpen(false);
    call();
  };

  const nameFilter = (value) => {
    // Agregamos el filtro a la data.
    // Si el filtro es true (all) entonces eliminamos los filtros
    addFilter({
      fullname: value === true ? null : value,
    });
  };

  return (
    <div className="space-y-2 h-full">
      <Card>
        <div className="flex flex-col text-center sm:flex-row ms:text-start sm:justify-between">
          <Title level={3}> Administradores</Title>
          <Button
            type="primary"
            onClick={handleOpen}
            icon={<UserAddOutlined />}
            // block
          >
            Agregar
          </Button>
        </div>
      </Card>
      <div className="flex flex-wrap -m-1.25">
        <div className="w-full md:w-1/4 p-1.25 mb-2 md:mb-0">
          <div className="h-full bg-white p-4 shadow-md rounded-lg">
            <div className="flex flex-col space-y-4 w-full">
              <h3 className="text-lg font-semibold">Búsqueda</h3>
              {/* Filtro por nombre */}
              <NameFilter onChange={(value) => nameFilter(value)} />
              {/* <AdministradorSelect onChange={adminFilter} /> */}
            </div>
          </div>
        </div>
        <div className="w-full md:w-3/4 p-1.25">
          <div className="h-full bg-white p-4 shadow-md rounded-lg">
            <AdministradoresList
              refresh={refresh}
              itemList={(item) => (
                <AdminItemList data={item} refresh={refresh} />
              )}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onCancel={() => handleOpen(false)}
        footer={false}
        title="Agregar Administrador"
        width={700}
      >
        <AdministradorForm refresh={refresh} />
      </Modal>
    </div>
  );
};

export default Administradores;
