/* eslint-disable camelcase */
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import 'moment/locale/es';
import { fontFamily } from 'components/PDF2/assets';

Moment.locale('es');

const styles = StyleSheet.create({
  voucherData: {
    flexDirection: 'column',
    fontFamily: fontFamily,
    fontSize: '12pt',
  },
  space: {
    flexGrow: '1',
    width: '38px',
  },
  bigRow: {
    flexDirection: 'column',
    marginVertical: 5,
  },
  row: {
    flexDirection: 'row',
    width: '100%',
  },
  title: {
    fontSize: '12pt',
    borderBottom: '0px',
    fontFamily: fontFamily,
    marginLeft: '3px',
  },
  specialist: {
    flexGrow: '3',
    borderBottom: '1px solid black',
    fontSize: '12pt',
    width: '114px',
    textTransform: 'capitalize',
  },
  date: {
    flexGrow: '2',
    borderBottom: '1px solid black',
    fontSize: '12pt',
    width: '76px',
  },
  type: {
    flexGrow: 2,
    borderBottom: '1px solid black',
    fontSize: '12pt',
    width: '76px',
    textTransform: 'capitalize',
  },
  attention_init: {
    flexGrow: 1,
    borderBottom: '1px solid black',
    fontSize: '12pt',
    width: '38px',
  },
  attention_finish: {
    flexGrow: 1,
    borderBottom: '1px solid black',
    fontSize: '12pt',
    width: '38px',
  },
  finish_state: {
    flexGrow: 2,
    borderBottom: '1px solid black',
    fontSize: '12pt',
    width: '76px',
  },
  equipment: {
    flexGrow: 5,
    borderBottom: '1px solid black',
    fontSize: '12pt',
    width: '190px',
    textTransform: 'capitalize',
  },
  recepcionist_name: {
    flexGrow: 3,
    borderBottom: '1px solid black',
    fontSize: '12pt',
    width: '114px',
    textTransform: 'capitalize',
  },
  client_name: {
    flexGrow: 3,
    borderBottom: '1px solid black',
    fontSize: '12pt',
    width: '114px',
    textTransform: 'capitalize',
  },
  client_rut: {
    flexGrow: 2,
    borderBottom: '1px solid black',
    fontSize: '12pt',
    width: '76px',
  },
});

function VoucherData(props) {
  const {
    recepcionist_name,
    finish_state,
    client_name,
    reason,
    attention_init,
    attention_finish,
    client_rut,
    equipment,
    specialist_name,
    date,
  } = props;
  return (
    <View style={styles.voucherData}>
      <View style={styles.bigRow}>
        <View style={styles.row}>
          <Text style={styles.specialist}>{specialist_name}</Text>
          <View style={styles.space} />
          <Text style={styles.recepcionist_name}>{recepcionist_name}</Text>
          <View style={styles.space} />
          <Text style={styles.client_name}>{client_name}</Text>
        </View>
        <View style={styles.row}>
          <Text style={{ ...styles.specialist, ...styles.title }}>
            Especialista
          </Text>
          <View style={styles.space} />
          <Text style={{ ...styles.recepcionist_name, ...styles.title }}>
            Recibe
          </Text>
          <View style={styles.space} />
          <Text style={{ ...styles.client_name, ...styles.title }}>
            Cliente
          </Text>
        </View>
      </View>
      <View style={styles.bigRow}>
        <View style={styles.row}>
          <Text style={styles.type}>{reason} </Text>
          <View style={styles.space} />
          <Text style={styles.date}>
            {Moment(date).format('DD [de] MMMM [del] YYYY')}
          </Text>
          <View style={styles.space} />
          <Text style={styles.attention_init}>{`${attention_init} `} </Text>
          <Text style={styles.attention_finish}>{attention_finish}</Text>
          <View style={styles.space} />
          <Text style={styles.client_rut}>{client_rut}</Text>
        </View>
        <View style={styles.row}>
          <Text style={{ ...styles.type, ...styles.title }}>Tipo</Text>
          <View style={styles.space} />
          <Text style={{ ...styles.date, ...styles.title }}>Fecha</Text>
          <View style={styles.space} />
          <Text style={{ ...styles.attention_init, ...styles.title }}>
            Inicio
          </Text>
          <Text style={{ ...styles.attention_finish, ...styles.title }}>
            Fin
          </Text>
          <View style={styles.space} />
          <Text style={{ ...styles.client_rut, ...styles.title }}>
            Rut cliente
          </Text>
        </View>
      </View>
      <View style={styles.bigRow}>
        <View style={styles.row}>
          <Text style={styles.equipment}>{equipment} </Text>
          <View style={styles.space} />
          <Text style={styles.finish_state}>{finish_state}</Text>
          <View style={styles.space} />
        </View>
        <View style={styles.row}>
          <Text style={{ ...styles.equipment, ...styles.title }}>
            Equipos Involucrados
          </Text>
          <View style={styles.space} />
          <Text style={{ ...styles.finish_state, ...styles.title }}>
            Estado Final
          </Text>
          <View style={styles.space} />
        </View>
      </View>
    </View>
  );
}
VoucherData.propTypes = {
  recepcionist_name: PropTypes.string.isRequired,
  client_name: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  attention_init: PropTypes.string.isRequired,
  attention_finish: PropTypes.string.isRequired,
  client_rut: PropTypes.string.isRequired,
  equipment: PropTypes.string.isRequired,
  specialist_name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
export default VoucherData;
