import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BuildingImage from '../../../assets/img/jpg/edificios_blanco_negro.jpg';
import LogoProlift from '../../../assets/img/png/prolift_logo_sbg.png';
import { API_PATH } from 'config';

const styles = StyleSheet.create({
  top: {
    position: 'relative',
    height: '11.98cm',
  },
  headerBody: {
    height: '17.09cm',
  },
  buildingImage: {
    position: 'absolute',
    top: '0',
    height: '11.98cm',
    width: '20.98cm',
    zIndex: '0',
  },
  greenBar: {
    width: '21cm',
    height: '2.73cm',
    backgroundColor: 'rgba(84, 130, 53, 0.5)',
    position: 'absolute',
    bottom: '0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  folio: {
    width: '3.3cm',
    backgroundColor: 'white',
    color: 'black',
    fontSize: '16px',
    marginLeft: '1cm',
    textAlign: 'center',
  },
  logo: {
    width: '4.76cm',
    height: '3.34cm',
    position: 'absolute',
    right: '0px',
    bottom: '-1.7cm',
    backgroundColor: 'white',
    border: '1px solid black',
    marginRight: '1cm',
    padding: '3px',
  },
  mainTextContainer: {
    margin: '3cm 2cm',
    fontSize: '45px',
    fontFamily: 'Times-Bold',
  },
  bottomTextContainer: {
    borderTop: '2px solid rgb(84, 130, 53)',
    width: '100%',
    fontFamily: 'Times-Roman',
  },
});

function BudgetPortrait(props) {
  const { id, reason, name, config } = props;
  return (
    <View style={styles.page}>
      <View style={styles.top}>
        <Image style={styles.buildingImage} source={BuildingImage} />
        <View style={styles.greenBar}>
          <Text style={styles.folio}>N°{id}</Text>
        </View>
        <Image
          style={styles.logo}
          source={
            config?.portraitLogo
              ? `${API_PATH}${config?.portraitLogo}`
              : 'LIFT LOGIC'
          }
        />
      </View>
      <View style={styles.headerBody}>
        <View style={styles.mainTextContainer}>
          <Text
            style={{
              color: 'black',
            }}
          >
            Necesidad de
          </Text>
          <Text
            style={{
              color: 'rgb(84, 130, 53)',
            }}
          >
            Reparación N°{id}
          </Text>
          <Text
            style={{
              fontFamily: 'Times-Roman',
              margin: '1.8cm 0cm',
            }}
          >
            {reason}
          </Text>
          <View style={styles.bottomTextContainer}>
            <Text style={{ fontSize: '28px', marginTop: '0.5cm' }}>{name}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}
BudgetPortrait.propTypes = {
  id: PropTypes.number.isRequired,
  reason: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default BudgetPortrait;
