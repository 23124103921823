export const estadosFinalesAscensor = [
  {
    label: 'En servicio',
    value: 'En servicio',
  },
  {
    label: 'En observación',
    value: 'En observación',
  },
  {
    label: 'Detenido',
    value: 'Detenido',
  },
];
