import { Card, Modal, notification, Table } from 'antd';
import React, { useState } from 'react';
import { useApi, usePutApi } from '../../../../hooks/useApi';
import Button from '../../../global/Button/Button';
import AddMaintanceItemForm from '../AddMaintanceItemForm/AddMaintanceItemForm';
import EditMaintanceItemForm from '../EditMaintanceItemForm/EditMaintanceItemForm';
import { getColumns } from './columns';
import './MaintanceItemsList.scss';
import Title from 'antd/es/typography/Title';

function TableMaintanceItems() {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({
    Element: null,
    title: 'Agregar Item',
  });

  const [items, reload] = useApi({
    url: 'maintance-report/item/all',
    defaultValue: [],
    successEnabled: false,
    key: 'items',
  });

  const [categorys] = useApi({
    url: 'maintance-report/item/category',
    defaultValue: [],
    successEnabled: false,
    key: 'categorys',
  });

  const [updateItem] = usePutApi({
    url: 'maintance-report/item',
  });

  const updateItemFunc = async (data, id) => {
    await updateItem(data, id);
    setOpen(false);
    reload();
  };

  const modalAddItem = () => {
    setOpen(true);
    setModal({
      ...modal,
      title: 'Agregar Item',
      Element: <AddMaintanceItemForm reload={reload} categorys={categorys} />,
    });
  };
  const modalEditItem = (values) => {
    setOpen(true);
    setModal({
      ...modal,
      title: 'Editar Item',
      Element: (
        <EditMaintanceItemForm
          updateFunc={updateItemFunc}
          categorys={categorys}
          initialValues={values}
        />
      ),
    });
  };

  const deleteNotification = (item) => {
    const { id } = item;
    notification.open({
      message: `¿Seguro que desea desactivar el item ${id}?`,
      btn: (
        <Button
          danger
          type="primary"
          onClick={() =>
            updateItemFunc(
              {
                activated: 0,
              },
              id
            )
          }
        >
          Desactivar
        </Button>
      ),
    });
  };

  const columns = getColumns({
    deleteNotification,
    modalEditItem,
  });

  return (
    <div className="maintance-items-list">
      <Card className="mb-3 welcome">
        <div className=" flex flex-col md:flex-row items-center md:justify-between ">
          <Title level={3}>
            <span className=" text-white">ITEMS DE MANTENCIÓN</span>
          </Title>
          <Button type="primary" onClick={modalAddItem}>
            Agregar Item
          </Button>
        </div>
      </Card>

      <Card>
        <div>
          <Table rowKey="id" dataSource={items} columns={columns} />
        </div>
      </Card>

      <Modal
        open={open}
        title={modal.title}
        onCancel={() => setOpen(false)}
        footer={false}
      >
        {modal.Element}
      </Modal>
    </div>
  );
}

export default TableMaintanceItems;
