// Lista.js
import React from 'react';
import { Form, Select, Input, Button, Space, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';

const { Text } = Typography;

const Lista = ({ field, form, completeName }) => {
  const bloqued = form.getFieldValue([...completeName, 'bloqued']) || [];
  return (
    <div>
      <Form.Item
        label="Tipo de Lista"
        name={[field.name, 'listType']}
        rules={[{ required: true, message: 'Seleccione el tipo de lista' }]}
      >
        <Select placeholder="Seleccione el tipo de lista" disabled={bloqued.includes('listType')}>
          <Select.Option value="bullets">Puntos</Select.Option>
          <Select.Option value="numbered">Enumerada</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name={[field.name, 'bloqued']} hidden>
        <Select placeholder="Seleccione el tipo de lista" />
      </Form.Item>

      <Form.List name={[field.name, 'items']} className="w-full">
        {(fields, { add, remove }) => (
          <div>
            {fields.map((item) => (
              <div key={item.key} className="space-x-2 flex text-center">
                <Form.Item
                  name={[item.name, 'text']}
                  rules={[
                    { required: true, message: 'Ingrese el texto de la lista' },
                  ]}
                  className="w-full "
                >
                  <TextArea
                    placeholder={'Texto de la lista'}
                    className="h-full"
                  />
                </Form.Item>
                <MinusCircleOutlined
                  className={
                    bloqued.includes('remove')
                      ? 'text-gray-400'
                      : 'text-red-500'
                  }
                  onClick={() =>
                    bloqued.includes('remove') ? null : remove(item.name)
                  }
                  disabled={bloqued.includes('remove')}
                />
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                disabled={bloqued.includes('add')}
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Agregar elemento
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
    </div>
  );
};

export default Lista;
