import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Modal, notification, Table } from 'antd';
import UserForm from 'components/forms/UserForm';
import { useUserTenant } from 'providers/Tenant/UserTenantProvider';
import { useUserActions } from 'providers/Users/UserActions';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Usuarios = () => {
  const { id } = useParams();
  const { call, loading, data } = useUserTenant();
  useEffect(() => {
    if (id) {
      call(id);
    }
  }, [call]);
  const { add } = useUserActions();
  const addUser = async (values) => {
    try {
      const response = await add({
        ...values,
        tenantId: id,
        active: true,
      });
      console.log(response);
      setOpen(false);
      return {
        resetFields: true,
      };
    } catch (error) {
      notification.error({
        message: 'Error al agregar el usuario',
      });
      return {
        resetFields: false,
      };
    }
  };
  const [open, setOpen] = useState(false);
  const openAddUserForm = () => {
    setOpen(true);
  };

  return (
    <div>
      <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
        <UserForm buttonLabel="Agregar" submitForm={addUser} />
      </Modal>
      <Card
        title="Usuarios"
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={openAddUserForm}
          >
            Agregar
          </Button>
        }
      >
        <Table
          rowKey={'rut'}
          loading={loading}
          dataSource={data}
          columns={[
            {
              title: 'Nombre',
              dataIndex: 'fullname',
              key: 'fullname',
            },
            {
              title: 'Rut',
              dataIndex: 'rut',
              key: 'rut',
            },
            {
              title: 'Cargo',
              dataIndex: 'type',
              key: 'type',
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
            },
            {
              title: 'Acciones',
              dataIndex: 'actions',
              key: 'actions',
            },
          ]}
        />
      </Card>
    </div>
  );
};

export default Usuarios;
