import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import { useAscensorEstados } from 'providers/AscensorEstadosProvider';
import { useMaintanceToDo } from 'providers/MaintanceToDoProvider';
import React, { useEffect } from 'react';

const AscensoresMaintanceToDoForm = ({ ascensores }) => {
  const { call, hasCalled, isLoading, error, data } = useAscensorEstados();
  useEffect(() => {
    if (!hasCalled) {
      call();
    }
  }, [call, hasCalled]);
  const { handleAscensoresEstados } = useMaintanceToDo();
  const handleForm = (_, { ascensores }) => {
    handleAscensoresEstados(
      ascensores?.map((item) => ({
        id: item.id,
        estado: item.estado,
      }))
    );
  };
  console.log(ascensores, data);
  return (
    <Form
      initialValues={{
        ascensores: ascensores?.map((item) => ({
          id: item.id,
          estado: Number(item.estado),
        })),
      }}
      onChange={handleForm}
      onValuesChange={handleForm}
    >
      <Form.List name="ascensores">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div key={key}>
                <Title level={3} className="text-center">
                  {ascensores[name].nombre}
                </Title>

                <Form.Item required={false}>
                  <Form.Item name={[name, 'id']} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Estado"
                    name={[name, 'estado']}
                    rules={[
                      {
                        required: true,
                        message: 'El estado del ascensores obligatorio',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Seleccione el estado"
                      loading={isLoading}
                      options={data?.map((element) => ({
                        label: element.nombre,
                        value: element.id,
                      }))}
                    />
                  </Form.Item>
                </Form.Item>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default AscensoresMaintanceToDoForm;
