import { LoadingOutlined } from '@ant-design/icons';
import { Transfer } from 'antd';
import { primaryColor } from 'assets/theme/colors';
import { useClientes } from 'providers/ClientesProvider';
import React, { useEffect, useState } from 'react';

const TransferClientsForm = ({ value, onChange }) => {
  const {
    isLoading: loadingCli,
    activeData: clientes,
    hasCalled,
    call,
  } = useClientes();

  useEffect(() => {
    if (!hasCalled) {
      call();
    }
  }, [hasCalled, call]);

  const [selectedKeys, setSelectedKeys] = useState([]);

  const onChangeTransfer = (nextTargetKeys) => {
    onChange(nextTargetKeys);
  };
  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  return (
    <>
      {!loadingCli ? (
        <Transfer
          titles={['Opciones', 'Asignados']}
          dataSource={clientes?.map((cliente) => ({
            key: cliente.id,
            title: cliente.name,
            description: cliente.name,
          }))}
          listStyle={{
            width: 300,
            height: 500,
          }}
          showSearch
          targetKeys={value}
          selectedKeys={selectedKeys}
          onChange={onChangeTransfer}
          onSelectChange={onSelectChange}
          filterOption={(inputValue, option) =>
            option.description.indexOf(inputValue) > -1
          }
          render={(item) => (
            <div
              style={{
                borderBottom: '2px dashed',
                borderColor: primaryColor,
              }}
            >
              <span className="whitespace-pre-wrap">{item.title}</span>
            </div>
          )}
        />
      ) : (
        <LoadingOutlined />
      )}
    </>
  );
};

export default TransferClientsForm;
