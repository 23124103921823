import {
  AppstoreOutlined,
  BarChartOutlined,
  EyeOutlined,
  FileTextOutlined,
  KeyOutlined,
  SyncOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { Card } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import React from 'react';

function Main() {
  return (
    <Card className="rounded-lg shadow-md mb-2">
      <Title level={2} className="text-center">
        <AppstoreOutlined className="pr-2" /> ProApp: La Solución Integral para
        la Mantención de Ascensores
      </Title>
      <Paragraph className="mt-4">
        En el mundo moderno, la eficiencia y precisión en la mantención de
        ascensores es esencial. ProApp ha sido diseñada pensando en ello, siendo
        la herramienta perfecta tanto para administradores como para
        especialistas en el campo.
      </Paragraph>
      <Title level={4} className="mt-6">
        <KeyOutlined className="pr-2" /> Características clave:
      </Title>
      <Paragraph>
        <ToolOutlined className="pr-2" /> Mantenimientos Simplificados: ProApp
        facilita la programación y seguimiento de las mantenciones de
        ascensores, asegurando que cada equipo opere en su máxima eficiencia.
      </Paragraph>
      <Paragraph>
        <FileTextOutlined className="pr-2" /> Órdenes de Trabajo y Presupuestos
        a la Medida: Genera de manera intuitiva órdenes de trabajo y elabora
        presupuestos ajustados a las necesidades de cada cliente.
      </Paragraph>
      <Paragraph>
        <BarChartOutlined className="pr-2" /> Reportes Personalizados: Con solo
        unos clics, obtén reportes en formato PDF.
      </Paragraph>
      <Paragraph>
        <SyncOutlined className="pr-2" /> Automatización Avanzada: Solo
        selecciona el cliente y los ascensores asociados, y ProApp se encargará
        del resto.
      </Paragraph>
      <Title level={4} className="mt-6">
        <EyeOutlined className="pr-2" /> Diseño y Experiencia:
      </Title>
      <Paragraph>
        Cada detalle en ProApp ha sido meticulosamente diseñado para brindar una
        experiencia de usuario excepcional. ¡Descubre cómo ProApp puede
        transformar tu gestión!
      </Paragraph>
    </Card>
  );
}

export default Main;
