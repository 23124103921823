export const headerHeight = '1.2cm';
export const marginTopPage = '3cm';
export const marginPage = '1.0cm';

export const textFontSize = '7pt';
export const titleFontSize = '7pt';
export const largeTitleFontSize = '12pt';

export const fontFamily = 'Times-Roman';
export const fontFamilyBold = 'Courier-Bold';

export const primaryColor = '#42BC23';
