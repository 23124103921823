import { AdminProvider } from 'providers/AdminProvider';
import { PDFStyleProvider } from 'providers/PDF/PDFStyleProvider';
import { TenantsProvider } from 'providers/Tenant/TenantProvider';
import { TenantTextProvider } from 'providers/Tenant/TextTenantProvider';
import { UserTenantProvider } from 'providers/Tenant/UserTenantProvider';
import { UserActionsProvider } from 'providers/Users/UserActions';
import React from 'react';

const whitTenantProvider = (Component) => {
  const WrappedComponent = (props) => (
    <TenantsProvider>
      <PDFStyleProvider>
        {/* <ClientesTenantProvider> */}
        <UserTenantProvider>
          <UserActionsProvider>
            <TenantTextProvider>
              <AdminProvider>
                <Component {...props} />
              </AdminProvider>
            </TenantTextProvider>
          </UserActionsProvider>
        </UserTenantProvider>
        {/* </ClientesTenantProvider> */}
      </PDFStyleProvider>
    </TenantsProvider>
  );

  WrappedComponent.displayName = `WithTenantProvider(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WrappedComponent;
};

export default whitTenantProvider;
