/* eslint-disable no-unused-vars */
import { StyleSheet, View } from '@react-pdf/renderer';
import { fontFamily, textFontSize } from 'components/PDF2/assets';

import { bool } from 'prop-types';
import React from 'react';
const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    // border: '1px solid black',
    // borderBottom: 'initial',
    // borderRight: 'initial',
    // borderTop: 'initial',
    // borderLeft: 'initial',
    fontSize: textFontSize,
    fontFamily: fontFamily,
  },
});

const TableContainer = (props) => {
  const {
    children,
    borderTop = true,
    borderLeft = true,
    borderBottom = true,
    borderRight = true,
  } = props;
  const borderStyle = '1px solid black';
  return (
    <View
      style={{
        ...styles.tableContainer,
        borderTop: !borderTop ? 'none' : borderStyle,
        borderLeft: !borderLeft ? 'none' : borderStyle,
        borderBottom: !borderBottom ? 'none' : borderStyle,
        borderRight: !borderRight ? 'none' : borderStyle,
      }}
    >
      {children}
    </View>
  );
};
TableContainer.propTypes = {
  borderTop: bool,
  borderLeft: bool,
  borderBottom: bool,
  borderRight: bool,
};

export default TableContainer;
