import React from 'react';
import { Form, Input, Button, Select } from 'antd';

const { Option } = Select;

const ColeccionForm = ({ form, initialValues, onFinish }) => {
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="nombre"
        label="Nombre"
        rules={[{ required: true, message: 'Por favor ingrese el nombre' }]}
      >
        <Input placeholder="Nombre" />
      </Form.Item>
      <Form.Item
        name="descripcion"
        label="Descripción"
        rules={[
          { required: true, message: 'Por favor ingrese la descripción' },
        ]}
      >
        <Input.TextArea placeholder="Descripción" />
      </Form.Item>
      <Form.Item name="imagen" label="Imagen URL">
        <Input placeholder="URL de la imagen" />
      </Form.Item>
      <Form.Item
        name="type"
        label="Tipo"
        rules={[{ required: true, message: 'Por favor seleccione el tipo' }]}
      >
        <Select placeholder="Seleccione el tipo">
          <Option value="PRODUCTOS">Productos</Option>
          <Option value="SERVICIOS">Servicios</Option>
          <Option value="TEXTOS">Textos</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ColeccionForm;
