import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import SelectClient from 'components/global/Form/SelectClient';
import { func } from 'prop-types';
import React from 'react';
import { usePostApi } from '../../../../hooks/useApi';

function FormMaintanceReports(props) {
  const { reloadReports, closeModal } = props;
  const [form] = useForm();
  const [post] = usePostApi({
    url: 'maintance-report/new-report',
  });

  const submitForm = async () => {
    const values = form.getFieldsValue();
    const response = await post(values);
    if (response.state === 'success') {
      reloadReports();
      closeModal();
      form.resetFields();
    }
  };
  return (
    <Form form={form} onFinish={submitForm}>
      <Form.Item
        name="idClient"
        rules={[{ required: true, message: 'Debe seleccionar un cliente' }]}
      >
        <SelectClient />
      </Form.Item>
      <Form.Item label="Alias" name="alias">
        <Input placeholder="Agrege un nombre para identificar el informe" />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">Asignar Informe</Button>
      </Form.Item>
    </Form>
  );
}

FormMaintanceReports.propTypes = {
  reloadReports: func.isRequired,
};

export default FormMaintanceReports;
