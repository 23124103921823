import { DownloadOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import useAuth from 'hooks/useAuth';
import { useActionsODT } from 'providers/ActionsOdtProvider';
import React, { useEffect, useContext } from 'react';

const ODTPage = ({ id }) => {
  const { callODT, orders, dowload: dowloadODT } = useActionsODT();
  const { user } = useAuth();

  useEffect(() => {
    if (id) {
      callODT(id);
    }
  }, [callODT, id]);

  // Filtrar las órdenes según el tipo de usuario
  const filteredOrders = orders?.filter((order) => {
    if (user.is_administrador) {
      return order.budget_status === 'odt_finalizada';
    }
    return true; // Mostrar todas las órdenes para otros tipos de usuario
  });
  console.log(orders);
  // Definir las columnas para la tabla
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id_budget',
      key: 'id_budget',
    },
    {
      title: 'Estado',
      dataIndex: 'budget_status',
      key: 'budget_status',
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Comuna',
      dataIndex: 'commune',
      key: 'commune',
    },
    {
      title: 'Cliente',
      dataIndex: 'name_client',
      key: 'name_client',
    },
    {
      title: 'Acciones',
      key: 'action',
      render: (item, record) => (
        <>
          {item.budget_status === 'odt_finalizada' ? (
            <Button
              icon={<DownloadOutlined />}
              onClick={() => dowloadODT(item.id_budget)}
            >
              ODT
            </Button>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={filteredOrders}
        rowKey={'id_order'}
      />
    </div>
  );
};

export default ODTPage;
