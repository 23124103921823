import { useAppApi, usePAppApi, useUAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext } from 'react';

const ObservacionAccionesContext = createContext(null);

export const ObservacionAccionesProvider = ({ children, id }) => {
  const map = useCallback((result) => {
    return result;
  }, []);

  const { isLoading: isLoadingPost, call: post } = usePAppApi({
    url: '/clientes/observations',
    mapResults: map,
  });
  const { isLoading: isLoadingPut, call: put } = useUAppApi({
    url: '/clientes/observations',
    mapResults: map,
  });

  const create = (data) => {
    return post(data);
  };
  const update = (id, data) => {
    return put(data, `/${id}`);
  };

  return (
    <ObservacionAccionesContext.Provider
      value={{
        create,
        update,
        isLoadingCreate: isLoadingPost,
        isLoadingUpdate: isLoadingPut,
      }}
    >
      {children}
    </ObservacionAccionesContext.Provider>
  );
};

export const useObservacionAcciones = () =>
  useContext(ObservacionAccionesContext);
