import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Modal } from 'antd';
import CategoryMaintanceForm, {
  addCategory,
  deleteCategory,
  editCategory,
} from 'components/forms/CategoryMaintanceForm';
import EliminarForm from 'components/forms/EliminarForm';
import MaintanceCategoriesList from 'components/lists/MaintanceCategoriesList';
import { useGetToApi } from 'hooks/useApi/useGetAxios';
import React, { useEffect, useMemo, useState } from 'react';

const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};

const Category = () => {
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [categoriesResponse, isLoadingCategories, callCategories] = useGetToApi(
    '/maintance/categories/all',
    {
      initialValues: {
        data: [],
      },
    }
  );
  const categories = useMemo(() => {
    return categoriesResponse.data;
  }, [categoriesResponse]);
  useEffect(() => {
    callCategories();
  }, [callCategories]);

  const successFinish = () => {
    callCategories();
    setModalProps(defaultModalProps);
  };

  const openDelete = async (category) => {
    setModalProps({
      open: true,
      children: (
        <EliminarForm
          title="Eliminar Categoría"
          id={category.id}
          deleteRegister={(id) => deleteCategory(id, successFinish)}
        />
      ),
    });
  };

  const openEdit = async (category) => {
    setModalProps({
      open: true,
      children: (
        <CategoryMaintanceForm
          buttonLabel="Editar"
          submitForm={(values) =>
            editCategory(values, category.id, successFinish)
          }
          formProps={{
            initialValues: category,
          }}
        />
      ),
      title: 'Editar categoría',
    });
  };

  const openAdd = () => {
    setModalProps({
      open: true,
      children: (
        <CategoryMaintanceForm
          buttonLabel="Agregar"
          submitForm={(values) => addCategory(values, successFinish)}
        />
      ),
      title: 'Agregar Cliente',
    });
  };
  return (
    <div>
      <Card>
        <div className="flex flex-row justify-between mb-3">
          <h3 className="uppercase mx-auto md:mx-0">
            Categorías{' '}
            <span className="hidden md:inline-block">de actividades</span>
          </h3>
          <Button type="primary" icon={<PlusOutlined />} onClick={openAdd}>
            Agregar
          </Button>
        </div>
      </Card>
      <MaintanceCategoriesList
        categories={categories}
        isLoading={isLoadingCategories}
        openDelete={openDelete}
        openAdd={openAdd}
        openEdit={openEdit}
      />
      <Modal
        {...modalProps}
        footer={false}
        onCancel={() => setModalProps(defaultModalProps)}
      />
    </div>
  );
};

export default Category;
