import { Select, Space } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { useClientes } from 'providers/Clientes/ClientesProvider';
import React, { useEffect } from 'react';

const ClienteSelect = ({ onChange }) => {
  const { data, call, loading } = useClientes();
  useEffect(() => {
    call();
  }, [call]);
  return (
    <Space direction="vertical" className="w-full">
      <Paragraph className="font-bold">Edificio:</Paragraph>
      <Select
        loading={loading}
        className="w-full !capitalize"
        placeholder="Seleccione"
        defaultValue="todos"
        onChange={onChange}
        showSearch
        filterOption={(input, option) => {
          return (option?.children ?? '')
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        filterSort={(optionA, optionB) =>
          (optionA?.name ?? '')
            .toLowerCase()
            .localeCompare((optionB?.name ?? '').toLowerCase())
        }
        optionFilterProp="children"
      >
        <Select.Option value={true}>Todos</Select.Option>
        {data
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map((opt) => (
            <Select.Option value={opt.id} key={opt.id}>
              {opt.name}
            </Select.Option>
          ))}
      </Select>
    </Space>
  );
};

export default ClienteSelect;
