import PDFOrderContainer from 'PDF/Order/PDFOrderContainer';
import { notification } from 'antd';
import useRequest from 'hooks/useRequest';

import { createContext, useCallback, useContext, useState } from 'react';

const ActionsODTContext = createContext(null);

export const ActionsODTProvider = ({ children }) => {
  const { request, isLoading, error, hasCalled } = useRequest();
  const dowload = async (id) => {
    notification.open({
      duration: 30,
      message: `Descargar Orden de Trabajo N°${id}`,
      btn: <PDFOrderContainer idBudget={id} />,
    });
  };

  const [orders, setOrders] = useState(null);

  const callODT = useCallback(
    async (idCliente) => {
      const result = await request(`/order/cliente/${idCliente}`, 'GET');
      setOrders(result.orders);
      return result;
    },
    [request]
  );

  return (
    <ActionsODTContext.Provider
      value={{
        dowload,
        callODT,
        orders,
      }}
    >
      {children}
    </ActionsODTContext.Provider>
  );
};

export const useActionsODT = () => useContext(ActionsODTContext);
