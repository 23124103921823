import useRequest from 'hooks/useRequest';
import { createContext, useCallback, useContext, useState } from 'react';

const EmergenciasContext = createContext(null);

export const EmergenciasProvider = ({ children }) => {
  const { request, isLoading, error, hasCalled } = useRequest();
  const [idCliente, setIdCliente] = useState(null);
  const [emergencias, setEmergencias] = useState(null);

  const callEmergencias = useCallback(
    async (idCliente) => {
      setIdCliente(idCliente);
      const result = await request(`/comprobantes/cliente/${idCliente}`, 'GET');
      setEmergencias(result);
      return result;
    },
    [request]
  );

  return (
    <EmergenciasContext.Provider
      value={{
        callEmergencias,
        emergencias,
      }}
    >
      {children}
    </EmergenciasContext.Provider>
  );
};

export const useEmergencias = () => useContext(EmergenciasContext);
