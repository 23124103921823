/* eslint-disable no-unused-vars */
import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Form,
  notification,
  Row,
  Skeleton,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import Title from 'antd/es/typography/Title';
import OrderItemCard from 'components/applications/OrderItemCard';
import OrderItemList from 'components/lists/OrderItemList';
import { useCallGetApi, usePutApi } from 'hooks/useApi';
import { useGetToApi } from 'hooks/useApi/useGetAxios';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
const items = [
  {
    key: 0,
    label: 'Preestablecer',
  },
  {
    key: 1,
    label: 'Marcar Inicio Labores',
  },
  {
    key: 3,
    label: 'Marcar Finalización',
  },
];

const OrderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // const [result, isLoading, callOrder] = useCallGetApi({
  //   url: `order/name/items?id_order=${id}`,
  //   key: 'order',
  // });
  const [orderData, isLoading, callOrder] = useGetToApi(
    `/order/name/items?id_order=${id}`,
    {
      initialValues: {
        order: {
          items: [],
          name_client: 'La orden no existe',
        },
      },
    }
  );
  const order = useMemo(() => {
    return orderData.order;
  }, [orderData.order]);

  const [putItems] = usePutApi({
    url: 'order/items/status',
  });
  const [form] = useForm();

  useEffect(() => {
    callOrder();
  }, [callOrder]);

  // const {
  //   order = {
  //     name_client: 'La orden no existe',
  //     items: [],
  //   },
  // } = result;

  useEffect(() => {
    callOrder();
  }, [callOrder]);
  const defaultSelected = items[1];
  const [selectedMenu, setSelectedMenu] = useState(defaultSelected);

  const onChangeCheckbox = ({ target }) => {
    const { checked } = target;
    if (checked) {
      // form.setFieldValue(true);
      order.items?.forEach((item) => {
        form.setFieldValue(item.id_item, true);
      });
    } else {
      form.resetFields();
    }
  };

  const onClickItem = (_item) => {
    const { key } = _item;
    const selectedItem = items.find((item) => item.key == key);
    setSelectedMenu(selectedItem ? selectedItem : defaultSelected);
  };

  const onAction = async () => {
    const values = form.getFieldsValue();
    const keys = Object.keys(values);
    const trueValues = keys.map((key) => {
      if (values[key]) {
        return key;
      } else {
        return undefined;
      }
    });
    const validValues = trueValues.filter((value) => value !== undefined);
    if (validValues.length === 0) {
      return notification.warning({
        message: 'No seleccionó ningún elemento',
      });
    }
    const dataToSend = {
      items: validValues,
      newStatus: selectedMenu.key,
    };
    await putItems(dataToSend);
    await callOrder();
    form.resetFields();
  };
  const onFinishOrder = () => {
    const itemsNotFinished = order.items?.filter(
      (item) => item.item_status_id !== 3
    );
    if (itemsNotFinished.length !== 0) {
      notification.warning({
        message:
          'Es necesario finalizar todos los items para poder Entregar la Orden de trabajo',
      });
    } else {
      return navigate(`../specialist/order/${id}/finish-form`);
    }
  };

  return (
    <div>
      <Skeleton loading={isLoading}>
        <Card className="mb-2">
          <Title level={3}>{order.name_client}</Title>
          <div className="mb-2 flex flex-row">
            <Link to="../specialist/order" className="mr-2">
              <Button icon={<ArrowLeftOutlined />}>Volver</Button>
            </Link>
            <Button type="primary" onClick={onFinishOrder} block>
              Finalizar Orden
            </Button>
          </div>
          <div className="flex flex-row w-full">
            <Button className="mr-2">
              <Checkbox onChange={onChangeCheckbox} />
              <span className="ml-1">Marcar Todos</span>
            </Button>
            <Dropdown.Button
              icon={<DownOutlined />}
              onClick={onAction}
              menu={{
                items: items,
                onClick: onClickItem,
                selectedKeys: [selectedMenu.key],
              }}
              block
              type="primary"
              className="w-full"
            >
              <p className="m-0 w-full">{selectedMenu.label}</p>
            </Dropdown.Button>
          </div>
        </Card>

        <div>
          <Form form={form}>
            <OrderItemList items={order.items} isLoading={false} />
          </Form>
        </div>
      </Skeleton>
    </div>
  );
};

export default OrderDetail;
