import { createContext, useCallback, useContext, useState } from 'react';
import { useAppApi } from 'hooks/useAppApi';
import useRequest from 'hooks/useRequest';

const DocumentContext = createContext(null);

export const DocumentProvider = ({ children }) => {
  // const [data, setData] = useState([]);
  const [elements, setElements] = useState([]);
  const { request, isLoading: requestLoading } = useRequest();

  const map = useCallback((result) => {
    return result;
  }, []);

  const {
    data: documents,
    isLoading,
    error,
    hasCalled,
    call,
  } = useAppApi({
    baseUrl: '/documents',
    mapResults: map,
  });

  const create = async (body) => {
    const result = await request('/documents', 'POST', body);
    // setData((prev) => [...prev, result]);
    return result;
  };

  const update = async (id, body) => {
    const result = await request(`/documents/${id}`, 'PUT', body);
    // setData((prev) =>
    //   prev.map((doc) => (doc.id === id ? { ...doc, ...result } : doc))
    // );
    return result;
  };

  const remove = async (id) => {
    await request(`/documents/${id}`, 'DELETE');
    // setData((prev) => prev.filter((doc) => doc.id !== id));
  };

  const get = useCallback(
    async (id) => {
      const result = await request(`/documents/${id}`, 'GET');
      return result;
    },
    [request]
  );

  const clone = async (id) => {
    const result = await request(`/documents/${id}/clone`, 'POST');
    // setData((prev) => [...prev, result]);
    return result;
  };

  const getElements = useCallback(
    async (documentId) => {
      const result = await request(`/documents/${documentId}`, 'GET');
      setElements(result.items);
      return result.items;
    },
    [request]
  );

  return (
    <DocumentContext.Provider
      value={{
        call,
        isLoading: isLoading || requestLoading,
        error,
        hasCalled,
        data: documents,
        create,
        update,
        remove,
        get,
        clone,
        getElements,
        elements,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};

export const useDocument = () => useContext(DocumentContext);
