import { Space } from 'antd';
import moment from 'moment';
import React from 'react';
import Badge from '../../components/global/Badge';

export const getColumns = (props) => {
  const { buttonActionToggleState } = props;
  return [
    {
      title: 'Estado',
      key: 'status',
      render: (record) => <Badge color={record.status_color} />,
    },
    {
      title: 'Ascensores',
      key: 'elevator',
      dataIndex: 'elevator',
    },
    {
      title: 'Descripción',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Última modificación',
      key: 'modified_date_item',
      dataIndex: 'modified_date_item',
      render: (date) =>
        date ? moment(date).format('YYYY-MM-DD HH:MM') : 'No modificado',
    },
    {
      title: 'Acciones',
      key: 'check',
      render: (record) => (
        <Space>
          {buttonActionToggleState(record.item_status_id, record.id_item)}
          {/* <ButtonState statusId={record.item_status_id} handleStatus={handleStatusItem} idItem={record.id_item}/> */}
        </Space>
      ),
    },
  ];
};

export const tabList = [
  {
    key: 'informacion',
    tab: 'Información',
  },
  {
    key: 'trabajo',
    tab: 'Trabajo',
  },
];
