/* eslint-disable no-unused-vars */
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PDFTable from '../../PDFTable/PDFTable';
import { formatter } from '../../../utils/formaters';
import { getImageApi } from '../../../api/budget';
import Image from '../../Elements/Some/Image';
import Container from '../../Elements/Some/Container';
import { PATH_GET_FILES } from '../../../config';
import Normal from '../../Elements/Text/Normal';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '12px',
    textTransform: 'uppercase',
    marginBottom: '10px',
  },
  leyend: {
    fontSize: '10px',
    margin: '0 20px',
    textAlign: 'justify',
  },
  tableContainer: {
    margin: '0px 20px',
    padding: '0px 20px',
  },
  separation: {
    margin: '5px 0px',
  },
  cellHeader: {
    width: '15vh',
    fontFamily: 'Helvetica-Bold',
    padding: '2px 5px',
    display: 'flex',
    justifyContent: 'center',
  },
  cellContent: {
    fontSize: '10px',
    borderRight: '1px solid black',
    padding: '2px 5px',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    margin: '10px',
    width: '150px',
    height: '150px',
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {},
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
  },
  N: {
    width: '37px',
    flexGrow: 1,
    borderRight: '1px solid black',
    paddingLeft: '1px',
  },
  Ascensor: {
    width: '74px',
    flexGrow: 2,
    borderRight: '1px solid black',
    paddingLeft: '1px',
  },
  Descripcion: {
    width: '222px',
    flexGrow: 6,
    borderRight: '1px solid black',
    paddingLeft: '1px',
  },
  Cantidad: {
    width: '37px',
    flexGrow: 1,
    borderRight: '1px solid black',
    paddingLeft: '1px',
  },
  ValorUnit: {
    width: '74px',
    flexGrow: 2,
    borderRight: '1px solid black',
    paddingLeft: '1px',
  },
  Total: {
    width: '111px',
    flexGrow: 3,
    borderRight: '1px solid black',
    paddingLeft: '1px',
  },
  EspacioBlanco: {
    width: '333px',
    flexGrow: 9,
    borderRight: '1px solid black',
  },
  finalCell: {
    width: '111px',
    flexGrow: 3,
    borderRight: '1px solid black',
    paddingLeft: '1px',
  },
});
function BudgetImage(props) {
  const { imageData } = props;
  const { image } = imageData;

  if (image) {
    return <Image source={`${PATH_GET_FILES}/image/${image}`} />;
  }
  return null;
}
const imageProps = {
  image: PropTypes.string.isRequired,
};
BudgetImage.propTypes = {
  imageData: PropTypes.shape(imageProps).isRequired,
};
const calcFinalDalta = (items, discount) => {
  let valNeto = 0;
  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    valNeto += item.quantity * item.unitValue;
  }
  const descuento = valNeto * discount;
  const iva = (valNeto - descuento) * 0.19;
  const total = valNeto - descuento + iva;
  return {
    valNeto,
    descuento,
    iva,
    total,
  };
};
function BudgetRecord(props) {
  const { description, items = [], images = [], discount } = props;
  if (items) {
    items.sort((a, b) => {
      return a.elevator - b.elevator;
    });
  }
  const finalData = calcFinalDalta(items, discount);
  return (
    <View>
      <Text style={styles.title}>2. Antecedentes y fundamentos técnicos</Text>
      <Normal>{description}</Normal>
      {images?.length !== 0 ? (
        <View>
          <Container wrap>
            {images.map((image) => (
              <BudgetImage imageData={image} key={image.id} />
            ))}
          </Container>
        </View>
      ) : null}

      {/* Tabla */}
      <View
        style={{
          flexDirection: 'column',
          fontFamily: 'Times-Roman',
          // flexWrap: 'wrap',
          fontSize: '10px',
          width: '100%',
        }}
      >
        <View style={{ ...styles.row, fontFamily: 'Times-Bold' }}>
          <Text style={styles.N}>N°</Text>
          <Text style={styles.Ascensor}>Ascensor</Text>
          <Text style={styles.Descripcion}>Descripción</Text>
          <Text style={styles.ValorUnit}>Val. Unit</Text>
          <Text style={styles.Cantidad}>Cant.</Text>
          <Text style={styles.Total}>Total</Text>
        </View>
        {items.map((item, index) => (
          <View style={styles.row} key={item.id}>
            <Text style={styles.N}>{index + 1}</Text>
            <Text style={styles.Ascensor}>{item.elevator}</Text>
            <Text style={styles.Descripcion}>{item.description}</Text>
            <Text style={styles.ValorUnit}>{item.unitValue}</Text>
            <Text style={styles.Cantidad}>{item.quantity}</Text>
            <Text style={styles.Total}>
              {formatter.format(item.quantity * item.unitValue)}
            </Text>
          </View>
        ))}
        <View style={styles.row}>
          <View style={styles.EspacioBlanco} />
          <Text
            style={{
              ...styles.finalCell,
              fontFamily: 'Times-Bold',
              backgroundColor: '#dbe5f1',
            }}
          >
            Valor Neto
          </Text>
          <Text
            style={{
              ...styles.finalCell,
              backgroundColor: '#dbe5f1',
            }}
          >
            {formatter.format(finalData.valNeto)}
          </Text>
        </View>
        {discount !== 0 ? (
          <>
            <View style={styles.row}>
              <View style={styles.EspacioBlanco} />
              <Text style={{ ...styles.finalCell, fontFamily: 'Times-Bold' }}>
                % Descuento
              </Text>
              <Text style={styles.finalCell}>{discount * 100}%</Text>
            </View>
            <View style={styles.row}>
              <View style={styles.EspacioBlanco} />
              <Text style={{ ...styles.finalCell, fontFamily: 'Times-Bold' }}>
                Descuento
              </Text>
              <Text style={styles.finalCell}>
                {formatter.format(finalData.descuento)}
              </Text>
            </View>
          </>
        ) : null}

        <View style={styles.row}>
          <View style={styles.EspacioBlanco} />
          <Text style={{ ...styles.finalCell, fontFamily: 'Times-Bold' }}>
            IVA
          </Text>
          <Text style={styles.finalCell}>
            {formatter.format(finalData.iva)}
          </Text>
        </View>
        <View style={{ ...styles.row, borderBottom: '1px solid black' }}>
          <View style={styles.EspacioBlanco} />
          <Text
            style={{
              ...styles.finalCell,
              fontFamily: 'Times-Bold',
              backgroundColor: '#002060',
              color: '#fff',
            }}
          >
            Total
          </Text>
          <Text
            style={{
              ...styles.finalCell,
              backgroundColor: '#002060',
              color: '#fff',
            }}
          >
            {formatter.format(finalData.total)}
          </Text>
        </View>
      </View>
    </View>
  );
}

const itemProps = {
  id: PropTypes.number.isRequired,
  elevator: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  unitValue: PropTypes.number.isRequired,
};

BudgetRecord.propTypes = {
  description: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(itemProps).isRequired).isRequired,
  images: PropTypes.arrayOf(PropTypes.shape(imageProps)).isRequired,
  discount: PropTypes.number.isRequired,
};

export default BudgetRecord;
