import { StyleSheet } from '@react-pdf/renderer';
import { fontFamily } from 'components/PDF2/assets';
import { createContext, useContext } from 'react';

const PDFStyleContext = createContext(
  StyleSheet.create({
    title: {
      fontSize: 20,
      textTransform: 'uppercase',
      fontFamily: fontFamily,
    },
    content: {
      marginHorizontal: '1cm',
      fontFamily: fontFamily,
      marginTop: '5px',
    },
    text: {
      fontSize: 12,
      fontWeight: 'normal',
    },
    bold: {
      fontSize: 10,
      fontWeight: 'bold',
    },
  })
);

export const PDFStyleProvider = ({ children }) => {
  return (
    <PDFStyleContext.Provider value={{}}>{children}</PDFStyleContext.Provider>
  );
};

export const usePDFStyle = () => useContext(PDFStyleContext);
