import { useAppApi, usePAppApi, useUAppApi } from 'hooks/useAppApi';
import useRequest from 'hooks/useRequest';
import { createContext, useCallback, useContext, useEffect } from 'react';

const ClienteContext = createContext(null);

export const ClienteProvider = ({ children, id }) => {
  const map = useCallback((result) => {
    return result;
  }, []);

  const {
    data,
    isLoading: loading,
    error,
    hasCalled,
    call: callNotFunctional,
  } = useAppApi({
    baseUrl: '/clientes',
    mapResults: map,
  });
  const call = useCallback(
    async (id_client) => {
      const result = await callNotFunctional({ extUrl: `/${id_client}` });
      return result;
    },
    [callNotFunctional]
  );

  const {
    data: ascensores,
    isLoading: loadingAscensores,
    error: errorAscensores,
    hasCalled: hasCalledAscensores,
    call: callNotFunctionalAscensores,
  } = useAppApi({
    baseUrl: '/clientes',
    mapResults: map,
  });

  const callAscensores = useCallback(
    async (id_client) => {
      const result = await callNotFunctionalAscensores({
        extUrl: `/${id_client}/ascensores`,
      });
      return result;
    },
    [callNotFunctionalAscensores]
  );

  const {
    data: dataConfig,
    isLoading: loadingConfig,
    error: errorConfig,
    hasCalled: hasCalledConfig,
    call: callNotFunctionalConfig,
  } = useAppApi({
    baseUrl: '/clientes',
    mapResults: map,
  });
  const callConfig = async (id_client) => {
    const result = await callNotFunctionalConfig({
      extUrl: `/${id_client}/config`,
    });
    return result;
  };

  const { call: updateConfig } = useUAppApi({
    url: '/clientes',
    mapResults: useCallback((data) => {
      return data;
    }, []),
  });

  const saveConfig = async (id_client, data) => {
    const result = await updateConfig(data, `/${id_client}/config`);
    return result;
  };
  const update = async (id_client, data) => {
    const result = await updateConfig(data, `/${id_client}`);
    return result;
  };

  const {
    request,
    data: requestData,
    isLoading,
    error: requestError,
    hasCalled: requestHasCalled,
  } = useRequest();

  const saveAscensores = async (data, id_client) => {
    const result = await request(
      `/clientes/${id_client}/ascensores`,
      'PUT',
      data
    );
    return result;
  };

  return (
    <ClienteContext.Provider
      value={{
        call,
        id,
        loading,
        error,
        hasCalled,
        data,
        callConfig,
        dataConfig,
        loadingConfig,
        errorConfig,
        hasCalledConfig,
        saveConfig,
        update,
        ascensores,
        loadingAscensores,
        errorAscensores,
        hasCalledAscensores,
        callAscensores,
        saveAscensores,
        isLoading: loading,
      }}
    >
      {children}
    </ClienteContext.Provider>
  );
};

export const useCliente = () => useContext(ClienteContext);
