// types.js
import Section from './Components/Section';
import ProductsTable from './Components/ProductsTable';
import List from './Components/List';
import Part from './Components/Part';
import ConceptList from './Components/KeysList';
import ImageSection from './Components/ImageSection';

const types = [
  {
    value: 'part',
    label: 'Parte',
    render: Part,
  },
  {
    value: 'section',
    label: 'Sección',
    render: Section,
  },
  {
    value: 'productos',
    label: 'Tabla de Productos',
    render: ProductsTable,
  },
  {
    value: 'lista',
    label: 'Lista',
    render: List,
  },
  {
    value: 'conceptList',
    label: 'Lista de Conceptos',
    render: ConceptList,
  },
  {
    value: 'image',
    label: 'Imagen',
    render: ImageSection,
  }
];

export default types;
