import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  title2: {
    fontSize: '12pt',
    fontFamily: 'Times-Bold',
    textTransform: 'uppercase',
  },
  container: {
    margin: '20pt 0pt',
  },
});
