import { useAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext } from 'react';

const DashboardContext = createContext(null);

export const DashboardProvider = ({ children, id }) => {
  const map = useCallback((result) => {
    return result;
  }, []);

  const { data, isLoading, error, hasCalled, call } = useAppApi({
    baseUrl: '/mixed/dashboard',
    mapResults: map,
  });

  return (
    <DashboardContext.Provider
      value={{
        call,
        id,
        isLoading,
        error,
        hasCalled,
        data,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => useContext(DashboardContext);
