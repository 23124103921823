import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Button, Card, List } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useMemo } from 'react';

const MaintanceCategoriesList = (props) => {
  const { categories, isLoading, openDelete, openEdit, onList = false } = props;
  const grid = useMemo(() => {
    if (onList) {
      return {
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        xl: 1,
        xxl: 1,
      };
    }
    return {
      gutter: 16,
      xs: 1,
      sm: 2,
      md: 2,
      lg: 3,
      xl: 4,
      xxl: 6,
    };
  }, [onList]);
  return (
    <List
      className="mt-3 text-center p-4 bg-gray-100 rounded-md shadow-md"
      itemLayout="horizontal"
      grid={grid}
      pagination={{
        align: 'end',
        position: 'bottom',
        pageSize: 12,
      }}
      dataSource={categories}
      loading={isLoading}
      rowKey="id"
      renderItem={(data) => (
        <List.Item key={data.id}>
          <Card
            actions={[
              <Button
                key={'delete'}
                type="link"
                danger
                onClick={() => openDelete(data)}
              >
                <DeleteFilled />
              </Button>,
              <Button key={'edit'} type="link" onClick={() => openEdit(data)}>
                <EditFilled />
              </Button>,
            ]}
          >
            <List.Item.Meta
              className=" uppercase text-center"
              title={data.nombre}
            />
          </Card>
        </List.Item>
      )}
    />
  );
};

export default MaintanceCategoriesList;
