import { Image, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import BlueBar from '../../../assets/img/png/blue-bar.png';
import TecnologicalBar from '../../../assets/img/png/tecnological-bar.png';
import LogoProlift from '../../../assets/img/png/prolift_logo_sbg.png';

const styles = StyleSheet.create({
  frontBar: {
    opacity: 0.9,
    position: 'absolute',
    top: '0',
    zIndex: '0',
    height: '4.09cm',
    width: '19.05cm',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  backBar: {
    position: 'absolute',
    top: '0',
    zIndex: '1',
    height: '4.09cm',
    width: '19.05cm',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  logo: {
    height: '2.5cm',
    width: '3.57cm',
    position: 'absolute',
    left: '14cm',
    top: '0.2cm',
    backgroundColor: 'white',
    padding: '0.1cm',
    borderRadius: '5px',
  },
  header: {
    width: '100vw',
    height: '4.09cm',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function VoucherHeader() {
  return (
    <View style={styles.header}>
      <Image src={BlueBar} style={styles.frontBar} />
      <Image src={TecnologicalBar} style={styles.backBar} />
      <Image src={LogoProlift} style={styles.logo} />
    </View>
  );
}

export default VoucherHeader;
