import React from 'react';
import Button from '../../components/global/Button';

const getButtonParameters = (statusId) => {
  switch (statusId) {
    case 0:
      return {
        color: 'yellow',
        content: 'Iniciar',
        newStatusId: 1,
      };
    case 1:
      return {
        color: 'green',
        content: 'Finalizar',
        newStatusId: 3,
      };
    case 2:
      return {
        color: 'green',
        content: 'Finalizar',
        newStatusId: 3,
      };
    case 3:
      return {
        color: 'red',
        content: 'Retroceder',
        newStatusId: 0,
      };
    default:
      return null;
  }
};

export const buttonToggleState = (statusId, itemId, update, refreshItems) => {
  const parameters = getButtonParameters(statusId);

  const toggleItemStatus = async () => {
    await update({ status_id: parameters.newStatusId }, itemId);
    refreshItems();
  };
  return (
    <Button color={parameters.color} onClick={toggleItemStatus}>
      {parameters.content}
    </Button>
  );
};

export const ss = 2;
