import { useApi, usePostApi, usePutApi } from '.';

/**
 * RUTA: /order
 */

/**
 * Hook para agregar ordenes de trabajo
 * @returns Hook
 */

export const usePostOrderApi = () => {
  const config = {
    url: 'order',
  };
  const [post] = usePostApi(config);
  return [post];
};

/**
 * Devuelve los items de una orden de trabajo
 * @param {Number} id - Id del presupuesto
 * @returns Hook
 */

export const useItemsApi = (id) => {
  const config = {
    url: `order/items?id=${id}`,
    successEnabled: false,
    key: 'items',
    defaultValue: [],
  };
  const [items, refresh] = useApi(config);
  return [items, refresh];
};

export const useSpecOrderApi = () => {
  const config = {
    url: 'order/specialist',
    successEnabled: false,
    key: 'orders',
    defaultValue: [],
  };
  const [orders, refresh] = useApi(config);
  return [orders, refresh];
};

/**
 *
 * @param {Number} idOrder - Id de la orden de la que queremos el ID
 * @returns Hook
 */
export const useSpecOrderItemsApi = (idOrder) => {
  const config = {
    url: `order/specialist/items?id_order=${idOrder}`,
    successEnabled: false,
    key: 'items',
    defaultValue: [],
  };
  const [items, refresh] = useApi(config);
  return [items, refresh];
};
/**
 * Hook que permite alternar el estado de un Item
 * @returns
 */
export const useOrderItemUpdate = () => {
  const config = {
    url: `order/specialist/item`,
    successEnabled: true,
  };
  const [put] = usePutApi(config);
  return [put];
};
/**
 * Hook que permite finalizar una orden
 * @returns
 */
export const useOrderFinish = () => {
  const config = {
    url: `order/specialist/finish-order`,
    successEnabled: true,
  };
  const [post] = usePostApi(config);
  return [post];
};
