/* eslint-disable no-unused-vars */
import { Spin } from 'antd';
import { shape } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useApi, useAppApi } from '../../../../../hooks/useApi';
import MaintancePDF from './MaintancePDF';
import { getOrderConfigApi } from 'api/budget';
import { getAccessTokenApi } from 'api/auth';

function MaintancePDFContainer(props) {
  const { itemData } = props;
  const { id_maintance_report: idReport } = itemData;

  // const [items, reloadItems] = useApi({
  //   url: `maintance-report/specialist/item?idReport=${idReport}`,
  //   defaultValue: [],
  //   successEnabled: false,
  //   key: 'items',
  // });
  const {
    payload: resItems,
    refresh: refreshItems,
    isLoading: isLoadingItems,
  } = useAppApi({
    url: `maintance-report/specialist/item?idReport=${idReport}`,
    defaultValue: {},
  });

  const [config, setConfig] = useState({});
  const token = getAccessTokenApi();
  useEffect(() => {
    getOrderConfigApi(token).then((result) => {
      setConfig(result);
    });
  }, [token]);

  useEffect(() => {
    refreshItems();
  }, [refreshItems, idReport]);

  // const [report, reloadReport] = useApi({
  //   url: `maintance-report/specialist/report/client?idReport=${idReport}`,
  //   defaultValue: {},
  //   successEnabled: false,
  //   key: 'report',
  // });

  const {
    payload: resReport,
    refresh: refreshReport,
    isLoading: isLoadingReport,
  } = useAppApi({
    url: `maintance-report/specialist/report/client?idReport=${idReport}`,
    defaultValue: {},
  });
  useEffect(() => {
    refreshReport();
  }, [refreshReport, idReport]);
  const { report } = resReport;
  const { items } = resItems;
  if (!isLoadingItems && !isLoadingReport) {
    return <MaintancePDF config={config} items={items} report={report} />;
  }
  return <Spin />;
}

MaintancePDFContainer.propTypes = {
  itemData: shape({}).isRequired,
};
export default MaintancePDFContainer;
