import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import Header from 'components/PDF2/Headers/Header';
import React from 'react';
import Title from './Title';
import { usePDFStyle } from 'providers/PDF/PDFStyleProvider';
import VoucherData from 'PDF/AttentionVoucher/VoucherData';
import Container from 'components/PDF2/Elements/Container';
import { PATH_GET_FILES } from 'config';
import RecSpeSignatures from 'PDF/Elements/Some/RecSpeSignatures';

const Constructor = ({ size, voucher }) => {
  const { content, text } = usePDFStyle();
  return (
    <Document>
      <Page size={size ?? 'A4'}>
        <View
          style={{
            height: '3cm',
          }}
          fixed
        >
          <Header
            company={{
              name: 'Lift Logic',
              rut: '20419279-0',
              phone: '+569 4254 2081',
            }}
            document={{
              id: 10000,
            }}
          />
        </View>
        <View style={content}>
          <Title title="Comprobante de Atención" />
          {/* Datos del cliente y comprobante */}
          <View
            style={{
              marginTop: 5,
            }}
          >
            <View>
              <VoucherData
                recepcionist_name={'Carla Escalona'}
                client_name={'Villamagna'}
                reason={'Emergencia'}
                attention_init={'11:00 pm'}
                attention_finish={'12:00 pm'}
                client_rut={'20419279-0'}
                equipment={'2A, 6B'}
                specialist_name={'David Silva Urrutia'}
                date={new Date()}
                finish_state={'En observación'}
              />
            </View>
          </View>
          <Title title="Observaciones" />
          {/* Datos del cliente y comprobante */}
          <View
            style={{
              marginTop: 5,
            }}
          >
            <Text
              style={[
                text,
                {
                  textAlign: 'justify',
                },
              ]}
            >
              {' '}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id
              elit velit. In justo lorem, tempus a fermentum id, sodales vel
              odio. Nulla sollicitudin leo a porta porta. Ut eget velit a tortor
              ultricies volutpat. Aliquam vitae turpis dapibus, ultrices velit
              id, placerat lorem. Quisque rutrum arcu ipsum, in elementum odio
              dignissim nec. Praesent nunc tortor, luctus in justo ac, malesuada
              egestas enim. Nulla a elit ultrices, consequat eros vestibulum,
              condimentum magna. Nam diam ipsum, tincidunt non eros non, semper
              feugiat nibh. Praesent lorem velit, pretium vel facilisis id,
              scelerisque eu lorem. Cras mollis risus sit amet nulla iaculis,
              sit amet luctus neque ornare. Interdum et malesuada fames ac ante
              ipsum primis in faucibus. Nullam placerat enim a fringilla
              dignissim. Donec blandit, justo quis ultricies varius, neque velit
              iaculis tortor, id dignissim arcu dolor id augue. Quisque
              malesuada fringilla ultrices. Quisque sit amet ullamcorper quam.
              Praesent sit amet vestibulum justo, quis lobortis lacus.
              Vestibulum non urna bibendum lectus rhoncus dignissim at a orci.
              Sed et pharetra elit, nec varius nunc. Nam iaculis tristique est,
              ac tempor lectus. Pellentesque accumsan volutpat fringilla.
              Pellentesque a elit euismod, pulvinar erat sit amet, fringilla
              ipsum. Nam metus dolor, posuere eu nulla eu, pellentesque euismod
              nunc. Etiam dictum nunc nec dolor feugiat vestibulum. Nam dictum
              leo a tortor placerat venenatis. Vestibulum libero enim, malesuada
              quis velit sed, vestibulum mollis dolor. Sed porta accumsan leo at
              mollis. Vivamus facilisis turpis et tortor pellentesque pretium.
              Suspendisse auctor, mi lacinia hendrerit vestibulum, arcu risus
              dapibus ligula, ut ultricies leo libero ac massa. Fusce et
              efficitur odio, nec rhoncus mauris. Vivamus lacinia luctus odio
              sed dapibus. Fusce sit amet vulputate nulla. Morbi rutrum vitae
              turpis at ullamcorper. Etiam feugiat elementum dignissim. Nullam
              tincidunt dolor in efficitur lacinia. Quisque in sodales magna,
              vel bibendum dolor. Duis lobortis risus vel lacus pellentesque
              dignissim. Integer vitae ante sollicitudin, ultrices velit eget,
              euismod nibh. Maecenas tortor metus, tristique non enim ac,
              ultrices fermentum leo. Donec gravida magna accumsan mauris
              sodales porta. Morbi dapibus massa nisl, sed tempor tortor iaculis
              quis. Nunc nunc dui, semper a odio vitae, vestibulum maximus
              neque. Fusce tellus leo, varius sit amet mauris non, luctus
              pulvinar sem. Fusce pharetra felis vel ex placerat, eget
              scelerisque erat bibendum. Nulla facilisi. Mauris semper mauris
              augue. Suspendisse eleifend blandit dolor sit amet ullamcorper.
              Vivamus auctor diam in bibendum auctor. Aliquam erat volutpat.
              Fusce tincidunt metus sit amet nunc pellentesque accumsan. Ut
              tortor lacus, lobortis id vulputate vitae, scelerisque a odio. Sed
              vel quam at augue sollicitudin vehicula. Ut ac quam feugiat,
              ultricies mauris eget, venenatis elit. Donec cursus tempor auctor.
              Morbi lacinia quis nisl et placerat. Curabitur condimentum
              scelerisque lacus, id vulputate mauris maximus in. Cras egestas
              metus non nibh tincidunt egestas. Phasellus quis feugiat nisi.
              Maecenas tempor porttitor felis sit amet eleifend. Nunc fringilla
              lectus leo, ac congue eros feugiat venenatis. Nunc vitae nibh ut
              leo varius posuere non vel neque. Nunc ut scelerisque lacus,
              vulputate cursus diam. Proin tincidunt posuere augue nec
              ullamcorper. Duis ac libero in nisi aliquam ultrices sit amet in
              purus. Donec vehicula tellus eu accumsan sollicitudin.
            </Text>
          </View>

          {/* Imágenes adjuntas */}
          {[].length > 0 ? (
            <View>
              <Title title="Imágenes Adjuntas" />
              <Container wrap>
                {[].map(({ image }) => (
                  <Image
                    key={image}
                    source={`${PATH_GET_FILES}/image/${image}`}
                  />
                ))}
              </Container>
            </View>
          ) : null}

          <View
            wrap={false}
            style={{
              marginTop: 5,
            }}
          >
            <Title title="Información de Entrega" />
            <View
              style={{
                marginTop: 5,
              }}
            >
              <Text style={text}>
                A continuación, se encuentra la firma, la información del
                especialista y de quien recibe la atención. Esto, cuenta como
                una constancia firmada de que se realizó la atención.
              </Text>
            </View>
            {/* <RecSpeSignatures
              specialistSignature={voucher.specialist_signature}
              recepcionistName={voucher.recepcionist_name}
              recepcionistSignature={voucher.recepcionist_signature}
              recepcionistCharge={voucher.recepcionist_charge}
              specialistName={voucher.specialist_name}
              specialistEspecialidad={voucher.specialist_especialidad}
              specialistCargo={voucher.specialist_cargo}
              specialistOtros={voucher.specialist_otros}
            /> */}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Constructor;
