import React, { useEffect, useState } from 'react';
import {
  Tabs,
  Table,
  Spin,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Switch,
} from 'antd';
import { useAdmin } from 'providers/AdminProvider';
import moment from 'moment';

const { TabPane } = Tabs;

const Admin = () => {
  const { getAllModels, getAllRecords, updateRecord, isLoading } = useAdmin();
  const [models, setModels] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [form] = Form.useForm();

  // Cargar todos los modelos registrados al montar el componente
  useEffect(() => {
    const fetchModels = async () => {
      const models = await getAllModels();
      setModels(models);
    };
    fetchModels();
  }, [getAllModels]);

  // Manejar el cambio de tab (modelo seleccionado)
  const handleTabChange = async (key) => {
    setLoading(true);
    const response = await getAllRecords(key);
    setData((prevData) => ({
      ...prevData,
      [key]: response,
    }));
    setSelectedModel(key);
    setLoading(false);
  };

  // Abrir el modal de edición
  const handleEdit = (record) => {
    setSelectedRecord(record);
    const initialValues = Object.fromEntries(
      Object.entries(record).map(([key, value]) => {
        if (
          typeof value === 'string' &&
          moment(value, moment.ISO_8601, true).isValid()
        ) {
          return [key, moment(value)];
        }
        return [key, value];
      })
    );
    form.setFieldsValue(initialValues);
  };

  // Enviar los cambios
  const handleUpdate = async (values) => {
    if (selectedRecord && selectedModel) {
      const updatedRecord = { ...selectedRecord, ...values };
      Object.keys(updatedRecord).forEach((key) => {
        if (moment.isMoment(updatedRecord[key])) {
          updatedRecord[key] = updatedRecord[key].toISOString();
        }
      });

      await updateRecord(selectedModel, selectedRecord.id, updatedRecord);
      setData((prevData) => ({
        ...prevData,
        [selectedModel]: {
          ...prevData[selectedModel],
          records: prevData[selectedModel].records.map((item) =>
            item.id === selectedRecord.id ? updatedRecord : item
          ),
        },
      }));
      setSelectedRecord(null);
    }
  };

  const renderRelations = (value) => {
    if (Array.isArray(value)) {
      return (
        <Button type="link" onClick={() => handleEdit(value)}>
          {value.length} items
        </Button>
      );
    }
    if (typeof value === 'object' && value !== null) {
      return (
        <Button type="link" onClick={() => handleEdit(value)}>
          Ver detalle
        </Button>
      );
    }
    return value;
  };

  const renderFormItem = (key, value, fieldType) => {
    switch (fieldType) {
      case 'string':
        return (
          <Form.Item label={key} name={key} key={key}>
            <Input />
          </Form.Item>
        );
      case 'number':
        return (
          <Form.Item label={key} name={key} key={key}>
            <InputNumber />
          </Form.Item>
        );
      case 'boolean':
        return (
          <Form.Item label={key} name={key} key={key} valuePropName="checked">
            <Switch />
          </Form.Item>
        );
      case 'date':
        return (
          <Form.Item label={key} name={key} key={key}>
            <DatePicker />
          </Form.Item>
        );
      default:
        return (
          <Form.Item label={key} key={key}>
            <pre>{JSON.stringify(value, null, 2)}</pre>
          </Form.Item>
        );
    }
  };

  const columns = (model) =>
    data[model]?.records?.length
      ? Object.keys(data[model].records[0]).map((key) => ({
          title: key,
          dataIndex: key,
          render: (text, record) => {
            if (
              typeof text === 'string' ||
              typeof text === 'number' ||
              moment.isMoment(text)
            ) {
              return (
                <Button type="link" onClick={() => handleEdit(record)}>
                  {text}
                </Button>
              );
            }
            return renderRelations(text);
          },
        }))
      : [];

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Admin Panel</h1>
      <Tabs onChange={handleTabChange}>
        {models?.map((model) => (
          <TabPane tab={model} key={model}>
            {loading ? (
              <div className="flex justify-center items-center h-64">
                <Spin />
              </div>
            ) : (
              <Table
                dataSource={data[model]?.records || []}
                columns={columns(model)}
                rowKey="id"
                className="w-full"
              />
            )}
          </TabPane>
        ))}
      </Tabs>
      <Modal
        visible={!!selectedRecord}
        title="Editar Registro"
        onCancel={() => setSelectedRecord(null)}
        onOk={() => form.submit()}
        footer={[
          <Button key="cancel" onClick={() => setSelectedRecord(null)}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Guardar
          </Button>,
        ]}
      >
        {selectedRecord && (
          <Form form={form} layout="vertical" onFinish={handleUpdate}>
            {Object.keys(selectedRecord).map((key) =>
              renderFormItem(
                key,
                selectedRecord[key],
                data[selectedModel]?.types?.[key] || 'unknown'
              )
            )}
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default Admin;
