import { useAppApi, usePAppApi, useUAppApi } from 'hooks/useAppApi';
import React, { createContext, useCallback, useContext } from 'react';

// Crear el contexto
const TenantTextContext = createContext(null);

// Componente proveedor del contexto
export const TenantTextProvider = ({ children }) => {
  const {
    call: notFunctionalCall,
    isLoading,
    data,
    hasCalled,
  } = useAppApi({
    baseUrl: '/tenants',
    mapResults: useCallback((data) => {
      console.log(data);
      return data;
    }, []),
  });
  const call = useCallback(
    async (id_tenant) => {
      const result = await notFunctionalCall({
        extUrl: `/${id_tenant}/texts`,
      });
      console.log(result);
      return result;
    },
    [notFunctionalCall]
  );

  const { call: addText } = usePAppApi({
    url: '/tenants/texts',
    mapResults: useCallback((data) => {
      console.log(data);
      return data;
    }, []),
  });
  const { call: updateTextsNotFunctional } = useUAppApi({
    url: '/tenants',
    mapResults: useCallback((data) => {
      console.log(data);
      return data;
    }, []),
  });
  const updateText = async (id, values) => {
    const result = await updateTextsNotFunctional(values, `/${id}/texts`);
    return result;
  };

  return (
    <TenantTextContext.Provider
      value={{
        call,
        isLoading,
        data,
        hasCalled,
        addText,
        updateText,
      }}
    >
      {children}
    </TenantTextContext.Provider>
  );
};

export const useTenantText = () => useContext(TenantTextContext);
