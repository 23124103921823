import { List } from 'antd';
import Ascensor from 'components/Ascensor';
import React from 'react';

const AscensoresList = (props) => {
  const {
    ascensores,
    openEditAscensor,
    isLoading,
    openDeleteAscensor,
    openDuplicateAscensor,
    noActions = false,
    extraItem = null,
  } = props;
  return (
    <List
      loading={isLoading}
      grid={{
        gutter: 12,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 2,
        xxl: 2,
      }}
      pagination={{
        align: 'end',
        position: 'bottom',
        pageSize: 8,
      }}
      dataSource={ascensores}
      renderItem={(ascensor) => (
        <Ascensor
          noActions={noActions}
          key={ascensor.id}
          data={ascensor}
          openEditAscensor={openEditAscensor}
          openDeleteAscensor={openDeleteAscensor}
          openDuplicateAscensor={openDuplicateAscensor}
          extra={extraItem}
        />
      )}
    />
  );
};

export default AscensoresList;
