export const VIGENTE = 'VIGENTE';
export const ATRASADO = 'ATRASADO';
export const INACTIVO = 'INACTIVO';
export const COMPLETADO = 'COMPLETADO';
export const SIN_ASIGNAR = 'SIN ASIGNAR';
export const ASIGNADO = 'ASIGNADO';
export const INICIADA = 'INICIADA';
export const CREADA = 'CREADA';

export const SOLICITUD_TIPOS = {
  SIN_ASIGNAR: 'SIN ASIGNAR',
  ASIGNADA: 'ASIGNADA',
  EN_CURSO: 'EN_CURSO',
  FINALIZADA: 'FINALIZADA',
  ENVIADA: 'ENVIADA',
  CANCELADA: 'CANCELADA',
};
export const ESTADO_COLORES = {
  SIN_ASIGNAR: 'gray',
  ASIGNADA: 'blue',
  EN_CURSO: 'orange',
  FINALIZADA: 'green',
  ENVIADA: 'purple',
  CANCELADA: 'red',
};
export const getColorForStatus = (status) => {
  return ESTADO_COLORES[status] || 'gray'; // Puedes definir 'defaultColor' como un color predeterminado
};
export const SOLICITUD_TIPOS_ARRAY = Object.values(SOLICITUD_TIPOS);
