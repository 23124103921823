import React from 'react';
import { Form, Input } from 'antd';

const { TextArea } = Input;

const PlantillaForm = ({ form }) => {
  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="titulo"
        label="Título"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese el título de la plantilla',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="texto"
        label="Texto"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese el texto de la plantilla',
          },
        ]}
        // className="min-h-[300px]"
      >
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  );
};

export default PlantillaForm;
