import { Image, Text, View } from '@react-pdf/renderer';

// import { fontFamily, marginPage, primaryColor }
import React from 'react';
import Logo from 'assets/logo';
import Normal from '../Elements/Text/Normal';
import { fontFamily, marginPage, primaryColor } from '../assets';
import Container from '../Elements/Container';
// import Image from '../Elements/Some/Image';
import { API_PATH } from 'config';

const Header = ({ company, document, fixed = true, config = {} }) => {
  const { name, rut, phone } = company;
  const { id } = document;
  return (
    <Container
      fixed={fixed}
      between
      dflex="row"
      style={{
        position: 'absolute',
        top: marginPage,
        left: marginPage,
        width: '100%',
      }}
    >
      <View>
        <Normal>{name}</Normal>
        <Container
          dflex="row"
          mt="2pt"
          style={{
            width: '150px',
            justifyContent: 'flex-start',
          }}
        >
          <Normal>RUT: </Normal>
          <Normal>{rut}</Normal>
        </Container>
        <Container dflex="row" mt="2pt">
          <Normal>Fono EMERGENCIA: </Normal>
          <Normal>{phone}</Normal>
        </Container>
      </View>
      <View>
        <Image
          style={{
            height: '2.2cm',
            width: '3.37cm',
            backgroundColor: 'white',
            padding: '0.1cm',
          }}
          source={
            config?.portraitLogo
              ? `${API_PATH}${config?.portraitLogo}`
              : 'LIFT LOGIC'
          }
        />
      </View>
      <Container
        dflex="row"
        style={{
          width: '200px',
          alignItems: 'center',
          fontFamily: fontFamily,
          fontSize: '16pt',
          justifyContent: 'center',
        }}
      >
        <Text>N° </Text>
        <Text
          style={{
            color: primaryColor,
          }}
        >
          {id}
        </Text>
      </Container>
    </Container>
  );
};

export default Header;
