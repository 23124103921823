import {
  CalendarOutlined,
  DownloadOutlined,
  RobotOutlined,
} from '@ant-design/icons';
import { Button, Card } from 'antd';
import CalendarSolc from 'components/CalendarSolc';
import SolicitudesForDayTable from 'components/tables/SolicitudesForDayTable';
import SolicitudesTable from 'components/tables/SolicitudesTable';
import { useMantenciones } from 'providers/MantencionesProvider';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useSolicitudesMantencion } from 'providers/SolicitudesMantencionProvider';

const MantencionesCalendario = () => {
  const refCard = useRef(null);
  const refCalendar = useRef(null);
  const {
    data: mantenciones,
    hasCalled: mantencionesHasCalled,
    call: callMantenciones,
    isLoading: mantencionesIsLoading,
  } = useMantenciones();

  useEffect(() => {
    if (refCard.current && refCalendar.current) {
      const calendarHeight = refCalendar.current.offsetHeight;
      refCard.current.style.height = `${calendarHeight}px`;
    }
  }, []);

  useEffect(() => {
    if (!mantencionesHasCalled) {
      callMantenciones();
    }
  }, [callMantenciones, mantencionesHasCalled]);
  const { openDowloadMantencion } = useSolicitudesMantencion();
  const handleClickInSolc = (record) => {
    openDowloadMantencion({
      id_mantencion: record.id,
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
      showSorterTooltip: false,
    },
    {
      title: 'Responsable',
      dataIndex: 'Responsable',
      key: 'responsable',
      render: (responsable) => responsable?.fullname,
    },
    {
      title: 'Cliente',
      dataIndex: 'Cliente',
      key: 'cliente',
      render: (cliente) => cliente?.name,
    },
    {
      title: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Última Actualización',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      width: 100,
      fixed: 'right',
      render: (_, record) => (
        <div className="space-x-2">
          <Button
            onClick={() => handleClickInSolc(record)}
            // className="w-20"
            type="link"
          >
            <DownloadOutlined /> Descargar
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="space-y-5 p-4">
      <Card className="mb-4 shadow-md">
        <div>
          <Link to="/admin/mantenciones/actividades">
            <Button icon={<RobotOutlined />} type="link">
              Actividades de Mantención
            </Button>
          </Link>
        </div>
      </Card>

      <Card
        className="mt-4"
        title={
          <>
            <CalendarOutlined className="text-xl" /> Calendario de Mantenciones
          </>
        }
        style={{ overflowX: 'auto' }}
      >
        <div ref={refCalendar}>
          <CalendarSolc />
        </div>
      </Card>
      <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
        <Card
          title="Solicitudes"
          className="flex-1"
          bodyStyle={{ padding: 10 }}
          style={{ overflowX: 'auto' }}
        >
          <SolicitudesTable />
        </Card>
        <Card
          title="Solicitudes del Día"
          className="flex-1"
          style={{ overflowX: 'auto' }}
        >
          <SolicitudesForDayTable />
        </Card>
      </div>
    </div>
  );
};

export default MantencionesCalendario;
