import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Divider, Form, Input, Upload } from 'antd';

import { API_PATH } from 'config';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const MaintanceProblemsForm = ({ Incidencias, form, initialDeleted }) => {
  // Convertir URLs de imágenes en el formato esperado por Upload

  const normFile = (e, fieldName) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const [deleted, setDeleted] = useState(initialDeleted || []);

  const hiddenItem = (name, hidden = true, remove) => {
    setDeleted({
      ...deleted,
      [name]: hidden,
    });
    const problemas = form.getFieldValue('problemas');
    let row = problemas[name];
    row.deleted = hidden;
    if (!row.descripcion) {
      row.descripcion = '';
    }

    problemas[name] = row;
    form.setFieldsValue({
      problemas: problemas,
    });
  };

  return (
    <Form.List name="problemas">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Form.Item required={false} key={key}>
              {deleted[name] ? (
                <Button
                  type="link"
                  block
                  onClick={() => hiddenItem(name, false)}
                >
                  Recuperar Problema {index + 1} eliminado
                </Button>
              ) : null}
              <Form.Item required={false} hidden={deleted[name]}>
                <Divider>Problema N° {index + 1}</Divider>
                <Form.Item {...restField} hidden name={[name, 'id']}>
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item {...restField} hidden name={[name, 'deleted']}>
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: deleted[name] ? false : true,
                      message: 'Debe ingresar una descripción del problema',
                    },
                  ]}
                  {...restField}
                  name={[name, 'descripcion']}
                  label="Descripción del problema"
                >
                  <Input.TextArea placeholder="Ingresar descripción del problema"></Input.TextArea>
                </Form.Item>
                <Form.Item
                  name={[name, 'image']}
                  {...restField}
                  label="Agregar Imagen"
                  valuePropName="fileList"
                  getValueFromEvent={(eventArgs) => normFile(eventArgs, name)}
                >
                  <Upload
                    name="image"
                    action={`${API_PATH}/upload/image/maintance_problems`}
                    listType="picture"
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>Subir imagen</Button>
                  </Upload>
                </Form.Item>
                <Form.Item className="w-full text-center mb-0">
                  <MinusCircleOutlined
                    className="my-2 text-danger text-lg"
                    onClick={() => hiddenItem(name, true, remove)}
                  />
                </Form.Item>
              </Form.Item>
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add({ id: uuidv4() })}
              block
              icon={<PlusOutlined />}
            >
              Añadir Item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default MaintanceProblemsForm;
