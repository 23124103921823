import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const CustomerInfo = ({ customerInfo, date, variablesMap, colors }) => {
  const styles = StyleSheet.create({
    container: {
      marginBottom: 10,
      position: 'relative',
    },
    header: {
      fontSize: 14,
      fontWeight: 'bold',
      color: colors.primary ?? '#4CAF50',
      marginBottom: 10,
    },
    name: {
      fontSize: 12,
      fontWeight: 'bold',
      color: '#333',
      marginBottom: 4,
    },
    info: {
      fontSize: 12,
      color: '#333',
      marginBottom: 2,
    },
    bold: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    email: {
      color: '#1a73e8',
    },
    date: {
      position: 'absolute',
      top: 0,
      right: 0,
      fontSize: 12,
      color: '#333',
    },
  });
  const getVariable = (key) => variablesMap[key] || '';
  const formattedDate = date
    ? new Date(date).toLocaleDateString()
    : new Date().toLocaleDateString();

  return (
    <View style={styles.container}>
      <Text style={styles.date}>Fecha: {formattedDate}</Text>
      {customerInfo?.map((key) => (
        <Text key={key} style={styles.info}>
          <Text style={styles.bold}>
            {key.replace('_cliente', '').replace(/_/g, ' ')}:
          </Text>{' '}
          {key === 'email_cliente' ? (
            <Text style={styles.email}>{getVariable(key)}</Text>
          ) : (
            getVariable(key)
          )}
        </Text>
      ))}
    </View>
  );
};

export default CustomerInfo;
