import React, { useEffect, useState } from 'react';
import { Routes, Route, HashRouter } from 'react-router-dom';
import whitAdminProvider from 'HOCs/withAdminProvider';
import {
  routesAdmin,
  routesAuth,
  routesMain,
  routesSpecialist,
} from './assets/routes/routes';
import useAuth from 'hooks/useAuth';
import whitSpecProvider from 'HOCs/whitSpecProvider';
import administradorRoutes from 'assets/routes/administrador.routes';
import whitAdministradorProvider from 'HOCs/whitAdministradorProvider';
import liftlogicRoutes from 'assets/routes/liftlogic.routes';
import whitTenantProvider from 'HOCs/whitTenantProvider';

const SecureRouter = () => {
  const { user, isLoading } = useAuth();
  const { type } = user || {};
  const [routes, setRoutes] = useState(null);
  useEffect(() => {
    if (!isLoading) {
      switch (type) {
        case 'ejecutivo':
          setRoutes(
            [...routesAdmin, ...routesAuth].map((route) => ({
              ...route,
              element: whitAdminProvider(route.element),
            }))
          );
          break;
        case 'administrador':
          setRoutes(
            [...administradorRoutes, ...routesAuth].map((route) => ({
              ...route,
              element: whitAdministradorProvider(route.element),
            }))
          );
          break;
        case 'especialista':
          setRoutes(
            [...routesSpecialist, ...routesAuth].map((route) => ({
              ...route,
              element: whitSpecProvider(route.element),
            }))
          );
          break;
        case 'liftlogic':
          setRoutes(
            [...liftlogicRoutes, ...routesAuth].map((route) => ({
              ...route,
              element: whitTenantProvider(route.element),
            }))
          );
          break;
        default:
          setRoutes([...routesMain, ...routesAuth]);
      }
    }
  }, [isLoading, type]);

  return (
    <HashRouter>
      {routes ? (
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={toString(index)}
              path={route.path}
              element={
                <route.layout>
                  <route.element />
                </route.layout>
              }
            />
          ))}
        </Routes>
      ) : null}
    </HashRouter>
  );
};

export default SecureRouter;
