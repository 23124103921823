import React from 'react';
import { Col, Form, Input } from 'antd';

const { TextArea } = Input;

const CollectionModule = ({ restField, name }) => (
  <>
    <Col xs={24}>
      <Form.Item
        {...restField}
        name={[name, 'titulo']}
        label="Título"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese el título',
          },
        ]}
      >
        <Input placeholder="Título (Opcional)" />
      </Form.Item>
    </Col>
    <Col xs={24}>
      <Form.Item
        {...restField}
        name={[name, 'description']}
        label="Contenido"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese el contenido',
          },
        ]}
      >
        <TextArea rows={4} placeholder="Descripción" />
      </Form.Item>
    </Col>
  </>
);

export default CollectionModule;
