import PropTypes from 'prop-types';

export default {
  bodyData: PropTypes.array.isRequired,
  headData: PropTypes.array.isRequired,
  renderBody: PropTypes.func.isRequired,
  renderHead: PropTypes.func.isRequired,
  renderExtraData: PropTypes.element,
};
// bodyData, headData, renderBody, renderHead, renderExtraData
