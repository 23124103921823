import React from 'react';
import { Form, Input } from 'antd';

const ClienteForm = () => {
  return (
    <>
      <Form.Item name="id" hidden={true}>
        <Input />
      </Form.Item>
      <div className="grid grid-cols-3 gap-6">
        <Form.Item name="name" label="Nombre/Razón social del cliente" className="col-span-2">
          <Input placeholder="Nombre/Razón social del cliente" />
        </Form.Item>

        <Form.Item name="rut" label="RUT del cliente" className="col-span-1">
          <Input placeholder="RUT del cliente" />
        </Form.Item>

        <Form.Item name="address" label="Dirección del cliente" className="col-span-2">
          <Input placeholder="Dirección del cliente" />
        </Form.Item>

        <Form.Item name="commune" label="Comuna del cliente" className="col-span-1">
          <Input placeholder="Comuna del cliente" />
        </Form.Item>

        <Form.Item name="contactName" label="Nombre de contacto del cliente" className="col-span-1">
          <Input placeholder="Nombre de contacto del cliente" />
        </Form.Item>

        <Form.Item name="phone" label="Teléfono del cliente" className="col-span-1">
          <Input placeholder="Teléfono del cliente" />
        </Form.Item>

        <Form.Item name="email" label="Correo electrónico del cliente" className="col-span-1">
          <Input placeholder="Correo electrónico del cliente" />
        </Form.Item>
      </div>
    </>
  );
};

export default ClienteForm;
