import React, { useEffect, useState } from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { useMediaQuery } from 'react-responsive';
import MantencionDocument from './MantencionDocument';
import { Button, Divider, Form, Input } from 'antd';
import { getAccessTokenApi } from 'api/auth';
import { getOrderConfigApi, getStaticMantencionData } from 'api/budget';
import useGenerateAndSendPDF from './useMantencionSend';
import useAuth from 'hooks/useAuth';

function MantencionDownload({ mantencion }) {
  const { Ascensores, mantencion: mantencionData } = mantencion;
  const { generateAndSendPDF, loading: isLoadingSendPDF } =
    useGenerateAndSendPDF();

  // Detect if the device is a mobile device
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { user } = useAuth();
  // console.log(user);

  const [config, setConfig] = useState({});
  const [staticData, setStaticData] = useState(null);
  const token = getAccessTokenApi();
  useEffect(() => {
    getStaticMantencionData(token).then((result) => {
      setStaticData(result.static);
      setConfig(result.tenant);
    });
  }, [token]);

  const onFinish = (values) => {
    generateAndSendPDF(mantencion, staticData, values.email, values.id);
  };
  // console.log(mantencionData, 'mantencionData');

  return (
    <div>
      {!isMobile && (
        <PDFViewer
          style={{
            width: '100%',
            height: '80vh',
          }}
        >
          <MantencionDocument
            staticData={staticData}
            Ascensores={Ascensores}
            mantencion={mantencionData}
            config={config}
            actividades={mantencion.actividades}
          />
        </PDFViewer>
      )}
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <PDFDownloadLink
          document={
            <MantencionDocument
              staticData={staticData}
              Ascensores={Ascensores}
              mantencion={mantencionData}
              actividades={mantencion.actividades}
              config={config}
            />
          }
          fileName={'Informe de mantención N°' + mantencionData.id}
        >
          {({ loading }) => (
            <Button type="primary" disabled={loading} block>
              {loading ? 'Generando documento...' : 'Descargar PDF'}
            </Button>
          )}
        </PDFDownloadLink>
      </div>
      {user.type === 'ejecutivo' && (
        <div>
          <Divider>Para enviar Correo</Divider>
          <Form
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              email: mantencionData?.Cliente?.email,
              id: mantencionData?.id,
            }}
          >
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email" required>
              <Input placeholder="Ingresar Email" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoadingSendPDF}
                block
              >
                Enviar Correo
              </Button>
            </Form.Item>
          </Form>
          {/* <Button type="primary" onClick={() => handleSendPDF(mantencionData)}>
          Enviar PDF
        </Button> */}
        </div>
      )}
    </div>
  );
}

export default MantencionDownload;
