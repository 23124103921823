import { Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  leyend: {
    fontSize: '10px',
    margin: '0 20px',
  },
  separation: {
    margin: '0px 20px',
  },
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '12px',
    textTransform: 'uppercase',
    marginBottom: '10px',
  },
});

function BudgetDataTransfer({ staticData = {} }) {
  return (
    <View wrap={false}>
      <Text style={styles.title}>5- Datos de transferencia:</Text>
      <Text style={styles.leyend}>{staticData?.bank}</Text>
      <Text style={styles.leyend}>{staticData?.account_type}</Text>
      <Text style={styles.leyend}>N° {staticData?.account_number}</Text>
      <Text style={styles.leyend}>{staticData?.account_holder}</Text>
      <Text style={styles.leyend}>{staticData?.rut}</Text>
      <Text style={styles.leyend}>{staticData?.email}</Text>
      <View style={styles.separation} />
      <Text style={styles.leyend}>{staticData?.extra}</Text>
    </View>
  );
}

export default BudgetDataTransfer;
