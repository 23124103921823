import React from 'react';
import { Col, Form, Input } from 'antd';

const { TextArea } = Input;

const CustomModule = ({ restField, name }) => (
  <>
    <Col xs={24}>
      <Form.Item {...restField} name={[name, 'titulo']} label="Título">
        <Input placeholder="Título (Opcional)" />
      </Form.Item>
    </Col>
    <Col xs={24}>
      <Form.Item {...restField} name={[name, 'description']} label="Contenido">
        <TextArea rows={4} placeholder="Descripción" />
      </Form.Item>
    </Col>
  </>
);

export default CustomModule;
