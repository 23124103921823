import React from 'react';
import { View } from '@react-pdf/renderer';
import P from '../Text/P';

const SpecialistBlock = (props) => {
  const { specialist } = props;
  const { cargo, especialidad, otros, name } = specialist;
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <P>{name}</P>
      {cargo ? <P>{cargo}</P> : null}
      {especialidad ? <P>{especialidad}</P> : null}
      {otros ? <P>{otros}</P> : null}
    </View>
  );
};

export default SpecialistBlock;
