import {
  InfoCircleOutlined,
  PlusOutlined,
  SettingOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import MaintancePDFContainer from 'Containers/MaintancePDFContainer';
import { Button, Card, Modal, Popover } from 'antd';
import { useCallGetApi } from 'api/useCallApi';
import EliminarForm from 'components/forms/EliminarForm';
import {
  deleteMantencionProg,
  generarmantencionesProg,
} from 'components/forms/MantencionProgForm';
// import MaintanceList from 'components/lists/MaintanceList';
import React, { useEffect, useState } from 'react';

const ContentPopover = (
  <div>
    <h4 className="text-lg font-semibold mb-2">
      Acerca de las Mantenciones Programadas:
    </h4>
    <p>
      En esta sección, se muestran las mantenciones programadas que han sido
      generadas de dos formas distintas:
    </p>
    <ol className="list-decimal list-inside">
      <li>
        Mantenciones Programadas Automáticamente: Estas mantenciones son
        verificadas y programadas de manera automática cada semana.
      </li>
      <li>
        Mantenciones Programadas Manualmente: Estas mantenciones han sido
        programadas de forma manual.
      </li>
    </ol>
    <p>
      Recomendamos revisar periódicamente esta sección para estar al tanto de
      las próximas mantenciones.
    </p>
  </div>
);

const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};
const Maintance = () => {
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [reports, callReports, statusReports, errorReports] = useCallGetApi(
    '/mantenciones/all/programadas',
    {
      defaultValue: [],
    }
  );
  useEffect(() => {
    callReports();
  }, [callReports]);

  const finish = () => {
    callReports();
    setModalProps(defaultModalProps);
  };
  const openDelete = async (id) => {
    setModalProps({
      open: true,
      children: (
        <EliminarForm
          title="Eliminar Actividad"
          id={id}
          deleteRegister={() => deleteMantencionProg(id, finish)}
        />
      ),
    });
  };
  const openMaintanceDowload = (id) => {
    setModalProps({
      open: true,
      children: <MaintancePDFContainer id={id} />,
      title: `Descargar Informe ${id}`,
    });
  };
  const syncMantAuto = async () => {
    await generarmantencionesProg(finish);
  };
  return (
    <div className="p-4 bg-gray-100">
      <Card className="mb-4 shadow-md">
        <div className="flex flex-row justify-between items-center">
          <div className="flex items-center">
            <SettingOutlined className="text-xl mr-2" />
            <h3 className="uppercase text-lg font-semibold">
              Mantenciones Programadas
            </h3>
            <Popover content={ContentPopover} trigger="click">
              <InfoCircleOutlined className="text-xl ml-2 cursor-pointer" />
            </Popover>
          </div>
          <div>
            <Button icon={<SyncOutlined />} onClick={syncMantAuto}>
              Sincronizar
            </Button>
          </div>
        </div>
      </Card>
      {/* <MaintanceList
        reports={reports}
        openDelete={openDelete}
        openDowload={openMaintanceDowload}
      /> */}
      <Modal
        {...modalProps}
        footer={false}
        onCancel={() => setModalProps(defaultModalProps)}
      />
    </div>
  );
};

export default Maintance;
