import { Text, View } from '@react-pdf/renderer';
import { bool, string } from 'prop-types';
import React from 'react';
import NormalStyle from './Normal.style';

function Normal(props) {
  const { children, indent } = props;
  const extraStyles = {};
  if (indent) {
    extraStyles.textIndent = '4pt';
  }
  return (
    <View style={{ ...NormalStyle.container, ...extraStyles }}>
      <Text style={NormalStyle.normal}>{children}</Text>
    </View>
  );
}
Normal.propTypes = {
  children: string.isRequired,
  indent: bool,
};
Normal.defaultProps = {
  indent: false,
};

export default Normal;
