/* eslint-disable no-unused-vars */
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import VoucherData from '../VoucherData/VoucherData';
import VoucherFooter from '../VoucherFooter/VoucherFooter';
import VoucherHeader from '../VoucherHeader/VoucherHeader';
import VoucherImages from '../VoucherImages/VoucherImages';
import VoucherObservation from '../VoucherObservation/VoucherObservation';
import VoucherSignatures from '../VoucherSignatures';
import { getImagesVoucher } from '../../../api/attentionVoucher';
import PageA4 from '../../Elements/Pages/PageA4';
// import Header from '../../Header/Header';
import Title1 from '../../Elements/Text/Title1';
import Normal from '../../Elements/Text/Normal';
import Container from '../../Elements/Some/Container';
import Title2 from '../../Elements/Text/Title2';
import Image from '../../Elements/Some/Image';
import { API_PATH, PATH_GET_FILES } from '../../../config';
import Footer from '../../Footer/Footer';
import SpecialistBlock from 'PDF/Elements/Some/SpecialistBlock';
import RecSpeSignatures from 'PDF/Elements/Some/RecSpeSignatures';
import { getAccessTokenApi } from 'api/auth';
import { getOrderConfigApi, getStaticVoucherData } from 'api/budget';
import Title from 'PDF/Constructor/Title';
import { usePDFStyle } from 'providers/PDF/PDFStyleProvider';
import Header from 'components/PDF2/Headers/Header';
import FinishDoc from 'PDF/Constructor/FinishDoc';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    marginHorizontal: '1.27cm',
  },
  footer: {
    marginHorizontal: '1.27cm',
  },
  body: {
    marginTop: 90,
    marginHorizontal: '1.27cm',
  },
  titleContainer: {
    fontSize: '16',
    fontFamily: 'Times-Bold',
    color: 'white',
    backgroundColor: '#17365D',
    width: '270px',
    paddingVertical: '10px',
    marginLeft: '15px',
    textAlign: 'center',
  },
  dataContainer: {
    marginTop: 0,
  },
  observationsContainer: {
    marginTop: 15,
    height: '150px',
    borderBottom: '1px',
  },
  imagesContainer: {
    marginTop: 5,
    height: '150px',
  },
  signaturesContainer: {
    marginTop: 15,
  },
});

function VoucherDocument(props) {
  const { voucher } = props;
  const [images, setImages] = useState([]);

  const [config, setConfig] = useState({});
  const token = getAccessTokenApi();
  useEffect(() => {
    getStaticVoucherData(token).then((result) => {
      setConfig(result?.tenant);
    });
  }, [token]);

  useEffect(() => {
    getImagesVoucher(voucher.id).then((result) => {
      setImages(result.images);
    });
  }, [voucher.id]);
  const { content, text } = usePDFStyle();
  console.log(voucher, config);
  return (
    <Document>
      <Page size={'A4'}>
        <View
          style={{
            height: '3cm',
          }}
          fixed
        >
          <Header
            config={config}
            company={{
              name: config?.company,
              rut: config?.rut,
              phone: config?.telefono,
            }}
            document={{
              id: voucher?.id,
            }}
          />
        </View>
        <View style={content}>
          <Title title="Comprobante de Atención" />
          {/* Datos del cliente y comprobante */}
          <View
            style={{
              marginTop: 5,
            }}
          >
            <View>
              <VoucherData
                recepcionist_name={voucher?.recepcionist_name}
                client_name={voucher?.client_name}
                reason={voucher?.reason}
                attention_init={voucher?.attention_init}
                attention_finish={voucher?.attention_finish}
                client_rut={voucher?.client_rut}
                equipment={voucher?.equipment}
                specialist_name={voucher?.specialist_name}
                date={voucher?.date}
                finish_state={voucher?.finish_state}
              />
            </View>
          </View>
          <Title title="Observaciones" />
          {/* Datos del cliente y comprobante */}
          <View
            style={{
              marginTop: 5,
            }}
          >
            <Text
              style={[
                text,
                {
                  textAlign: 'justify',
                },
              ]}
            >
              {voucher?.observations ?? 'SIN OBSERVACIONES'}
            </Text>
          </View>

          {/* Imágenes adjuntas */}
          {images?.length > 0 ? (
            <View>
              <Title title="Imágenes Adjuntas" />
              <Container wrap>
                {images?.map(({ image }) => (
                  <Image
                    key={image}
                    source={`${PATH_GET_FILES}/image/${image}`}
                  />
                ))}
              </Container>
            </View>
          ) : null}

          <View
            wrap={false}
            style={{
              marginTop: 5,
            }}
          >
            <Title title="Información de Entrega" />
            <View
              style={{
                marginTop: 5,
              }}
            >
              <Text style={text}>
                A continuación, se encuentra la firma, la información del
                especialista y de quien recibe la atención. Esto, cuenta como
                una constancia firmada de que se realizó la atención.
              </Text>
            </View>
            <RecSpeSignatures
              specialistSignature={`${API_PATH}/public/signature/${voucher.specialist_signature}`}
              recepcionistName={voucher.recepcionist_name}
              recepcionistSignature={`${API_PATH}/public/signature/${voucher.recepcionist_signature}`}
              recepcionistCharge={voucher.recepcionist_charge}
              specialistName={voucher.specialist_name}
              // specialistEspecialidad={voucher.specialist_especialidad}
              // specialistCargo={voucher.specialist_cargo}
              // specialistOtros={voucher.specialist_otros}
            />
          </View>
          <FinishDoc
            titular={voucher?.specialist_name}
            titularTitulo={
              voucher?.specialist_especialidad ?? 'Técnico en Ascensores'
            }
            titularCargo={voucher?.specialist_cargo ?? 'Especialista'}
            tituloSegundario={config?.correo}
            web={config?.web ?? 'david.silva@liftlogic.cl'}
            telefonos={config?.telefonos ?? []}
          />
        </View>
      </Page>
    </Document>
  );
}
const voucherProps = {
  attention_finish: PropTypes.string.isRequired,
  attention_init: PropTypes.string.isRequired,
  client_name: PropTypes.string.isRequired,
  client_rut: PropTypes.string.isRequired,
  finish_state: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  id_client: PropTypes.number.isRequired,
  observations: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  recepcionist_name: PropTypes.string.isRequired,
  recepcionist_signature: PropTypes.string.isRequired,
  specialist_rut: PropTypes.string.isRequired,
  specialist_signature: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
const userProps = {
  fullname: PropTypes.string.isRequired,
};
VoucherDocument.propTypes = {
  voucher: PropTypes.shape(voucherProps).isRequired,
  user: PropTypes.shape(userProps).isRequired,
};

export default VoucherDocument;
