import { useState } from 'react';

/**
 * @typedef {Object} ModalHook
 * @property {Function} handleOpen - Cambia el estado de open del modal
 * @property {Function} handleElement - Cambia el Elemento actual del modal
 * @property {Function} handleAtributes - Cambia los atributos del modal como el title o el footer
 * @property {React.Element} getElement - Retorna el elemento actual del modal
 * @property {Object} atributes - Retorna los atributos actuales del modal
 * @property {Object} props - Retorna los props actuales
 * @property {Bool} open - Retorna si el modal se muestra o no
 */

/**
 *
 * @param {Function} modalCases - Función que debe indicar qué componente se devuelve a partir del modalElement. Elemento especial del hook personalizado
 * @returns {ModalHook} - Hook personalizado para manejar el modal
 */

const useModal = (modalCases) => {
  // Estado para la apertura o cierrte del estado

  const [open, setOpen] = useState(false);
  const [modalElement, setModalElement] = useState(null);
  const [props, setProps] = useState(null);
  const [atributes, setAtributes] = useState({
    title: '',
    footer: false,
  });

  /**
   *
   * @param {Object} atributes - Objeto con los atributos a modificar
   * @param {Text} atributes.title - modificación del titulo
   * @param {Text} atributes.footer - modificación del footer
   */

  const handleAtributes = (_atributes) => {
    setAtributes({ ...atributes, ..._atributes });
  };

  /**
   *
   * @param {bool} _open - Indica si se debe cerrar o abrir el modal. Por defecto, hará lo contrario a lo que estaba haciendo antes
   */

  const handleOpen = (_open = !open) => {
    setOpen(_open);
  };

  /**
   *
   * @param {Text} element - Indicar qué elemento deberá devolver según lo que se haya puesto en el modalCases
   * @param {Object} props - Props que tendrá el nuevo elemento. Por defecto será null
   */

  const handleElement = (element, _props = null) => {
    setModalElement(element);
    setProps(_props);
  };

  /**
   *
   * @returns Retorna Element indicado en el modalCases según el element almacenado en el estado
   */

  const getElement = () => modalCases(modalElement, props);

  /**
   * Hook de retorno que devuelve handleOpen, handleElement, getElement.
   */
  const modal = {
    handleOpen,
    handleElement,
    getElement,
    handleAtributes,
    atributes,
    open,
    props,
  };

  return modal;
};

export default useModal;
