/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { Suspense, useMemo } from 'react';
import { Button, Modal, Space, Table } from 'antd';
import PropTypes, { bool, func, shape } from 'prop-types';
import BudgetBadge from '../BudgetBadge/BudgetBadge';
import BudgetDropdownButton from '../BudgetDropdownButton/BudgetDropdownButton';

function BudgetTable(props) {
  const {
    budgets,
    refreshBudgets,
    modalOpen,
    handleOpenModal,
    ModalComponent,
    modalOptions,
    modalProps,
    modalAtributes,
  } = props;

  const refreshData = () => {
    refreshBudgets(true);
  };
  const editBudget = (record) => {
    modalOptions.setModalToEditForm(record);
    handleOpenModal(true);
  };
  const openOrderForm = (record) => {
    modalOptions.setModalToODTForm(record);
    handleOpenModal(true);
  };
  const openStateForm = (record) => {
    modalOptions.setModalToODTSatus(record);
    handleOpenModal(true);
  };
  const openDowloadOrder = (record) => {
    modalOptions.setModalToODTDowload(record.idBudget);
    handleOpenModal(true);
  };
  const closeModal = () => {
    handleOpenModal(false);
  };
  const { clientNames, status } = useMemo(() => {
    // Inicializa el objeto con arrays vacíos para los nombres y estados.
    const initial = { clientNames: [], status: [] };

    const result = budgets.reduce((acc, budget) => {
      // Verifica si el nombre ya existe en el acumulador.
      if (!acc.clientNames.some((item) => item.value === budget.name)) {
        acc.clientNames.push({ value: budget.name, text: budget.name });
      }

      // Verifica si el estado ya existe en el acumulador.
      if (!acc.status.some((item) => item.value === budget.status)) {
        acc.status.push({
          text: budget.statusDescription,
          value: budget.status,
        });
      }

      return acc;
    }, initial);

    // Devuelve los objetos formateados.
    return {
      clientNames: result.clientNames,
      status: result.status,
    };
  }, [budgets]);

  return (
    <>
      <Table
        dataSource={budgets}
        columns={[
          {
            title: 'Estado',
            key: 'status',
            width: '10%',
            filters: status,
            onFilter: (value, record) => record.status.includes(value),
            render: (record) => (
              <p>
                <BudgetBadge
                  description={record.statusDescription}
                  color={record.statusColor}
                />
              </p>
            ),
          },
          {
            title: 'ID',
            dataIndex: 'idBudget',
            key: 'idBudget',
            width: '6%',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.idBudget - b.idBudget,
            showSorterTooltip: false,
          },
          {
            title: 'Cliente',
            dataIndex: 'name',
            filters: clientNames,
            onFilter: (value, record) => record.name.includes(value),
            key: 'name',
          },
          {
            title: 'Presupuesto',
            dataIndex: 'reason',
            key: 'reason',
          },
          {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
          },
          {
            title: 'Comuna',
            dataIndex: 'commune',
            key: 'commune',
          },
          {
            title: 'Correo',
            dataIndex: 'email',
            key: 'email',
          },
          {
            title: 'Acciones',
            key: 'action',
            render: (record) => (
              <Space>
                {record.status === 'odt_no_asignada' ? (
                  <Button type="primary" onClick={() => openOrderForm(record)}>
                    ODT
                  </Button>
                ) : null}
                {record.status === 'odt_en_proceso' ? (
                  <Button type="primary" onClick={() => openStateForm(record)}>
                    ODT
                  </Button>
                ) : null}
                {record.status === 'odt_asignada' ? (
                  <Button type="primary" onClick={() => openStateForm(record)}>
                    ODT
                  </Button>
                ) : null}
                {record.status === 'odt_finalizada' ? (
                  <Button
                    type="primary"
                    onClick={() => openDowloadOrder(record)}
                  >
                    ODT
                  </Button>
                ) : null}
                <BudgetDropdownButton
                  budget={record}
                  refreshData={refreshData}
                  editBudget={editBudget}
                />
              </Space>
            ),
          },
        ]}
        rowKey="idBudget"
        scroll={{ x: 1500 }}
      />
      <Modal
        open={modalOpen}
        onCancel={closeModal}
        footer={modalAtributes.footer}
        title={modalAtributes.title}
        width={1000}
      >
        <Suspense fallback={<div>Cargando...</div>}>
          {ModalComponent && <ModalComponent {...modalProps} />}
        </Suspense>
      </Modal>
    </>
  );
}
const budgetProps = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  commune: PropTypes.string.isRequired,
  email: PropTypes.string,
  reason: PropTypes.string.isRequired,
  guarantee: PropTypes.string.isRequired,
  include: PropTypes.string.isRequired,
  conditions: PropTypes.string.isRequired,
  createdOn: PropTypes.string,
  description: PropTypes.string.isRequired,
  finishTime: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  discount: PropTypes.number,
};

BudgetTable.propTypes = {
  budgets: PropTypes.arrayOf(PropTypes.shape(budgetProps)),
  refreshBudgets: PropTypes.func.isRequired,
  modalOpen: bool.isRequired,
  handleOpenModal: func.isRequired,
  ModalComponent: shape({}),
  modalOptions: shape({}).isRequired,
  modalProps: shape({}),
  modalAtributes: shape({}).isRequired,
};

BudgetTable.defaultProps = {
  budgets: [],
  ModalComponent: {},
  modalProps: {},
};
export default BudgetTable;
