import { getAccessTokenApi } from 'api/auth';
import axios from 'axios';
import { API_PATH } from 'config';
import { useCallback, useState } from 'react';
import notificationApi from 'utils/notificationApi';
const defaultValue = {
  state: null,
  data: null,
  message: null,
};

export const useGetToApi = (
  url,
  { config = {}, initialValues = defaultValue }
) => {
  const [payload, setPayload] = useState(initialValues);

  // Reload indica si se debe volver a pedir los datos a la API
  const [isLoading, setIsLoading] = useState(false);

  const token = getAccessTokenApi();

  const call = useCallback(async () => {
    setIsLoading(true);
    axios
      .get(`${API_PATH}${url}`, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        params: config.params || null,
      })
      .then((response) => {
        return response.data;
      })
      .then((result) => {
        notificationApi(result, false);
        setPayload(result);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        return {
          status: 'client',
          codeMessage: 'ERROR_EN_LA_PETICION',
          message: err.message,
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, token]);

  return [payload, isLoading, call];
};
