import { StyleSheet } from '@react-pdf/renderer';
import { fontFamilyBold, titleFontSize } from 'components/PDF2/assets';

export default StyleSheet.create({
  title1: {
    fontSize: titleFontSize,
    fontFamily: fontFamilyBold,
    textTransform: 'uppercase',
  },
});
