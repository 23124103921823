import { useAppApi, usePAppApi, useUAppApi } from 'hooks/useAppApi';
import React, { createContext, useCallback, useContext } from 'react';
import { debounce } from 'lodash';
// Crear el contexto
const ClientesActionsContext = createContext(null);

// Componente proveedor del contexto
export const ClientesActionsProvider = ({ children }) => {
  const { call: update, isLoading: loadingUpdate } = useUAppApi({
    url: '/clientes',
    mapResults: useCallback((data) => {
      console.log(data);
      return data;
    }, []),
  });

  const toggleStatus = async (id, active) => {
    await update(
      {
        active: !active,
      },
      `/${id}`
    );
  };

  // Utiliza debounce para evitar actualizaciones repetidas sin sentido
  const toggleAutoPreven = useCallback(
    debounce(async (id, active) => {
      await update({ preventiva: active }, `/${id}/preventiva-automatizada`);
    }, 500),
    []
  );
  return (
    <ClientesActionsContext.Provider
      value={{
        toggleStatus,
        loadingUpdate,
        toggleAutoPreven,
      }}
    >
      {children}
    </ClientesActionsContext.Provider>
  );
};

export const useClientesActions = () => useContext(ClientesActionsContext);
