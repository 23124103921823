import { useAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext } from 'react';

const ObservacionesContext = createContext(null);

export const ObservacionesProvider = ({ children }) => {
  const map = useCallback((result) => {
    return result;
  }, []);

  const { isLoading, call, data, error, hasCalled } = useAppApi({
    baseUrl: '/clientes/observations',
    mapResults: map,
  });

  const {
    isLoadingForClient,
    call: callForClientNF,
    data: dataForClient,
    error: errorForClient,
    hasCalled: hasCalledForClient,
  } = useAppApi({
    baseUrl: '/clientes',
    mapResults: map,
  });

  const callForClient = useCallback(
    (id) => {
      return callForClientNF({ extUrl: `/${id}/observations/` });
    },
    [callForClientNF]
  );

  return (
    <ObservacionesContext.Provider
      value={{
        callForClient,
        call,
        dataForClient,
        errorForClient,
        hasCalledForClient,
        isLoadingForClient,
        data,
        error,
        hasCalled,
        isLoading,
      }}
    >
      {children}
    </ObservacionesContext.Provider>
  );
};

export const useObservaciones = () => useContext(ObservacionesContext);
