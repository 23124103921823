/* eslint-disable react/forbid-prop-types */
import { Page } from '@react-pdf/renderer';
import { any, bool, shape } from 'prop-types';
import React from 'react';
import PageA4Style from './PageA4.style';

function PageA4(props) {
  const { children, debug, style } = props;
  const perStyle = {
    ...style,
  };

  return (
    <Page
      size="A4"
      style={{ ...PageA4Style.container, ...perStyle }}
      debug={debug}
    >
      {children}
    </Page>
  );
}

PageA4.propTypes = {
  children: any.isRequired,
  debug: bool,
  style: shape({}),
};
PageA4.defaultProps = {
  debug: false,
  style: {},
};

export default PageA4;
