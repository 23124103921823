// import BudgetDowload from 'PDF/Presupuesto/BudgetDowload/BudgetDowload';
import { notification } from 'antd';
import MaintancePDFContainer from 'components/specialist/MaintanceReports/FormMaintanceReports/TableMaintanceReports/MaintancePDFContainer';
import { createContext, useContext } from 'react';

const ActionsMantencionesDepContext = createContext(null);

export const ActionsMantencionesDepProvider = ({ children }) => {
  const dowload = async (id) => {
    notification.open({
      duration: 30,
      message: `Descargar Informe de Mantención N°${id}`,
      btn: (
        <MaintancePDFContainer
          itemData={{
            id_maintance_report: id,
          }}
        />
      ),
    });
  };

  return (
    <ActionsMantencionesDepContext.Provider
      value={{
        dowload,
      }}
    >
      {children}
    </ActionsMantencionesDepContext.Provider>
  );
};

export const useActionsMantencionesDep = () =>
  useContext(ActionsMantencionesDepContext);
