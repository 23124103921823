import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Modal } from 'antd';
import { STATUS_ERROR, STATUS_LOADING, useCallGetApi } from 'api/useCallApi';
import ErrorComponent from 'components/ErrorComponent';
import EliminarForm from 'components/forms/EliminarForm';
import MaintanceAsignationForm, {
  addAsignation,
  deleteAsignation,
  editAsignation,
  switchActiveAsignation,
} from 'components/forms/MaintanceAsignationForm';
import MaintanceAsignationsList from 'components/lists/MaintanceAsignationsList';
import React, { useEffect, useState } from 'react';

const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};

const Asignations = () => {
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [actividades, callActividades, statusActividades, errorActividades] =
    useCallGetApi('/actividad/all', {
      defaultValue: [],
    });

  useEffect(() => {
    callActividades();
  }, [callActividades]);

  const [
    asignaciones,
    callAsignaciones,
    statusAsignaciones,
    errorAsignaciones,
  ] = useCallGetApi('/programacion-automatica/all', {
    defaultValue: [],
  });

  useEffect(() => {
    callAsignaciones();
  }, [callAsignaciones]);

  const finish = () => {
    callAsignaciones();
    setModalProps(defaultModalProps);
  };

  const openDelete = async (data) => {
    setModalProps({
      open: true,
      children: (
        <EliminarForm
          title="Eliminar Actividad"
          id={data.id}
          deleteRegister={(id) => deleteAsignation(id, finish)}
        />
      ),
    });
  };

  const openEdit = async ({ AscProgs, Cliente, ...data }) => {
    const initialAscensores = {};
    if (AscProgs.length > 0) {
      AscProgs?.forEach(({ Ascensor, id }) => {
        initialAscensores[Ascensor.id] = true;
      });
    }
    const initialValues = {
      ...data,
      id_cliente: Cliente.id,
      ascensores: initialAscensores,
    };
    setModalProps({
      open: true,
      children: (
        <MaintanceAsignationForm
          buttonLabel="Editar"
          activities={actividades}
          submitForm={(values) =>
            editAsignation(
              {
                ...values,
                id_cliente: Cliente.id,
              },
              data.id,
              finish
            )
          }
          initialClient={Cliente.id}
          formProps={{
            initialValues,
          }}
          isEdit={true}
        />
      ),
      title: `Editar Asignación de ${Cliente.name}`,
      width: 1000,
    });
  };

  const addAsignationSubmit = (values) => {
    if (values.ascensores) {
      values.ascensores = Object.entries(values.ascensores);
    }
    return addAsignation(
      {
        ...values,
        activo: true,
      },
      finish
    );
  };
  const openAdd = () => {
    setModalProps({
      open: true,
      children: (
        <MaintanceAsignationForm
          buttonLabel="Agregar"
          submitForm={(values) => addAsignationSubmit(values)}
          activities={actividades}
        />
      ),
      title: 'Nueva asignación',
      width: 1000,
    });
  };
  const switchActive = (data) => {
    switchActiveAsignation(data.id, finish);
  };

  if (statusAsignaciones === STATUS_ERROR) {
    return <ErrorComponent message={errorAsignaciones.message} />;
  }
  if (statusActividades === STATUS_ERROR) {
    return <ErrorComponent message={errorActividades.message} />;
  }

  return (
    <div className="p-4 bg-gray-100">
      <Card className="mb-4 shadow-md">
        <div className="flex flex-row justify-between items-center">
          <div className="flex items-center">
            <SettingOutlined className="text-xl mr-2" />
            <h3 className="uppercase text-lg font-semibold">
              Asignaciones{' '}
              <span className="hidden md:inline-block">autoprogramadas</span>
            </h3>
          </div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="ml-4"
            onClick={openAdd}
          >
            Agregar
          </Button>
        </div>
      </Card>
      <MaintanceAsignationsList
        isLoading={statusAsignaciones === STATUS_LOADING}
        dataSource={asignaciones}
        openDelete={openDelete}
        openEdit={openEdit}
        switchActive={switchActive}
      />
      <Modal
        {...modalProps}
        footer={false}
        onCancel={() => setModalProps(defaultModalProps)}
      />
    </div>
  );
};

export default Asignations;
