import React from 'react';
import PresupuestoItemsTable from 'components/tables/PresupuestoItemsTable';

const ItemTableModule = ({ form, name, restField }) => (
  <PresupuestoItemsTable
    items={form.getFieldValue(['items', name, 'itemsTable']) || []}
    name={name}
    restField={restField}
  />
);

export default ItemTableModule;
