import { useAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext } from 'react';

const OneMantencionContext = createContext(null);

export const OneMantencionProvider = ({ children }) => {
  const map = useCallback((result) => {
    return result;
  }, []);

  const {
    data,
    isLoading,
    error,
    hasCalled,
    call: notFunctionalCall,
  } = useAppApi({
    baseUrl: '/mantenciones',
    mapResults: map,
  });

  const call = async (idMantencion) => {
    return notFunctionalCall({
      extUrl: `/${idMantencion}`,
    });
  };
  return (
    <OneMantencionContext.Provider
      value={{
        call,
        isLoading,
        error,
        hasCalled,
        data,
        // activeData: data?.filter((element) => element.active === true),
      }}
    >
      {children}
    </OneMantencionContext.Provider>
  );
};

export const useOneMantencion = () => useContext(OneMantencionContext);
