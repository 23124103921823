import { Text, View } from '@react-pdf/renderer';
import { string } from 'prop-types';
import React from 'react';

function Title1(props) {
  const { children, margin } = props;

  return (
    <View style={{ margin: margin ? margin : '12pt 0pt' }}>
      <Text
        style={{
          fontSize: '16pt',
          fontFamily: 'Times-Bold',
          textTransform: 'uppercase',
        }}
      >
        {children}
      </Text>
    </View>
  );
}
Title1.propTypes = {
  children: string.isRequired,
};

export default Title1;
