import { Button, Form, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useForm } from 'antd/lib/form/Form';
import { arrayOf, func, shape } from 'prop-types';
import React from 'react';
import { usePostApi } from '../../../../hooks/useApi';

function AddMaintanceItemForm(props) {
  const { reload, categorys } = props;
  const [form] = useForm();
  const [post] = usePostApi({
    url: `maintance-report/item`,
  });
  const submitAddItemForm = async () => {
    const fieldsValues = form.getFieldsValue();
    const response = await post(fieldsValues);
    if (response.state === 'success') {
      reload();
      form.resetFields();
    }
  };
  return (
    <Form form={form} onFinish={submitAddItemForm} layout="horizontal">
      <Form.Item
        name="id_category"
        rules={[
          {
            required: true,
            message: 'Seleccione la categoría',
          },
        ]}
        label="Categoría"
      >
        <Select placeholder="Seleccione la categoría">
          {categorys?.map((item) => (
            <Select.Option key={item.id}>{item.category}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Descripción"
        name="description"
        rules={[
          {
            required: true,
            message: 'La descripción es obligatoria',
          },
        ]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">Agregar</Button>
      </Form.Item>
    </Form>
  );
}

AddMaintanceItemForm.propTypes = {
  reload: func.isRequired,
  categorys: arrayOf(shape({})).isRequired,
};

export default AddMaintanceItemForm;
