import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  bodyPresupuesto: {
    // marginHorizontal: '20px',
  },
  img: {
    maxWidth: '150px',
    margin: '10px',
  },
  horizontalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxHeight: '200px',
  },
  page: {
    paddingBottom: 60,
    paddingTop: 100,
  },
  item: {
    marginBottom: '10px',
    padding: '0px 20px',
  },
  p: {
    textIndent: '40px',
  },
  bye: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textStrong: {
    fontSize: '12px',
    fontFamily: 'Helvetica-Bold',
  },
  subText: {
    fontSize: '10px',
    color: '#7D7D7C',
  },
  textLogo: {
    fontSize: '12px',
    color: '#2A2D8C',
  },
});
