import { Text } from '@react-pdf/renderer';
import { fontBold } from 'PDF/keys';
import React from 'react';

const P = (props) => {
  const { children, negrita, style } = props;

  return (
    <Text
      style={{
        fontSize: '12pt',
        fontFamily: negrita ? fontBold : 'Times-Roman',
        textAlign: 'justify',
        maxWidth: '100%',
        ...style,
      }}
    >
      {children}
    </Text>
  );
};

export default P;
