import React, { useState } from 'react';
import { Form, Input } from 'antd';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

const mdParser = new MarkdownIt();

const MarkdownModule = ({ restField, name, label, form }) => {
  return (
    <>
      <Form.Item {...restField} label={label} name={[name, 'description']} className="w-full">
        <Module />
      </Form.Item>
    </>
  );
};

const Module = ({ onChange, value }) => {
  const [markdownText, setMarkdownText] = useState(value);
  const handleEditorChange = ({ text }) => {
    setMarkdownText(text);
    onChange(text);
  };
  return (
    <MdEditor
      value={markdownText}
      renderHTML={(text) => mdParser.render(text)}
      onChange={handleEditorChange}
      className="w-full min-h-[500px]"
      view={{
        menu: false,
      }}
      canView={{
        menu: false,

      }}
    />
  );
};

export default MarkdownModule;
