import { useAppApi } from 'hooks/useAppApi';
import React, { createContext, useCallback, useContext } from 'react';

// Crear el contexto
const UserTenantContext = createContext(null);

// Componente proveedor del contexto
export const UserTenantProvider = ({ children }) => {
  const {
    call: notFunctionalCall,
    isLoading,
    data,
    hasCalled,
  } = useAppApi({
    baseUrl: '/user/all/tenants',
    mapResults: useCallback((data) => {
      console.log(data);
      return data;
    }, []),
  });
  const call = useCallback(
    async (id_tenant) => {
      const result = await notFunctionalCall({
        extUrl: `/${id_tenant}`,
      });
      console.log(result);
      return result;
    },
    [notFunctionalCall]
  );
  return (
    <UserTenantContext.Provider
      value={{
        call,
        isLoading,
        data,
        hasCalled,
      }}
    >
      {children}
    </UserTenantContext.Provider>
  );
};

export const useUserTenant = () => useContext(UserTenantContext);
