import { Button } from 'antd';
import CertificationList from 'components/applications/CertificationList';
import { useApi } from 'hooks/useApi';
import React from 'react';
import { Link } from 'react-router-dom';

const Certification = () => {
  const [certificaciones] = useApi({
    url: 'asimet/certificaciones',
    key: 'values',
    successEnabled: false,
    defaultValue: [],
  });
  return (
    <div>
      <div className="page-header">
        <h3 className="font-w-text font-xl">Certificaciones ASIMET</h3>
        <Link to="nueva">
          <Button type="primary">Nueva Certificación</Button>
        </Link>
      </div>
      <div className="mt-5">
        <CertificationList certificaciones={certificaciones} />
      </div>
    </div>
  );
};

export default Certification;
