import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { deleteToApi, postToApi, putToApi } from 'api/axios';
import React, { useEffect } from 'react';
import notificationApi from 'utils/notificationApi';

export const deleteAscensor = async (id, isFinish) => {
  const response = await deleteToApi(`/ascensor/eliminar/${id}`);
  if (notificationApi(response.data || response)) {
    isFinish();
    return {
      resetFields: true,
    };
  }
  return {
    resetFields: false,
  };
};

export const addAscensor = async (values, id, isFinish) => {
  const response = await postToApi('/ascensor/add', {
    ...values,
    id_cliente: id,
  });
  if (notificationApi(response.data || response)) {
    isFinish();
    return {
      resetFields: true,
    };
  }
  return {
    resetFields: false,
  };
};

export const editAscensor = async (values, id, isFinish) => {
  const response = await putToApi(`/ascensor/update/${id}`, values);
  if (notificationApi(response.data || response)) {
    isFinish();
    return {
      resetFields: true,
    };
  }
  return {
    resetFields: false,
  };
};

const AscensorForm = (props) => {
  const { submitForm, buttonLabel, formProps } = props;

  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [formProps, form]);

  const finishForm = async () => {
    const values = form.getFieldsValue();
    const response = await submitForm(values, formProps);
    if (response.resetFields) {
      form.resetFields();
    }
  };

  return (
    <Form {...formProps} form={form} onFinish={finishForm} layout="vertical">
      <Form.Item
        name="nombre"
        label="Nombre del ascensor"
        rules={[
          {
            required: true,
            message: 'La nombre es obligatorio',
          },
          {
            max: 60,
            message: 'La nombre no puede tener más de 60 caracteres',
          },
        ]}
      >
        <Input placeholder="Nombre" />
      </Form.Item>
      <Form.Item
        name="marca"
        label="Marca"
        rules={[
          {
            max: 45,
            message: 'La marca no puede tener más de 45 caracteres',
          },
        ]}
      >
        <Input placeholder="Marca" />
      </Form.Item>
      <Form.Item
        name="modelo"
        label="Modelo"
        rules={[
          {
            max: 45,
            message: 'El modelo no puede tener más de 45 caracteres',
          },
        ]}
      >
        <Input placeholder="Modelo" />
      </Form.Item>
      <Form.Item
        name="capacidad"
        label="Capacidad"
        rules={[
          {
            max: 45,
            message: 'La capacidad no puede tener más de 45 caracteres',
          },
        ]}
      >
        <Input placeholder="Capacidad" />
      </Form.Item>
      <Form.Item
        name="pisos"
        label="Pisos"
        rules={[
          {
            max: 45,
            message: 'Los pisos no pueden tener más de 45 caracteres',
          },
        ]}
      >
        <Input placeholder="Pisos" />
      </Form.Item>
      {/* <Form.Item
        name="estado"
        label="Estado actual del ascensor"
        rules={[
          {
            max: 45,
            message: 'El estado no puede tener más de 45 caracteres',
          },
        ]}
      >
        <Input placeholder="Estado" />
      </Form.Item> */}
      <Form.Item
        name="numero_serie"
        label="Número de serie"
        rules={[
          {
            max: 135,
            message: 'El número de serie no puede tener más de 135 caracteres',
          },
        ]}
      >
        <Input placeholder="Número de serie" />
      </Form.Item>
      <Form.Item>
        <Button block type="primary" htmlType="submit">
          {buttonLabel || 'Enviar'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AscensorForm;
