import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Card,
  message,
  Divider,
  Row,
  Col,
  Modal,
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import PresupuestoCollectionDrawer from 'components/PresupuestoCollectionDrawer';
import PresupuestoItemsDrawer from 'components/PresupuestoItemsDrawer';
import PresupuestoClienteDrawer from 'components/PresupuestoClienteDrawer';
import { v4 as uuidv4 } from 'uuid';
import Title from 'antd/es/typography/Title';
import PresupuestoItemsTable from 'components/tables/PresupuestoItemsTable';
import { useDocument } from 'providers/DocumentProvider';
import { useParams } from 'react-router-dom';
import PresupuestoViewer from 'PDF/PresupuestoComposer/PresupuestoViewer';

const { TextArea } = Input;
const { Option } = Select;

const MODULE_KEYS = {
  CUSTOM: 'custom',
  COLLECTION: 'collection',
  ITEM_TABLE: 'itemTable',
  CUSTOMER_INFO: 'customerInfo',
};

const CompositorPresupuestos = () => {
  const { documentId } = useParams();
  const [form] = Form.useForm();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [itemTableDrawerVisible, setItemTableDrawerVisible] = useState(false);
  const [customerInfoDrawerVisible, setCustomerInfoDrawerVisible] =
    useState(false);
  const [currentFieldKey, setCurrentFieldKey] = useState(null);
  const [deletedItems, setDeletedItems] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const { get, create } = useDocument();

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const document = await get(documentId);
        if (document) {
          form.setFieldsValue({
            items: document.items.map((item) => ({
              ...item,
              id: uuidv4(),
            })),
          });
        }
      } catch (error) {
        message.error('Error al cargar el documento');
      }
    };

    if (documentId) {
      fetchDocument();
    }
  }, [documentId, get, form]);

  const handleTypeChange = (value, fieldKey) => {
    setCurrentFieldKey(fieldKey);
    if (value === MODULE_KEYS.COLLECTION) {
      setDrawerVisible(true);
    } else if (value === MODULE_KEYS.ITEM_TABLE) {
      setItemTableDrawerVisible(true);
    } else if (value === MODULE_KEYS.CUSTOMER_INFO) {
      setCustomerInfoDrawerVisible(true);
    }
  };

  const handleTemplateSelect = (template) => {
    if (currentFieldKey !== null) {
      form.setFieldsValue({
        items: form.getFieldValue('items').map((item, index) => {
          if (index === currentFieldKey) {
            return {
              ...item,
              titulo: template.titulo,
              description: template.texto,
            };
          }
          return item;
        }),
      });
      setDrawerVisible(false);
      setItemTableDrawerVisible(false);
      setCustomerInfoDrawerVisible(false);
    }
  };

  const handleSaveItems = (items) => {
    if (currentFieldKey !== null) {
      form.setFieldsValue({
        items: form.getFieldValue('items').map((item, index) => {
          if (index === currentFieldKey) {
            return {
              ...item,
              itemsTable: items,
            };
          }
          return item;
        }),
      });
      setItemTableDrawerVisible(false);
    }
  };

  const handleRemove = (name) => {
    const items = form.getFieldValue('items');
    const itemToRemove = items.find((_, index) => index === name);
    setDeletedItems([...deletedItems, itemToRemove]);
    form.setFieldsValue({
      items: items.filter((_, index) => index !== name),
    });
  };

  const onFinish = async (values) => {
    try {
      const payload = {
        ...values,
        deletedItems,
      };
      const result = await create(payload);
      message.success('Elementos agregados correctamente');
    } catch (error) {
      message.error('Error al agregar los elementos');
    }
  };

  const [items, setItems] = useState([]);
  const openPreview = () => {
    setPreviewVisible(true);
    setItems(form.getFieldValue('items'));
  };

  return (
    <div>
      <Modal
        title="Vista Previa"
        open={previewVisible}
        onCancel={() => setPreviewVisible(false)}
        width={1000}
      >
        <PresupuestoViewer items={items} />
      </Modal>
      <Card className="welcome mb-2">
        <div className="flex flex-col items-center md:flex-row md:justify-between">
          <Title level={3}>
            <span className="text-white">Compositor de Documentos</span>
          </Title>
          <Button type="primary" onClick={openPreview} icon={<EyeOutlined />}>
            Vista Previa
          </Button>
        </div>
      </Card>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.List
          name="items"
          initialValue={[
            {
              id: uuidv4(),
              type: MODULE_KEYS.CUSTOM,
              titulo_type: 'principal',
              titulo: '',
              description: '',
            },
          ]}
        >
          {(fields, { add, remove, move }) => (
            <>
              <div className="-mx-2 flex flex-col items-center">
                {fields.map(({ key, name, ...restField }, index) => {
                  const isSecondaryTitle =
                    form.getFieldValue(['items', key, 'titulo_type']) ===
                    'secundario';
                  const itemType = form.getFieldValue(['items', key, 'type']);
                  return (
                    <div key={key} className="w-full md:w-1/2 px-2 mb-4">
                      <Card className={isSecondaryTitle ? 'bg-gray-100' : ''}>
                        <Divider>Posición N°{index + 1}</Divider>
                        <Row gutter={16}>
                          <Col xs={24} md={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'type']}
                              label="Tipo de Elemento"
                              rules={[
                                {
                                  required: true,
                                  message: 'Seleccione un tipo de elemento',
                                },
                              ]}
                            >
                              <Select
                                placeholder="Seleccione el tipo de elemento"
                                onChange={(value) =>
                                  handleTypeChange(value, key)
                                }
                              >
                                <Option value={MODULE_KEYS.CUSTOM}>
                                  Personalizado
                                </Option>
                                <Option value={MODULE_KEYS.COLLECTION}>
                                  De una Colección
                                </Option>
                                <Option value={MODULE_KEYS.ITEM_TABLE}>
                                  Tabla de Ítems
                                </Option>
                                <Option value={MODULE_KEYS.CUSTOMER_INFO}>
                                  Información del Cliente
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'titulo_type']}
                              label="Tipo de Título"
                              rules={[
                                {
                                  required: true,
                                  message: 'Seleccione el tipo de título',
                                },
                              ]}
                            >
                              <Select placeholder="Seleccione el tipo de título">
                                <Option value="principal">Principal</Option>
                                <Option value="secundario">Secundario</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'titulo']}
                              label="Título"
                              rules={[
                                {
                                  required: itemType !== MODULE_KEYS.ITEM_TABLE,
                                  message: 'Por favor ingrese el título',
                                },
                              ]}
                            >
                              <Input placeholder="Título (Opcional)" />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'description']}
                              label="Contenido"
                              rules={[
                                {
                                  required: itemType !== MODULE_KEYS.ITEM_TABLE,
                                  message: 'Por favor ingrese el contenido',
                                },
                              ]}
                            >
                              <TextArea rows={4} placeholder="Descripción" />
                            </Form.Item>
                          </Col>
                        </Row>
                        {itemType === MODULE_KEYS.ITEM_TABLE && (
                          <PresupuestoItemsTable
                            items={
                              form.getFieldValue([
                                'items',
                                key,
                                'itemsTable',
                              ]) || []
                            }
                          />
                        )}
                        <div className="flex justify-between">
                          <Button
                            icon={<ArrowUpOutlined />}
                            disabled={index === 0}
                            type="primary"
                            onClick={() => move(index, index - 1)}
                          />
                          <Button
                            icon={<ArrowDownOutlined />}
                            type="primary"
                            disabled={index === fields.length - 1}
                            onClick={() => move(index, index + 1)}
                          />
                          <MinusCircleOutlined
                            className="mt-2 text-lg text-red-600"
                            onClick={() => handleRemove(name)}
                          />
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </div>
              <Form.Item>
                <Button
                  className="px-10"
                  onClick={() =>
                    add({
                      id: uuidv4(),
                      type: MODULE_KEYS.CUSTOM,
                      titulo_type: 'principal',
                      titulo: '',
                      description: '',
                    })
                  }
                  icon={<PlusOutlined />}
                  type="primary"
                >
                  Añadir Elemento
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Form>
      <PresupuestoCollectionDrawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        onSelectTemplate={handleTemplateSelect}
      />
      <PresupuestoItemsDrawer
        visible={itemTableDrawerVisible}
        onClose={() => setItemTableDrawerVisible(false)}
        onSave={handleSaveItems}
      />
      <PresupuestoClienteDrawer
        visible={customerInfoDrawerVisible}
        onClose={() => setCustomerInfoDrawerVisible(false)}
        onSelectTemplate={handleTemplateSelect}
      />
    </div>
  );
};

export default CompositorPresupuestos;
